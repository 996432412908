import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from '@shared/services/project.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Inventory } from '@domain/models/inventory.model';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { ProjectType } from '@core/enums/project-type.enum';
import { TranslateService } from '@node_modules/@ngx-translate/core';

@Component({
  selector: 'app-inventory-add',
  styleUrls: ['inventory-add.component.scss'],
  templateUrl: 'inventory-add.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class InventoryAddComponent implements OnInit, OnDestroy {
  private inventory = new Inventory({});
  private subscriptionProjectLoaded: Subscription;

  public form;
  public showErrors = false;
  public project = new Project({});
  public defaultInventories: SelectItem[] = [];
  public projectType = ProjectType;
  public projectTypes: { [key: string]: string };
  public parentList: SelectItem[];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();

    this.defaultInventories = await this.projectService.getDefaultInventories();

    this.parentList = await this.projectService.getCascadingInventories();

    this.initForm();

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public async initForm(): Promise<void> {
    const projectTypes = await BaseDataIndexedDbModel.query().get({ key: 'project-type' });
    const privateProjectType = projectTypes.values.find((type) => {
      return type.value === 'project-type-private';
    });

    this.projectTypes = projectTypes.values?.reduce((acc, current) => {
      return {
        ...acc,
        [current.value]: current.id,
      };
    }, {});

    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventory.id),
      name: this.formBuilder.control('', Validators.required),
      parent_id: this.formBuilder.control(''),
      building: this.formBuilder.control(
        this.project.type_base_data_value_id === privateProjectType.id ? 'huis' : '',
        Validators.required
      ),
      floor: this.formBuilder.control(
        this.project.type_base_data_value_id === privateProjectType.id ? 0 : '',
        Validators.required
      ),
      default_inventory_id: this.formBuilder.control('', Validators.required),
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      // Parenting & value fixing for it
      const parent_id = this.form.value.parent_id;

      if (parent_id) {
        this.form.value.parent = parent_id;
        this.form.value.parent_name = parent_id.name;
        this.form.value.parent_id = parent_id.id;
      }

      this.inventory = new Inventory({ ...this.form.value });
      this.inventory.project_id = this.project.id;
      this.inventory.default_inventory_id = this.form.get('default_inventory_id').value?.id;

      await this.saveSubcategories(this.inventory);
    } else {
      this.showErrors = true;
    }
  }

  public async saveSubcategories(inventory: Inventory): Promise<void> {
    const hasItems = inventory?.parent?.items?.some((inventory) => {
      return 'amount' in inventory;
    });

    if (inventory?.parent?.items?.length && hasItems) {
      this.confirmationService.confirm({
        message: this.translateService.instant('movers_complete.inventory.board.link-inventory.message'),
        header: this.translateService.instant('movers_complete.confirmation.confirm.label'),
        icon: 'fa fa-question-circle',
        acceptLabel: this.translateService.instant('general.yes'),
        rejectLabel: this.translateService.instant('general.no'),
        accept: async (_) => {
          await this.saveInventory(inventory);
        },
      });
    } else {
      await this.saveInventory(inventory);
    }
  }

  private async saveInventory(inventory: Inventory): Promise<void> {
    await this.projectService.saveNewInventory(inventory);

    this.onCloseClick();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory');
  }
}
