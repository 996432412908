import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { SelectItem } from 'primeng/api';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { MachineType } from '@domain/models/machine-type.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Tenant } from '@domain/models/tenant.model';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Subject } from '@node_modules/rxjs';

@Component({
  selector: 'app-options-detail-item',
  templateUrl: './options-detail-item.component.html',
  styleUrls: ['./options-detail-item.component.scss'],
})
export class OptionsDetailItemComponent implements OnDestroy {
  @Input() disabled: boolean;
  @Input() activity: ProjectActivity;
  @Input() applicable: boolean;
  @Input() showButton: boolean;
  @Input() allowDelete: boolean;
  @Input() addressOptions: SelectItem[];

  @Output() activityClick = new EventEmitter();
  @Output() onRemoveActivityClick = new EventEmitter();

  public localeNL: CalendarLocale = new CalendarLocale();
  public machines: any[] = [];
  public isVanRooyen: boolean;
  public dataLoadedMachines: boolean;
  public currentDate: Date = new Date();

  private tenant: Tenant;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private synchronisationService: SynchronisationService) {
    /** ToDo: Make dynamic and tenantable */
    this.synchronisationService.myTenant$.pipe(takeUntil(this.destroy$)).subscribe((tenant) => {
      this.tenant = tenant;

      this.isVanRooyen = this.tenant && (this.tenant.key === 'pavanrooyen' || this.tenant.key === 'pavanrooyen-mbo');
    });

    this.dataLoadedMachines = false;

    if (this.isVanRooyen === true) {
      this.setMachines();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAddActivityClick(type: string): void {
    if (!this.disabled) {
      this.activityClick.emit(type);
    }
  }

  public removeActivityClick(activity: ProjectActivity): void {
    if (!this.disabled) {
      this.onRemoveActivityClick.emit(activity);
    }
  }

  public onSelectMachine(machine): void {
    if (!this.disabled) {
      this.activity.number_trucks = machine !== null ? 1 : 0;
    }
  }

  private async setMachines(): Promise<void> {
    if (!this.disabled) {
      await MachineType.query.each((machineType) => {
        return this.machines.push({
          label: machineType.name,
          value: machineType.name,
        });
      });
      this.dataLoadedMachines = true;
    }
  }
}
