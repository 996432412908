import { DomainModel } from '@domain/domain.model';
import { Role } from '@domain/models/role.model';

export class User extends DomainModel {
  // Configuration
  public entity = 'user';
  public table = 'users';
  public sync = false;

  // Fields
  public id: number;
  public current_role_id: number;
  public name: string;
  public name_lcase: string;
  public email: string;
  public password?: string;
  public password_confirmation?: string;
  public signature_image?: string;
  public is_accountmanager = false;
  public is_executor = false;
  public locale_id: string;

  public role?: Role;
  public groups?: any;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
