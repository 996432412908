import { Component, AfterViewInit, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { ValidatorService } from '@capturum/ui/api';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-blank',
  templateUrl: './loginLayout.component.html',
  styleUrls: ['./loginLayout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginLayoutComponent implements AfterViewInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private synchronisationService: SynchronisationService,
    private renderer: Renderer2,
    private validatorService: ValidatorService
  ) {
    this.validatorService.setValidationMessages({
      equalsField: 'auth.reset-password.mismatch.message',
    });
  }

  public ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'login-bg');

    this.synchronisationService
      .getTenantCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const bodyElement = document.querySelector('body.login-bg');

        this.renderer.setStyle(bodyElement, 'background-image', `url('/assets/default/images/login_bg2.png')`);
      });
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'login-bg');
    this.renderer.removeStyle(document.body, 'background-image');
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
