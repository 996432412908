import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared/shared.module';
import { SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { ReportOverviewComponent } from '@features/report/report-overview.component';
import { PowerBIComponentComponent } from './power-bi/power-bi.component';
import { service as PBIService, factories } from 'powerbi-client';

export function powerBiServiceFactory() {
  return new PBIService.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
}

@NgModule({
  declarations: [ReportOverviewComponent, PowerBIComponentComponent],
  imports: [BrowserModule, PrimeNGSharedModule, SharedModule, RouterModule],
  providers: [
    {
      provide: 'PowerBIService',
      useFactory: powerBiServiceFactory,
    },
  ],
  exports: [ReportOverviewComponent],
})
export class ReportModule {}
