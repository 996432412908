<app-manage-layout>
  <div class="ibox-title">
    <h5>Tenants</h5>
  </div>

  <div class="ibox-content">
    <em-online-table [options]="tableOptions" (rowClicked)="onRowClick($event)"> </em-online-table>
  </div>

  <div class="ibox-footer">
    <button type="button" class="btn btn-primary" (click)="addNewItem()"><i class="fa fa-plus"></i> Toevoegen</button>
  </div>
</app-manage-layout>
