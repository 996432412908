/**
 * Class used for defining the language type of primeNG calendar component
 */
export class CalendarLocale {
  // Fields
  public firstDayOfWeek = 0;
  public dayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  public dayNamesShort = ['zo', 'ma', 'di', 'wo', 'do', 'vrij', 'za'];
  public dayNamesMin = ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'];
  public monthNames = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ];

  public monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  public today = 'Vandaag';
  public clear = 'Selectie wissen';
}
