import { NgModule } from '@angular/core';
import { ApiModule } from '@capturum/api';
import { environment } from '@environments/environment';
import { AuthModule } from '@capturum/auth';
import { CapturumHttpCodeInterceptor, CompleteModule, TranslationLoader } from '@capturum/complete';
import { CapturumMissingTranslationHandler } from '@capturum/shared';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImageViewComponent } from './builders/list-widgets/image-view/image-view.component';
import { ImagesInputTypeComponent } from '@core/builders/input-types/images-input-type/images-input-type.component';
import { SharedModule } from '@shared/shared.module';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { CapturumImageViewerModule } from '@capturum/ui/image-viewer';
import { CommonModule } from '@angular/common';

function onAuthError(): void {
  window.location.href = `/login`;
}

@NgModule({
  imports: [
    NgxPermissionsModule.forRoot(),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: '/login',
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CapturumMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CompleteModule.forRoot(
      {
        indexedDbModels: [],
        databaseName: 'moverscomplete-db',
        version: 1,
        environment: environment,
      },
      NgxPermissionsService,
      NgxPermissionsModule
    ),
    SharedModule,
    CapturumFileUploadModule,
    CapturumFilePreviewListModule,
    CapturumImageViewerModule,
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapturumHttpCodeInterceptor,
      multi: true,
    },
  ],
  declarations: [ImageViewComponent, ImagesInputTypeComponent],
})
export class CoreModule {}
