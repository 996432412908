<div class="row">
  <div *ngIf="item" class="col-sm-4">
    <cap-input
      type="text"
      [placeholder]="placeholderName"
      [(ngModel)]="item.details"
      [attr.value]="item.details"
      [disabled]="disabled"
      [readonly]="disabled">
    </cap-input>
  </div>

  <div *ngIf="item?.start" class="col-sm-4">
    <cap-dropdown
      [sortAlphabetically]="false"
      [options]="times"
      placeholder="Tijdstip"
      [(ngModel)]="item.start"
      [disabled]="disabled"
      [readonly]="disabled"
      [showClear]="true">
    </cap-dropdown>
  </div>

  <div *ngIf="item?.end" class="col-sm-4 d-flex">
    <cap-dropdown
      [sortAlphabetically]="false"
      [options]="times"
      class="flex-grow-1 mr-1"
      placeholder="Tijdstip"
      [(ngModel)]="item.end"
      [disabled]="disabled"
      [readonly]="disabled"
      [showClear]="true">
    </cap-dropdown>

    <span class="input-group-btn" *ngIf="!disabled">
      <a class="btn btn-danger" (click)="onDeleteClick()">
        <i class="fa fa-times"></i>
      </a>
    </span>
  </div>
</div>
