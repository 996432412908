import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { Machine } from '@domain/models/machine.model';
import { Signature } from '@domain/models/signature.model';
import { SignatureService } from '@shared/services/signature.service';

import { takeUntil } from '@node_modules/rxjs/operators';
import { getAddressDisplayName } from '@core/utils/address-display-name.utils';
import { WorkAssignmentItemType } from '@core/enums/work-assignment-item-type.enum';
import { AddressWorkAssignmentType } from '@core/enums/address-work-assignment-type.enum';
import { getMappedBaseDataByKey } from '@core/utils/base-data.utils';

@Component({
  selector: 'app-inventory-work-assignment-detail',
  templateUrl: 'work-assignment-detail.component.html',
  styleUrls: ['./work-assignment-detail.component.scss'],
})
export class InventoryWorkAssignmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature', { static: false })
  clientSignature: SignatureComponent;

  @ViewChild('teamleaderSignature', { static: false })
  teamleaderSignature: SignatureComponent;

  public project: Project;
  public workAssignment: WorkAssignment;
  public addressOptionsPickup: Array<{ label: string; value: string }> = [];
  public addressOptionsDelivery: Array<{ label: string; value: string }> = [];
  public times = [];
  public localeNL: CalendarLocale = new CalendarLocale();
  public signatures: Signature[] = [];
  public machines: any[] = [];
  public subscriptionProjectLoaded: Subscription;
  public addresses: Address[];
  public disabled = false;
  public readyToView = false;
  public workAssignmentItemTypes: { [key: string]: string };
  public workAssignmentItemType = WorkAssignmentItemType;
  public addressWorkAssignmentTypes: { [key: string]: string };
  public addressWorkAssignmentType = AddressWorkAssignmentType;

  private tenantCode: string;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private route: ActivatedRoute,
    private synchronisationService: SynchronisationService,
    private projectService: ProjectService,
    private signatureService: SignatureService
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      if (this.project) {
        this.disabled = readOnly;
      }
    });

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;

      this.addresses = this.project.addresses;
      this.orderAddresses();
      this.initialize();
    });
  }

  public async ngOnInit(): Promise<void> {
    this.workAssignmentItemTypes = await getMappedBaseDataByKey('work-assignment-item-type');
    this.addressWorkAssignmentTypes = await getMappedBaseDataByKey('address-work-assignment-type');

    this.project = await this.projectService.getProject();

    this.addresses = this.project.addresses;
    this.orderAddresses();
    this.workAssignment = new WorkAssignment({});

    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }

    this.initialize();

    this.synchronisationService
      .getTenantCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantCode) => {
        return (this.tenantCode = tenantCode);
      });

    // Get id of address to edit by route params
    this.route.params.subscribe(async (params) => {
      const result = await this.projectService.getWorkAssignment(params['id']);

      if (result) {
        this.workAssignment = result;
      }

      this.readyToView = true;
      this.signatures = await Signature.query.where('work_assignment_id').equals(params['id']).toArray();
    });

    await this.setMachines();
  }

  public async ngOnDestroy(): Promise<void> {
    this.signatureService.addSignatures(this.signatures).subscribe();

    // Cleanup address items without selected address
    this.workAssignment.address_work_assignments = this.workAssignment.address_work_assignments.filter(
      (value: WorkAssignmentAddress) => {
        return value.address_id !== null;
      }
    );

    await this.projectService.saveWorkAssignment(this.workAssignment);

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAddPersonClick(): void {
    this.addWorkAssignmentItem(WorkAssignmentItemType.person);
  }

  public onAddCarClick(): void {
    this.addWorkAssignmentItem(WorkAssignmentItemType.car);
  }

  public onAddAddressClick(type: string): void {
    const newWorkAssignmentAddress = new WorkAssignmentAddress({
      work_assignment_id: this.workAssignment.id,
      type_base_data_value_id: this.addressWorkAssignmentTypes[AddressWorkAssignmentType[type]],
      _new: true,
      address_id: null,
    });

    newWorkAssignmentAddress.setDefaults();
    this.workAssignment.address_work_assignments.push(newWorkAssignmentAddress);
  }

  public onDeleteItemClick(item: WorkAssignmentItem): void {
    if (!this.disabled) {
      this.removeWorkAssignmentItem(item);
    }
  }

  public showClientSignatureForm(): void {
    if (!this.disabled) {
      this.clientSignature.showForm();
    }
  }

  public showTeamleaderSignatureForm(): void {
    if (!this.disabled) {
      this.teamleaderSignature.showForm();
    }
  }

  public showSignatureForm(reference: SignatureComponent): void {
    if (!this.disabled) {
      reference.showForm();
    }
  }

  public removeSignature(id: string): void {
    if (!this.disabled) {
      this.signatureService.removeSignature(id).subscribe((_) => {
        this.signatures = this.signatures.filter((signature) => {
          return signature.id !== id;
        });
      });
    }
  }

  public async onDeleteAddressClick(address: any): Promise<void> {
    if (!this.disabled) {
      const index = this.workAssignment.address_work_assignments.indexOf(address);

      if (index === -1) {
        return;
      }

      this.workAssignment.address_work_assignments.splice(index, 1);
      await this.projectService.deleteWorkAssignmentAddress(address);
    }
  }

  /**
   * Print the work-assignment overview
   */
  public printOverview(): void {
    const divToPrint = document.getElementById('work-assignment');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1100,height=768');

    popupWin.document.open();
    popupWin.document.write(
      '<html>' +
        ' <head>' +
        head.innerHTML +
        '</head>' +
        ' <body>' +
        '  <img src="/assets/' +
        this.tenantCode +
        '/images/logo.png" style="width: 175px;"><br /><br />' +
        '   ' +
        divToPrint.innerHTML +
        '' +
        ' </body>' +
        '</html>'
    );

    // Make address dropdowns full width, as the button is removed in printing
    const viewChildDropdownElementsAddress = popupWin.document.getElementsByClassName('dropdowns');

    for (let i = 0; i < viewChildDropdownElementsAddress.length; i++) {
      viewChildDropdownElementsAddress[i].classList.remove('col-sm-10');
      viewChildDropdownElementsAddress[i].classList.add('col-sm-12');
    }

    // Timeout to make sure the window is loaded
    setTimeout(() => {
      return popupWin.print();
    }, 300);
    popupWin.document.close();
  }

  /**
   * Returns the address in one complete string
   *
   * @param address: Address
   * @return string
   */
  public getDisplayAddress(address: Address): string {
    const addressParts = [
      `${address.street || ''} ${address.housenumber || ''} ${
        address.housenumber_add ? '- ' + address.housenumber_add : ''
      }`.trim(),
      `${address.zipcode || ''} ${address.city || ''}`.trim(),
      `${address.country}`.trim(),
    ];

    return addressParts.reduce((prev, current) => {
      return (prev && current ? prev + ', ' : prev) + current;
    });
  }

  public hasMachines(): boolean {
    return this.machines.length > 0;
  }

  public addSignature(): void {
    if (!this.disabled) {
      this.signatures.push(new Signature({ work_assignment_id: this.workAssignment.id }));
    }
  }

  private async setMachines(): Promise<void> {
    await Machine.query.each((machine) => {
      return this.machines.push({ label: machine.name, value: machine.name });
    });
  }

  private addWorkAssignmentItem(type: string): void {
    const item = new WorkAssignmentItem({
      work_assignment_id: this.workAssignment.id,
      type_base_data_value_id: this.workAssignmentItemTypes[type],
    });

    item.setDefaults();
    this.workAssignment.items.push(item);
  }

  private async removeWorkAssignmentItem(item: WorkAssignmentItem): Promise<void> {
    if (!this.disabled) {
      const index = this.workAssignment.items.indexOf(item);

      if (index === -1) {
        return;
      }

      this.workAssignment.items.splice(index, 1);
      await this.projectService.deleteWorkAssignmentItem(item);
    }
  }

  private orderAddresses(): void {
    this.addresses.sort((a: Address, b: Address) => {
      if (a.index === null) {
        return 0;
      } else if (b.index === null) {
        return 1;
      }

      return a.index.toString().localeCompare(b.index.toString());
    });
  }

  private initialize() {
    // Add address options
    this.addressOptionsPickup = [];
    this.addressOptionsDelivery = [];
    for (const address of this.addresses) {
      this.addressOptionsPickup.push({
        label: getAddressDisplayName(address),
        value: address.id,
      });
      this.addressOptionsDelivery.push({
        label: getAddressDisplayName(address),
        value: address.id,
      });
    }
  }
}
