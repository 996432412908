import { Component } from '@angular/core';
import { ManageOptionsDetailComponent } from '../options-detail/options-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
})
export class ManageOptionsListComponent {
  public listKey = 'list_options';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageOptionsDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.option.single'),
    });
  }
}
