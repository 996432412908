import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { EmailQuotationAttachmentsDetailComponent } from '@features/manage/components/email-quotation-attachments/email-quotation-attachments-detail/email-quotation-attachments-detail.component';
@Component({
  selector: 'app-email-quotation-attachments-list',
  templateUrl: './email-quotation-attachments-list.component.html',
  styleUrls: ['./email-quotation-attachments-list.component.scss'],
})
export class EmailQuotationAttachmentsListComponent {
  public listKey = 'list_quotation_attachments';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(EmailQuotationAttachmentsDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.email-quotation-attachments.single'),
    });
  }
}
