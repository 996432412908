import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PublicTranslationService } from '@capturum/complete';
import { DestroyBase } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, first, map, switchMap } from 'rxjs';
import { LoginConfigService } from '@capturum/login';

@Injectable({
  providedIn: 'root',
})
export class InventoryPublicTranslationResolver extends DestroyBase {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly publicTranslateService: PublicTranslationService,
    private readonly configService: LoginConfigService
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.setPublicTranslations().pipe(map(Boolean));
  }

  private setPublicTranslations(): Observable<any> {
    return combineLatest([
      this.publicTranslateService.loadTranslations(),
      this.httpClient.get('assets/i18n/public-translation.json'),
      this.configService.getConfig(),
    ]).pipe(
      switchMap(([publicTranslations, localTranslations, config]) => {
        return this.translateService.reloadLang(config.defaultLanguage).pipe(
          first(),
          switchMap(() => {
            Object.keys(localTranslations).forEach((language) => {
              this.translateService.setTranslation(language, localTranslations[language], true);
            });

            return this.translateService.use(config.defaultLanguage);
          })
        );
      })
    );
  }
}
