<div class="row">
  <div class="col-sm-7">
    <cap-dropdown
      [options]="machines"
      placeholder="Naam"
      [(ngModel)]="item.details"
      [disabled]="disabled"
      [readonly]="disabled"
      [showClear]="true">
    </cap-dropdown>
  </div>

  <div class="col-sm-2">
    <cap-dropdown
      [options]="times"
      placeholder="Tijdstip"
      [(ngModel)]="item.start"
      [disabled]="disabled"
      [readonly]="disabled"
      [showClear]="true">
    </cap-dropdown>
  </div>

  <div class="col-sm-2">
    <cap-dropdown
      [options]="times"
      placeholder="Tijdstip"
      [(ngModel)]="item.end"
      [disabled]="disabled"
      [readonly]="disabled"
      [showClear]="true">
    </cap-dropdown>
  </div>

  <div class="col-sm-1 deleteButton">
    <span class="input-group-btn" *ngIf="!disabled">
      <a class="btn btn-danger" (click)="onDeleteClick()">
        <i class="fa fa-times"></i>
      </a>
    </span>
  </div>
</div>
