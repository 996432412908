<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="ibox-title">
          <h5>Details</h5>
        </div>
        <form [formGroup]="form" class="form-horizontal">
          <div class="ibox-content">
            <div
              class="form-group"
              [ngClass]="{ 'has-error': errors.name || (showErrors && !this.form.controls['reference_nr'].valid) }">
              <label class="col-sm-3 control-label">Referentie</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Referentie nummer"
                  formControlName="reference_nr"
                  name="name" />
                <span class="help-block m-b-none" *ngIf="errors.reference_nr">{{ errors.name[0] }}</span>
                <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['reference_nr'].valid"
                  >Vul referentie in</span
                >
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Synchronisatie status</label>
              <div class="col-sm-9">
                <span class="fa-stack fa-lg">
                  <i
                    *ngIf="!project.is_new && !project.is_changed"
                    class="fa fa-check fa-stack-1x"
                    style="color: green"
                    title="Gesynchroniseerd"></i>
                  <i
                    *ngIf="!project.is_new && project.is_changed"
                    class="fa fa-times fa-stack-1x"
                    style="color: orange"
                    title="Gewijzigd, niet gesynchroniseerd"></i>
                  <i
                    *ngIf="project.is_new"
                    class="fa fa-times fa-stack-1x"
                    style="color: red"
                    title="Nieuw, niet gesynchroniseerd"></i>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
