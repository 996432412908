import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environments/environment';
import { AppModule } from './app/app.module';
import { init } from '@sentry/angular-ivy';

init({
  dsn: environment.sentryUrl,
  environment: environment.name,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(success => console.log(`Bootstrap success`)).catch(err => console.error(err));;
