import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';

@Injectable()
export class ContactGuard {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryContactListComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    return true;
  }
}
