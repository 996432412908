import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Quotation } from '@domain/models/quotation.model';
import { Project } from '@domain/models/project.model';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { environment } from '@environments/environment';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { UserService } from '@shared/services/user.service';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { TenantConfigQuotationCompanyDetails } from '@domain/models/tenant-config-quotation-company-details.model';
import { TenantConfigQuotationPaymentDetails } from '@domain/models/tenant-config-quotation-payment-details.model';
import { SelectItem } from 'primeng/api';
import { Tenant } from '@domain/models/tenant.model';
import { filter, takeUntil } from '@node_modules/rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { getBaseDataByKey, getMappedBaseDataByKey } from '@core/utils/base-data.utils';
import { BaseDataValueApi } from '@node_modules/@capturum/complete';
import { QuotationStatus } from '@core/enums/quotation-status.enum';
import { ProjectType } from '@core/enums/project-type.enum';
import { AddressType } from '@core/enums/address-type.enum';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { first, from, switchMap } from '@node_modules/rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { AdditionalCostQuotationComponent } from '@features/inventory/additional-cost-quotation/additional-cost-quotation.component';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SettingService } from '@shared/services/setting.service';
import { QuotationCheck } from '@domain/models/quotation-check.model';
import { QuotationSignService } from '@shared/services/quotation-sign.service';
import { InventoryService } from '@core/services/inventory.service';
import { ProjectStatus } from '@core/enums/project-status.enum';

interface InventoryTableItem {
  name: string;
  totalAmountOfItems: number;
  totalPrice: number;
  totalPriceWithVAT: number;
}

@Component({
  selector: 'app-inventory-quotation',
  templateUrl: 'quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryQuotationComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature', { static: false }) clientSignature: SignatureComponent;
  @ViewChild('valuatorSignature', { static: false }) valuatorSignature: SignatureComponent;
  @ViewChild('listRenderer')
  public listRenderer: CapturumListRendererComponent;

  public project: Project;
  public client: Client;
  public quotation: Quotation;
  public pickupAddress: Address;
  public deliverAddress: Address;
  public disabled = true;
  public project_id: string;
  public workAssignments: WorkAssignment[];
  public workAssignmentAddresses: any;
  public calculatingBasePrice: boolean;
  public environment: object;
  public localeNL: CalendarLocale = new CalendarLocale();
  public onlineMode = true;
  public showPricesExclVat = false;
  public currentUserSign: string = null;
  public totalOptionsPrice = 0;
  public totalOptionsVatPrice = 0;
  public quotationTemplatesList: SelectItem[] = [];
  public sentToExact = false;
  public tenant: Tenant;
  public quotationCompanyDetails: TenantConfigQuotationCompanyDetails;
  public quotationPaymentDetails: TenantConfigQuotationPaymentDetails;
  public quotationCompanyDetailsKeys: Array<Object>;
  public quotationPaymentDetailsKeys: Array<Object>;
  public quotationStatuses: BaseDataValueApi[];
  public addressTypes: BaseDataValueApi[];
  public movingCostsOptions = [];
  public logo = '';
  public hasBulkPrices: boolean = false;
  public showAddressesSeparately: boolean = false;
  public applicableSpecialties: ProjectSpecialty[] = [];
  public inventories: InventoryTableItem[] = [];
  public newInventories = [];
  public contacts: Contact[] = [];
  public totalVAT = 0;
  public subtotalPrice = 0;
  public totalsVat = [];
  public quotationChecks: QuotationCheck[] = [];

  private projectTypes: Record<string, string>;

  private addresses: Address[];
  private mainAddress: Address;
  private quotationSent: boolean;
  private subscriptionProjectLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  public paymentForm: FormGroup;
  public projectStatusLabel: string;
  public newStatusId: string;
  public pendingStatusId: string;
  public bookedStatusId: string;

  constructor(
    private synchronisationService: SynchronisationService,
    private projectService: ProjectService,
    private userService: UserService,
    private dataService: DataService,
    private api: ApiServiceWithLoaderService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translateService: TranslateService,
    private settingService: SettingService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private builderActionService: CapturumBuilderActionService,
    private quotationService: QuotationSignService,
    private inventoryService: InventoryService
  ) {
    this.paymentForm = new FormGroup({
      invoice_attn: new FormControl('', [Validators.email]),
      invoice_name: new FormControl(''),
    });
  }

  public async ngOnInit(): Promise<void> {
    this.paymentForm.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(() => {
      if (
        (this.paymentForm.value.invoice_attn !== '' &&
          (this.paymentForm.value.invoice_name === '' || this.paymentForm.value.invoice_name === null)) ||
        ((this.paymentForm.value.invoice_attn === '' || this.paymentForm.value.invoice_attn === null) &&
          this.paymentForm.value.invoice_name !== '')
      ) {
        this.toastService.warning(
          this.translateService.instant('movers_complete.quotation.warning.label'),
          this.translateService.instant('movers_complete.quotation.both_required.warning.text')
        );
      }

      if (!this.paymentForm.controls['invoice_attn'].valid) {
        this.toastService.warning(
          this.translateService.instant('movers_complete.quotation.warning.label'),
          this.translateService.instant('movers_complete.quotation.email_not_valid.warning.text')
        );
      }

      this.quotation.invoice_attn = this.paymentForm.value.invoice_attn;
      this.quotation.invoice_name = this.paymentForm.value.invoice_name;
    });

    try {
      this.projectTypes = await getMappedBaseDataByKey('project-type');

      if (this.projectTypes) {
        this.movingCostsOptions = [
          {
            label: this.translateService.instant('movers_complete.project.quotation.show-incl.label'),
            value: this.projectTypes[ProjectType.private],
            type: ProjectType.private,
          },
          {
            label: this.translateService.instant('movers_complete.project.quotation.show-excl.label'),
            value: this.projectTypes[ProjectType.business],
            type: ProjectType.business,
          },
        ];
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

    try {
      const data = await getBaseDataByKey('quotation-status');

      if (data) {
        this.quotationStatuses = data.values;
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

    try {
      const data = await getBaseDataByKey('address-type');

      if (data) {
        this.addressTypes = data.values;
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

    this.onlineMode = navigator.onLine;
    this.logo = this.settingService.getValueString('movers_complete.quotation_logo');
    this.hasBulkPrices = this.settingService.getValue('movers_complete.has_bulk_prices');
    this.showAddressesSeparately = this.settingService.getValue(
      'movers_complete.show_loading_and_unloading_addresses_separately'
    );

    this.synchronisationService.myTenant$?.pipe(takeUntil(this.destroy$)).subscribe((tenant) => {
      this.tenant = tenant;

      // ToDo: Refactor and replace for settings
      if (this.tenant.key !== 'harrievanerp') {
        this.quotationTemplatesList = [
          {
            label: 'Standaard',
            value: 'standard',
          },
        ];
      } else {
        this.quotationTemplatesList = [
          {
            label: 'Standaard',
            value: 'standard',
          },
          {
            label: 'Nacalculatie',
            value: 'recalculation',
          },
          {
            label: 'Opslag',
            value: 'storage',
          },
        ];
      }
    });

    this.quotationCompanyDetails = this.synchronisationService.getTenantConfigQuotation('company_details');
    if (this.quotationCompanyDetails) {
      this.quotationCompanyDetailsKeys = Object.keys(this.quotationCompanyDetails).map((key) => {
        return { key: key };
      });
    }

    this.quotationPaymentDetails = this.synchronisationService.getTenantConfigQuotation('payment_details');
    if (this.quotationPaymentDetails) {
      this.quotationPaymentDetailsKeys = Object.keys(this.quotationPaymentDetails).map((key) => {
        return { key: key };
      });
    }

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;

      if (this.disabled) {
        this.paymentForm.disable({ emitEvent: false });
      }
    });

    this.calculatingBasePrice = false;
    this.quotation = new Quotation({});
    this.client = new Client({});
    this.project = new Project({});
    this.environment = environment;
    this.addresses = [];
    this.contacts = [];
    this.quotationSent = false;

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;

      this.paymentForm.patchValue(project.quotation, { emitEvent: false });

      if (this.projectTypes) {
        this.setVatChoice(this.project.type_base_data_value_id);
      }

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.totalOptionsPrice = this.getTotalOptionsPrice();
      this.totalOptionsVatPrice = this.getTotalOptionsVatPrice();

      // Make sure the related properties are present for activities, before sorting them in the view
      if (this.project.activities && this.project.activities.length > 0) {
        for (const activity of this.project.activities) {
          if (activity) {
            activity.init();
          }
        }
      }

      this.addresses = this.project.addresses;
      this.applicableSpecialties = this.project.specialties.filter((s) => {
        return s.applicable;
      });
      this.inventories = this.prepareInventoriesForTable();

      // Determine main address and pickup/deliver address

      if (this.addresses.length > 0) {
        for (const address of this.addresses) {
          const addressType = this.addressTypes.find((addressType) => {
            return addressType.id === address.address_type?.type_base_data_value_id;
          })?.value;

          switch (addressType) {
            case AddressType.main:
              this.mainAddress = address;
              break;
            case AddressType.delivery:
              this.deliverAddress = address;
              break;
            case AddressType.pickup:
              this.pickupAddress = address;
              break;
            default:
              break;
          }
        }
      }

      this.quotation =
        this.project.quotation ?? new Quotation({ project_id: this.project.id, estimated_distance_km: null });
      this.updateTotalStorageValue(this.quotation.storage_value_price);

      this.quotation.coordinator_price = null;

      this.fillInvoiceContact();
      this.retrieveActivityAddresses();
      this.initForm();
      this.projectService.setCurrentClient(this.project.client);
    });

    this.fillInvoiceContact();
    this.initForm();

    this.currentUserSign = await this.userService.getUserSign();

    // Reload project
    this.project = await this.projectService.getProject();

    if (this.project.id) {
      await this.projectService.loadProject(this.project.id);
    }

    this.inventoryService.getInventoriesByProjectId(this.project.id).subscribe((inventories) => {
      this.newInventories = inventories;
    });

    try {
      this.projectStatusLabel = await this.getProjectStatus();
    } catch (error) {
      console.error('An error occurred:', error);
    }

    try {
      this.quotationChecks = await QuotationCheck.query
        .where('project_type_base_data_value_id')
        .equals(this.project.type_base_data_value_id)
        .toArray();

      this.quotationChecks = this.quotationChecks.filter(({ deleted_at }) => {
        const projectCreatedAt = new Date(this.project.created_at);

        return !deleted_at || new Date(deleted_at) > projectCreatedAt;
      });

      this.quotationChecks.map((check) => {
        check.is_selected = false;
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }

    this.quotationService
      .getQuotationChecks(this.quotation.id, {
        include: ['quotationChecks'],
      })
      .subscribe((val) => {
        if (val?.data?.quotationChecks.length > 0) {
          val.data.quotationChecks.map((check) => {
            const validQuotationIndex = this.quotationChecks.findIndex((quotationCheck) => {
              return quotationCheck.id === check.id;
            });

            this.quotationChecks[validQuotationIndex].is_selected = true;
          });
        }
      });

    try {
      this.contacts = await Contact.query.where('client_id').equals(this.project.client_id).toArray();
    } catch (error) {
      console.error('An error occurred:', error);
    }

    if (this.project.type_base_data_value_id === ProjectType.private) {
      this.quotation.guarantee_certificate_price = this.quotation.guarantee_certificate_price || 0;
    } else {
      this.quotation.guarantee_certificate_price = 0;
    }

    this.route.params.subscribe(async (params: any) => {
      const result = await this.projectService.getAllWorkAssignments(params['project']);

      if (result) {
        this.workAssignments = result;
        await this.initWorkAssignmentAddresses();
      }
    });

    if (this.project?.is_new) {
      from(this.synchronisationService.getSyncJson(this.project, true))
        .pipe(
          switchMap((syncJson) => {
            return this.api.post('/sync/post', [syncJson]);
          })
        )
        .subscribe(() => {});
    }
  }

  public prepareInventoriesForTable(): InventoryTableItem[] {
    return this.project.inventories
      ?.map((inventory) => {
        const name = inventory.cascading_name;

        const totalAmountOfItems = inventory.items?.reduce((acc, current) => {
          return acc + (current?.amount ?? 0);
        }, 0);

        const totalPriceWithVAT = inventory.items?.reduce((acc, current) => {
          return acc + (current?.amount ?? 0) * (current?.price ?? current?.price ?? 0) * 1.21;
        }, 0);

        const totalPrice = inventory.items?.reduce((acc, current) => {
          return acc + (current?.amount ?? 0) * (current?.price ?? current?.price ?? 0);
        }, 0);

        return {
          name,
          totalAmountOfItems,
          totalPrice,
          totalPriceWithVAT,
        };
      })
      .filter((inventory) => {
        return inventory.totalAmountOfItems > 0;
      });
  }

  public async getProjectStatus(): Promise<string> {
    const statusList = await this.projectService.getFullStatusList();

    this.newStatusId = statusList.find((item: any) => {
      return item.key === ProjectStatus.new;
    }).value;

    this.pendingStatusId = statusList.find((item: any) => {
      return item.key === ProjectStatus.pending;
    }).value;

    this.bookedStatusId = statusList.find((item: any) => {
      return item.key === ProjectStatus.booked;
    }).value;

    const result = statusList.find((item) => {
      return item.value === this.project.status;
    });

    return result ? result.label : '';
  }

  public openAdditionalCostForm(): void {
    if (!this.disabled) {
      const dialogRef = this.dialogService.open(AdditionalCostQuotationComponent, {
        header: this.translateService.instant('movers_complete.project.quotation.free-to-fill-in.label'),
        data: {
          project_id: this.project.id,
          showPricesExclVat: this.showPricesExclVat,
        },
      });

      dialogRef.onClose.pipe(first()).subscribe((inventoryItem) => {
        this.listRenderer.refresh();
      });
    }
  }

  public listRendererDataChange(): void {
    this.calculateTotals();
    this.addPopupEventListener();
  }

  public addPopupEventListener(): void {
    this.listRenderer.clickAction = {
      key: 'row_click',
      options: {
        component: AdditionalCostQuotationComponent,
        project_id: this.project.id,
        showPricesExclVat: this.showPricesExclVat,
      },
      type: 'open_popup',
    };

    this.builderActionService
      .getActionExecutions()
      .pipe(
        takeUntil(this.destroy$),
        filter((a) => {
          return a !== undefined && ['submit', 'delete'].includes(a.action.key);
        })
      )
      .subscribe(() => {
        this.listRenderer.refresh();
      });
  }

  public calculateTotals(): void {
    this.subtotalPrice = 0;
    this.totalVAT = 0;

    this.applicableSpecialties.map((specialty) => {
      this.subtotalPrice = this.subtotalPrice + specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0);
      this.totalVAT =
        this.totalVAT +
        (specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0) * (1 + specialty.specialty.vat_rate / 100) -
          specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0));
    });

    this.inventories.map((inventory) => {
      this.subtotalPrice = this.subtotalPrice + inventory.totalPrice;
      this.totalVAT = this.totalVAT + (inventory.totalPriceWithVAT - inventory.totalPrice);
    });

    this.listRenderer?.data.forEach((dataRow) => {
      this.totalsVat.push({
        amount:
          Number(dataRow.priceInclVatWithCurrency.replace('€ ', '')) -
          Number(dataRow.priceExclVatWithCurrency.replace('€ ', '')),
        vat: dataRow.vatRateWithPercentage.replace('%', ''),
      });

      this.subtotalPrice = this.subtotalPrice + Number(dataRow.priceExclVatWithCurrency.replace('€ ', ''));

      this.totalVAT =
        this.totalVAT +
        (Number(dataRow.priceInclVatWithCurrency.replace('€ ', '')) -
          Number(dataRow.priceExclVatWithCurrency.replace('€ ', '')));
    });
  }

  public saveQuotationChecks(): void {
    if (this.quotation?.id && this.project?.id) {
      this.quotationService
        .sendQuotationChecks(
          this.quotation?.id,
          this.project.id,
          this.quotationChecks
            .filter((check) => {
              return check.is_selected === true;
            })
            .map((check) => {
              return check.id;
            })
        )
        .subscribe();
    }
  }

  public async ngOnDestroy(): Promise<void> {
    this.saveQuotationChecks();

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.quotationSent) {
      this.quotation.status_base_data_value_id = this.quotationStatuses.find((item) => {
        return item.value === QuotationStatus.queued;
      }).id;
    }

    await this.saveQuotation();

    this.destroy$.next();
    this.destroy$.complete();
  }

  public async updateBasePrice(): Promise<void> {
    this.quotation.calculated_volume = this.projectService.calculateVolume();
    this.quotation.distance = this.quotation.distance_km * 1000;

    const volume = this.quotation.total_volume > 0 ? this.quotation.total_volume : this.quotation.calculated_volume;
    const distance =
      this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km : this.quotation.distance / 1000;

    if (distance > 0 && volume > 0) {
      // Calculated base price, based on distance and total volume from bulk price list
      await this.dataService.findBulkPrice(distance, volume).then((item) => {
        if (item === undefined) {
          /** If the program enters here, for some reason item is undefined. For debugging see data.service #findBulkPrices() and log the variables. */
          return this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('movers_complete.inventory.quotation.base_price.warning.text')
          );
        }

        /** If item is defined set the base price to the item price and update the total prices */
        if (item) {
          if (!this.quotation.manually_changed_base_price) {
            this.quotation.base_price = item.price;
          }

          this.updateTotal();
        }

        return;
      });
    }
  }

  public async updateStorageWeeks(): Promise<void> {
    if (this.quotation.storage_week_text !== null || this.quotation.storage_week_text !== '') {
      await this.calculateStorageWeekPrice(this.quotation.estimated_weeks_in_storage, this.quotation.storage_week_text);
    }

    if (this.quotation.storage_value_price !== null) {
      await this.updateTotalStorageValue(this.quotation.storage_value_price);
    }
  }

  public async updateTotal(eventValue?: string, attribute?: string): Promise<void> {
    if (eventValue === '' || eventValue === undefined) {
      eventValue = '€ 0,00';
    }

    if (eventValue && attribute) {
      const value = this.convertFocusEventValueToNumber(eventValue);

      this.quotation[attribute] = this.showPricesExclVat ? (value / 121) * 100 : value;
    }

    this.quotation.subtotal_price = 0;
    this.quotation.subtotal_price += this.quotation.base_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.unpacking_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_price || 0;
    this.quotation.subtotal_price += this.quotation.coordinator_price || 0;
    this.quotation.subtotal_price += this.quotation.assembly_price || 0;
    this.quotation.subtotal_price += this.quotation.mount_unmount_ict || 0;
    this.quotation.subtotal_price += this.quotation.cable_management || 0;
    this.quotation.subtotal_price += this.quotation.specials_price || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_15_cubic || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_40_cubic || 0;
    this.quotation.subtotal_price += this.quotation.mover || 0;
    this.quotation.subtotal_price += this.quotation.remove_lights_curtains_price || 0;
    this.quotation.subtotal_price += this.quotation.piano_grand_organ_price || 0;
    this.quotation.subtotal_price += this.quotation.add_protection || 0;
    this.quotation.subtotal_price += this.quotation.elevator_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.piano_safe_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.floor_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.handyman_certificate_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_week_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_handling_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_value_total_price || 0;
    this.quotation.subtotal_price += this.quotation.parking_waiver_price || 0;

    if (this.project.type_base_data_value_id === ProjectType.private) {
      this.quotation.subtotal_price += this.quotation.guarantee_certificate_price || 0;
    }

    this.quotation.subtotal_price += +this.quotation.custom_option_1_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_2_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_3_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_4_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_5_price || 0;

    this.quotation.subtotal_price += +this.totalOptionsPrice || 0;

    // Calculate vat (excluded parking waiver, storage insurance)
    const calcVat = (amount) => {
      return amount ? Math.round(amount * 100 * 0.21) / 100 : 0;
    };

    this.quotation.vat_price = 0;
    this.quotation.vat_price += calcVat(this.quotation.base_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.unpacking_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_price);
    this.quotation.vat_price += calcVat(this.quotation.coordinator_price);
    this.quotation.vat_price += calcVat(this.quotation.assembly_price);
    this.quotation.vat_price += calcVat(this.quotation.mount_unmount_ict);
    this.quotation.vat_price += calcVat(this.quotation.cable_management);
    this.quotation.vat_price += calcVat(this.quotation.specials_price);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_15_cubic);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_40_cubic);
    this.quotation.vat_price += calcVat(this.quotation.mover);
    this.quotation.vat_price += calcVat(this.quotation.remove_lights_curtains_price);
    this.quotation.vat_price += calcVat(this.quotation.piano_grand_organ_price);
    this.quotation.vat_price += calcVat(this.quotation.add_protection);
    this.quotation.vat_price += calcVat(this.quotation.elevator_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.piano_safe_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.floor_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.storage_week_total_price);
    this.quotation.vat_price += calcVat(this.quotation.storage_handling_price);

    this.quotation.vat_price += calcVat(this.quotation.storage_value_total_price);

    this.quotation.vat_price += calcVat(+this.quotation.custom_option_1_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_2_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_3_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_4_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_5_price);

    this.quotation.vat_price += this.totalOptionsVatPrice;

    this.quotation.total_price = this.quotation.subtotal_price + this.quotation.vat_price;
  }

  public async submitQuotation(status: string): Promise<void> {
    this.saveQuotationChecks();

    if (!this.disabled) {
      switch (status) {
        case 'pending':
          this.quotationSent = true;

          this.quotation.status_base_data_value_id = this.quotationStatuses.find((item) => {
            return item.value === QuotationStatus.queued;
          }).id;
          break;
        case 'booked':
          this.quotationSent = true;

          this.quotation.status_base_data_value_id = this.quotationStatuses.find((item) => {
            return item.value === QuotationStatus.quotation_sent;
          }).id;
          break;
        default:
          break;
      }

      this.toastService.info(
        this.translateService.instant('movers_complete.toast.sync.title'),
        this.translateService.instant('movers_complete.inventory.quotation.submit.text')
      );

      this.saveProjectQuotation(status);
    }
  }

  public async updateTotalStorageValue(amount: any): Promise<void> {
    const rounded = amount / 1000;
    const basePrice = rounded * 0.55;

    this.quotation.storage_value_total_price = basePrice;

    await this.updateTotal();

    if (this.quotation.estimated_weeks_in_storage !== null && this.quotation.estimated_weeks_in_storage > 0) {
      await this.calculateStorageForPeriod(this.quotation.estimated_weeks_in_storage, basePrice);
    }
  }

  public showClientSignatureForm(): void {
    if (!this.disabled) {
      this.clientSignature.showForm();
      this.quotation.signatureClientDate = new Date();
    }
  }

  public showValuatorSignatureForm(): void {
    if (!this.disabled) {
      this.valuatorSignature.showForm();
      this.quotation.signatureValuatorDate = new Date();
    }
  }

  public loadMySignature(): void {
    if (!this.disabled) {
      this.quotation.signature_valuator_image = this.currentUserSign;
      this.quotation.signatureValuatorDate = new Date();
    }
  }

  public setVatChoice(projectType: string): void {
    this.showPricesExclVat = projectType === this.projectTypes[ProjectType.business];

    this.cdr.detectChanges();
    this.inventories = [...this.prepareInventoriesForTable()];
    this.applicableSpecialties = [...this.applicableSpecialties];

    this.addPopupEventListener();
  }

  public async initForm(): Promise<void> {
    await this.updateDistance();
    await this.updateBasePrice();
    await this.updateAssemblyPrice();
    await this.updatePackingPrice();
    await this.updateTotal();
  }

  private async calculateStorageWeekPrice(daysOfStorage: number, storageWeekPrice: any): Promise<void> {
    if (
      typeof Number(storageWeekPrice) === 'number' &&
      typeof Number(storageWeekPrice) !== 'undefined' &&
      typeof Number(daysOfStorage) === 'number'
    ) {
      this.quotation.storage_week_total_price = Number(storageWeekPrice) * Number(daysOfStorage);

      await this.updateTotal();
    } else {
      this.toastService.error(
        this.translateService.instant('movers_complete.inventory.toast.calculate_storage.error.title'),
        this.translateService.instant('movers_complete.inventory.quotation.calculate_storage.toast.error.error')
      );
    }
  }

  private async calculateStorageForPeriod(daysOfStorage: number, basePrice: number): Promise<void> {
    if (typeof Number(daysOfStorage) === 'number') {
      if (this.project.type_base_data_value_id === ProjectType.private) {
        daysOfStorage -= 4 * 7; // Amount of days is 4 weeks * 7 days
      }

      const numberOfPeriods = Math.ceil(daysOfStorage / (4 * 7));
      const totalStoragePrice = basePrice * numberOfPeriods;

      totalStoragePrice > 0
        ? (this.quotation.storage_value_total_price = totalStoragePrice)
        : (this.quotation.storage_value_total_price = 0);

      await this.updateTotal();
    } else {
      this.toastService.error(
        this.translateService.instant('movers_complete.inventory.toast.calculate_storage.error.title'),
        this.translateService.instant('movers_complete.inventory.quotation.calculate_storage_period.toast.error.text')
      );
    }
  }

  private async initWorkAssignmentAddresses(): Promise<void> {
    this.workAssignmentAddresses = [];
    for (const assignment of this.workAssignments) {
      if (assignment.address_work_assignments.length > 0) {
        for (const address of assignment.address_work_assignments) {
          this.workAssignmentAddresses.push(address);
        }
      }
    }

    await this.updateDistance();
  }

  private async saveQuotation(): Promise<void> {
    if (!this.disabled) {
      this.quotation.estimated_distance =
        this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km * 1000 : null;

      await this.projectService.saveQuotation(this.quotation);
    }
  }

  private updateAssemblyPrice(): void {
    if (this.quotation.assembly_price || this.disabled) {
      return;
    }

    this.quotation.assembly_price = this.projectService.calculateAssemblyTotal() * 45;
  }

  private updatePackingPrice(): void {
    if (this.quotation.packing_complete_price || this.disabled) {
      return;
    }

    this.quotation.packing_complete_price = this.projectService.calculatePackingTotal() * 45;
  }

  private async updateDistance(): Promise<void> {
    if (!this.pickupAddress || !this.deliverAddress || this.disabled || !navigator.onLine) {
      return;
    }

    let result = null;

    try {
      result = await this.api
        .post('/address/distance', {
          from: {
            street: this.pickupAddress.street,
            housenumber: this.pickupAddress.housenumber,
            zipcode: this.pickupAddress.zipcode,
            city: this.pickupAddress.city,
            country: this.pickupAddress.country,
          },
          to: {
            street: this.deliverAddress.street,
            housenumber: this.deliverAddress.housenumber,
            zipcode: this.deliverAddress.zipcode,
            city: this.deliverAddress.city,
            country: this.deliverAddress.country,
          },
        })
        .toPromise();
    } catch (e) {
      this.toastService.error(
        this.translateService.instant('movers_complete.inventory.toast.calculate_distance.error.title'),
        this.translateService.instant('movers_complete.inventory.quotation.calculate_distance.toast.error.text')
      );
    }

    if (result && result.data && result.data > 0) {
      this.quotation.distance = result.data;
    } else {
      this.quotation.distance = 0;
    }

    this.quotation.distance_km = this.quotation.distance / 1000;
    this.quotation.estimated_distance_km =
      this.quotation.estimated_distance > 0 ? this.quotation.estimated_distance / 1000 : null;
  }

  /**
   * Fill the text field for contact in invoice based on facturation addresses
   */
  private fillInvoiceContact(): void {
    const facturationAddresses: Address[] = [];

    for (const address of this.addresses) {
      if (address.type === 'Facturatieadres' || address.type === 'Facturatie adres') {
        facturationAddresses.push(address);
      }
    }

    if (!this.quotation.invoice_name) {
      this.quotation.invoice_name = facturationAddresses.length > 0 ? facturationAddresses[0].email : '';
    }
  }

  /** Asynchroniously fetch the address belonging to the activity */
  private async retrieveActivityAddresses(): Promise<void> {
    for (const activity of this.project.activities) {
      if (activity.address_id) {
        const address: Address = await this.dataService.getById('addresses', activity.address_id);

        activity.address = address ? address.getDisplayName() : null;
      }
    }
  }

  private getTotalOptionsPrice(): number {
    return (
      this.applicableSpecialties
        .map((specialty) => {
          return (specialty.hours_estimate || 0) * (specialty.specialty.cost_rate || 0);
        })
        ?.reduce((prev: number, current: number) => {
          return +current + +prev;
        }, 0) || 0
    );
  }

  private getTotalOptionsVatPrice(): number {
    return (
      this.applicableSpecialties
        .map((specialty) => {
          return (
            (specialty.hours_estimate || 0) *
            ((specialty.specialty.cost_rate || 0) * (+specialty.specialty.vat_rate / 100))
          );
        })
        ?.reduce((prev: number, current: number) => {
          return +current + +prev;
        }, 0) || 0
    );
  }

  private convertFocusEventValueToNumber(eventValue: string): number {
    const value = +eventValue.substring(2).replace('.', '').replace(',', '.') || 0;

    return eventValue.charAt(0) === '-' ? +`-${value}` : value;
  }

  public async submitToExact(): Promise<void> {
    this.toastService.info(
      this.translateService.instant('movers_complete.inventory.toast.exact_online.title'),
      this.translateService.instant('movers_complete.inventory.quotation.exact_online.action_started.toast.info.text')
    );

    await this.saveProjectQuotation();

    this.toastService.info(
      this.translateService.instant('movers_complete.inventory.toast.exact_online.title'),
      this.translateService.instant('movers_complete.inventory.quotation.exact_online.invoice_sending.toast.info.text')
    );

    this.projectService.sendToExact(this.project.id).subscribe(
      () => {
        this.sentToExact = true;
        this.toastService.info(
          this.translateService.instant('movers_complete.inventory.toast.exact_online.title'),
          this.translateService.instant('movers_complete.inventory.quotation.exact_online.invoice_sent.toast.info.text')
        );
      },
      () => {
        this.toastService.error(
          this.translateService.instant('movers_complete.inventory.toast.exact_online.title'),
          this.translateService.instant(
            'movers_complete.inventory.quotation.exact_online.invoice_sent.toast.error.text'
          )
        );
      }
    );
  }

  private async saveProjectQuotation(status: string = null): Promise<void> {
    await this.saveQuotation();

    if (status) {
      this.project.status = status;
    }

    await this.projectService.saveProject();

    try {
      const result = await this.synchronisationService.syncToBackend();

      if (result) {
        this.api.get('/project/' + this.project.id).subscribe((response) => {
          if (response && response.data) {
            if (response.data.projects && response.data.projects[0]) {
              this.project.editing_by = response.data.projects[0].editing_by;
              this.project.reference_nr = response.data.projects[0].reference_nr;
            }

            if (response.data.quotation) {
              this.quotation.created_at = response.data.quotation.created_at;

              if (this.project.quotation) {
                this.project.quotation.created_at = response.data.quotation.created_at;
              }
            }

            this.toastService.success(
              this.translateService.instant('movers_complete.toast.sync.title'),
              this.translateService.instant(
                'movers_complete.inventory.quotation.exact_online.invoice_sent.success.text'
              )
            );
          } else {
            this.toastService.error(
              this.translateService.instant('movers_complete.toast.sync.title'),
              this.translateService.instant('movers_complete.inventory.quotation.project_retrieve.error.text')
            );
          }
        });
      }
    } catch (error) {
      this.toastService.error(
        this.translateService.instant('movers_complete.toast.sync.title'),
        this.translateService.instant(
          status
            ? 'movers_complete.inventory.quotation.project_quotation_sent.error.text'
            : 'movers_complete.inventory.quotation.project_quotation_save.error.text'
        )
      );
      this.synchronisationService.shouldSync = true;
    }
  }
}
