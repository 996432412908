import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { QuotationCheckDetailComponent } from '../quotation-check-detail/quotation-check-detail.component';

@Component({
  selector: 'app-quotation-check-list',
  templateUrl: './quotation-check-list.component.html',
  styleUrls: ['./quotation-check-list.component.scss'],
})
export class QuotationCheckListComponent {
  public listKey = 'list_quotation_checks';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}
  public addNewItem(): void {
    this.dialogService.open(QuotationCheckDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.quotation_check.single'),
    });
  }
}
