import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ManageActivitiesDetailComponent } from '@features/manage/components/activities/activities-detail/activities-detail.component';
import { ManageActivitiesListComponent } from '@features/manage/components/activities/activities-list/activities-list.component';
import { ManageAddressTypesDetailComponent } from '@features/manage/components/address-types/address-types-detail/address-types-detail.component';
import { ManageAddressTypesListComponent } from '@features/manage/components/address-types/address-types-list/address-types-list.component';
import { ManageBulkPricesDetailComponent } from '@features/manage/components/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ManageBulkPricesListComponent } from '@features/manage/components/bulk-prices/bulk-prices-list/bulk-prices-list.component';
import { ManageDefaultInventoriesDetailComponent } from '@features/manage/components/default-inventories/default-inventories-detail/default-inventories-detail.component';
import { ManageDefaultInventoriesListComponent } from '@features/manage/components/default-inventories/default-inventories-list/default-inventories-list.component';
import { ManageDefaultItemsDetailComponent } from '@features/manage/components/default-items/default-items-detail/default-items-detail.component';
import { ManageDefaultItemsListComponent } from '@features/manage/components/default-items/default-items-list/default-items-list.component';
import { ManageHouseTypesDetailComponent } from '@features/manage/components/house-types/house-types-detail/house-types-detail.component';
import { ManageHouseTypesListComponent } from '@features/manage/components/house-types/house-types-list/house-types-list.component';
import { ManageMaterialsDetailComponent } from '@features/manage/components/materials/materials-detail/materials-detail.component';
import { ManageMaterialsListComponent } from '@features/manage/components/materials/materials-list/materials-list.component';
import { ManageOptionsDetailComponent } from '@features/manage/components/options/options-detail/options-detail.component';
import { ManageOptionsListComponent } from '@features/manage/components/options/options-list/options-list.component';
import { ManageRelationGroupsDetailComponent } from '@features/manage/components/relation-groups/relation-groups-detail/relation-groups-detail.component';
import { ManageRelationGroupsListComponent } from '@features/manage/components/relation-groups/relation-groups-list/relation-groups-list.component';
import { ManageTenantsDetailComponent } from '@features/manage/components/tenants/tenants-detail/tenants-detail.component';
import { ManageTenantsListComponent } from '@features/manage/components/tenants/tenants-list/tenants-list.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumTableModule } from '@capturum/ui/table';
import { TableModule } from '@shared/modules/table/table.module';
import { DataService } from '@shared/services/data.service';
import { SharedModule } from '@shared/shared.module';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { QuotationCheckDetailComponent } from '@features/manage/components/quotation-checks/quotation-check-detail/quotation-check-detail.component';
import { QuotationCheckListComponent } from '@features/manage/components/quotation-checks/quotation-check-list/quotation-check-list.component';
import { ManageAddressesDetailComponent } from '@features/manage/components/addresses/addresses-detail/addresses-detail.component';
import { ManageAddressesListComponent } from '@features/manage/components/addresses/addresses-list/addresses-list.component';
import { ManageContactsDetailComponent } from '@features/manage/components/contacts/contacts-detail/contacts-detail.component';
import { ManageContactsListComponent } from '@features/manage/components/contacts/contacts-list/contacts-list.component';
import { ManageComponent } from './manage.component';
import { ManageLayoutComponent } from '@features/manage/pages/manage-layout.component';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { EmailQuotationAttachmentsListComponent } from './components/email-quotation-attachments/email-quotation-attachments-list/email-quotation-attachments-list.component';
import { EmailQuotationAttachmentsDetailComponent } from './components/email-quotation-attachments/email-quotation-attachments-detail/email-quotation-attachments-detail.component';

@NgModule({
  providers: [DataService],
  declarations: [
    ManageComponent,
    ManageTenantsListComponent,
    ManageTenantsDetailComponent,
    ManageAddressTypesListComponent,
    ManageAddressTypesDetailComponent,
    ManageHouseTypesListComponent,
    ManageHouseTypesDetailComponent,
    ManageAddressesListComponent,
    ManageAddressesDetailComponent,
    ManageContactsListComponent,
    ManageContactsDetailComponent,
    ManageMaterialsListComponent,
    ManageMaterialsDetailComponent,
    ManageOptionsListComponent,
    ManageOptionsDetailComponent,
    ManageActivitiesListComponent,
    ManageRelationGroupsListComponent,
    ManageActivitiesDetailComponent,
    ManageRelationGroupsDetailComponent,
    ManageDefaultInventoriesListComponent,
    ManageDefaultInventoriesDetailComponent,
    ManageDefaultItemsListComponent,
    ManageDefaultItemsDetailComponent,
    ManageBulkPricesListComponent,
    ManageBulkPricesDetailComponent,
    ManageAddressesListComponent,
    QuotationCheckListComponent,
    QuotationCheckDetailComponent,
    ManageLayoutComponent,
    EmailQuotationAttachmentsListComponent,
    EmailQuotationAttachmentsDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule,
    CapturumInfoTableModule,
    CapturumTableModule,
    TableModule,
    CapturumListRendererModule,
    CapturumFormRendererModule,
    CapturumFileUploadModule,
    CapturumFilePreviewListModule,
    CapturumDropdownModule,
  ],
  exports: [ManageLayoutComponent],
})
export class ManageModule {}
