<ng-container *ngIf="arentMaterialsEnabled" xmlns="http://www.w3.org/1999/html">
  <app-material-header
    (materialGroupChange)="onMaterialGroupChange($event)"
    [materialGroups]="materialGroups"></app-material-header>
</ng-container>

<div class="row">
  <div class="col-lg-3 col-md-3 col-sm-6" *ngFor="let material of materials; let index = index">
    <div class="new-inventory-item small justify-content-between" [formGroup]="form">
      <span class="mr-3">{{ material.name }}</span>

      <span *ngIf="disabled"> {{ form.controls['amount' + index].value }} </span>

      <cap-input-number
        *ngIf="!disabled && form.get('amount' + index)"
        [showButtons]="true"
        class="input-increment"
        buttonLayout="horizontal"
        decrementButtonClass="default"
        incrementButtonClass="default"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="fa fa-minus"
        [min]="0"
        [max]="100"
        [formControlName]="'amount' + index"
        (ngModelChange)="changeAmount($event, material)">
      </cap-input-number>
    </div>
  </div>
</div>
