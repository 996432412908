import { NgxPermissionsService } from 'ngx-permissions';
import { Injectable } from '@angular/core';
import { AuthService } from '@capturum/auth';
import jwtDecode from 'jwt-decode';
import { DataService } from '@shared/services/data.service';

@Injectable()
export class UserService {
  constructor(
    private auth: AuthService,
    private dataService: DataService,
    private permissionsService: NgxPermissionsService
  ) {}

  /**
   * Checks if the current user has the given role
   * @param roleCode Role code
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === roleCode;
  }

  public async getUserSign(): Promise<string | null> {
    const jwt = this.getTokenAsObject();
    let userSign: string = null;

    if (jwt && jwt.sub) {
      const user = await this.dataService.getById('users', jwt.sub);

      if (user && user.signature_image) {
        userSign = user.signature_image;
      }
    }

    return userSign;
  }

  /**
   * Checks if the current user had administrator rights
   */
  public isSuperuser(): boolean {
    return !!this.permissionsService.getPermission('superuser.manage');
  }

  /**
   * Checks if the current user had administrator rights
   */
  public isAdministrator(): boolean {
    return !!this.permissionsService.getPermission('setting.manage');
  }

  public getUserId(): number {
    return this.getTokenAsObject().sub;
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }
}
