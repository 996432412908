import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { from, Subscription, switchMap } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { InventoryFlowValidationService } from '@core/services/inventory-flow-validation.service';
import { ProjectStatus } from '@core/enums/project-status.enum';
import { getMappedBaseDataByKey } from '@core/utils/base-data.utils';
import { SynchronisationService } from '@shared/services/synchronisation.service';

@Component({
  selector: 'app-inventory-address-list',
  templateUrl: 'address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class InventoryAddressListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent, { static: false }) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public addresses: Address[];
  public disabled = true;
  public disableDelete = true;
  public disableChangeOrder = true;
  public projectStatuses: { [key: string]: string };
  public validation = false;

  private invoiceFacturationId: number;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionAddressAdded: Subscription;

  public constructor(
    private api: ApiServiceWithLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private validationService: InventoryFlowValidationService,
    private synchronisationService: SynchronisationService
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.addressAdded.subscribe(async (_) => {
      this.project.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
    });

    this.getInvoiceFacturationId();
    this.invoiceFacturationId = null;
  }

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    this.projectStatuses = await getMappedBaseDataByKey('project-status');

    this.setTableOptions();
    await this.getAddresses();

    // In case project was loaded
    this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;
      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
      this.getAddresses();
    });

    // In case address was added (from popup)
    this.projectService.addressAdded.subscribe((_) => {
      this.table.onChangeTable();
      this.getAddresses();
    });

    if (this.project?.is_new) {
      from(this.synchronisationService.getSyncJson(this.project, true))
        .pipe(
          switchMap((syncJson) => {
            return this.api.post('/sync/post', [syncJson]);
          })
        )
        .subscribe(() => {});
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionAddressAdded) {
      this.subscriptionAddressAdded.unsubscribe();
    }
  }

  public async cloneAddress(row: Address): Promise<void> {
    if (!this.disabled) {
      delete row.id;
      row.index = this.table.rows.length + 1;

      await this.projectService.saveAddress(row);
    }
  }

  public onRowClick(data: any): void {
    if (!this.disabled) {
      this.router.navigateByUrl(
        '/admin/project/' + this.project.id + '/address(popup:admin/project/address/' + data.id + ')'
      );
    }
  }

  public onActionClick(data: any): void {
    if (!this.disabled) {
      this[data.action](data.row);
    }
  }

  public onAddClick(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/add)');
    }
  }

  public checkForSelectedRows(selectedRows: Address[]): void {
    this.disableDelete = !selectedRows.length;
  }

  public onDeleteClick(): void {
    if (!this.disabled) {
      this.confirmationService.confirm({
        message: 'Wilt u de geselecteerde adressen verwijderen?',
        header: 'Bevestiging',
        icon: 'fa fa-question-circle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nee',
        accept: (_) => {
          let availableRows = cloneDeep(this.table.rows);

          this.table.selectedRows.forEach(async (row: any) => {
            this.projectService.deleteAddress(row.id);

            availableRows = availableRows.filter((availableRow: any) => {
              return availableRow.id !== row.id;
            });

            this.disableDelete = true;

            if (availableRows && availableRows.length > 0) {
              const movableRows = availableRows.filter((availableRow: any) => {
                return availableRow.index > row.index;
              });

              if (movableRows && movableRows.length > 0) {
                movableRows.forEach((movableRow: any) => {
                  movableRow.index--;
                });

                await this.projectService.saveAddresses(movableRows);
              }
            }
          });

          this.table.onChangeTable();

          this.toastService.success(
            this.translateService.instant('movers_complete.inventory.address.success'),
            this.translateService.instant('movers_complete.inventory.address.deleted')
          );
        },
      });
    }
  }

  private async getAddresses(): Promise<void> {
    this.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
    this.disableChangeOrder =
      this.addresses.length < 2 ||
      this.project?.status_base_data_value_id === this.projectStatuses[ProjectStatus.booked];
  }

  private async getInvoiceFacturationId() {
    const result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');

    for (const item of result) {
      // @todo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5
      if (item.name === 'Facturatie adres' || item.name === 'Facturatieadres') {
        this.invoiceFacturationId = item.id;
      }
    }
  }

  private setTableOptions(): void {
    let action: any[] = [];

    if (!this.disabled) {
      action = [{ title: 'Adres dupliceren', action: 'cloneAddress', icon: 'fa-clone' }];
    }

    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        {
          title: this.translateService.instant('movers_complete.inventory.address.order.label'),
          name: 'index',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.address.type.label'),
          name: 'type',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.address.house_type.label'),
          name: 'house_type',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.address.address.label'),
          name: 'address',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.address.description.label'),
          name: 'description',
        },
      ],
      actions: action,
      noResultsMessage: 'Er zijn nog geen adressen aangemaakt',
      withDelete: true,
      itemsPerPage: 500,
      url: '/address',
      tableName: 'addresses',
      filtering: { hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }] },
      rowDataTransformer: (rows) => {
        this.validate(rows);

        for (const row of rows) {
          if (row.address_type_id !== this.invoiceFacturationId) {
            row.address = `${row.street} ${row.housenumber} ${row.housenumber_add ? '- ' + row.housenumber_add : ''}, ${
              row.zipcode
            } ${row.city}, ${row.country}`;
          } else {
            row.address = row.email;
          }

          if (!row.index) {
            row.index = 'n.v.t.';
          }

          row.type = row.address_type?.name;
        }

        return rows.sort((a, b) => {
          return a.index > b.index ? 1 : -1;
        });
      },
    });
  }

  private validate(addresses: string[]): void {
    this.validationService.setValidation(addresses.length > 0);
  }
}
