<div class="box-content-wrap">
  <div class="ibox-title">
    <h5>Timeline</h5>
    <span class="fa fa-times cancel-button" (click)="onCloseClick()"></span>
  </div>
  <ng-container *ngIf="project?.events; else noProjectEvents"> </ng-container>
  <div class="ibox-content scroll-fix">
    <cap-timeline
      [items]="project.events | sortByProperty : 'updated_at' : 'desc'"
      [divider]="true"
      class="comments-timeline mt-3">
      <ng-template capTemplate="content" let-timeline="timeline">
        <div class="timeline__created mt-2 mb-2">
          {{ timeline?.updated_at | date : 'dd-MM-yyyy HH:mm:ss' }}
        </div>

        <div class="timeline__title mt-2 mb-2">
          {{ timeline?.created_by?.name }}
        </div>

        <div class="timeline__content mb-2">
          <div class="col-md-7" [innerHTML]="timeline.event | nl2br">
            <span *ngIf="timeline.api_log_id">
              <a
                title="API informatie"
                *ngIf="!this.apiLogsOpen.includes(timeline.apiLog)"
                (click)="openApiLog(timeline?.apiLog)"
                [class]="'status-' + timeline?.apiLog?.status">
                <i class="fa fa-search-plus"></i>
              </a>
              <a
                title="API informatie"
                *ngIf="this.apiLogsOpen.includes(timeline.apiLog)"
                (click)="closeApiLog(timeline?.apiLog)"
                [class]="'status-' + timeline?.apiLog?.status">
                <i class="fa fa-search-minus"></i>
              </a>
            </span>
          </div>
        </div>

        <div
          class="col-md-push-3 col-md-9 event"
          *ngIf="timeline.api_log_id && this.apiLogsOpen.includes(timeline?.apiLog)">
          <div class="col-md-12" *ngIf="timeline?.apiLog?.api">API: {{ timeline?.apiLog?.api }}</div>
          <div class="col-md-12" *ngIf="timeline?.apiLog?.status">Status: {{ timeline?.apiLog?.status }}</div>
          <div class="col-md-12" *ngIf="timeline?.apiLog?.message">Message: {{ timeline?.apiLog?.message }}</div>
          <div class="col-md-12" *ngIf="timeline?.apiLog?.data && timeline?.apiLog?.data !== 'null'">
            Data: {{ timeline?.apiLog?.data }}
          </div>
        </div>
      </ng-template>
    </cap-timeline>
  </div>

  <div class="ibox-footer">
    <cap-button type="button" styleClass="secondary" label="Sluiten" (onClick)="onCloseClick()"> </cap-button>

    <cap-button type="button" styleClass="primary" label="Nieuw" (onClick)="openCreateEvent()"> </cap-button>
  </div>

  <ng-template #noProjectEvents>
    <p>Nog geen events beschikbaar om weer te geven.</p>
  </ng-template>
</div>
