import { DomainModel } from '@domain/domain.model';
import { RelationGroup } from '@domain/models/relation-group.model';

export class Specialty extends DomainModel {
  // Configuration
  public entity = 'specialty';
  public table = 'specialties';
  public sync = true;

  // Fields
  public relation_group_ids: number[];
  public name: string;
  public description: string;
  public index: number;
  public cost_rate: number;
  public vat_rate: number;
  public has_hours: boolean;
  public has_remarks: boolean;
  public has_date: boolean;
  public exact_code: string;
  public sends_notification: boolean;
  public is_default_selected: boolean;
  public deleted_at: Date | null;

  public relationGroups: RelationGroup[];

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
