import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';

import { SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';

import { RouterModule } from '@angular/router';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { ProjectService } from '@shared/services/project.service';
import { QuotationSignService } from '@shared/services/quotation-sign.service';
import { BlankInvoiceComponent } from './blank-invoice/blank-invoice.component';
import { TreeTableModule } from 'primeng/treetable';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationService } from 'primeng/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';

const CAPTURUM_UI_MODULES = [CapturumButtonModule];

@NgModule({
  providers: [ProjectService, QuotationSignService, ConfirmationService],
  declarations: [ProjectOverviewComponent, BlankInvoiceComponent],
  imports: [
    ...CAPTURUM_UI_MODULES,
    BrowserModule,
    TreeTableModule,
    TableModule,
    PrimeNGSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
    CapturumInputModule,
    CapturumSelectButtonModule,
    ConfirmDialogModule,
  ],
  exports: [ProjectOverviewComponent],
})
export class ProjectModule {}
