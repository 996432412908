import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { detectBody } from '../../../../app.helpers';

@Component({
  selector: 'app-popup-layout',
  templateUrl: './popup-layout.component.html',
  styleUrls: ['./popup-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupLayoutComponent implements OnInit {
  public ngOnInit(): any {
    detectBody();
  }

  @HostListener('window:resize') onResize() {
    detectBody();
  }
}
