import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';
import { CapturumPdfViewerModule } from '@capturum/ui/pdf-viewer';
@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CapturumPdfViewerModule],
})
export class PdfViewerDialogComponent implements OnInit {
  public url: string;
  public name: string;

  constructor(private readonly dialogReference: DynamicDialogRef, private readonly dialogConfig: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.url = this.dialogConfig?.data?.url;
    this.name = this.dialogConfig?.data?.name;
  }

  public download(): void {
    saveAs(this.url, this.name);
  }

  public closeDialog(): void {
    this.dialogReference.close();
  }
}
