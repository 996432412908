import { DomainModel } from '@domain/domain.model';

export class MaterialGroup extends DomainModel {
  // Configuration
  public entity = 'material-group';
  public table = 'material_groups';
  public sync = true;

  // Fields
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
