import { Component } from '@angular/core';
import { Address } from '@domain/models/address.model';
import { ManageAddressesDetailComponent } from '../addresses-detail/addresses-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addresses-list',
  templateUrl: './addresses-list.component.html',
  styleUrls: ['./addresses-list.component.scss'],
})
export class ManageAddressesListComponent<T = Address> {
  public listKey = 'list_address';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageAddressesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.address.single'),
    });
  }
}
