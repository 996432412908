import { DialogLayoutComponent } from './../features/gui/layouts/dialog-layout/dialog-layout.component';
import { ZipCodeValidatorDirective } from './directives/validation/zipcode-validator.directive';
import { LoadingComponent } from './controls/loading/loading.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AsteriksComponent } from '@shared/controls/asteriks/asteriks.component';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { AddMyTenantPipe } from '@shared/pipes/add-my-tenant.pipe';
import { ContactFullNamePipe } from '@shared/pipes/contact-full-name.pipe';
import { ConvertVatPrice } from '@shared/pipes/convert-vat-price.pipe';
import { Nl2BrPipe } from '@shared/pipes/nl2br.pipe';
import { SortByDatePropertyPipe } from '@shared/pipes/sort-by-date-property.pipe';
import { SortByIndexPipe } from '@shared/pipes/sort-by-index.pipe';
import { SortByPropertyPipe } from '@shared/pipes/sort-by-property.pipe';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { OnlineTableComponent } from './controls/table/online-table.component';
import { NestedColumnValuePipe } from './pipes/nested-column-value.pipe';
import { ObservablePipe } from './pipes/observable.pipe';
import { SettingPipe } from './pipes/setting.pipe';
import { IsProjectTypePipe } from './pipes/project-type.pipe';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CapturumDynamicFiltersModule } from '@capturum/ui/dynamic-filters';
import { PreviewImagePipe } from './pipes/preview-image.pipe';

@NgModule({
  declarations: [
    OfflineTableComponent,
    OnlineTableComponent,
    DialogLayoutComponent,
    AppPatternRestrictDirective,
    AsteriksComponent,
    LoadingComponent,
    SortByDatePropertyPipe,
    SortByIndexPipe,
    SortByPropertyPipe,
    AddMyTenantPipe,
    ConvertVatPrice,
    ContactFullNamePipe,
    SignatureComponent,
    Nl2BrPipe,
    SettingPipe,
    ObservablePipe,
    NestedColumnValuePipe,
    ZipCodeValidatorDirective,
    IsProjectTypePipe,
    PreviewImagePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot(),
    TreeTableModule,
    TableModule,
    TooltipModule,
    TranslateModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ReactiveFormsModule,
    SignaturePadModule,
    CapturumButtonModule,
    CapturumInfoTableModule,
    CapturumSharedModule,
    CapturumMultiSelectModule,
    CapturumDropdownModule,
    CapturumDynamicFiltersModule,
    TableModule,
  ],
  exports: [
    OfflineTableComponent,
    OnlineTableComponent,
    AsteriksComponent,
    LoadingComponent,
    DialogLayoutComponent,
    AppPatternRestrictDirective,
    SortByDatePropertyPipe,
    SortByIndexPipe,
    SortByPropertyPipe,
    ConvertVatPrice,
    ContactFullNamePipe,
    AddMyTenantPipe,
    PaginationModule,
    MultiSelectModule,
    SignatureComponent,
    Nl2BrPipe,
    SettingPipe,
    TranslateModule,
    ZipCodeValidatorDirective,
    IsProjectTypePipe,
    PreviewImagePipe,
  ],
  providers: [TitleCasePipe],
})
export class SharedModule {}
