<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12 text-center">
      <cap-button
        *ngIf="!authenticatedUser?.is_executor"
        icon="fa fa-plus"
        iconPos="right"
        label="Nieuw project"
        styleClass="primary"
        type="button"
        (onClick)="addNewProject()">
      </cap-button>
    </div>

    <div class="col-lg-12">
      <div class="ibox mt-3">
        <div class="section-separator d-flex align-items-center justify-content-center mb-2">
          <hr />
          <h5>Open projecten <span class="text-danger"> (Let op: Open projecten altijd sluiten!)</span></h5>
          <hr />
        </div>

        <div class="col-sm-4 text-center">
          <h5 *ngIf="!onlineMode" class="open-project-title text-danger font-bold">OFFLINE MODUS</h5>
        </div>

        <div *ngIf="projectOpenTableOptions">
          <em-offline-table
            #projectOpenTable
            [options]="projectOpenTableOptions"
            (rowClicked)="onOpenProjectTableRowClick($event)">
          </em-offline-table>

          <cap-button
            *ngIf="onlineMode"
            styleClass="primary"
            label="Projecten synchroniseren & sluiten"
            (onClick)="closeOpenProjects()">
          </cap-button>
        </div>
      </div>
    </div>

    <div *ngIf="onlineMode && searchTableReady" class="col-lg-12">
      <div class="ibox">
        <div class="section-separator d-flex align-items-center justify-content-center mb-2">
          <hr />
          <h5>Zoek projecten</h5>
          <hr />
        </div>
        <div *ngIf="projectSearchTableOptions">
          <em-online-table
            #projectSearchTable
            [options]="projectSearchTableOptions"
            (rowClicked)="onSearchProjectTableRowClick($event)">
          </em-online-table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Blanco Offerte Printen"
  [(visible)]="displayModal"
  [baseZIndex]="10000"
  [draggable]="false"
  [minY]="70"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px', height: '500px' }">
  <div>
    <div class="row">
      <div class="col-sm-12 modal-title">
        <span>Vul hieronder de opties aan:</span>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-9 modal-label w-100">Aantal VAKWERK:</label>
      <div class="col-sm-3">
        <cap-input
          pattern="^[0-9]+$"
          type="number"
          [(ngModel)]="numberOfSpecialties"
          (blur)="setSpecialtiesLength()"
          (input)="setSpecialtiesLength()">
        </cap-input>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-9 modal-label w-100">Aantal WERKZAAMHEDEN:</label>
      <div class="col-sm-3">
        <cap-input
          pattern="^[0-9]+$"
          type="number"
          [(ngModel)]="numberOfActivities"
          (blur)="setActivitiesLength()"
          (input)="setActivitiesLength()">
        </cap-input>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3 modal-label w-100">Type:</label>
      <div class="col-sm-9 text-right">
        <cap-select-button [optionLabel]="'label'" [options]="clientTypeOptions" (change)="setType($event.value)">
        </cap-select-button>
      </div>
    </div>
  </div>

  <p-footer>
    <cap-button styleClass="primary" label="OK" type="button" (onClick)="printBlankInvoice()"> </cap-button>

    <cap-button styleClass="secondary" label="Annuleren" type="button" (onClick)="displayModal = false"> </cap-button>
  </p-footer>
</p-dialog>

<app-blank-invoice [activities]="activities" [specialties]="specialties" [type]="selectedProjectType">
</app-blank-invoice>
