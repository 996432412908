import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DataUrl, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder } from '@node_modules/@angular/forms';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { ActionType } from '@core/enums/action-type.enum';

@Component({
  selector: 'app-inventory-item-detail',
  styleUrls: ['inventory-item-details.component.scss'],
  templateUrl: 'inventory-item-details.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class InventoryItemDetailsComponent implements OnInit, OnDestroy {
  @Input() inventoryItem: InventoryItem;
  @Input() modalId;
  @Input() disabled;
  @Output() inventoryItemChanged = new EventEmitter();
  @Output() inventoryItemDeleted = new EventEmitter();
  public form: UntypedFormGroup;

  private subscriptionImageResize: Subscription;

  constructor(
    private imageCompress: NgxImageCompressService,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder
  ) {
    this.modalId = this.config?.data?.inventoryItem.inventory_id;
    this.inventoryItem = this.config?.data?.inventoryItem;
    this.disabled = this.config?.data?.disabled;
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      amount: this.formBuilder.control(this.inventoryItem.amount || ''),
      price: this.formBuilder.control(this.inventoryItem.price || '', [Validators.required]),
      remarks: this.formBuilder.control(this.inventoryItem.remarks || ''),
      image: this.formBuilder.control(this.inventoryItem.image || ''),
    });

    if (this.disabled) {
      this.form.disable();
    }
  }

  public ngOnDestroy() {
    if (this.subscriptionImageResize) {
      this.subscriptionImageResize.unsubscribe();
    }
  }

  public compressFile(): Promise<any> {
    return this.imageCompress.uploadFile().then(({ image, orientation }: UploadResponse) => {
      this.imageCompress.compressFile(image, orientation, 50, 50).then((result: DataUrl) => {
        this.inventoryItem.image = result;
        this.form.get('image').setValue(this.inventoryItem.image);
      });
    });
  }

  public changeAmount(amount: number): void {
    if (!amount) {
      amount = 0;
    }

    this.inventoryItem.amount = +amount;
  }

  public deleteItem(): void {
    this.dialogRef.close({ inventoryItem: this.inventoryItem, typeAction: ActionType.DELETE });
  }

  public closeModalAndSave(): void {
    if (this.form.valid) {
      this.inventoryItem = {
        ...this.inventoryItem,
        ...this.form.value,
      };

      this.dialogRef.close({ inventoryItem: this.inventoryItem, typeAction: ActionType.UPDATE });
    }
  }
}
