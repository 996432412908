import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/* PrimeNG controls */
import { SharedModule } from '@shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { WorkassignmentAddressDetailComponent } from '@features/inventory/work-assignment/workassignment-address-detail/workassignment-address-detail.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { DataService } from '@shared/services/data.service';
import { InventoryProjectComponent } from '@features/inventory/project/project-detail.component';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { InventoryItemComponent } from '@features/inventory/inventory/item/inventory-item.component';
import { InventoryAddItemComponent } from '@features/inventory/inventory/add-item/inventory-add-item.component';
import { InventoryItemDetailsComponent } from '@features/inventory/inventory/details/inventory-item-details.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryStaticComponent } from '@features/inventory/inventory/static/inventory-static.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryWorkAssignmentItemComponent } from '@features/inventory/work-assignment/work-assignment-item.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { MaterialHeaderComponent } from '@features/inventory/material/header/material-header.component';
import { OptionsDetailItemComponent } from './options/options-detail-item/options-detail-item.component';
import { InventoryOverviewComponent } from './overview/overview.component';
import { InventoryWorkAssignmentDropdownItemComponent } from '@features/inventory/work-assignment/work-assignment-dropdown-item.component';
import { InventoryAddressSortComponent } from '@features/inventory/address/address-sort/address-sort.component';
import { InventoryPicturesComponent } from '@features/inventory/pictures/pictures.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { EventDetailComponent } from '@features/inventory/event/event-detail.component';
import { EventListComponent } from '@features/inventory/event/event-list.component';
import { StepsModule } from 'primeng/steps';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumAutocompleteModule } from '@capturum/ui/autocomplete';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';
import { CapturumTimelineModule } from '@capturum/ui/timeline';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { TranslateModule } from '@ngx-translate/core';
import { IsNumberPipe } from './inventory/pipes/is-number.pipe';
import { ProductTotalPipe } from './inventory/pipes/product-total.pipe';
import { TotalPriceByRoomPipe } from '@shared/pipes/total-price-by-room.pipe';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { AdditionalCostQuotationComponent } from './additional-cost-quotation/additional-cost-quotation.component';
import { ManageModule } from '@features/manage/manage.module';

@NgModule({
  providers: [DataService, NgxImageCompressService],
  declarations: [
    InventoryFlowComponent,
    InventoryProjectComponent,
    InventoryClientComponent,
    InventoryContactListComponent,
    InventoryContactsDetailComponent,
    InventoryAddressListComponent,
    InventoryAddressDetailComponent,
    InventoryOptionsDetailComponent,
    InventoryBoardComponent,
    InventoryHeaderComponent,
    InventoryItemComponent,
    InventoryAddItemComponent,
    InventoryItemDetailsComponent,
    InventoryClientSearchComponent,
    InventoryQuotationComponent,
    InventoryStaticComponent,
    InventoryAddComponent,
    InventoryWorkAssignmentListComponent,
    InventoryWorkAssignmentDetailComponent,
    InventoryWorkAssignmentItemComponent,
    InventoryWorkAssignmentDropdownItemComponent,
    WorkassignmentAddressDetailComponent,
    OptionsDetailItemComponent,
    MaterialComponent,
    MaterialHeaderComponent,
    InventoryOverviewComponent,
    InventoryAddressSortComponent,
    InventoryPicturesComponent,
    InventoryPictureItemDetailComponent,
    EventDetailComponent,
    EventListComponent,
    IsNumberPipe,
    ProductTotalPipe,
    TotalPriceByRoomPipe,
    AdditionalCostQuotationComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    StepsModule,
    CheckboxModule,
    CalendarModule,
    InputMaskModule,
    SharedModule,
    TranslateModule.forRoot(),
    RouterModule,
    DialogModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputSwitchModule,
    DragDropModule,
    InputTextareaModule,
    CurrencyMaskModule,
    CapturumButtonModule,
    CapturumDropdownModule,
    CapturumAutocompleteModule,
    CapturumInputModule,
    CapturumCalendarModule,
    CapturumInputSwitchModule,
    CapturumTextareaModule,
    CapturumSelectButtonModule,
    SelectButtonModule,
    CapturumSharedModule,
    CapturumTimelineModule,
    CapturumInputNumberModule,
    CapturumCheckboxModule,
    CapturumFileUploadModule,
    ConfirmDialogModule,
    CapturumListRendererModule,
    CapturumFormRendererModule,
    ManageModule,
  ],
  exports: [InventoryFlowComponent, TotalPriceByRoomPipe],
})
export class InventoryModule {}
