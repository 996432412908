import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@domain/models/contact.model';

@Pipe({
  name: 'ContactFullName',
})
export class ContactFullNamePipe implements PipeTransform {
  transform(contact: Contact): string {
    let result = contact.name;

    if (contact.initials) {
      result = `${contact.initials} ${contact.name}`;
    }

    return result;
  }
}
