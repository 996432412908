import { Pipe, PipeTransform } from '@angular/core';
import { Inventory } from '@domain/models/inventory.model';

@Pipe({
  name: 'productTotal',
})
export class ProductTotalPipe implements PipeTransform {
  public transform(value: string, inventories: Inventory[]): number {
    let total = 0;

    for (const inventory of inventories) {
      if (inventory.items) {
        for (const item of inventory.items) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            if (item.amount) {
              total += +item.amount;
            }
          }
        }
      }
    }

    return total;
  }
}
