import { AuthService } from '@capturum/auth';
import { Injectable } from '@angular/core';
import { Observable } from '@root/node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ExactOnlineService extends ApiServiceWithLoaderService {
  public endpoint = 'exact-online';

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  public getUrl(): Observable<{ url: string }> {
    return this.get(`/${this.endpoint}/login-url`);
  }

  public submitCode(code: string): Observable<any> {
    return this.post(`/${this.endpoint}/code`, {
      code: code,
    });
  }
}
