import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ProjectModule } from '@features/project/project.module';
import { QuotationSignComponent } from '@features/quotationSign/quotation-sign.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { LayoutsModule } from '@features/gui/layouts/layouts.module';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';
import { CommonModule } from '@angular/common';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';

@NgModule({
  declarations: [QuotationSignComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    CheckboxModule,
    CalendarModule,
    ProjectModule,
    CapturumListRendererModule,
    LayoutsModule,
    CapturumButtonModule,
    CapturumCalendarModule,
    CapturumSelectButtonModule,
    CommonModule,
    CapturumSharedModule,
    CapturumCheckboxModule,
  ],
  exports: [],
})
export class QuotationSignModule {}
