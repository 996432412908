import { Component, Input } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { FilterMatchMode, FilterType } from '@capturum/ui/api';
import { FilterConfig } from '@core/models/filter-config.model';
import { FilterMetadata, FilterOperator } from 'primeng/api';
import { FilterService } from '../../services/table-filter.service';
import { SearchService } from '../../services/table-search.service';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent extends DestroyBase {
  @Input() public config: FilterConfig[];
  @Input() public filters: Record<string, FilterMetadata>;

  public FilterType: typeof FilterType = FilterType;
  public FilterMatchMode: typeof FilterMatchMode = FilterMatchMode;

  constructor(public readonly filterService: FilterService, public readonly searchService: SearchService) {
    super();
  }

  public updateFilter(value: any, field: string, matchMode: FilterMatchMode): void {
    this.filterService.updateFilters({
      field,
      value,
      matchMode,
      operator: FilterOperator.AND,
    });
  }

  public updateSearch(value: string): void {
    this.searchService.setSearchValue(value);
  }
}
