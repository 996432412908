<form [formGroup]="form" class="w-100">
  <div class="modal-body">
    <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['name'].valid }">
      <div class="row">
        <label class="col-4 m-0 control-label" asteriks>{{
          'movers_complete.inventory.contact.name.label' | translate
        }}</label>

        <div class="col-8">
          <cap-input type="text" formControlName="name"></cap-input>
          <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['name'].valid">{{
            'movers_complete.inventory.add.name.validation' | translate
          }}</span>
        </div>
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['remarks'].valid }">
      <div class="row">
        <label class="col-4 m-0 control-label">{{ 'movers_complete.manage.contact.remarks.label' | translate }}</label>

        <div class="col-8">
          <cap-input type="text" formControlName="remarks"></cap-input>
          <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['remarks'].valid">{{
            'movers_complete.inventory.add.remarks.validation' | translate
          }}</span>
        </div>
      </div>
    </div>

    <div
      *ngIf="hasBulkPrices"
      class="form-group"
      [ngClass]="{ 'has-error': showErrors && !this.form.controls['volume'].valid }">
      <div class="row">
        <label class="col-4 m-0 control-label" asteriks>{{
          'movers_complete.inventory.cubic.meters.label' | translate
        }}</label>

        <div class="col-8">
          <cap-input
            required
            maxlength="255"
            formControlName="volume"
            type="number"
            pattern="^[0-9]+((,|.)[0-9]{0,2})?$"></cap-input>
        </div>

        <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['volume'].valid">{{
          'movers_complete.inventory.add.cubic.meters.validation' | translate
        }}</span>
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['price'].valid }">
      <div class="row">
        <label class="col-4 m-0 control-label" asteriks>{{
          'movers_complete.inventory.overview.price.label' | translate
        }}</label>

        <div class="col-8">
          <cap-input
            required
            maxlength="255"
            formControlName="price"
            type="number"
            pattern="^[0-9]+((,|.)[0-9]{0,2})?$"></cap-input>
        </div>

        <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['volume'].valid">{{
          'movers_complete.inventory.add.price.validation' | translate
        }}</span>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <cap-button
      type="button"
      styleClass="secondary"
      [label]="'button.close' | translate"
      (click)="close()"></cap-button>

    <cap-button
      styleClass="primary"
      data-dismiss="modal"
      [disabled]="!form.valid"
      [label]="'button.add' | translate"
      (click)="submit()">
    </cap-button>
  </div>
</form>
