<div class="box-content-wrap">
  <div class="ibox-content" *ngIf="project">
    <div class="specialties-activities">
      <ng-container *ngIf="project.hasSpecialties()">
        <div class="row border-bottom pt-3 pb-3">
          <div class="col-sm-12">
            <h5>VAKWERK:</h5>

            <ng-container *ngFor="let specialty of project.specialties">
              <ng-container *ngIf="specialty.applicable">
                <div class="row">
                  <div class="col-sm-3">
                    <p>{{ specialty.specialty?.name }}</p>
                  </div>

                  <div class="col-sm-2">
                    <p>{{ specialty?.hours_estimate }} uur</p>
                  </div>

                  <div class="col-sm-2">
                    <p>{{ specialty.start | date : 'dd-MM-yyyy' }}</p>
                  </div>

                  <div class="col-sm-5">
                    <p>{{ specialty.details }}</p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="project.hasActivities()">
        <div class="row border-bottom pt-3 pb-3">
          <div class="col-sm-12">
            <h5>WERKZAAMHEDEN:</h5>

            <ng-container *ngFor="let activity of orderedActivities">
              <ng-container *ngIf="activity.applicable">
                <div class="row">
                  <div class="col-sm-3">
                    <p>{{ activity?.activity?.name }}</p>
                  </div>

                  <div class="col-sm-3">
                    <p>{{ activity.number_movers || 0 }} verhuizers / {{ activity.number_trucks || 0 }} wagens</p>
                  </div>

                  <div class="col-sm-2">
                    <p *ngIf="activity?.start">{{ activity.start | date : 'dd-MM-yyyy' }}</p>
                  </div>

                  <div class="col-sm-4">
                    <p *ngIf="activity?.details">{{ activity.details }}</p>
                  </div>

                  <div class="col-sm-4 spacing-address-activity">
                    <p *ngIf="activity?.address">Adres {{ activity.address }}</p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="ibox-content" *ngIf="tableOptions">
      <em-offline-table [options]="tableOptions" (rowClicked)="onRowClick($event)"> </em-offline-table>
    </div>
  </div>

  <div class="ibox-footer">
    <cap-button label="Toevoegen" [disabled]="disabled" icon="fa fa-plus" styleClass="primary" (onClick)="onAddClick()">
    </cap-button>

    <cap-button
      label="Verwijderen"
      [disabled]="disabled"
      icon="fa fa-trash"
      styleClass="primary"
      (onClick)="onDeleteClick()">
    </cap-button>
  </div>
</div>
