import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { MaterialGroup } from '@domain/models/material-group.model';

@Component({
  selector: 'app-material-header',
  templateUrl: 'material-header.component.html',
  styleUrls: ['./material-header.component.scss'],
})
export class MaterialHeaderComponent implements OnChanges {
  @Output()
  materialGroupChange = new EventEmitter<MaterialGroup>();

  @Input()
  materialGroups;

  public materialGroupList: SelectItem[] = [];
  public materialGroup: MaterialGroup;

  public ngOnChanges() {
    this.loadMaterialGroups();
  }

  /**
   * When new inventory is selected
   */
  public onMaterialGroupChange() {
    this.materialGroupChange.emit(this.materialGroup);
  }

  /**
   * Load inventories for dropdown
   */
  private loadMaterialGroups() {
    this.materialGroupList = [];
    if (!this.materialGroups) {
      return;
    }

    this.materialGroups.forEach((materialGroup: MaterialGroup) => {
      this.materialGroupList.push({
        label: materialGroup.name,
        value: materialGroup,
      });
    });
  }
}
