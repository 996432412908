import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByIndex',
})
export class SortByIndexPipe implements PipeTransform {
  transform(values: any[], relation?: string, relationSortField?: string): any {
    if (values && values.length > 0) {
      return values.sort((a, b) => {
        if (relation && relationSortField) {
          const relationA = a[relation];
          const relationB = b[relation];

          if (relationA && relationB) {
            return relationA[relationSortField] > relationB[relationSortField] ? 1 : -1;
          }

          return -1;
        }

        return a.index > b.index ? 1 : -1;
      });
    }

    return values;
  }
}
