import { ManageDefaultItemsDetailComponent } from './../default-items-detail/default-items-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-default-items-list',
  templateUrl: './default-items-list.component.html',
  styleUrls: ['./default-items-list.component.scss'],
})
export class ManageDefaultItemsListComponent {
  public listKey = 'list_default_items';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageDefaultItemsDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.item.single'),
    });
  }
}
