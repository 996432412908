import { DomainModel } from '@domain/domain.model';
import { InventoryItem } from '@domain/models/inventory-item.model';

import { v4 as uuid } from 'uuid';

export class Inventory extends DomainModel {
  // Configuration
  public entity = 'inventory';
  public table = 'inventories';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public parent_id: string;
  public parent: Inventory;
  public parent_name: string;
  public cascading_name: string;
  public default_inventory_id: number;
  public has_children = false;
  public name?: string;
  public building?: string;
  public floor?: string;
  public volume?: number;

  public packing_amount = 0;
  public assembly_amount = 0;

  public items: InventoryItem[] = [];
  public inventory_items: InventoryItem[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    this.items = await InventoryItem.query.where('inventory_id').equals(this.id).toArray();

    if (this.parent_id) {
      this.parent = await Inventory.query.where('id').equals(this.parent_id).first();
      await this.parent.init();
      this.parent_name = this.parent.name;
    }

    this.has_children = (await Inventory.query.where('parent_id').equals(this.id).count()) > 0;
    this.cascading_name = this.getCascadingName();
  }

  protected getCascadingName(): string {
    let name = this.name;

    if (this.parent_id) {
      name = this.parent.getCascadingName() + ' > ' + name;
    }

    return name;
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      parent_id: this.parent_id,
      parent_name: this.parent_name,
      cascading_name: this.cascading_name,
      default_inventory_id: this.default_inventory_id,
      name: this.name || '',
      building: this.building || '',
      floor: this.floor || 0,
      packing_amount: this.packing_amount || 0,
      assembly_amount: this.assembly_amount || 0,
    };
  }
}
