<div class="loading-container">
  <div *ngIf="tableData.length">
    <p-table
      [value]="tableData"
      [pSelectableRow]="true"
      [(selection)]="selectedRows"
      (onRowSelect)="handleRowToggle($event.data, true)"
      (onRowUnselect)="handleRowToggle($event.data, false)">
      <ng-template pTemplate="header">
        <tr>
          <th class="column-checkbox">
            <p-tableHeaderCheckbox
              #headerCheckbox
              (click)="selectAllRows(headerCheckbox.checked)"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let column of options.columns" pSortableColumn="{{ column.name }}">
            {{ column.title }} <p-sortIcon field="{{ column.name }}"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-address>
        <tr>
          <td><p-tableCheckbox [value]="address"></p-tableCheckbox></td>
          <td *ngFor="let column of options.columns" (click)="onRowClick(address)">{{ address[column.name] }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
