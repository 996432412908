import { Injectable } from '@angular/core';
import { ApiService } from '@node_modules/@capturum/api';
import { Inventory } from '@domain/models/inventory.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends ApiService<Inventory> {
  protected endpoint = 'project';

  public getInventoriesByProjectId(projectId: string): Observable<Inventory[]> {
    return this.apiHttp.get<any>(`/${this.endpoint}/${projectId}/inventories`).pipe(
      map((response) => {
        return response.data.inventories_deep_total;
      })
    );
  }
}
