export class TenantConfigStylesheet {
  public primary_color: string;

  constructor(attributes) {
    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        this[attributeName] = attributes[attributeName];
      }
    }
  }
}
