import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ProjectService } from '@shared/services/project.service';
import { InventoryType } from '@core/enums/inventory-type.enum';
import { Inventory } from '@domain/models/inventory.model';
import { Project } from '@domain/models/project.model';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-inventory-header',
  templateUrl: 'inventory-header.component.html',
  styleUrls: ['./inventory-header.component.scss'],
})
export class InventoryHeaderComponent implements OnInit, OnChanges {
  @Input() inventory: Inventory;
  @Input() project: Project;
  @Input() inventories: Inventory[];
  @Input() volumeTotal: number;
  @Input() meterboxTotal: number;
  @Input() disabled: boolean;

  @Output() inventoryChange = new EventEmitter<Inventory>();

  public floor;
  public inventoryType = InventoryType;
  public inventoryList: SelectItem[] = [];
  public hideInventoriesTopbar = false;

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private settingService: SettingService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    this.hideInventoriesTopbar = this.settingService.getValue('movers_complete.hide_inventories_topbar');
  }

  public ngOnChanges(): void {
    this.loadInventories();
  }

  /**
   * When new inventory is selected
   */
  public onInventoryChange(): void {
    this.inventoryChange.emit(this.inventory);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add)');
  }

  /**
   * Delete selected inventory
   */
  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u deze inventarisatie verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.projectService.deleteInventory(this.inventory?.id);
      },
    });
  }

  /**
   * Load inventories for dropdown
   */
  private loadInventories(): void {
    this.inventoryList = [];

    if (this.inventories) {
      this.inventories.forEach((inventory: Inventory) => {
        const itemAmount = inventory.items.reduce((amount, current) => {
          if (!current.amount) {
            current.amount = 0;
          }

          return amount + current.amount;
        }, 0);

        this.inventoryList.push({
          label: `${inventory.cascading_name ?? inventory.name} (${+itemAmount ? +itemAmount : 0})`,
          value: inventory,
          disabled: inventory.has_children,
        });
      });

      this.inventoryList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
  }
}
