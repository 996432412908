import Dexie from 'dexie';
import { environment } from '@environments/environment';
import { DomainModel } from '@domain/domain.model';
import { Activity } from '@domain/models/activity.model';
import { Address } from '@domain/models/address.model';
import { AddressTemplate } from '@domain/models/address-template.model';
import { AddressType } from '@domain/models/address-type.model';
import { ApiLog } from '@domain/models/api-log.model';
import { BulkPrice } from '@domain/models/bulk-price.model';
import { Client } from '@domain/models/client.model';
import { ClientTemplate } from '@domain/models/client-template.model';
import { Contact } from '@domain/models/contact.model';
import { ContactTemplate } from '@domain/models/contact-template.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { DefaultInventoryItem } from '@domain/models/default-inventory-item.model';
import { DefaultItem } from '@domain/models/default-item.model';
import { Event } from '@domain/models/event.model';
import { HouseType } from '@domain/models/house-type.model';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { Machine } from '@domain/models/machine.model';
import { MachineType } from '@domain/models/machine-type.model';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { Picture } from '@domain/models/picture.model';
import { Project } from '@domain/models/project.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Quotation } from '@domain/models/quotation.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { RelationGroup } from '@domain/models/relation-group.model';
import { Role } from '@domain/models/role.model';
import { Setting } from '@domain/models/setting.model';
import { Signature } from '@domain/models/signature.model';
import { Specialty } from '@domain/models/specialty.model';
import { Task } from '@domain/models/task.model';
import { Tenant } from '@domain/models/tenant.model';
import { User } from '@domain/models/user.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Location } from '@domain/models/location.model';
import Transaction = Dexie.Transaction;
import { Type } from '@angular/core';
import { QuotationCheck } from '@domain/models/quotation-check.model';

type MigrationFn = (transaction: Transaction) => PromiseLike<any> | void;
type DexieStoreVersions = {
  version: number;
  schema: Record<string, string> | null;
  migrator?: MigrationFn | null;
}[];

export class DexieStore extends Dexie {
  private static _store: DexieStore = null;
  private static _isReady: boolean;

  /**
   * This map defines which table is mapped to which DomainModel
   *
   * @private
   */
  private classMap: Record<string, Type<DomainModel>> = {
    activities: Activity,
    addresses: Address,
    address_templates: AddressTemplate,
    address_types: AddressType,
    api_logs: ApiLog,
    bulk_prices: BulkPrice,
    clients: Client,
    client_templates: ClientTemplate,
    contacts: Contact,
    contact_templates: ContactTemplate,
    default_inventories: DefaultInventory,
    default_inventory_items: DefaultInventoryItem,
    default_items: DefaultItem,
    events: Event,
    house_types: HouseType,
    inventories: Inventory,
    inventory_items: InventoryItem,
    locations: Location,
    machines: Machine,
    machine_type: MachineType,
    materials: Material,
    material_groups: MaterialGroup,
    pictures: Picture,
    projects: Project,
    project_activities: ProjectActivity,
    project_materials: ProjectMaterial,
    project_specialties: ProjectSpecialty,
    quotations: Quotation,
    quotation_materials: QuotationMaterial,
    quotation_tasks: QuotationTask,
    relation_groups: RelationGroup,
    roles: Role,
    settings: Setting,
    signatures: Signature,
    specialties: Specialty,
    tasks: Task,
    tenant: Tenant,
    users: User,
    work_assignments: WorkAssignment,
    address_work_assignments: WorkAssignmentAddress,
    work_assignment_items: WorkAssignmentItem,
    quotation_checks: QuotationCheck,
  };

  /**
   * The different version definitions of the indexedDB
   *
   * @private
   */
  private versions: DexieStoreVersions = [
    {
      version: 1,
      schema: {},
    },
    {
      version: 230,
      schema: {
        activities: '++id, project_type_base_data_value_id',
        addresses: 'id, remote_id, project_id',
        address_templates: 'id, location_id',
        address_types: '++id',
        api_logs: 'id, project_id, api, status, message, remote_id, data, created_at, updated_at',
        bulk_prices: '++id, remote_id, distance_min, distance_max, volume_min, volume_max',
        clients: 'id, parent_id',
        client_templates: 'id, parent_id',
        contacts: 'id, client_id',
        contact_templates: 'id, location_id',
        default_inventories: 'cascading_id',
        default_inventory_items: '++id, default_inventory_id, default_item_id',
        default_items: '++id',
        events: 'id, eventable_id, eventable_type, api_log_id, event, created_by, created_at, updated_at',
        house_types: '++id',
        inventories: 'id, project_id, default_inventory_id, parent_id',
        inventory_items: 'id, inventory_id',
        locations: '++id, name, order',
        machines: '++id',
        machine_type: '++id',
        materials: '++id, material_group_id',
        material_groups: '++id',
        pictures: 'id, project_id',
        projects: 'id',
        project_activities: 'id, project_id, activity_id, [activity_id+project_id]',
        project_materials: 'id, project_id, material_id',
        project_specialties: 'id, project_id, specialty_id, [specialty_id+project_id]',
        quotations: 'id, project_id, reference',
        quotation_materials: 'id, quotation_id, material_id',
        quotation_tasks: 'id, quotation_id, task_id',
        relation_groups: '++id, name',
        roles: '++id',
        settings: '++id, tenant_id, name',
        signatures: '++id, work_assignment_id',
        specialties: '++id, project_type',
        tasks: '++id',
        users: '++id, role_id',
        work_assignments: 'id, project_id',
        address_work_assignments: 'id, work_assignment_id',
        work_assignment_items: 'id, work_assignment_id',
        tenant: '++id',
      },
    },
    {
      version: 229,
      schema: {
        tenant: '++id',
      },
    },
    {
      version: 230,
      schema: {
        machine_types: '++id',
      },
    },
    {
      version: 231,
      schema: {
        quotation_checks: '++id, project_type',
      },
    },
    {
      version: 232,
      schema: {
        address_fields: 'id',
        address_field_values: 'id, address_id',
      },
    },
    {
      version: 233,
      schema: {
        address_fields: 'id',
        address_field_values: 'id, address_id',
      },
    },
    {
      version: 234,
      schema: {
        contacts: '++id',
      },
    },
    {
      version: 235,
      schema: {
        contacts: '++id',
      },
    },
    {
      version: 236,
      schema: {
        contacts: '++id, project_id',
      },
    },
    {
      version: 237,
      schema: {
        contacts: '++id, project_id, client_id',
      },
    },
    {
      version: 238,
      schema: {
        contacts: '++id, project_id, client_id',
      },
    },
    {
      version: 239,
      schema: {
        quotation_checks: '++id, project_type_base_data_value_id',
      },
    },
  ];

  constructor() {
    super(environment.db_name, { autoOpen: true });
  }

  public static getInstance(): DexieStore {
    if (!DexieStore._store) {
      DexieStore._store = new DexieStore();
    }

    return DexieStore._store;
  }

  public async initialize(): Promise<void> {
    if (!this.isOpen() && !DexieStore._isReady) {
      for (const version of this.versions) {
        const newVersion = this.version(version.version);

        if (version.schema) {
          newVersion.stores(version.schema as Record<string, string>);
        }

        if (version.migrator) {
          newVersion.upgrade((tx) => {
            return (version.migrator as MigrationFn)(tx);
          });
        }
      }

      // Map each table to class model
      Object.keys(this.classMap).forEach((key) => {
        this[key].mapToClass(this.classMap[key]);
      });

      DexieStore._isReady = true;
      DomainModel.store = this;
    }
  }

  public async reset(): Promise<void> {
    await this.delete();
    DexieStore._isReady = false;
    DexieStore._store = new DexieStore();
    await this.initialize();
  }
}
