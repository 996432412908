import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss'],
})
export class InventoryPicturesComponent implements OnInit {
  public listKey = 'list_pictures';
  public project: Project;
  public disabled = true;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private projectService: ProjectService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public addNewFile(): void {
    if (!this.disabled) {
      this.dialogService.open(InventoryPictureItemDetailComponent, {
        header: this.translateService.instant('movers_complete.manage.quotation_attachment.file.label'),
      });
    }
  }
}
