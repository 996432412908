import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExactOnlineService } from '@shared/services/exact-online.service';
import { OnInit } from '@node_modules/@angular/core';
import { Observable, filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-settings-exact',
  templateUrl: 'exact-online.component.html',
  styleUrls: ['./exact-online.component.scss'],
})
export class ExactOnlineComponent implements OnInit {
  public text$: Observable<string>;

  constructor(private exactService: ExactOnlineService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.text$ = this.route.queryParams.pipe(
      filter((params) => {
        return !!params.code;
      }),
      switchMap(({ code }) => {
        return this.exactService.submitCode(code);
      })
    );
  }
}
