import { Dexie } from 'dexie';
import * as moment from 'moment';
import { DexieStore } from '@domain/dexie-store';

/**
 * Abstract class for models
 * Contains base methods for data logic and defines properties used for database setup (Dexie)
 *
 * A model can contain these default fields:
 * '++id'         field is unique and autoincremented
 * '*_remote_id'  field contains the id of the backend record.
 * 'updated_at'   field contains timestamp when record was updated locally
 * 'synced_at'    field contains timestamp when record was synced
 * 'sync_message' field contains message if sync failed
 */
export abstract class DomainModel {
  static store: DexieStore;

  abstract entity: string;
  abstract table: string;

  public id: any;

  public synced_at?: Date;
  public synced_message?: string;

  public remote_id?: number | string;

  public created_at?: Date | string;
  public updated_at?: Date | string;
  public deleted_at?: Date | string;

  /**
   * Set if model should be synchronised with server
   */
  public sync = false;

  constructor(attributes) {
    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        const instanceAttribute = attributes[attributeName];

        this[attributeName] = instanceAttribute;
      }
    }

    // Generate negative unique id if not set
    if (!this.id) {
      this.id = -Date.now();
    }
  }

  // @ts-ignore
  static get query(this: new (attributes) => DomainModel): Dexie.Table<any, any> {
    return DomainModel.store[new this({}).table];
  }

  public static list<T extends DomainModel>(): T[] {
    return [];
  }

  public static getInstance(this: new (attributes) => DomainModel): DomainModel {
    return new this({});
  }

  public async init(): Promise<any> {}

  /**
   * Returns the object representation suitable for the backend
   */
  public getData(): any {
    const data = JSON.parse(JSON.stringify(this));

    delete data.entity;
    delete data.table;
    delete data.schema;
    delete data.synced_at;
    delete data.synced_message;
    delete data.sync;

    data.created_at = this.formatDate(data.created_at);
    data.updated_at = this.formatDate(data.updated_at);
    data.deleted_at = this.formatDate(data.deleted_at);

    return data;
  }

  protected formatDate(date?: Date): string {
    if (!date) {
      return null;
    }

    let newData = moment(date).format('YYYY-MM-DD HH:mm:ss');

    if (newData === 'Invalid date') {
      newData = moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
    }

    return newData;
  }

  protected displayDate(date?: Date): string {
    if (!date) {
      return '';
    }

    return moment(date).format('DD-MM-YYYY');
  }

  protected displayDateTime(date?: Date): string {
    if (!date) {
      return '';
    }

    return moment(date).format('DD-MM-YYYY HH:mm:ss');
  }
}
