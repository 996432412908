<div class="form-group pb-3" *ngIf="applicable">
  <div class="detail-item row">
    <label class="control-label col-3">Datum</label>

    <cap-calendar
      [locale]="localeNL"
      class="col"
      [disabled]="disabled"
      [(ngModel)]="activity.startDate"
      dateFormat="dd-mm-yy"
      [minDate]="currentDate"
      placeholder="Selecteer een datum">
    </cap-calendar>
  </div>

  <div class="detail-item row">
    <label class="control-label col-3">Locatie</label>

    <cap-dropdown
      [(ngModel)]="activity.address_id"
      [disabled]="disabled"
      [readonly]="disabled"
      class="col"
      [options]="addressOptions"
      [showClear]="true"
      placeholder="Selecteer een locatie">
    </cap-dropdown>
  </div>

  <div class="detail-item row">
    <label class="control-label col-3">Uitvoering</label>

    <div class="row col">
      <div class="col-6">
        <cap-input
          label="Verhuizers"
          maxlength="255"
          [disabled]="disabled"
          [(ngModel)]="activity.number_movers"
          type="number"
          pattern="^[0-9]$">
        </cap-input>
      </div>

      <div class="col-6">
        <cap-dropdown
          *ngIf="isVanRooyen"
          [options]="machines"
          [disabled]="disabled"
          [readonly]="disabled"
          [(ngModel)]="activity.machine"
          (onChange)="onSelectMachine($event)"
          [showClear]="true"
          label="Wagen"
          placeholder="Selecteer een wagen">
        </cap-dropdown>

        <cap-input
          *ngIf="!isVanRooyen"
          maxlength="255"
          [disabled]="disabled"
          [(ngModel)]="activity.number_trucks"
          type="number"
          label="Wagens"
          pattern="^[0-9]$">
        </cap-input>
      </div>
    </div>
  </div>

  <div class="detail-item row">
    <label class="control-label col-3 input-label">Bijzonderheden</label>

    <cap-textarea [disabled]="disabled" [readOnly]="disabled" class="col" [(ngModel)]="activity.details">
    </cap-textarea>
  </div>

  <div *ngIf="!disabled">
    <cap-button
      *ngIf="showButton"
      styleClass="primary"
      class="add-button"
      icon="fa fa-plus"
      [label]="'button.add' | translate"
      (onClick)="onAddActivityClick(activity.activity.id)">
    </cap-button>

    <cap-button
      *ngIf="allowDelete"
      styleClass="error"
      icon="fa fa-trash"
      [label]="'button.delete' | translate"
      (onClick)="removeActivityClick(activity)">
    </cap-button>
  </div>
</div>
