<div class="box-content-wrap">
  <div class="ibox-title">
    <h5>{{ 'movers_complete.inventory.address.address-sort.title' | translate }}</h5>
    <span class="fa fa-times pull-right" (click)="onCloseClick()"></span>
  </div>

  <div class="ibox-content">
    <div class="boundary">
      <div class="sort-list" cdkDropList (cdkDropListDropped)="drop($event)">
        <div
          class="sort-item"
          cdkDrag
          *ngFor="let address of addresses"
          id="{{ address.id }}"
          cdkDragBoundary=".boundary">
          {{ address?.index || 'n.v.t.' }} - {{ getAddressDisplayName(address) }}
        </div>
      </div>
    </div>

    <span class="helper-text">{{ 'movers_complete.inventory.address.address-sort.instructions' | translate }}</span>
  </div>

  <div class="ibox-footer">
    <cap-button
      styleClass="primary"
      [disabled]="this.disableSave"
      [label]="'button.submit' | translate"
      (onClick)="save()">
    </cap-button>

    <cap-button styleClass="secondary" [label]="'button.close' | translate" (onClick)="onCloseClick()"> </cap-button>
  </div>
</div>
