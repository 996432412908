<div class="new-inventory-item flex-column">
  <div class="tile-header d-flex">
    <span class="mr-3"><ng-content></ng-content></span>
  </div>

  <div class="tile-footer d-flex" [formGroup]="form">
    <cap-input-number
      *ngIf="!disabled"
      [showButtons]="true"
      buttonLayout="horizontal"
      inputId="horizontal"
      spinnerMode="horizontal"
      decrementButtonClass="default"
      incrementButtonClass="default"
      incrementButtonIcon="pi pi-plus"
      decrementButtonIcon="fa fa-minus"
      [min]="0"
      [max]="100"
      formControlName="amount"
      [ngModel]="amount | number : '1.0-2'"
      (ngModelChange)="changeAmount($event)">
    </cap-input-number>

    <span *ngIf="hasBulkPrices" class="d-flex flex-grow-1 align-items-center justify-content-center">
      {{ 'movers_complete.project.quotation.total-volume.label' | translate }}
      {{ amount | number : '1.0-2' }} m<sup>3</sup>
    </span>
  </div>
</div>
