<div class="ibox scroll-fix">
  <div class="ibox-title">
    <h5>{{ 'movers_complete.entity.address.single' | translate }}</h5>
    <span class="fa fa-times close-icon" (click)="onCloseClick()"></span>
  </div>

  <ng-container *ngIf="form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
      <div class="ibox-content">
        <div
          class="form-group"
          [ngClass]="{
            'has-error': errors.address_type_id || (showErrors && this.form.controls['address_type_id'].invalid)
          }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.address.type.label' | translate }}
          </label>
          <div class="col-sm-8">
            <cap-dropdown
              [options]="addressTypes"
              formControlName="address_type_id"
              [placeholder]="'button.select' | translate"
              [showClear]="true">
            </cap-dropdown>

            <span class="help-block m-b-none" *ngIf="errors.address_type_id">{{ errors.address_type_id[0] }}</span>
          </div>
        </div>

        <div class="divider"></div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': errors.house_type_id || (showErrors && this.form.controls['house_type_id'].invalid)
          }">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.address.house_type.label' | translate }}
          </label>
          <div class="col-sm-8">
            <cap-dropdown
              [options]="houseTypes"
              formControlName="house_type_id"
              [placeholder]="'button.select' | translate"
              [showClear]="true">
            </cap-dropdown>
            <span class="help-block m-b-none" *ngIf="errors.house_type_id">{{ errors.house_type_id[0] }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.address.companies.label' | translate }}
          </label>
          <div class="col-sm-8">
            <cap-dropdown
              (change)="updateAddressTemplatesList($event?.value)"
              [options]="companiesList"
              [showClear]="true"
              [filter]="true"
              formControlName="company_id"
              [placeholder]="'movers_complete.inventory.contact.companies.placeholder' | translate">
            </cap-dropdown>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.address.fixed_addresses.label' | translate }}
          </label>
          <div class="col-sm-8">
            <cap-dropdown
              (change)="selectAddressTemplate($event?.value)"
              [options]="addressTemplatesList"
              [showClear]="true"
              [filter]="true"
              formControlName="address_template_id"
              [placeholder]="'movers_complete.general.placeholder.select_applicable' | translate">
            </cap-dropdown>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4" [asteriks]="!isInvoiceAddressType">
            {{ 'movers_complete.inventory.address.zipcode_housenumber.label' | translate }}
          </label>

          <div class="col-sm-8">
            <div class="row">
              <div class="col-8">
                <input class="form-control" formControlName="zipcode" (blur)="onAddressChange()" />
              </div>

              <div class="col-4 housenumber-addition">
                <input
                  type="number"
                  class="form-control col-7 housenumber"
                  formControlName="housenumber"
                  (blur)="onAddressChange()" />

                <input class="form-control col-4 offset-1 addtion" formControlName="housenumber_add" />
              </div>
            </div>

            <div [ngClass]="{ 'has-error': errors.zipcode || (showErrors && this.form.controls['zipcode'].invalid) }">
              <span class="help-block m-b-none" *ngIf="errors.zipcode">{{ errors.zipcode[0] }}</span>
            </div>

            <div
              [ngClass]="{
                'has-error': errors.housenumber || (showErrors && this.form.controls['housenumber'].invalid)
              }">
              <span class="help-block m-b-none" *ngIf="errors.housenumber">{{ errors.housenumber[0] }}</span>
            </div>

            <div
              [ngClass]="{
                'has-error': errors.housenumber_add || (showErrors && this.form.controls['housenumber_add'].invalid)
              }">
              <span class="help-block m-b-none" *ngIf="errors.housenumber_add">{{ errors.housenumber_add[0] }}</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4" [asteriks]="!isInvoiceAddressType">
            {{ 'movers_complete.manage.address.street.label' | translate }}
          </label>

          <div
            class="col-sm-8"
            [ngClass]="{ 'has-error': errors.street || (showErrors && this.form.controls['street'].invalid) }">
            <input class="form-control" formControlName="street" />
            <span class="help-block m-b-none" *ngIf="errors.street">{{ errors.street[0] }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4" [asteriks]="!isInvoiceAddressType">
            {{ 'movers_complete.manage.address.city.label' | translate }}
          </label>

          <div
            class="col-sm-8"
            [ngClass]="{ 'has-error': errors.city || (showErrors && this.form.controls['city'].invalid) }">
            <input class="form-control" formControlName="city" />
            <span class="help-block m-b-none" *ngIf="errors.city">{{ errors.city[0] }}</span>
          </div>
        </div>

        <div
          class="form-group"
          [ngClass]="{ 'has-error': errors.country || (showErrors && this.form.controls['country'].invalid) }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.manage.address.country.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" formControlName="country" />
            <span class="help-block m-b-none" *ngIf="errors.country">{{ errors.country[0] }}</span>
          </div>
        </div>

        <div
          class="form-group"
          [ngClass]="{ 'has-error': errors.description || (showErrors && this.form.controls['description'].invalid) }">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.address.description.label' | translate }}
          </label>
          <div class="col-sm-8">
            <cap-textarea
              class="form-control"
              [disabled]="disabled"
              [readonly]="disabled"
              formControlName="description"
              [rows]="5"></cap-textarea>
            <span class="help-block m-b-none" *ngIf="errors.description">{{ errors.description[0] }}</span>
          </div>
        </div>
      </div>

      <div class="ibox-footer">
        <cap-button
          *ngIf="project?.status !== 'booked'"
          type="submit"
          [disabled]="form.invalid"
          styleClass="primary"
          [label]="(mode.isAdd ? 'button.add' : 'button.edit') | translate"
          [icon]="mode.isAdd ? 'fa fa-plus' : 'fa fa-pencil'">
        </cap-button>

        <cap-button styleClass="secondary" (onClick)="onCloseClick()" [label]="'button.close' | translate"></cap-button>
      </div>
    </form>
  </ng-container>
</div>
