<div class="ibox-content striped-forms">
  <div class="section-item row pb-3">
    <h5 class="mb-3 col-12">{{ 'movers_complete.inventory.option.specialty.title' | translate }}</h5>

    <ng-container *ngFor="let item of specialties | sortByProperty : 'specialty.name'">
      <div *ngIf="item?.specialty" class="specialty col-sm-12 col-md-6">
        <fieldset [disabled]="disabled">
          <div class="row mb-3">
            <label class="col-3">{{ item.specialty?.name }}</label>

            <cap-input-switch
              [disabled]="disabled"
              class="col"
              [ngModel]="item.applicable"
              (change)="toggleSwitch(item.id)"></cap-input-switch>
          </div>

          <ng-container *ngIf="item.applicable">
            <div class="row" *ngIf="item.specialty.has_hours">
              <label class="control-label col-3">
                {{ 'movers_complete.inventory.option.amount.label' | translate }}
              </label>

              <cap-input
                pattern="^[0-9]+((,|.)[0-9]{0,2})?$"
                class="col"
                maxlength="255"
                [(ngModel)]="item.hours_estimate"></cap-input>
            </div>

            <div class="row" *ngIf="item.specialty.has_remarks">
              <label class="control-label col-3">
                {{ 'movers_complete.inventory.option.remarks.label' | translate }}
              </label>

              <cap-textarea [(ngModel)]="item.details" class="col"></cap-textarea>
            </div>

            <div class="row" *ngIf="item.specialty.has_date">
              <label class="control-label col-3">
                {{ 'movers_complete.inventory.option.date.label' | translate }}
              </label>

              <cap-calendar
                [locale]="localeNL"
                [minDate]="currentDate"
                class="col"
                [(ngModel)]="item.startDate"
                dateFormat="dd-mm-yy"
                [placeholder]="'movers_complete.inventory.option.date.placeholder' | translate"></cap-calendar>
            </div>
          </ng-container>
        </fieldset>
      </div>
    </ng-container>
  </div>

  <div class="section-item row mt-3 pb-3">
    <h5 class="mb-3 col-12">{{ 'movers_complete.inventory.option.activity.title' | translate }}</h5>

    <ng-container *ngFor="let activity of displayActivityArray; let i = index">
      <div *ngIf="activity" class="activity col-sm-12 col-md-6">
        <fieldset [disabled]="disabled">
          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ activity?.name }}</label>

              <p-inputSwitch
                (click)="setApplicable(activity)"
                [disabled]="disabled"
                class="col"
                [(ngModel)]="activity.applicable">
              </p-inputSwitch>
            </div>

            <ng-container *ngFor="let activityItem of activity.activities; let j = index">
              <app-options-detail-item
                [disabled]="disabled"
                [activity]="activityItem"
                (activityClick)="onAddActivityClick($event)"
                (onRemoveActivityClick)="onRemoveActivityClick($event)"
                [applicable]="activity.applicable"
                [showButton]="activity.activities.length - 1 === j"
                [allowDelete]="activity.activities.length > 1"
                [addressOptions]="addressOptions">
              </app-options-detail-item>
            </ng-container>
          </div>
        </fieldset>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="form" class="section-item mt-3 pb-3">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <label class="control-label">{{
          'movers_complete.inventory.option.activity_description.label' | translate
        }}</label>

        <cap-textarea
          [readonly]="disabled"
          [disabled]="disabled"
          [placeholder]="'movers_complete.inventory.option.activity.description.placeholder' | translate"
          formControlName="own_description_activities"
          [rows]="4"
          [cols]="50"
          [autoResize]="true">
        </cap-textarea>
      </div>

      <div class="col-sm-12 col-md-6">
        <label class="control-label">Omschrijving werkzaamheden klant</label>

        <cap-textarea
          [readonly]="disabled"
          [disabled]="disabled"
          [placeholder]="'movers_complete.inventory.option.activity.description.placeholder' | translate"
          formControlName="client_description_activities"
          [rows]="4"
          [cols]="50"
          [autoResize]="true">
        </cap-textarea>
      </div>
    </div>
  </form>
</div>
