<div class="login-wrapper d-flex align-items-center justify-content-center h-100">
  <div class="content-wrapper row">
    <div class="content-left col-12 col-md-5">
      <div class="logo" data-test="logo">
        <img
          [src]="'zandbergen-logo.png' | assets : 'images' | addMyTenant | async"
          onerror="this.src = '/assets/images/zandbergen-logo.png'"
          alt="emendis" />
      </div>
    </div>

    <div class="content-right col-12 col-md-7">
      <!-- View/routes wrapper-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
