import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  name: 'Test',
  applicationName: 'Inventarisatie module',
  baseUrl: 'https://moverscomplete-test.staging.emendis.nl/api',
  sentryUrl: 'https://9878948b02ba454f8eb63f226068e006@o4504360297496576.ingest.us.sentry.io/4504377869008896',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: false,
  },
  db_name: 'inventorycomplete_invc_db',
};
