import { DomainModel } from '@domain/domain.model';
import { Material } from '@domain/models/material.model';

import { v4 as uuid } from 'uuid';

export class ProjectMaterial extends DomainModel {
  // Configuration
  public entity = 'project-material';
  public table = 'project_materials';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public material_id: number;
  public amount: number;

  public material: Material;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    this.material = await Material.query.get(this.material_id);
  }

  public getData() {
    return {
      id: this.id,
      project_id: this.project_id,
      material_id: this.material_id,
      amount: this.amount || 0,
    };
  }
}
