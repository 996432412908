import { ManageRelationGroupsDetailComponent } from './../relation-groups-detail/relation-groups-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-relation-groups-list',
  templateUrl: './relation-groups-list.component.html',
  styleUrls: ['./relation-groups-list.component.scss'],
})
export class ManageRelationGroupsListComponent {
  public listKey = 'list_relation_groups';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageRelationGroupsDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.relation_group.single'),
    });
  }
}
