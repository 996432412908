<div *ngIf="options.columns && tableData" class="loading-container">
  <div *ngIf="statusList && typeList" class="d-flex justify-content-between mt-2">
    <cap-dynamic-filters
      #dynamicFiltersComponent
      [showResetButton]="false"
      [showRefreshButton]="false"
      [storageKey]="'online-project-list'"
      [filterConfig]="dynamicFilters"
      [(activeFilters)]="activeFilters"
      (activeFiltersChange)="handleFilterChange($event)">
    </cap-dynamic-filters>

    <div class="d-flex actions filter-buttons">
      <cap-button
        styleClass="tertiary"
        [label]="'reset.button' | translate"
        [icon]="'fas fa-times'"
        (onClick)="resetFilters()">
      </cap-button>

      <cap-button
        styleClass="tertiary"
        [label]="'refresh.button' | translate"
        [icon]="'fas fa-sync'"
        (onClick)="refreshFilters()">
      </cap-button>
    </div>
  </div>

  <cap-info-table
    #dataTable
    dataKey="id"
    class="online-table"
    [stateKey]="'online-project-list'"
    [columns]="tableColumns"
    [data]="tableData"
    [lazyLoading]="true"
    [loading]="loading"
    [pagination]="true"
    [paginator]="paginator"
    [selectable]="true"
    [texts]="{ noResults: options.noResultsMessage }"
    (onLazyLoad)="loadLazy($event)"
    (onRowClick)="onRowSelect($event)">
    <ng-template capTemplate="actions" let-item="item">
      <cap-row-action [actions]="actions" [item]="item"></cap-row-action>
    </ng-template>
  </cap-info-table>
</div>
