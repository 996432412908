import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CapturumFormRendererComponent, FormFieldsConfig, FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject, filter, first, takeUntil } from 'rxjs';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-additional-cost-quotation',
  templateUrl: './additional-cost-quotation.component.html',
  styleUrls: ['./additional-cost-quotation.component.scss'],
})
export class AdditionalCostQuotationComponent implements AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('formRenderer', { static: true })
  public formRenderer: CapturumFormRendererComponent;

  public modelId: string;
  public disabled: boolean = false;
  public showPricesExclVat: boolean;
  public formKey = 'form_project_quotation_additional_costs';
  public loaded: Observable<FormFieldsConfig>;

  public constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private formSaverService: FormSaverService,
    private projectService: ProjectService
  ) {
    this.modelId = this.dialogConfig.data?.item?.id;
    this.showPricesExclVat = this.dialogConfig.data?.showPricesExclVat ?? true;

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public ngAfterViewInit(): void {
    this.loaded = this.formRenderer?.fields$;

    this.formRenderer.form?.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((v) => {
          return v.project_id === null;
        })
      )
      .subscribe(() => {
        this.formRenderer.form.patchValue({
          project_id: this.dialogConfig.data?.project_id,
        });
      });

    this.formRenderer.form?.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((v) => {
          return v.price === null;
        })
      )
      .subscribe(() => {
        const formValue = this.formRenderer.form.value;

        if (this.showPricesExclVat) {
          this.formRenderer.form.patchValue({
            price: formValue.price_excl_vat,
          });
        } else {
          this.formRenderer.form.patchValue({
            price: formValue.price_excl_vat * (1 + formValue.vat_rate / 100),
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    const formValue = this.formRenderer.form.value;

    if (this.showPricesExclVat) {
      this.formRenderer.form.patchValue({
        price_excl_vat: formValue.price,
      });
    } else {
      this.formRenderer.form.patchValue({
        price_excl_vat: formValue.price / (1 + formValue.vat_rate / 100),
      });
    }

    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
