import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { User } from '@domain/models/user.model';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Observable, Subject } from '@node_modules/rxjs';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { toMapItems } from '@capturum/builders/core';
import { MapItem } from '@capturum/auth';
import { Role } from '@core/enums/role.enum';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @ViewChild('signature', { static: false }) signature: SignatureComponent;

  public form: any;
  public user: User = new User({});
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public roleList: SelectItem[] = [];
  public localeList$: Observable<MapItem[]>;
  public isAccountmanager = false;
  public isExecutor = false;

  private signatureEdited = false;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private api: ApiServiceWithLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.initForm();

    // Get id of address to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params['id'];

      this.isAdd = id === 'add';
      if (!this.isAdd) {
        this.api.get('/user/' + id).subscribe((result: any) => {
          this.user = result.data;
          this.initForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.initForm();
      }
    });

    this.api
      .get('/role/list')
      .pipe(toMapItems, takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result) {
          this.roleList = result;
        }
      });

    this.localeList$ = toMapItems(this.api.get('/tenant/locale/list'), 'id', 'name');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.user.id || null),
      name: this.formBuilder.control(this.user.name, Validators.required),
      email: this.formBuilder.control(this.user.email, Validators.email),
      password: this.formBuilder.control(this.user.password, Validators.minLength(8)),
      password_confirmation: this.formBuilder.control(this.user.password_confirmation, Validators.minLength(8)),
      current_role_id: this.formBuilder.control(this.user.current_role_id, Validators.required),
      is_accountmanager: this.formBuilder.control(this.user.is_accountmanager, Validators.required),
      is_executor: this.formBuilder.control(this.user.is_executor, Validators.required),
      locale_id: this.formBuilder.control(this.user.locale_id, Validators.required),
    });

    if (this.isAdd) {
      this.form.controls.password.setValidators([Validators.required, Validators.minLength(8)]);
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.showErrors = true;

      return;
    }

    // Check password match
    if (this.form.value.password !== this.form.value.password_confirmation) {
      this.showErrors = true;
      this.toastService.warning(
        this.translateService.instant('movers_complete.entity.user.plural'),
        this.translateService.instant('auth.reset-password.mismatch.message')
      );

      return;
    }

    this.showErrors = false;
    this.loading = true;

    const userData = { ...this.form.value };

    if (!userData.password || userData.password.length === 0) {
      delete userData.password;
      delete userData.password_confirmation;
    }

    if (this.signatureEdited) {
      userData.signature_image = this.user.signature_image;
    }

    let request;

    if (this.isAdd) {
      request = this.api.post('/user', userData);
    } else {
      request = this.api.patch('/user/' + userData.id, userData);
    }

    request.subscribe(
      (_) => {
        this.toastService.success(
          this.translateService.instant('movers_complete.entity.user.plural'),
          this.translateService.instant('movers_complete.entity.save.success.text', {
            entity: this.translateService.instant('movers_complete.entity.user.single'),
          })
        );
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/users');
        });
      },
      (error: any) => {
        this.loading = false;
        if (error.status === 422 && error.error) {
          this.toastService.warning(
            this.translateService.instant('movers_complete.entity.user.plural'),
            error.error.message
          );
        } else {
          this.toastService.error(
            this.translateService.instant('movers_complete.entity.user.plural'),
            this.translateService.instant('movers_complete.entity.update.error.text', {
              entity: this.translateService.instant('movers_complete.entity.user.plural'),
            })
          );
        }
      }
    );
  }

  public onDelete(): void {
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde gebuiker verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: (_) => {
        this.api.delete('/user/' + this.user.id).subscribe(
          (_) => {
            this.toastService.success(
              this.translateService.instant('movers_complete.entity.user.plural'),
              this.translateService.instant('movers_complete.entity.delete.success.text', {
                entity: this.translateService.instant('movers_complete.entity.user.single'),
              })
            );
            this.router.navigateByUrl('/').then(() => {
              // HACK Use double navigation to force reload..
              this.router.navigateByUrl('/admin/users');
            });
          },
          () => {
            this.toastService.error(
              this.translateService.instant('movers_complete.entity.user.plural'),
              this.translateService.instant('movers_complete.entity.delete.error.text', {
                entity: this.translateService.instant('movers_complete.entity.user.single'),
              })
            );
          }
        );
      },
    });
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/users');
  }

  public showSignatureForm(): void {
    this.signatureEdited = true;
    this.signature.showForm();
  }

  public deleteSignature(): void {
    this.signatureEdited = true;
    this.user.signature_image = null;
  }

  public onSelectRole(roleId: string): void {
    this.isAccountmanager = !!this.roleList.find((item) => {
      return item.value === roleId && item.label === Role.accountmanager;
    });
    this.isExecutor = !!this.roleList.find((item) => {
      return item.value === roleId && item.label === Role.uitvoerder;
    });
  }
}
