import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CapturumBuilderFileInputComponent } from '@capturum/builders/form-renderer';
import { ImageViewerComponent, ImageViewerService } from '@capturum/ui/image-viewer';
import { VaporFileUploadService } from '@capturum/api';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';

import { saveAs } from 'file-saver';
import { PdfViewerDialogComponent } from '@shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { FileApiService } from '@core/services/file-api.service';
import { Subject } from 'rxjs';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from 'rxjs/operators';

export interface FilePreviewItem {
  name: string;
  url?: string;
  id?: string;
  uploading?: boolean;
  uploadProgress?: number;
  file?: string;
}

@Component({
  selector: 'app-images-input-type',
  templateUrl: './images-input-type.component.html',
  styleUrls: ['./images-input-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImagesInputTypeComponent extends CapturumBuilderFileInputComponent implements OnInit, OnDestroy {
  public showImageViewer: boolean;
  public imageViewerSrc: any;
  public srcFile: string;
  public disabled = false;
  protected destroySubject$: Subject<boolean> = new Subject<boolean>();

  @ViewChild(ImageViewerComponent)
  public imageViewer: ImageViewerComponent;

  constructor(
    vaporFileUploadService: VaporFileUploadService,
    store: Store,
    cdr: ChangeDetectorRef,
    private imageViewerService: ImageViewerService,
    protected dialogService: DialogService,
    private fileService: FileApiService,
    private projectService: ProjectService
  ) {
    super(vaporFileUploadService, store, cdr);
  }

  public ngOnInit(): void {
    this.imageViewerService.setConfig({
      actions: [
        {
          icon: 'fas fa-download',
          action: () => {},
          key: 'download',
        },
      ],
    });

    this.projectService.projectIsReadOnly.pipe(takeUntil(this.destroySubject$)).subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    super.ngOnInit();
  }

  public openFile(event: { file: FilePreviewItem }): void {
    this.srcFile = event.file.name;

    if (this.srcFile.includes('.pdf')) {
      this.openPdfViewerPopup(event.file);
    } else {
      this.imageViewerSrc = [{ url: event.file.file, name: event.file.name }];
      this.showImageViewer = true;
    }
  }

  public openPdfViewerPopup(file: FilePreviewItem): void {
    this.dialogService.open(PdfViewerDialogComponent, {
      header: file.name,
      data: {
        url: file.file,
        name: file.name,
      },
    });
  }

  public handleFileUpload(files: File[]): void {
    super.handleFileChange(files);

    this.field.parent.form.markAsDirty();
  }

  public handleAction(event: string): void {
    if (event === 'download') {
      const file = this.previewFiles[this.imageViewer.imageIndex];

      if (file) {
        if (file.uploadProgress !== null) {
          this.fileService.download(file.id, file.name);
        } else {
          saveAs(file.url, file.name);
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next(true);
    this.destroySubject$.complete();
  }

  public async closePreviewer(): Promise<void> {
    this.showImageViewer = false;

    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }
}
