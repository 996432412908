import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, shareReplay, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@capturum/auth';
import { ApiService, ListOptions } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceWithLoaderService {
  private _loader = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private authService: AuthService) {}

  public getLoader(): Observable<number> {
    return this._loader.asObservable().pipe(shareReplay(1));
  }

  public onAuthError: () => void = () => {
    if (!environment.production) {
      console.warn('onAuthError has to be set in order to redirect to login page');
    }
  };

  public get(url: string, options?: ListOptions): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}${url}${ApiService.generateQuery(options)}`, {
        headers: this.getHeaders(),
        responseType: 'json',
      })
      .pipe(
        catchError((response: Response) => {
          return this.handleError(response);
        })
      );
  }

  public getBlob(url: string, type: string): Observable<any> {
    const headers = this.getHeaders();

    headers.delete('Content-Type');

    return this.http.get(`${environment.baseUrl}${url}`, { headers: headers }).pipe(
      map((response: Response) => {
        return new Blob([JSON.stringify(response.blob())], { type: type });
      }),
      catchError((response: Response) => {
        return this.handleError(response);
      })
    );
  }

  public post(url: string, data: any): Observable<any> {
    delete data.id;

    return this.http.post(`${environment.baseUrl}${url}`, data, { headers: this.getHeaders() }).pipe(
      catchError((response: Response) => {
        return this.handleError(response);
      })
    );
  }

  public patch(url: string, data: any): Observable<any> {
    delete data.id;

    return this.http
      .patch(`${environment.baseUrl}${url}`, data, { headers: this.getHeaders(), responseType: 'json' })
      .pipe(
        catchError((response: Response) => {
          return this.handleError(response);
        })
      );
  }

  public put(url: string, data: any): Observable<any> {
    return this.http
      .put(`${environment.baseUrl}${url}`, data, { headers: this.getHeaders(), responseType: 'json' })
      .pipe(
        catchError((response: Response) => {
          return this.handleError(response);
        })
      );
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}${url}`, { headers: this.getHeaders() }).pipe(
      catchError((response: Response) => {
        return this.handleError(response);
      })
    );
  }

  public increaseLoaderValueByOne(): void {
    setTimeout(() => {
      this._loader.next(0);
    }, 7500);

    return this._loader.next(+this._loader.getValue() + 1);
  }

  public decreaseLoaderValueByOne(): void {
    setTimeout(() => {
      let amount = this._loader.getValue() - 1;

      if (amount < 0) {
        amount = 0;
      }

      if (+this._loader.getValue() <= 0) {
        return this._loader.next(0);
      } else {
        return this._loader.next(+amount);
      }
    }, 10);
  }

  public getHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    if (customHeaders) {
      return customHeaders;
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const token = this.authService.getToken();

    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    return headers;
  }

  private handleError(error: any): Observable<any> {
    if (error.status === 401) {
      this.onAuthError();
    }

    return throwError(error);
  }
}
