import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4;

import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SelectItem } from 'primeng/api';
import { DexieStore } from '@domain/dexie-store';

/**
 * QueryOptions for sorting and filtering indexedDB based query's
 */
export class QueryOptions {
  public usePaging = true;
  public pageSize = 10;
  public pageIndex = 0;

  // Sorting variables
  public sortColumn = null;
  public sortOrder = 'desc';

  // Filtering variables
  // columns array contains array of object { name: , filter: , filterMode: }
  public columns: Array<any> = [];
  public globalFilter: any;

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }
}

@Injectable()
export class DataService {
  private indexedDB = DexieStore.getInstance();
  private allowBackend = false;
  private record;
  private totalRecords;
  private rec: Observable<any>;
  private result;
  private urlQuery;

  constructor(private api: ApiServiceWithLoaderService) {}

  /**
   * Get data from API or indexedDB with additional filters and sorting.
   *
   * @param tableName indexedDB table name
   * @param options QueryOptions for indexedDB usage
   * @param url URl of API call in case of backend connection
   */
  async get(tableName: string, options: QueryOptions, url: string) {
    if (!this.allowBackend) {
      this.result = this.indexedDB.table(tableName);

      const items = await this.result.toArray();

      // Init each model for custom processing
      for (const model of items) {
        await model.init();
      }

      this.result = items;

      this.processSearching(options);
      this.processSorting(options);
      this.paginate(options);

      return this.result;
    } else {
      // Use API service
    }
  }

  /**
   * Get data indexedDB by id
   *
   * @param tableName indexedDB table name
   * @param id ID number
   */
  async getById(tableName: string, id: any) {
    const item = await this.indexedDB.table(tableName).get(id);

    if (!item) {
      return undefined;
    }

    item.init();

    return item;
  }

  /**
   * Get data indexedDB by id
   *
   * @param tableName indexedDB table name
   * @param column
   * @param value
   */
  async getBy(tableName: string, column: string, value: any) {
    const filter = {};

    filter[column] = value;
    const items = await this.indexedDB.table(tableName).where(filter).toArray();

    // Init each model for custom processing
    for (const model of items) {
      await model.init();
    }

    return items;
  }

  /**
   * Start filtering the object store by creating a WhereClause instance.
   * http://dexie.org/docs/Table/Table.where()
   *
   * @param {number} distance
   * @param {number} volume
   * @returns {Promise<any>}
   */
  async findBulkPrice(distance: number, volume: number) {
    if (!this.allowBackend) {
      distance = Math.ceil(distance);
      volume = Math.ceil(volume);

      const maxVolume = await this.indexedDB.table('bulk_prices').orderBy('volume_max').reverse().first();

      if (!maxVolume) {
        return undefined;
      }

      /** Set the volume on the max available volume if it's more than the max */
      volume = volume > maxVolume.volume_max ? maxVolume.volume_max : volume;

      const maxDistance = await this.indexedDB.table('bulk_prices').orderBy('distance_max').reverse().first();

      if (!maxDistance) {
        return undefined;
      }

      /** Set the distance on the max available distance if it's more than the max */
      distance = distance > maxDistance.distance_max ? maxDistance.distance_max : distance;

      const collection = await this.indexedDB
        .table('bulk_prices')
        .where('distance_min')
        .belowOrEqual(distance)
        .and(function (table) {
          return table.distance_max >= distance;
        })
        .and(function (table) {
          return table.volume_min <= volume;
        })
        .and(function (table) {
          return table.volume_max >= volume;
        });

      return collection.first();
    }
  }

  /**
   * Count the number of total records with the applied filters
   *
   * @param tableName indexedDB table name
   * @param options QueryOptions for indexedDB usage
   * @param url URl of API call in case of backend connection
   */
  async count(tableName: string, options: QueryOptions, url: string) {
    if (this.allowBackend) {
      return 0;
    }
    this.result = this.indexedDB.table(tableName);

    const items = await this.result.toArray();

    // Init each model for custom processing
    for (const model of items) {
      await model.init();
    }

    this.result = items;

    this.processSearching(options);
    this.processSorting(options);

    return this.result.length;
  }

  /**
   * Create or Update object
   *
   * @param tableName indexedDB table name
   * @param object the object to be writen to the indexedDB table
   */
  async createOrUpdate(tableName: string, object): Promise<any> {
    if (!object) {
      return;
    }

    if (!this.allowBackend) {
      if (object.id == null) {
        object.id = uuid();
      }

      return await this.indexedDB.table(tableName).put(object);
    } else {
      // Use API service
    }
  }

  /**
   * Add object
   *
   * @param tableName indexedDB table name
   * @param object the object to be writen to the indexedDB table
   */
  async add(tableName: string, object) {
    if (!this.allowBackend) {
      if (object.id == null) {
        object.id = uuid();
      }

      return await this.indexedDB.table(tableName).add(object);
    } else {
      // Use API service
    }
  }

  /**
   * Update object
   *
   * @param tableName indexedDB table name
   * @param id primary key id
   * @param attributes Object with attributes which need to be updated
   */
  async update(tableName: string, id: number, attributes: Object) {
    if (!this.allowBackend) {
      return await this.indexedDB.table(tableName).update(id, attributes);
    } else {
      // Use API service
    }
  }

  /**
   * Delete object
   *
   * @param tableName indexedDB table name
   * @param id primary key id
   */
  async delete(tableName: string, id: any) {
    if (!this.allowBackend) {
      return await this.indexedDB.table(tableName).delete(id);
    } else {
      // Use API service
    }
  }

  /**
   * Process available sorting
   *
   * @param options QueryOptions
   */
  processSorting(options: QueryOptions) {
    if (options.sortColumn) {
      this.result.sort((a, b) => {
        return a[options.sortColumn].localeCompare(b[options.sortColumn]);
      });

      if (options.sortOrder !== 'asc') {
        this.result = this.result.reverse();
      }
    }
  }

  /**
   * Paginate result
   *
   * @param options QueryOptions
   */
  paginate(options: QueryOptions) {
    if (options.usePaging) {
      const start = options.pageIndex * options.pageSize;
      const end = start + options.pageSize;

      this.result = this.result.slice(start, end);
    }
  }

  processSearching(options: QueryOptions) {
    if (!options || options.columns.length === 0) {
      return;
    }

    // Do not process if no filters are given
    if (
      !options.globalFilter &&
      options.columns.find((column) => {
        return column.filter !== undefined;
      }) === undefined
    ) {
      return;
    }

    this.result = this.result.filter((record) => {
      let include = false;

      options.columns.forEach((column) => {
        // Skip if no filters are set
        if (!options.globalFilter && !column.filter) {
          return;
        }

        if (!record[column.name]) {
          return;
        }

        if (options.globalFilter && ('' + record[column.name]).includes(options.globalFilter)) {
          include = true;
        }
        if (column.filter && ('' + record[column.name]).includes(column.filter)) {
          include = true;
        }
      });

      return include;
    });
  }

  public sortDropdownByLabel(items: SelectItem[]): SelectItem[] {
    return items.sort((a: SelectItem, b: SelectItem): number => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
  }
}
