import { ListOptions } from '@capturum/api';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { CapturumInfoTableComponent } from '@capturum/ui/info-table';
import { FilterMatchMode, TableAction } from '@capturum/ui/api';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { BaseListComponent } from '@node_modules/@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, tap } from '@node_modules/rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProjectService } from '@shared/services/project.service';
import { SelectItem } from '@node_modules/primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class OnlineTableOptions {
  public endpoint: string;
  public noResultsMessage = 'Er zijn geen gegevens gevonden';
  public search: boolean;
  public withDelete: boolean;
  public sort?: {
    column: string;
    order?: string;
  };

  public columns: Array<any> = [{ title: 'Name', name: 'name' }];

  public listOptions: ListOptions;

  public paginator = {
    rows: 10,
    total: 0,
    total_pages: 0,
    first: 0,
    per_page: 10,
    current_page: 1,
  };

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public rowDataTransformer: (data: any[]) => any[] = (data) => {
    return data;
  };
}

@Component({
  selector: 'em-online-table',
  templateUrl: 'online-table.component.html',
  styleUrls: ['./table.scss'],
})
export class OnlineTableComponent extends BaseListComponent<any> implements OnInit {
  @Input() options: OnlineTableOptions = new OnlineTableOptions();
  @Input() rows: any;
  @Output() rowClicked: EventEmitter<{ row: any; column: string }> = new EventEmitter<{ row: any; column: string }>();
  @ViewChild(CapturumInfoTableComponent, { static: false }) public dataTable: CapturumInfoTableComponent;

  @ViewChild(CapturumDynamicFiltersComponent)
  public dynamicFiltersRef: CapturumDynamicFiltersComponent;

  public dynamicFilters: DynamicFilterConfig;

  public form: UntypedFormGroup;
  public loading: boolean;
  public totalRecords: number;
  public tableColumns: InfoTableColumn[] = [];
  public tableData = [];
  public actions: TableAction[];
  public activeFilters: ActiveFilters[] = [];
  private updateTableData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public statusList: SelectItem[] = null;
  public typeList: SelectItem[] = null;
  private lazyLoad$ = new Subject<LazyLoadEvent & { page: number; per_page: number }>();

  public constructor(
    public confirmationService: ConfirmationService,
    public projectService: ProjectService,
    public translateService: TranslateService,
    public readonly injector: Injector,
    private api: ApiServiceWithLoaderService,
    private formBuilder: UntypedFormBuilder
  ) {
    super(injector, translateService);
    this.form = this.formBuilder.group({
      search: [''],
    });

    this.lazyLoad$
      .pipe(
        takeUntilDestroyed(),
        tap((event) => {
          this.loading = true;
          this.actions = this.getActions();

          if (event?.per_page) {
            this.options.listOptions.perPage = event.per_page;
          }

          this.options.listOptions.sort = [
            {
              field: event?.sortField ?? 'reference_nr',
              direction: event?.sortField && event?.sortOrder === 1 ? 'asc' : 'desc',
            },
          ];
        }),
        switchMap(() => {
          return this.api.get(this.options.endpoint, this.options.listOptions);
        }),
        tap({
          next: (result) => {
            this.totalRecords = result?.meta?.pagination?.total;

            this.rows =
              this.options.rowDataTransformer(result?.data)?.map((el) => {
                return {
                  ...el,
                  planning: el.is_synced_with_pcm === null ? '' : el.is_synced_with_pcm ? '✅' : '❌',
                };
              }) || [];

            this.tableColumns = this.options.columns.map((column) => {
              return {
                field: column.name,
                title: column.title,
              };
            });

            this.tableColumns.push({
              field: 'actions',
              type: InfoTableColumnType.Actions,
              disableRowClick: true,
            });

            this.paginator = {
              rows: result?.meta.pagination.per_page,
              total: result?.meta.pagination.total,
              total_pages: result?.meta.pagination.total_pages,
              first: (result?.meta.pagination.current_page - 1) * result?.meta.pagination.per_page,
              per_page: result?.meta.pagination.per_page,
              current_page: result?.meta.pagination.current_page,
            };

            this.tableData = this.rows;

            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        })
      )
      .subscribe();
  }

  public ngOnInit(): any {
    this.loading = true;

    this.setFilters();

    this.listenToUpdateTableData();
  }

  public async setFilters(): Promise<void> {
    try {
      this.statusList = await this.projectService.getStatusList();
      this.typeList = await this.projectService.getTypeList();

      this.configureFilters();
    } catch (error) {
      return;
    }
  }

  public listenToUpdateTableData(): void {
    this.updateTableData$
      .asObservable()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.dataTable?.onPerPageChange(this.paginator.per_page ?? this.options.paginator.per_page); // reload
      });
  }

  public handleFilterChange(activeFilters: ActiveFilters[]): void {
    const search = activeFilters?.find((activeFilter) => {
      return activeFilter.field === 'search';
    })?.value;

    const filters = activeFilters
      .filter((activeFilter) => {
        return activeFilter.field !== 'search';
      })
      .map((activeFilter) => {
        return {
          ...activeFilter,
          operator: activeFilter.matchMode,
        };
      });

    this.options.listOptions = {
      ...this.options.listOptions,
      search,
      filters,
    };

    this.updateTableData$.next(true);
  }

  public loadLazy(event: LazyLoadEvent & { page: number; per_page: number }) {
    this.lazyLoad$.next(event);
  }

  public onRowSelect(event): void {
    if (event) {
      this.rowClicked.emit(event);
    }
  }

  public onDeleteClick(value: any): void {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u het geselecteerde item wilt verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        this.api.delete(`${this.options.endpoint}/${value.id}`).subscribe(() => {
          this.toastService.success(
            this.translateService.instant('movers_complete.inventory.address.success'),
            this.translateService.instant('toast.success.delete', {
              entity: this.translateService.instant('movers_complete.entity.project.single'),
            })
          );

          this.loadLazy({ first: 1, page: 1, filters: {}, rows: 10, per_page: 10 });
        });
      },
    });
  }

  private getActions(): TableAction[] {
    const actions = [];

    if (this.options.withDelete) {
      actions.push({
        label: 'Delete',
        callback: (product) => {
          this.onDeleteClick(product);
        },
        icon: 'fas fa-trash',
        value: null,
      });
    }

    return actions;
  }

  private configureFilters(): void {
    this.dynamicFilters = {
      filters: [
        {
          type: DynamicFilterType.input,
          field: 'search',
          icon: 'fas fa-search',
          matchMode: FilterMatchMode.LIKE,
          label: null,
          placeholder: this.translateService.instant('button.search'),
        },
        {
          type: DynamicFilterType.dropdown,
          field: 'type_base_data_value_id',
          icon: 'fas fa-user',
          matchMode: FilterMatchMode.EQUALS,
          operator: FilterMatchMode.EQUALS,
          label: null,
          options: this.typeList,
          placeholder: this.translateService.instant('movers_complete.inventory.address.type.label'),
        },
        {
          type: DynamicFilterType.multiselect,
          field: 'status_base_data_value_id',
          icon: 'fas fa-list-check',
          matchMode: FilterMatchMode.IN,
          operator: FilterMatchMode.IN,
          options: this.statusList,
          placeholder: this.translateService.instant('movers_complete.inventory.client.status.label'),
        },
      ],
    };
  }

  public resetFilters(): void {
    this.dynamicFiltersRef.resetFilters();
    this.dataTable.resetFilters();
    this.cdr.detectChanges();
  }

  public refreshFilters(): void {
    this.updateTableData$.next(true);
  }
}
