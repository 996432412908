import { AfterViewInit, Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActionMenuItem } from '@capturum/ui/action-menu';
import { environment } from '@environments/environment';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import jwt_decode from 'jwt-decode';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { UserService } from '@shared/services/user.service';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends DestroyBase implements OnInit, AfterViewInit {
  @Input() public showActionsMenu: boolean = true;
  @ViewChild('navbar', { static: true })
  public navbar: ElementRef;

  public pageTitle: string;
  public version: string;
  public environment: any;
  public userName = '';
  public actionMenuItems: ActionMenuItem[] = [];
  public tenantLogo: string = '';

  constructor(
    private synchronisationService: SynchronisationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private user: UserService,
    private authService: AuthService,
    private settingService: SettingService
  ) {
    super();

    this.version = environment.version;
    this.environment = environment;
    this.userName = (jwt_decode(localStorage.getItem('token')) as any).name;

    this.tenantLogo = this.settingService.getValueString('general.tenant_logo');

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        map(() => {
          return this.activatedRoute;
        }),
        map((route) => {
          while (route.firstChild) route = route.firstChild;

          return route;
        }),
        filter((route) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route) => {
          return route.data;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        this.pageTitle = event['title'];
      });
  }

  public ngOnInit(): void {
    this.setActionMenuItems();
  }

  public ngAfterViewInit(): void {
    this.synchronisationService.myTenant$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.navbar.nativeElement.style.background = data.config_stylesheet.primary_color;
    });
  }

  /**
   * TODO: use blueprint logout method from auth service (couldn't get it to work quick enough :( )
   *
   * @returns {Promise<void>}
   */
  public logout(): void {
    this.authService.logout().subscribe((result: any) => {
      if (result) {
        this.router.navigate(['/login']);
      }
    });
  }

  public navigate(url: string): void {
    this.router.navigate([url]);
  }

  private setActionMenuItems(): void {
    this.actionMenuItems = [
      {
        label: 'Home',
        icon: 'fa fa-2x fa-home',
        callback: () => {
          return this.navigate('/admin/projects');
        },
      },
      {
        label: 'Rapportage',
        hidden: this.user.isAdministrator() && !this.settingService.getValueString('general.has_powerbi_report_module'),
        icon: 'fa fa-2x fa-file-text',
        callback: () => {
          return this.navigate('/admin/report');
        },
      },
      {
        label: 'Beheer',
        hidden: !this.user.isAdministrator(),
        icon: 'fa fa-2x fa-user-gear',
        callback: () => {
          return this.navigate('/admin/manage');
        },
      },
      {
        label: 'Gebruikers',
        hidden: !this.user.isAdministrator(),
        icon: 'fa fa-2x fa-users',
        callback: () => {
          return this.navigate('/admin/users');
        },
      },
      {
        label: 'Instellingen',
        icon: 'fa fa-2x fa-gears',
        callback: () => {
          return this.navigate('/admin/settings');
        },
      },
      {
        label: 'Uitloggen',
        icon: 'fa fa-2x fa-sign-out',
        callback: () => {
          return this.logout();
        },
      },
    ];
  }
}
