import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SearchService {
  private _search: string;
  private _currentValue: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public onChange(): Observable<string> {
    return this._currentValue.asObservable();
  }

  public getSearchValue(): string {
    return this._search;
  }

  public setSearchValue(updatedValue: string): void {
    this._search = updatedValue;
    this._currentValue.next(this._search);
  }
}
