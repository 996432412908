<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="ibox-title">
          <h5>{{ 'setting.overview.title' | translate }}</h5>
        </div>

        <div class="ibox-content">
          <div class="row">
            <div class="col-12" [innerHTML]="text$ | async"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
