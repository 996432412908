<div id="inventory-overview">
  <div class="col-md-3 col-sm-12 logo">
    <img [src]="logo" onerror="this.src = '/assets/images/zandbergen-logo.svg'" />
  </div>

  <div class="border-bottom"></div>

  <div class="header d-flex border-bottom justify-content-between pt-3 pb-3">
    <h2 id="inventory-overview-title">
      {{ 'movers_complete.inventory.overview.overview.title' | translate }} -
      {{ project?.client.name ? project.client.name : '' }}
      {{ project?.reference_nr ? '(' + project.reference_nr + ')' : '' }}
    </h2>

    <div class="tenant-address">
      <div class="company-details" *ngIf="quotationCompanyDetails?.length">
        <strong>{{ 'movers_complete.overview.company-details' | translate }}</strong>
        <ul>
          <ng-template *ngFor="let companyDetail of quotationCompanyDetails | keyvalue">
            <li>{{ companyDetail.value }}</li>
          </ng-template>
        </ul>
      </div>

      <div class="payment-details" *ngIf="quotationPaymentDetails?.length">
        <strong>{{ 'movers_complete.overview.payment-details' | translate }}</strong>
        <ul>
          <ng-template *ngFor="let paymentDetail of quotationPaymentDetails | keyvalue">
            <li>{{ paymentDetail.value }}</li>
          </ng-template>
        </ul>
      </div>
    </div>

    <cap-button
      [label]="'movers_complete.button.print' | translate"
      id="inventory-overview-print-button"
      class="print-button"
      styleClass="primary"
      (onClick)="printOverview()">
    </cap-button>
  </div>

  <!-- Client -->
  <div class="ibox-content mt-4 pb-4 border-bottom" *ngIf="project">
    <h3>{{ 'movers_complete.inventory.overview.client.label' | translate }}</h3>

    <fieldset>
      <div class="ibox-item" *ngIf="project?.type_base_data_value_id">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.client.type.label' | translate }}</label>
          <div class="col-9 value">{{ 'base-data.' + project.type_base_data_value_id | translate | titlecase }}</div>
        </div>
      </div>

      <div class="ibox-item" *ngIf="project?.reference_nr">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.reference.label' | translate }}</label>
          <div class="col-9 value">{{ project.reference_nr }}</div>
        </div>
      </div>

      <div class="ibox-item">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.client.status.label' | translate }}</label>
          <div class="col-9 value">
            {{ 'base-data.' + project.status_base_data_value_id | translate | titlecase }}
          </div>
        </div>
      </div>

      <div class="ibox-item" *ngIf="project?.accountmanagerName">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.contact_person.label' | translate }}</label>
          <div class="col-9 value">{{ project.accountmanagerName }}</div>
        </div>
      </div>

      <div class="ibox-item">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.executor.label' | translate }}</label>
          <div class="col-9 value">{{ getCorrectExecutor(project.executor_id) }}</div>
        </div>
      </div>

      <div class="ibox-item" *ngIf="project?.clientName">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.client_name.label' | translate }}</label>
          <div class="col-9 value">{{ project.clientName }}</div>
        </div>
      </div>

      <div class="ibox-item">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.relation_group.label' | translate }}</label>
          <div class="col-9 value">
            {{ relationGroup?.name ? relationGroup?.name : '-' }}
          </div>
        </div>
      </div>

      <div class="ibox-item" *ngIf="project?.client?.description !== ''">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.description.label' | translate }}</label>
          <div class="col-9 value">{{ project.client.description }}</div>
        </div>
      </div>

      <div
        class="ibox-item"
        *ngIf="project?.client?.remarks && project.client.remarks !== '' && project.client.remarks !== undefined">
        <div class="row">
          <label class="col-3">{{ 'movers_complete.inventory.overview.remark_internal.label' | translate }}</label>
          <div class="col-9 value">
            {{ project.client.remarks }}
          </div>
        </div>
      </div>
    </fieldset>
  </div>

  <!-- Adressen -->
  <div class="ibox-content mt-4 pb-4 border-bottom">
    <h3>{{ 'movers_complete.entity.address.plural' | translate }}</h3>

    <ng-container *ngIf="addresses?.length > 0">
      <div *ngFor="let address of addresses | sortByIndex; let i = index">
        <fieldset [ngClass]="{ 'bottom-divider': addresses[i + 1] }">
          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.type.label' | translate }}</label>
              <div class="col-9 value">{{ address?.address_type?.name }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="invoiceFacturationId && address?.address_type_id === invoiceFacturationId">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.email.label' | translate }}</label>
              <div class="col-9 value">{{ address.email }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="address?.address_type_id !== invoiceFacturationId">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.address.label' | translate }}</label>
              <div class="col-9 value">{{ getDisplayAddress(address) }}</div>
            </div>
          </div>
        </fieldset>
      </div>
    </ng-container>

    <ng-container *ngIf="addresses?.length < 1">
      <span>
        <small>
          <strong>{{ 'movers_complete.inventory.overview.address.error.text' | translate }}</strong>
        </small>
      </span>
    </ng-container>
  </div>

  <!-- Contactpersonen -->
  <div class="ibox-content mt-4 pb-4 border-bottom">
    <h3>{{ 'movers_complete.inventory.overview.contact_persons.label' | translate }}</h3>

    <ng-container *ngIf="contacts?.length > 0">
      <div *ngFor="let contact of contacts; let i = index">
        <fieldset [ngClass]="{ 'bottom-divider': contacts[i + 1] }">
          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.name.label' | translate }}</label>
              <div class="col-9 value">
                {{ contact.initials ? contact.initials + '. ' + contact.name : contact.name }}
              </div>
            </div>
          </div>

          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.email.label' | translate }}</label>
              <div class="col-9 value">{{ contact.email }}</div>
            </div>
          </div>

          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.phone.label' | translate }}</label>
              <div class="col-9 value">{{ contact.phone }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="contact?.mobile !== ''">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.mobile.label' | translate }}</label>
              <div class="col-9 value">{{ contact.mobile }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="contact?.remarks && contact.remarks !== undefined && contact.remarks !== ''">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.remark_internal.label' | translate }}</label>
              <div class="col-9 value">{{ contact.remarks }}</div>
            </div>
          </div>
        </fieldset>
      </div>
    </ng-container>

    <ng-container *ngIf="contacts?.length < 1">
      <span>
        <small>
          <strong>{{ 'movers_complete.inventory.overview.contact_person.error.text' | translate }}</strong>
        </small>
      </span>
    </ng-container>
  </div>

  <!-- Opties -->
  <div class="ibox-content mt-4 pb-4 border-bottom">
    <h3>{{ 'movers_complete.entity.option.plural' | translate }}</h3>

    <ng-container *ngIf="specialties?.length > 0">
      <div *ngFor="let specialty of specialties; let i = index">
        <fieldset [ngClass]="{ 'bottom-divider': specialties[i + 1] }">
          <div class="ibox-item">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.name.label' | translate }}</label>
              <div class="col-9 value">{{ specialty.specialty.name }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="specialty?.specialty?.has_remarks && specialty?.details !== ''">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.description.label' | translate }}</label>
              <div class="col-9 value">{{ specialty.details }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="specialty?.specialty.has_hours && specialty?.hours_estimate">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.hour_estimate.label' | translate }}</label>
              <div class="col-9 value">{{ specialty.hours_estimate }}</div>
            </div>
          </div>

          <div class="ibox-item" *ngIf="specialty?.specialty.has_date && specialty?.start && specialty.start !== ''">
            <div class="row">
              <label class="col-3">{{ 'movers_complete.inventory.overview.date.label' | translate }}</label>
              <div class="col-9 value">{{ specialty.start | date : 'dd-MM-yyyy' }}</div>
            </div>
          </div>
        </fieldset>
      </div>
    </ng-container>

    <ng-container *ngIf="specialties?.length < 1">
      <span>
        <small>
          <strong>{{ 'movers_complete.inventory.overview.work.error.text' | translate }}</strong>
        </small>
      </span>
    </ng-container>
  </div>

  <!-- Inventarisaties -->
  <div class="ibox-content mt-4 pb-4 border-bottom">
    <h3>{{ 'movers_complete.inventory.overview.inventories.label' | translate }}</h3>
    <ng-container *ngIf="inventories?.length > 0">
      <ng-container *ngFor="let inventory of inventories; let i = index">
        <ng-container *ngIf="getTotalPriceOfRoom(inventory.id)">
          <div [ngClass]="{ 'bottom-divider': inventories[i + 1] }">
            <fieldset class="inventory-items-overview">
              <div class="ibox-item">
                <div class="row">
                  <label class="col-3">
                    {{ 'movers_complete.inventory.overview.room.label' | translate }}&nbsp;
                    <small class="small-gray-text">
                      ({{ 'movers_complete.inventory.overview.floor.label' | translate | lowercase }})
                    </small>
                  </label>
                  <div class="col-9 value">
                    {{ inventory.cascading_name }} {{ inventory.floor ? '(' + inventory.floor + ')' : '' }}
                  </div>
                </div>
              </div>

              <div class="ibox-item">
                <div class="row">
                  <label class="col-3">{{ 'movers_complete.inventory.overview.price.label' | translate }}</label>
                  <div class="col-9 value">
                    {{ inventory.id | totalPriceByRoom : project.inventories | currency : 'EUR' }}
                  </div>
                </div>
              </div>
            </fieldset>

            <ng-container *ngIf="inventory?.items?.length < 1">
              <span>
                <small>
                  <strong>{{ 'movers_complete.inventory.overview.items.error.text' | translate }}</strong>
                </small>
              </span>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="inventories?.length < 1">
      <span>
        <small>
          <strong>{{ 'movers_complete.inventory.overview.inventories.error.text' | translate }}</strong>
        </small>
      </span>
    </ng-container>
  </div>

  <!-- Werkzaamheden -->
  <ng-container>
    <div class="ibox-content mt-4 pb-4 border-bottom">
      <h3>{{ 'movers_complete.inventory.option.activity.title' | translate }}</h3>

      <ng-container *ngIf="activities?.length > 0">
        <div *ngFor="let activity of activities | sortByDateProperty : 'start' : 'asc'; let i = index">
          <fieldset [ngClass]="{ 'bottom-divider': activities[i + 1] }">
            <div class="ibox-item">
              <div class="row">
                <label class="col-3">{{ 'movers_complete.inventory.overview.name.label' | translate }}</label>
                <div class="col-9 value">{{ activity.activity.name }}</div>
              </div>
            </div>

            <div
              class="ibox-item"
              *ngIf="activity?.details && activity.details !== '' && activity.details !== undefined">
              <div class="row">
                <label class="col-3">{{ 'movers_complete.inventory.overview.description.label' | translate }}</label>
                <div class="col-9 value">{{ activity.details }}</div>
              </div>
            </div>

            <div class="ibox-item" *ngIf="activity?.start && activity.start !== '' && activity.start !== undefined">
              <div class="row">
                <label class="col-3">{{ 'movers_complete.inventory.overview.date.label' | translate }}</label>
                <div class="col-9 value">{{ activity.start | date : 'dd-MM-yyyy' }}</div>
              </div>
            </div>

            <div
              class="ibox-item"
              *ngIf="activity?.number_movers && activity.number_movers !== '' && activity.number_movers !== undefined">
              <div class="row">
                <label class="col-3">{{ 'movers_complete.inventory.overview.amount_movers.label' | translate }}</label>
                <div class="col-9 value">{{ activity.number_movers }}</div>
              </div>
            </div>

            <div
              class="ibox-item"
              *ngIf="activity?.number_trucks && activity.number_trucks !== '' && activity.number_trucks !== undefined">
              <div class="row">
                <label class="col-3">{{ 'movers_complete.inventory.overview.amount_trucks.label' | translate }}</label>
                <div class="col-9 value">{{ activity.number_trucks }}</div>
              </div>
            </div>
          </fieldset>
        </div>
      </ng-container>

      <ng-container *ngIf="activities?.length < 1">
        <span>
          <small>
            <strong>{{ 'movers_complete.inventory.overview.activity.error.text' | translate }}</strong>
          </small>
        </span>
      </ng-container>
    </div>
  </ng-container>

  <!-- Materialen -->
  <div class="ibox-content mt-4 pb-4 border-bottom">
    <h3>{{ 'movers_complete.entity.material.plural' | translate }}</h3>

    <ng-container *ngIf="materials?.length > 0">
      <div *ngFor="let material of materials; let i = index">
        <ng-container *ngIf="material?.amount > 0">
          <fieldset>
            <div class="ibox-item">
              <div class="row">
                <label class="col-3">{{ material?.material?.name }}</label>
                <div class="col-9 value">
                  {{ material?.amount }}
                </div>
              </div>
            </div>
          </fieldset>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="materials?.length < 1">
      <span>
        <small>
          <strong>{{ 'movers_complete.inventory.overview.material.error.text' | translate }}</strong>
        </small>
      </span>
    </ng-container>
  </div>
</div>
