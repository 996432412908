<div class="box-content-wrap">
  <div class="ibox-title">
    <h5>{{ 'movers_complete.entity.user.single' | translate }}</h5>
    <span class="fa fa-times pull-right" (click)="onCancel()"></span>
  </div>

  <form [formGroup]="form" class="form-horizontal">
    <div class="ibox-content scroll-fix">
      <app-loading *ngIf="loading"></app-loading>
      <div *ngIf="!loading">
        <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['name'].valid }">
          <label class="col-sm-4 control-label" asteriks>
            {{ 'movers_complete.setting.user.name.label' | translate }}
          </label>

          <div class="col-sm-12">
            <input type="text" class="form-control" placeholder="Naam" formControlName="name" />
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['email'].valid }">
          <label class="col-sm-4 control-label" asteriks>
            {{ 'movers_complete.setting.user.email.label' | translate }}
          </label>

          <div class="col-sm-12">
            <input type="text" class="form-control" placeholder="Email" formControlName="email" />
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['locale_id'].valid }">
          <label class="col-sm-4 control-label" asteriks>{{
            'movers_complete.profile.locale_id.label' | translate
          }}</label>
          <div class="col-sm-12">
            <cap-dropdown
              [options]="localeList$ | async"
              formControlName="locale_id"
              [showClear]="true"
              [sortAlphabetically]="false"></cap-dropdown>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['password'].valid }">
          <label class="col-sm-4 control-label" asteriks>{{ 'auth.password.placeholder' | translate }}</label>
          <div class="col-sm-12">
            <cap-input
              type="password"
              formControlName="password"
              styleClass="form-control"
              [placeholder]="'auth.password.placeholder' | translate">
            </cap-input>

            <span class="help-block m-b-none" *ngIf="!isAdd">
              {{ 'movers_complete.setting.user.password.helper.label' | translate }}
            </span>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4 control-label" asteriks>{{
            'auth.password-confirmation.placeholder' | translate
          }}</label>
          <div class="col-sm-12">
            <cap-input
              type="password"
              formControlName="password_confirmation"
              styleClass="form-control"
              [placeholder]="'auth.password-confirmation.placeholder' | translate">
            </cap-input>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': showErrors && !this.form.controls['current_role_id'].valid }">
          <label class="col-sm-2 control-label" asteriks>{{ 'role.detail.title' | translate }}</label>
          <div class="col-sm-12">
            <cap-dropdown
              [options]="roleList"
              formControlName="current_role_id"
              [showClear]="true"
              [placeholder]="'movers_complete.placeholder.role.select' | translate"
              (changeSelection)="onSelectRole($event.value)"></cap-dropdown>
          </div>
        </div>

        <div
          *ngIf="!isAccountmanager"
          class="form-group"
          [ngClass]="{ 'has-error': showErrors && !this.form.controls['is_accountmanager'].valid }">
          <label class="col-sm-4 control-label">
            {{ 'movers_complete.setting.user.is_accountmanager.label' | translate }}
          </label>
          <p-checkbox class="checkbox ml-1" formControlName="is_accountmanager" binary="true"></p-checkbox>
        </div>

        <div
          *ngIf="!isExecutor"
          class="form-group"
          [ngClass]="{ 'has-error': showErrors && !this.form.controls['is_executor'].valid }">
          <label class="col-sm-4 control-label">
            {{ 'movers_complete.setting.user.is_executor.label' | translate }}
          </label>

          <p-checkbox class="checkbox ml-1" formControlName="is_executor" binary="true"></p-checkbox>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            {{ 'movers_complete.setting.user.siganature.label' | translate }}
          </label>

          <div class="col-sm-4">
            <div class="d-flex signature-buttons">
              <cap-button
                styleClass="primary"
                [label]="'movers_complete.setting.user.siganature_add.label' | translate"
                (onClick)="showSignatureForm()">
              </cap-button>

              <cap-button
                *ngIf="user?.signature_image"
                icon="fa fa-trash"
                styleClass="primary error"
                [label]="'movers_complete.setting.user.siganature_delete.label' | translate"
                (onClick)="deleteSignature()">
              </cap-button>
            </div>

            <app-signature #signature [(signature)]="user.signature_image"> </app-signature>

            <div *ngIf="user.signature_image">
              <img [src]="user.signature_image" class="signature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="col-sm-12 col-sm-offset-2 buttons">
    <cap-button
      icon="fas fa-check"
      styleClass="primary submit"
      [label]="'button.submit' | translate"
      (onClick)="onSubmit()">
    </cap-button>

    <cap-button icon="fa fa-ban" styleClass="secondary" [label]="'button.cancel' | translate" (onClick)="onCancel()">
    </cap-button>

    <cap-button
      icon="fas fa-trash"
      styleClass="primary error"
      [label]="'button.delete' | translate"
      (onClick)="onDelete()">
    </cap-button>
  </div>
</div>
