export class TenantConfigQuotationPaymentDetails {
  public bic: string;
  public email: string;
  public iban: string;
  public kvk: string;
  public vat: string;

  constructor(attributes) {
    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        this[attributeName] = attributes[attributeName];
      }
    }
  }
}
