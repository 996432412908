<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="ibox-title d-flex justify-content-between align-items-center">
          <h5>{{ 'movers_complete.entity.user.plural' | translate }}</h5>

          <button class="btn btn-primary" (click)="onAddClick()">
            <i class="fa fa-plus"></i> {{ 'button.add' | translate }}
          </button>
        </div>

        <div class="ibox-content">
          <cpb-list-renderer [key]="listkey"></cpb-list-renderer>
        </div>
      </div>
    </div>
  </div>
</div>
