import { Component } from '@angular/core';
import { ManageActivitiesDetailComponent } from '@features/manage/components/activities/activities-detail/activities-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activities-list',
  templateUrl: './activities-list.component.html',
  styleUrls: ['./activities-list.component.scss'],
})
export class ManageActivitiesListComponent {
  public listKey = 'list_activities';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageActivitiesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.activity.single'),
    });
  }
}
