import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ProjectService } from '@shared/services/project.service';
import { UserService } from '@shared/services/user.service';
import { Project } from '@domain/models/project.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { InventoryFlowValidationService } from '@core/services/inventory-flow-validation.service';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { Subject } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/operators';
import { SettingService } from '@shared/services/setting.service';
import { DexieStore } from '@domain/dexie-store';

@Component({
  selector: 'app-inventory-flow',
  templateUrl: 'inventory-flow.component.html',
  styleUrls: ['./inventory-flow.component.scss'],
})
export class InventoryFlowComponent implements OnInit, OnDestroy {
  public currentIndex = 0;
  public project: Project;
  public routes: MenuItem[];
  public onlineMode = true;
  public labelSave: string;
  public labelSaveProgress: string;

  public validation: boolean;
  public stepOneValidation: boolean;
  public stepTwoValidation: boolean;
  public stepThreeValidation: boolean;
  private store = DexieStore.getInstance();

  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private synchronisationService: SynchronisationService,
    private api: ApiServiceWithLoaderService,
    private user: UserService,
    private validationService: InventoryFlowValidationService,
    private translateService: TranslateService,
    private settingService: SettingService
  ) {
    this.api.increaseLoaderValueByOne();

    this.onlineMode = navigator.onLine;

    // Remove quotation route for executor
    if (this.user.hasRole('executor') || this.user.hasRole('executor-mbo')) {
      this.routes = this.routes.filter((r) => {
        return r.routerLink !== 'quotation';
      });
    }

    this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;

      this.projectService.updateProjectReadOnlyStatus(this.project);
      this.api.decreaseLoaderValueByOne();
    });
  }

  public ngOnInit(): void {
    this.labelSave = this.translateService.instant('movers_complete.inventory-flow.save');
    this.labelSaveProgress = this.translateService.instant('movers_complete.inventory-flow.back-to-overview');

    this.updateRouteAccessibility();
    this.processRouteParams();
    this.setCurrentIndex();
    this.stepValidation();

    // Listen to route changes to update current index
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentIndex();
      }
    });
  }

  public async ngOnDestroy(): Promise<void> {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public next(): void {
    if (this.currentIndex < this.routes.length) {
      this.router.navigate([this.routes[this.currentIndex + 1].routerLink], { relativeTo: this.route });
    }
  }

  public previous(): void {
    if (this.currentIndex > 0) {
      this.router.navigate([this.routes[this.currentIndex - 1].routerLink], { relativeTo: this.route });
    }
    this.validationService.setValidation(true);
  }

  public save(): void {
    // Reload page and the navigate back to projects
    this.router.navigate([this.routes[this.currentIndex].routerLink], { relativeTo: this.route }).then(() => {
      this.router.navigate(['/projects']);
    });
  }

  // ToDo: Remove unused param checks if PCM is changed
  // Read project route id and load project in case of page refresh
  public processRouteParams(): void {
    this.route.params?.subscribe(async (params: any) => {
      const projectId = params['project'];

      if (this.route.snapshot.queryParamMap.get('sync')) {
        await this.synchronisationService.loadSingleProjectData(projectId);
      }

      await this.projectService.loadProject(projectId);
    });
  }

  // Set the index according to url
  public setCurrentIndex(): void {
    this.routes.forEach((route: any, index: number) => {
      if (this.router.url.includes(route.routerLink)) {
        this.currentIndex = index;
      }
    });

    this.updateRouteAccessibility();
  }

  private stepValidation(): void {
    this.validationService.setValidation(false);

    this.validationService
      .getValidation()
      .pipe(takeUntil(this.destroy$))
      .subscribe((validation: boolean): void => {
        this.validation = validation;
      });
  }

  private updateRouteAccessibility(): void {
    this.stepOneValidation =
      !!this.project?.accountmanager_id &&
      !!this.project?.client.name &&
      !!this.project?.client.relation_group_id &&
      !!this.project?.client.location_id &&
      !!this.project?.delivery_date;
    this.stepTwoValidation = this.project?.addresses?.length > 0;
    this.stepThreeValidation = this.project?.client?.contacts?.length > 0;

    this.routes = [
      { routerLink: 'client', label: this.translateService.instant('movers_complete.inventory.overview.client.label') },
      {
        routerLink: 'address',
        label: this.translateService.instant('movers_complete.entity.address.single'),
        disabled: !(this.currentIndex >= 1 || this.stepOneValidation),
      },
      {
        routerLink: 'contact',
        label: this.translateService.instant('movers_complete.inventory.overview.contact_persons.label'),
        disabled: !(this.currentIndex >= 2 || this.stepTwoValidation),
      },
      {
        routerLink: 'options',
        label: this.translateService.instant('movers_complete.config.manage.options.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      {
        routerLink: 'inventory',
        label: this.translateService.instant('movers_complete.inventory.board.inventory.label'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      {
        routerLink: 'material',
        label: this.translateService.instant('movers_complete.config.manage.materials.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      {
        routerLink: 'overview',
        label: this.translateService.instant('movers_complete.inventory.overview.overview.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      {
        routerLink: 'quotation',
        label: this.translateService.instant('movers_complete.inventory.quotation.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      {
        routerLink: 'pictures',
        label: this.translateService.instant('movers_complete.inventory.pictures.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      },
      // If you're adding a new tab, please increment $numberOfNavigationTabs in scss file: src/clients/pavanrooyen/styles/variables.scss
    ];

    if (this.settingService.getValue('movers_complete.has_work_assignments')) {
      this.routes.push({
        routerLink: 'work-assignment',
        label: this.translateService.instant('movers_complete.inventory.work.assignment.title'),
        disabled: !(this.currentIndex >= 3 || this.stepThreeValidation),
      });
    }
  }
}
