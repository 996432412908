import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from '@node_modules/rxjs/operators';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Subject } from '@node_modules/rxjs';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-report-overview',
  templateUrl: 'report-overview.component.html',
  styleUrls: ['./report-overview.component.scss'],
})
export class ReportOverviewComponent implements OnDestroy {
  public embedConfig: any = null;

  private destroy$: Subject<boolean> = new Subject();

  public constructor(private api: ApiServiceWithLoaderService, private settingService: SettingService) {
    this.api
      .get('/power-bi/auth')
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response && response.access_token) {
          const reportID = this.settingService.getValueString('general.powerbi_report_id');

          this.embedConfig = {
            type: 'report',
            id: reportID,
            embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=' + reportID,
            accessToken: response.access_token,
            tokenType: 'embed',
            hostname: 'https://app.powerbi.com',
            settings: {
              panes: {
                bookmarks: {
                  visible: false,
                },
                fields: {
                  expanded: false,
                },
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: false,
                },
                selection: {
                  visible: true,
                },
                syncSlicers: {
                  visible: true,
                },
                visualizations: {
                  expanded: false,
                },
              },
            },
          };
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
