import { ManageDefaultInventoriesDetailComponent } from '@features/manage/components/default-inventories/default-inventories-detail/default-inventories-detail.component';
import { LocationDetailComponent } from '@features/manage/components/locations/location-detail/location-detail.component';
import { LocationModule } from '@features/manage/components/locations/location.module';
import { ManageDefaultItemsDetailComponent } from '@features/manage/components/default-items/default-items-detail/default-items-detail.component';
import { ManageActivitiesDetailComponent } from '@features/manage/components/activities/activities-detail/activities-detail.component';
import { ManageContactsDetailComponent } from '@features/manage/components/contacts/contacts-detail/contacts-detail.component';
import { ManageMaterialsDetailComponent } from '@features/manage/components/materials/materials-detail/materials-detail.component';
import { ManageAddressesDetailComponent } from '@features/manage/components/addresses/addresses-detail/addresses-detail.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumToastModule } from '@capturum/ui/toast';

// App services
import { TableModule } from 'primeng/table';
import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { LayoutsModule } from '@features/gui/layouts/layouts.module';
import { LoginModule } from '@features/login/login.module';
import { UserModule } from '@features/user/user.module';
import { ProjectModule } from '@features/project/project.module';
import { InventoryModule } from '@features/inventory/inventory.module';
import { SettingsModule } from '@features/settings/settings.module';
import { ProjectGuard } from '@shared/guards/project.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

/** Sentry init */
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignatureService } from '@shared/services/signature.service';
import { ManageModule } from '@features/manage/manage.module';
import { OnlineStatusService } from '@shared/services/online-status.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SettingService } from '@shared/services/setting.service';
import { ReportModule } from '@features/report/report.module';
import { CoreModule } from '@core/core.module';
import { AuthGuard, AuthService } from '@capturum/auth';
import { NgxsModule } from '@ngxs/store';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { PermissionGuard, TranslationLoader } from '@capturum/complete';
import { CapturumMissingTranslationHandler } from '@capturum/shared';
import { TranslateModule, MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';
import { ManageAddressTypesDetailComponent } from '@features/manage/components/address-types/address-types-detail/address-types-detail.component';
import { ManageHouseTypesDetailComponent } from '@features/manage/components/house-types/house-types-detail/house-types-detail.component';
import { ManageRelationGroupsDetailComponent } from '@features/manage/components/relation-groups/relation-groups-detail/relation-groups-detail.component';
import { ManageBulkPricesDetailComponent } from '@features/manage/components/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ExactOnlineService } from '@shared/services/exact-online.service';
import { ManageOptionsDetailComponent } from '@features/manage/components/options/options-detail/options-detail.component';
import { createErrorHandler } from '@node_modules/@sentry/angular-ivy';
import { QuotationCheckDetailComponent } from '@features/manage/components/quotation-checks/quotation-check-detail/quotation-check-detail.component';
import { EmailQuotationAttachmentsDetailComponent } from '@features/manage/components/email-quotation-attachments/email-quotation-attachments-detail/email-quotation-attachments-detail.component';
import { QuotationSignModule } from '@features/quotationSign/quotation-sign.module';
import { ImageViewComponent } from '@core/builders/list-widgets/image-view/image-view.component';
import { ImagesInputTypeComponent } from '@core/builders/input-types/images-input-type/images-input-type.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutsModule,
    LoginModule,
    UserModule,
    ProjectModule,
    ManageModule,
    LocationModule,
    SettingsModule,
    ReportModule,
    QuotationSignModule,
    InventoryModule,
    TableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ProgressSpinnerModule,
    CoreModule,
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumToastModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CapturumMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    NgxsModule.forRoot(),
    CapturumBuilderCoreModule.forRoot({
      baseUrl: 'builders',
      components: {
        list_address_types_row_click: ManageAddressTypesDetailComponent,
        list_address_row_click: ManageAddressesDetailComponent,
        list_house_types_row_click: ManageHouseTypesDetailComponent,
        list_materials_row_click: ManageMaterialsDetailComponent,
        list_quotation_checks_row_click: QuotationCheckDetailComponent,
        list_contacts_row_click: ManageContactsDetailComponent,
        list_relation_groups_row_click: ManageRelationGroupsDetailComponent,
        list_activities_row_click: ManageActivitiesDetailComponent,
        list_bulk_prices_row_click: ManageBulkPricesDetailComponent,
        list_default_items_row_click: ManageDefaultItemsDetailComponent,
        list_locations_row_click: LocationDetailComponent,
        list_default_inventories_row_click: ManageDefaultInventoriesDetailComponent,
        list_options_row_click: ManageOptionsDetailComponent,
        list_contact_templates_row_click: ManageContactsDetailComponent,
        list_quotation_attachments_row_click: EmailQuotationAttachmentsDetailComponent,
        list_pictures_row_click: InventoryPictureItemDetailComponent,
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: '',
      types: [
        {
          name: 'image',
          component: ImagesInputTypeComponent,
        },
        {
          name: 'file',
          component: ImagesInputTypeComponent,
        },
      ],
    }),
    CapturumListRendererModule.forRoot({
      list_default_inventories: {
        styleClassExpression: (row) => {
          return row.parents_count > 1 ? 'multiple-parents' : '';
        },
      },
      list_address_types_row_click: {
        actions: {
          row_click: {
            component: ManageAddressTypesDetailComponent,
          },
        },
      },
      widgets: {
        defaultImageViewer: ImageViewComponent,
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    ProjectGuard,
    ClientGuard,
    UserGuard,
    AddressGuard,
    ContactGuard,
    ContactListGuard,
    OptionsGuard,
    AuthGuard,
    InventoryGuard,
    QuotationGuard,
    BootstrapGuard,
    PermissionGuard,
    AuthService,
    DataService,
    ExactOnlineService,
    UserService,
    SettingService,
    SynchronisationService,
    SignatureService,
    OnlineStatusService,
    ApiServiceWithLoaderService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
