<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="back-btn text-left page__main main ml-5 my-3">
          <a routerLink="/admin/manage">
            <i class="fa fa-chevron-left"></i>
            {{ 'button.back-to-overview' | translate }}
          </a>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
