import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CapturumFormRendererComponent, FormFieldsConfig, FormSaverService } from '@capturum/builders/form-renderer';
import { first, Observable } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-quotation-attachments-detail',
  templateUrl: './email-quotation-attachments-detail.component.html',
  styleUrls: ['./email-quotation-attachments-detail.component.scss'],
})
export class EmailQuotationAttachmentsDetailComponent implements AfterViewInit {
  @ViewChild('formRenderer')
  public formRenderer: CapturumFormRendererComponent;

  public modelId: string;
  public formKey = 'form_quotation_attachment';
  public loaded: Observable<FormFieldsConfig>;

  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.modelId = this.dialogConfig.data?.item.id;
  }

  public ngAfterViewInit(): void {
    this.loaded = this.formRenderer?.fields$;
  }

  public onSubmit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);

        const entity = this.translateService.instant('movers_complete.entity.email-quotation-attachments.single');

        this.toastService.success(
          this.translateService.instant('movers_complete.entity.email-quotation-attachments.single'),
          this.translateService.instant('movers_complete.entity.toast.updated', { entity })
        );

        this.router.navigateByUrl('/').then(() => {
          this.router.navigateByUrl('/admin/manage/email-quotation-attachments');
        });
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
