import { ManageDefaultInventoriesDetailComponent } from './../default-inventories-detail/default-inventories-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-default-inventories-list',
  templateUrl: './default-inventories-list.component.html',
  styleUrls: ['./default-inventories-list.component.scss'],
})
export class ManageDefaultInventoriesListComponent {
  public listKey = 'list_default_inventories';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageDefaultInventoriesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.room.single'),
    });
  }
}
