import { Component, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from '@root/node_modules/rxjs';
import { first } from '@node_modules/rxjs/operators';
import { AddressType } from '@domain/models/address-type.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CapturumFormRendererComponent, FormFieldsConfig, FormSaverService } from '@capturum/builders/form-renderer';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address-types-detail',
  templateUrl: './address-types-detail.component.html',
  styleUrls: ['./address-types-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAddressTypesDetailComponent implements AfterViewInit {
  @ViewChild('formRenderer')
  public formRenderer: CapturumFormRendererComponent;

  public item: AddressType;
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: UntypedFormGroup;
  public mode = { isAdd: true };
  public modelId: string;
  public formKey = 'form_address_type';
  public loaded: Observable<FormFieldsConfig>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.form = new UntypedFormGroup({});
    this.item = new AddressType({}).getData();
    this.isAdd = false;
    this.loading = true;
    this.showErrors = false;
    this.modelId = this.dialogConfig.data?.item.id;
  }

  public ngAfterViewInit(): void {
    this.loaded = this.formRenderer?.fields$;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);

        const entity = this.translateService.instant('movers_complete.entity.address-type.single');

        this.toastService.success(
          this.translateService.instant('movers_complete.entity.address-type.single'),
          this.translateService.instant('movers_complete.entity.toast.updated', { entity })
        );

        this.router.navigateByUrl('/').then(() => {
          this.router.navigateByUrl('/admin/manage/address-types');
        });
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
