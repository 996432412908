<div class="header d-flex">
  <cap-dropdown
    [options]="inventoryList"
    [(ngModel)]="inventory"
    (change)="onInventoryChange()"
    [placeholder]="'movers_complete.inventory.board.room.placeholder' | translate"
    [showClear]="true"
    [filter]="true">
  </cap-dropdown>

  <div class="buttons">
    <cap-button
      styleClass="primary"
      icon="fas fa-plus"
      class="text-nowrap"
      [disabled]="disabled"
      (onClick)="onAddClick()"
      [label]="'movers_complete.inventory.board.room_add.label' | translate">
    </cap-button>

    <cap-button
      styleClass="secondary"
      icon="fas fa-trash"
      [disabled]="disabled"
      (onClick)="onDeleteClick()"
      [label]="'button.delete' | translate">
    </cap-button>
  </div>
</div>
<div class="tile" *ngIf="!hideInventoriesTopbar">
  <div class="d-grid">
    <div class="row detail-info">
      <div class="col-7 detail-label">
        {{ 'movers_complete.inventory.board.room_current.label' | translate }}
      </div>

      <div class="col-5 detail-value">
        <b>{{ inventory?.volume | isNumber | number }} m<sup>3</sup></b>
      </div>
    </div>

    <div class="row detail-info">
      <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.inventory.label' | translate }}</div>

      <div class="col-5 detail-value">
        <b>{{ volumeTotal | isNumber | number }} m<sup>3</sup></b>
      </div>
    </div>

    <ng-container *ngIf="project.type === 'private'">
      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.barrels.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.barrel | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.moving_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.movingBoxes | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.book_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.BookBoxes | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.wardrobe_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.wardrobeBoxes | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.painting_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.painting | productTotal : inventories }}</b>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="project.type === 'business'">
      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.dkb.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.dkb | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.meter_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.mb | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.computer_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.computerBox | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.roll_container.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.rolco | productTotal : inventories }}</b>
        </div>
      </div>

      <div class="row detail-info">
        <div class="col-7 detail-label">{{ 'movers_complete.inventory.board.moving_box.label' | translate }}</div>

        <div class="col-5 detail-value">
          <b>{{ inventoryType.movingBoxes | productTotal : inventories }}</b>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- @TODO: Find out how these should be displayed: -->
<!-- 
<ng-container *ngIf="project.type === 'business' && inventory && inventory.building">
    <label class="col-sm-2 control-label input-label">Gebouw</label>
    <span class="col-sm-4 form-control-static">{{inventory?.building}}</span>
    <label class="col-sm-2 control-label input-label">Verdieping</label>
    <span class="col-sm-4 form-control-static">{{inventory?.floor}}</span>
</ng-container> -->
