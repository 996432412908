<div class="ibox-title d-flex justify-content-between align-items-center">
  <h5>{{ 'movers_complete.inventory.pictures.title' | translate }}</h5>

  <cap-button
    styleClass="primary"
    [disabled]="disabled"
    [label]="'button.add' | translate"
    [icon]="'fa fa-plus'"
    (onClick)="addNewFile()">
  </cap-button>
</div>

<div class="ibox-content" *ngIf="project">
  <cpb-list-renderer
    [key]="listKey"
    [context]="{
      project_id: project?.id,
      read_only: disabled
    }">
  </cpb-list-renderer>
</div>
