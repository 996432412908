import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from '@root/node_modules/rxjs';
import { first } from 'rxjs/operators';
import { ToastService } from '@capturum/ui/api';
import { CapturumFormRendererComponent, FormFieldsConfig, FormSaverService } from '@capturum/builders/form-renderer';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-default-items-detail',
  templateUrl: './default-items-detail.component.html',
  styleUrls: ['./default-items-detail.component.scss'],
})
export class ManageDefaultItemsDetailComponent implements AfterViewInit {
  @ViewChild('formRenderer')
  public formRenderer: CapturumFormRendererComponent;

  public modelId: string;
  public formKey = 'form_default_item';
  public loaded: Observable<FormFieldsConfig>;

  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.modelId = this.dialogConfig.data?.item.id;
  }

  public ngAfterViewInit(): void {
    this.loaded = this.formRenderer?.fields$;
  }

  public onSubmit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);

        const entity = this.translateService.instant('movers_complete.entity.item.single');

        this.toastService.success(
          this.translateService.instant('movers_complete.entity.item.single'),
          this.translateService.instant('movers_complete.entity.toast.updated', { entity })
        );

        this.router.navigateByUrl('/').then(() => {
          this.router.navigateByUrl('/admin/manage/default-items');
        });
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
