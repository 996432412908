import { DomainModel } from '@domain/domain.model';

export class Activity extends DomainModel {
  // Configuration
  public entity = 'activity';
  public table = 'activities';
  public sync = true;

  // Fields
  public name: string;
  public sends_notification: boolean;
  public project_type_base_data_value_id: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  public static listByProjectType(projectTypeId: string): Promise<Activity[]> {
    return Activity.query.where('project_type_base_data_value_id').equals(projectTypeId).toArray();
  }
}
