import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber',
})
export class IsNumberPipe implements PipeTransform {
  public transform(value: any): number {
    if (!Number(value)) {
      try {
        /** Parse string to float, and format to 2 decimals */
        let result = parseFloat(value) || 0;

        return (result = Math.round(result * 100) / 100);
      } catch {
        return 0;
      }
    }

    return Number(value) || 0;
  }
}
