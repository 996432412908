import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class QuotationGuard {
  constructor(
    private user: UserService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.isAdministrator() || this.user.hasRole('user')) {
      return true;
    }

    this.toastService.error(
      this.translateService.instant('toast.error.title'),
      this.translateService.instant('movers_complete.toast.access.error.text')
    );

    return false;
  }
}
