import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';

@Injectable()
export class InventoryGuard {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryBoardComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    return true;
  }
}
