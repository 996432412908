import { ManageBulkPricesDetailComponent } from './../bulk-prices-detail/bulk-prices-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bulk-prices-list',
  templateUrl: './bulk-prices-list.component.html',
  styleUrls: ['./bulk-prices-list.component.scss'],
})
export class ManageBulkPricesListComponent {
  public listKey = 'list_bulk_prices';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageBulkPricesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.bulk_price.single'),
    });
  }
}
