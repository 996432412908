import { DomainModel } from '@domain/domain.model';

export class Role extends DomainModel {
  // Configuration
  public entity = 'role';
  public table = 'roles';
  public sync = false;

  // Fields
  public id: number;
  public name: string;
  public code: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
