import { DomainModel } from '@domain/domain.model';
import { v4 as uuid } from 'uuid';

export class InventoryItem extends DomainModel {
  // Configuration
  public entity = 'inventory-item';
  public table = 'inventory_items';
  public sync = true;
  public id: string;

  // Fields
  public inventory_id: string;
  public amount: number;
  public name: string;
  public volume: number;
  public price: number;
  public meterbox: number;
  public remarks: string;
  public image: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      inventory_id: this.inventory_id,
      amount: this.amount || 0,
      name: this.name || '',
      volume: this.volume || 0,
      price: this.price || 0,
      remarks: this.remarks || '',
      image: this.image || null,
    };
  }
}
