import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment_ from 'moment';
const moment = moment_;

@Pipe({
  name: 'nestedColumnValue',
})
export class NestedColumnValuePipe implements PipeTransform {
  public transform(data: any, field: string, type?: string, enumOptions?: any[], icon?: string): any {
    const fields = field.split('.');
    let value = data[fields[0]];

    if (type && type === 'array') {
      const dataAttribute = fields[fields.length - 2]; // Second last field is array of objects
      const valueAttribute = fields[fields.length - 1]; // Last field is attribute to display

      // Create an array with data to display
      value = value[dataAttribute];

      if (!value) {
        return '';
      }

      value = value.map((val) => {
        return val[valueAttribute];
      });
    } else {
      for (let index = 1; index < fields.length; index++) {
        if (value) {
          value = value[fields[index]];
        }
      }
    }

    if (!type) {
      return value;
    }

    switch (type) {
      case 'array':
        return value.join(', ');
      case 'boolean':
        if (value === true) {
          return 'yes';
        }

        return 'no';
      case 'date':
        if (value) {
          return moment(moment.utc(value)).format('DD-MM-Y');
        }

        return '';
      case 'datetime':
        if (value) {
          return moment(moment.utc(value)).format('DD-MM-Y HH:mm');
        }

        return '';
      case 'icon':
        // Check for row specific icon
        icon = icon || value;

        return `<div class="icon-table text-center">
                    <i class="${icon}"></i>
                </div>`;
      case 'icon_text':
        // Check for row specific icon
        icon = icon || value;

        if (isObservable(value)) {
          value = value.pipe(
            map((emittedValue) => {
              return `<div class="icon-table">
                    <i class="${icon}"></i> ${emittedValue}
                </div>`;
            })
          );

          return value;
        }

        return of(`<div class="icon-table">
                    <i class="${icon}"></i> ${value}
                </div>`);
      case 'percentage':
        return `${value}%`;
      case 'float':
        return Math.round(value * 100) / 100;
      case 'yes_no':
        return `<div class="yes-no-value ${value === true ? 'yes-value' : 'no-value'}">
                    ${value === true ? '&#10004;' : 'x'}
                </div>`;
      case 'enum':
        const enumOption = enumOptions.find((option) => {
          return option.value === value;
        });

        if (enumOption) {
          return enumOption.label;
        }

      /* Falls through */
      default:
        return value;
    }
  }
}
