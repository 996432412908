import { DomainModel } from '@domain/domain.model';
import { v4 as uuid } from 'uuid';
import { Project } from '@domain/models/project.model';

export class ClientTemplate extends DomainModel {
  // Configuration
  public entity = 'client_template';
  public table = 'client_templates';
  public sync = true;
  public id: string;

  // Fields
  public name: string;
  public parent_id?: string;

  public parent_project?: Project;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id || '',
      name: this.name || '',
      parent_id: this.parent_id || '',
      parent_project: this.parent_project,
    };
  }
}
