<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-12 p-0">
      <div class="box-content-wrap">
        <div class="mt-5">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isSuperuser()">
              <div class="manage-item" [routerLink]="['/admin/manage/settings']">
                {{ 'movers_complete.config.manage.settings.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/address-types']">
                {{ 'movers_complete.config.manage.address-types.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/house-types']">
                {{ 'movers_complete.config.manage.house-types.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/addresses']">
                {{ 'movers_complete.config.manage.addresses.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/contacts']">
                {{ 'movers_complete.config.manage.contacts.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/materials']">
                {{ 'movers_complete.config.manage.materials.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/options']">
                {{ 'movers_complete.config.manage.options.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/activities']">
                {{ 'movers_complete.config.manage.activities.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/relation-groups']">
                {{ 'movers_complete.config.manage.relation-groups.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator() && hasBulkPrices">
              <div class="manage-item" [routerLink]="['/admin/manage/bulk-prices']">
                {{ 'movers_complete.config.manage.bulk-prices.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/default-items']">
                {{ 'movers_complete.config.manage.default-items.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/default-inventories']">
                {{ 'movers_complete.config.manage.default-inventories.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/locations']">
                {{ 'movers_complete.config.manage.locations.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/quotation-checks']">
                {{ 'movers_complete.config.manage.quotation-checks.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/admin/manage/email-quotation-attachments']">
                {{ 'movers_complete.config.manage.email-quotation-attachments.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/builders/configuration']">
                {{ 'builders.list_configurations.title' | translate }}
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="userService.isAdministrator()">
              <div class="manage-item" [routerLink]="['/builders/release']">
                {{ 'builders.list_releases.title' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
