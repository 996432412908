import { Injectable } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { TypeCheckUtils } from '@core/utils/type-check.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TableFilter } from '../components/table-filter/models/table-filter.model';

@Injectable()
export class FilterService extends DestroyBase {
  private _queueDelay = 0;
  private _filters: TableFilter[] = [];
  private _queue: Subject<TableFilter[]> = new Subject<TableFilter[]>();
  private _activeFilters: BehaviorSubject<TableFilter[]> = new BehaviorSubject<TableFilter[]>(this._filters);

  constructor() {
    super();

    this.handleQueuedFilters();
  }

  public handleQueuedFilters(): void {
    this._queue
      .asObservable()
      .pipe(debounceTime(this._queueDelay), takeUntil(this.destroy$))
      .subscribe((filterQueue) => {
        this._activeFilters.next(filterQueue);
      });
  }

  public onChange(): Observable<TableFilter[]> {
    return this._activeFilters.asObservable();
  }

  public getFilters(): TableFilter[] {
    return this._filters;
  }

  public setFilters(filters: TableFilter[]): void {
    this._filters = filters.filter((currentFilter) => {
      return this.canAddFilter(currentFilter);
    });

    this._queue.next(this._filters);
  }

  public canAddFilter(filter: TableFilter): boolean {
    return !!filter.value && filter.value !== '';
  }

  public isEmptyFilter(value: any): boolean {
    const isEmptyString = TypeCheckUtils.isString(value) && (!value || value.length === 0);
    const isEmptyArray = Array.isArray(value) && value.length === 0;

    return isEmptyString || isEmptyArray;
  }

  public updateFilters(filter: TableFilter): void {
    if (this.isEmptyFilter(filter.value)) {
      this.removeFilter(filter.field);
    } else {
      this.addFilter(filter);
    }
  }

  public addFilter(filter: TableFilter): void {
    if (!this.canAddFilter(filter)) {
      return;
    }

    this._filters = [
      ...this._filters.filter((activeFilter) => {
        return activeFilter.field !== filter.field;
      }),
      filter,
    ];

    this._queue.next(this._filters);
  }

  public removeFilter(field: string): void {
    this._filters = [
      ...this._filters.filter((activeFilter) => {
        return activeFilter.field !== field;
      }),
    ];

    this._queue.next(this._filters);
  }
}
