import * as isEqual from 'lodash/isEqual';

export function isArrayOfEqualObjects<T>(arr1: T[], arr2: T[]): boolean {
  const sortedA = arr1.slice().sort();
  const sortedB = arr2.slice().sort();

  return (
    sortedA.length === sortedB.length &&
    sortedA.every((value, index) => {
      return isEqual(sortedB[index], value);
    })
  );
}

export function isNumeric(val: any): val is number {
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat (#15100)
  return !Array.isArray(val) && val - parseFloat(val) + 1 >= 0;
}
