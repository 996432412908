import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SettingService } from '@shared/services/setting.service';

@Injectable({ providedIn: 'root' })
export class SettingResolver {
  constructor(private settingService: SettingService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.settingService.loadAll();
  }
}
