<div class="row">
  <div class="col-lg-12">
    <div class="ibox-content" *ngIf="tableOptions">
      <em-offline-table
        [options]="tableOptions"
        (rowClicked)="onRowClick($event)"
        (change)="validation()"></em-offline-table>
    </div>

    <div class="ibox-footer" *ngIf="project?.status !== 'booked' && !disabled">
      <cap-button styleClass="primary" [label]="'button.add' | translate" (click)="onAddClick()">
        <i class="fa fa-plus"></i>
      </cap-button>

      <cap-button
        styleClass="primary"
        [disabled]="table?.selectedRows?.length === 0"
        [label]="'button.delete' | translate"
        (click)="onDeleteClick()">
        <i class="fa fa-trash"></i>
      </cap-button>
    </div>
  </div>
</div>
