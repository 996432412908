<div class="row">
  <label class="col-md-1 col-sm-2 m-0">Groep</label>

  <div class="col-md-6 col-sm-10">
    <cap-dropdown
      [options]="materialGroupList"
      [(ngModel)]="materialGroup"
      (onChange)="onMaterialGroupChange()"
      placeholder="Selecteer een groep"
      [filter]="true"
      [showClear]="true">
    </cap-dropdown>
  </div>
</div>
