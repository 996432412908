import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { BlueprintFile } from '@core/interfaces/file.interface';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileApiService extends ApiService<BlueprintFile> {
  protected endpoint = 'file';

  public download(fileId: string, fileName?: string): void {
    this.apiHttp.getBlob(`/${this.endpoint}/${fileId}/download`, '').subscribe((response) => {
      saveAs(response, fileName);
    });
  }
}
