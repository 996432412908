import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Activity } from '@domain/models/activity.model';
import { Specialty } from '@domain/models/specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { SelectItem } from 'primeng/api';
import { User } from '@domain/models/user.model';
import { ProjectMaterial } from './project-material.model';
import { Picture } from './picture.model';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { Event } from '@domain/models/event.model';
import { getMappedBaseDataByKey } from '@core/utils/base-data.utils';
import { ProjectType } from '@core/enums/project-type.enum';
import { Contact } from '@domain/models/contact.model';
import { InventoryDeepTotal } from '@domain/models/inventory-deep-total.interface';

export class Project extends DomainModel {
  // Configuration
  public entity = 'project';
  public table = 'projects';
  public sync = true;
  public id: string;

  // Fields
  public _original: any;
  public is_new = false;
  public is_changed = false;

  public type?: string;
  public projectType?: string;
  public status = 'new';
  public client_id?: string;
  public clientName?: string;
  public accountmanager_id: number;
  public accountmanagerName: string;
  public editing_by: number;
  public editingBy: User;
  public executor_id?: number;
  public reference_nr?: string;
  public own_description_activities?: string;
  public client_description_activities?: string;
  public insurance_certificate_link?: string;
  public delivery_date?: Date | string;
  public type_base_data_value_id?: string;
  public status_base_data_value_id?: string;

  public editor: any;
  public client = new Client({});
  public accountmanager = new User({});
  public addresses: Address[] = [];
  public contacts: Contact[] = [];
  public specialties: ProjectSpecialty[] = [];
  public activities: ProjectActivity[] = [];
  public inventories: Inventory[] = [];
  public quotation?: Quotation;
  public materials: ProjectMaterial[] = [];
  public pictures: Picture[] = [];
  public events: Event[] = [];
  public inventories_deep_total: InventoryDeepTotal[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  /**
   * Returns the project status list options
   */
  public static getStatusList(): SelectItem[] {
    return [
      { label: 'Nieuw', value: 'new' },
      { label: 'Geboekt', value: 'booked' },
      { label: 'In afwachting', value: 'pending' },
      { label: 'Verloren', value: 'lost' },
      { label: 'Inactief', value: 'inactive' },
    ];
  }

  /**
   * Returns the status name by value
   * @param status string
   */
  public static getStatusName(status: string): string {
    const result = this.getStatusList().find((item) => {
      return item.value === status;
    });

    return result ? result.label : '';
  }

  public async init() {
    const projectTypes = await getMappedBaseDataByKey('project-type');

    if (!this.type_base_data_value_id) {
      this.type_base_data_value_id = projectTypes[ProjectType.private];
    }

    this.type = this.type_base_data_value_id;
    // Set project type name
    switch (this.type_base_data_value_id) {
      case projectTypes[ProjectType.business]:
        this.projectType = ProjectType.business;
        break;
      case projectTypes[ProjectType.private]:
        this.projectType = ProjectType.private;
        break;
      default:
        this.projectType = '?';
        break;
    }

    // Set relations
    if (this.client_id) {
      this.client = await Client.query.get(this.client_id);
      this.clientName = this.client ? this.client.name : '';
    }

    if (this.accountmanager_id) {
      this.accountmanager = await User.query.get(this.accountmanager_id);
      this.accountmanagerName = this.accountmanager ? this.accountmanager.name : '';
    }

    if (this.editor && this.editor.length > 0) {
      this.editor = this.editor[0];
    }

    if (this.delivery_date && this.delivery_date !== 'Invalid Date') {
      this.delivery_date = moment(this.delivery_date).toDate();
    } else {
      this.delivery_date = null;
    }

    await this.loadInventories();

    this.inventories.forEach((inventory: Inventory) => {
      inventory = new Inventory(inventory);
      inventory.init();
    });
  }

  public async loadActivities() {
    if (!this.id) {
      return;
    }

    const allActivities = await Activity.query.toArray();

    this.activities = await ProjectActivity.query
      .where('project_id')
      .equals(this.id)
      .filter((projectActivity) => {
        const activity = allActivities.find((filterActivity) => {
          return filterActivity.id === projectActivity.activity_id;
        });

        return activity?.project_type_base_data_value_id === this.type_base_data_value_id;
      })
      .toArray();

    for (const activity of this.activities) {
      await activity.init();
    }

    const projectActivityIds = this.activities.map((activity) => {
      return activity.activity_id;
    });

    const activities = await Activity.query
      .where('project_type_base_data_value_id')
      .equals(this.type_base_data_value_id)
      .and((activity) => {
        return !projectActivityIds.includes(activity.id);
      })
      .toArray();

    for (const activity of activities) {
      await activity.init();
      const projectActivity = new ProjectActivity({
        activity_id: activity.id,
        project_id: this.id,
      });

      await projectActivity.init();
      this.activities.push(projectActivity);
    }
  }

  public async loadMaterials() {
    if (!this.id) {
      return;
    }

    this.materials = await ProjectMaterial.query.where('project_id').equals(this.id).toArray();
    for (const material of this.materials) {
      await material.init();
    }
  }

  public async loadEvents() {
    if (!this.id) {
      return;
    }

    this.events = await Event.query
      .where('eventable_id')
      .equals(this.id)
      .and((item) => {
        return item.eventable_type == 'PAVanRooyen\\Domain\\Project\\Project';
      })
      .toArray();
    for (const event of this.events) {
      await event.init();
    }
  }

  public async loadSpecialties() {
    if (!this.id) {
      return;
    }

    this.specialties = await ProjectSpecialty.query.where('project_id').equals(this.id).toArray();
    for (const specialty of this.specialties) {
      await specialty.init();
    }

    this.specialties = this.specialties.filter(async (projectSpecialty) => {
      if (
        ((!projectSpecialty.specialty || !projectSpecialty.specialty.relationGroups) &&
          !projectSpecialty.hours_estimate) ||
        (!projectSpecialty.applicable &&
          !projectSpecialty.hours_estimate &&
          !projectSpecialty.specialty.relationGroups.find((item) => {
            return item.id === this.client.relation_group_id;
          }))
      ) {
        await ProjectSpecialty.query.delete(projectSpecialty.id);

        return false;
      }

      return true;
    });

    const allSpecialties = (await Specialty.query.toArray()).filter((item) => {
      const relationGroupIds = item.relationGroups?.map((relationGroup) => {
        return relationGroup.id;
      });

      return item.deleted_at === null && relationGroupIds.includes(this.client?.relation_group_id);
    });

    const newSpecialties: Specialty[] = allSpecialties.filter((item) => {
      return !this.specialties.find((projectSpecialty) => {
        return projectSpecialty.specialty ? projectSpecialty.specialty.id === item.id : false;
      });
    });

    // Fill specialties if not available yet
    if (newSpecialties) {
      for (const specialty of newSpecialties) {
        await specialty.init();

        const projectSpecialty = new ProjectSpecialty({
          specialty_id: specialty.id,
          project_id: this.id,
          applicable: specialty.is_default_selected,
        });

        await projectSpecialty.init();

        this.specialties.push(projectSpecialty);
      }
    }
  }

  public async loadInventories() {
    this.inventories = await Inventory.query.where('project_id').equals(this.id).toArray();

    for (const inventory of this.inventories) {
      await inventory.init();
    }
  }

  public async loadQuotation() {
    this.quotation = await Quotation.query.where('project_id').equals(this.id).first();
    await this.quotation?.init();
  }

  public async loadAddresses() {
    this.addresses = await Address.query.where('project_id').equals(this.id).toArray();
    for (const address of this.addresses) {
      await address.init();
    }
  }

  public async loadContacts(): Promise<void> {
    this.contacts = await Contact.query.where('client_id').equals(this.client.id).toArray();

    for (const contact of this.contacts) {
      await contact.init();
    }
  }

  public async loadPictures() {
    this.pictures = await Picture.query.where('project_id').equals(this.id).toArray();
    for (const picture of this.pictures) {
      await picture.init();
    }
  }

  public hasActivities() {
    return (
      this.activities &&
      this.activities.some((activity) => {
        return activity.applicable;
      })
    );
  }

  public hasSpecialties() {
    return (
      this.specialties &&
      this.specialties.some((specialty) => {
        return specialty.applicable;
      })
    );
  }

  public getApplicableSpecialties(): ProjectSpecialty[] {
    return this.specialties.filter((specialty) => {
      return specialty.applicable;
    });
  }

  public getData(): any {
    return {
      id: this.id,
      client_id: this.client_id,
      type: this.type_base_data_value_id || '',
      reference_nr: this.reference_nr || '',
      own_description_activities: this.own_description_activities || '',
      client_description_activities: this.client_description_activities || '',
      insurance_certificate_link: this.insurance_certificate_link || '',
      delivery_date: this.delivery_date ? moment(this.delivery_date).format('YYYY-MM-DD') : null,
      executor_id: this.executor_id,
      accountmanager_id: this.accountmanager_id,
      type_base_data_value_id: this.type_base_data_value_id || '',
      status_base_data_value_id: this.status_base_data_value_id || '',
    };
  }
}
