import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

declare let jQuery: any;

@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.template.html',
})
export class NavigationComponent implements AfterViewInit {
  public user;

  constructor(private router: Router, private api: ApiServiceWithLoaderService) {
    api.get('/me').subscribe((user) => {
      this.user = user;
    });
  }

  public ngAfterViewInit(): void {
    jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%',
      });
    }
  }

  public activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
}
