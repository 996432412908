import { Observable, of, Subject, firstValueFrom } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { Project } from '@domain/models/project.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { DataService } from '@shared/services/data.service';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { environment } from '@environments/environment';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { BehaviorSubject } from '@node_modules/rxjs';
import { Signature } from '@domain/models/signature.model';
import { Picture } from '@domain/models/picture.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Tenant } from '@domain/models/tenant.model';
import { catchError, filter, map, switchMap, takeUntil } from '@node_modules/rxjs/operators';
import * as hexToHsl from 'hex-to-hsl';
import { Event } from '@domain/models/event.model';
import { HttpClient } from '@angular/common/http';
import { SettingService } from '@shared/services/setting.service';
import { CSSVarNames, ThemeService, ToastService } from '@capturum/ui/api';
import { DexieStore } from '@domain/dexie-store';
import { ListOptions } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { responseData } from '@capturum/builders/core';

@Injectable()
export class SynchronisationService implements OnDestroy {
  public SynchronisingCompleted = new Subject<any>();
  public synchronisingAction$ = new BehaviorSubject<boolean>(true);
  public myTenant$ = new BehaviorSubject<Tenant>(null);
  public shouldSync: boolean;

  private store = DexieStore.getInstance();
  private state = { added: false, finished: false };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private api: ApiServiceWithLoaderService,
    private auth: AuthService,
    private dataService: DataService,
    private settingService: SettingService,
    private http: HttpClient,
    private readonly themeService: ThemeService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    // Register to internet connection online event
    window.addEventListener(
      'online',
      async () => {
        if (this.shouldSync) {
          await this.synchronise();
          this.shouldSync = false;
        }
      },
      false
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resolve(): Observable<boolean> {
    return this.api.get('/tenants/my').pipe(
      takeUntil(this.destroy$),
      map((result) => {
        if (result && result.data) {
          this.setMyTenantStyling(result.data);

          this.myTenant$.next(result.data);

          return true;
        }

        return false;
      })
    );
  }

  public getTenantConfigQuotation(property: string): any {
    return this.myTenant$ &&
      this.myTenant$.getValue() &&
      this.myTenant$.getValue().config_quotation &&
      this.myTenant$.getValue().config_quotation[property]
      ? this.myTenant$.getValue().config_quotation[property]
      : null;
  }

  public getTenantCode(): Observable<string> {
    return this.myTenant$.asObservable().pipe(
      filter(Boolean),
      switchMap((tenant: any) => {
        if (tenant.code) {
          return this.http.get(`/assets/${tenant.code}/logo.png`).pipe(
            map(() => {
              return tenant.code;
            })
          );
        }
      }),
      catchError(() => {
        return of('default');
      }),
      takeUntil(this.destroy$)
    );
  }

  public getTenantLogo(): string {
    return this.myTenant$ && this.myTenant$.getValue() && this.myTenant$.getValue().logo
      ? this.myTenant$.getValue().logo
      : '/assets/images/zandbergen-logo.png';
  }

  public async clearProjectData(projectId: string): Promise<any> {
    const project = await Project.query.get({ id: projectId });
    const quotation = await Quotation.query.get({ project_id: projectId });
    const inventory = await Inventory.query.get({ project_id: projectId });
    const workAssignment = await WorkAssignment.query.get({ project_id: projectId });

    return DexieStore.getInstance()
      .table('projects')
      .where('id')
      .equals(projectId)
      .delete()
      .then(() => {
        try {
          const tables = this.getProjectTables();

          return Object.keys(tables).forEach(async (tableName) => {
            let column = 'project_id';
            let value = project.id;

            if (['quotation_materials', 'quotation_tasks'].includes(tableName)) {
              if (!quotation || quotation.id) {
                return;
              }

              column = 'quotation_id';
              value = quotation.id;
            } else if (['inventory_items'].includes(tableName)) {
              if (!inventory || inventory.id) {
                return;
              }

              column = 'inventory_id';
              value = inventory.id;
            } else if (['projects'].includes(tableName)) {
              if (!project || !project.id) {
                return;
              }

              column = 'id';
              value = project.id;
            } else if (['clients'].includes(tableName)) {
              if (!project || !project.client_id) {
                return;
              }

              column = 'id';
              value = project.client_id;
            } else if (['contacts'].includes(tableName)) {
              if (!project || !project.client_id) {
                return;
              }

              column = 'client_id';
              value = project.client_id;
            } else if (['work_assignment_items', 'address_work_assignments', 'signatures'].includes(tableName)) {
              if (!workAssignment || !workAssignment.id) {
                return;
              }

              column = 'work_assignment_id';
              value = workAssignment.id;
            } else if (['events'].includes(tableName)) {
              column = 'eventable_id';
              value = project.id;
            }

            await DexieStore.getInstance().table(tableName).where(column).equals(value).delete();
          });
        } catch (error) {
          console.error(error);

          return null;
        }
      });
  }

  public setSynchronisingAction(action: boolean): void {
    this.synchronisingAction$.next(action);
  }

  public async synchronise(): Promise<void> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;

      return;
    }

    await this.getListData();

    this.showSyncReadyToast();

    this.SynchronisingCompleted.next(this.state);
  }

  public showSyncReadyToast(): void {
    this.toastService.success(
      this.translateService.instant('movers_complete.entity.application.single'),
      this.translateService.instant('movers_complete.application.ready.text')
    );
  }

  public async syncToBackend(clearOnSuccess = false, closedProjectIds: number[] = null): Promise<boolean> {
    let errorReceived = false;
    let projects = await Project.query.toArray();

    if (closedProjectIds) {
      projects = projects.filter((project) => {
        return closedProjectIds.indexOf(project.id) !== -1;
      });
    }

    for (const project of projects) {
      // Check if id is set
      if (!project.id) {
        continue;
      }

      // Check if it isn't a read only project
      if (!project.editingBy?.name) {
        const data = await this.getSyncJson(project);
        const newData = JSON.parse(JSON.stringify(data));
        const originalData = project._original ? JSON.parse(JSON.stringify(project._original)) : {};

        // Compare new data and original
        const diff = this.getDiff(newData, originalData);

        if (diff && project.id) {
          const somethingWentWrong =
            originalData &&
            originalData.project &&
            originalData.project.reference_nr &&
            newData &&
            newData.quotation &&
            newData.quotation._deleted;

          if (somethingWentWrong) {
            this.toastService.error(
              this.translateService.instant('movers_complete.sync.error.title'),
              this.translateService.instant('movers_complete.sync.project_overwriten.error.text', {
                project_number: originalData.project.reference_nr,
              })
            );
            errorReceived = true;
          } else {
            // Apply changes to server
            const results = await this.api.post('/sync/post', [diff]).toPromise();

            for (let i = 0; i < results.length; i++) {
              const response = results[i];

              if (response !== 'ok') {
                errorReceived = true;
                console.error('Error response: ', results);

                if (response === 'SEND_PROJECT_ERROR') {
                  this.toastService.error(
                    this.translateService.instant('movers_complete.sync.error.title'),
                    this.translateService.instant('movers_complete.sync.planning.error.text')
                  );
                } else {
                  this.toastService.error(
                    this.translateService.instant('movers_complete.sync.error.title'),
                    this.translateService.instant('movers_complete.sync.response.error.text', {
                      message: response,
                    })
                  );
                }
              } else {
                this.toastService.success(
                  this.translateService.instant('movers_complete.toast.sync.title'),
                  this.translateService.instant('movers_complete.entity.sync.success.text', {
                    entity: this.translateService.instant('movers_complete.entity.project.single'),
                  })
                );
              }
            }
          }

          if (!errorReceived) {
            await this.loadSingleProjectData(project.id, true);

            if (clearOnSuccess) {
              await this.clearProjectData(project.id);
            } else {
              // Update status
              const updateProjects = await Project.query.toArray();

              for (const proj of updateProjects) {
                proj.is_changed = false;
                proj.is_new = false;

                await this.dataService.createOrUpdate('projects', proj);
              }
            }
          }
        } else {
          await this.clearProjectData(project.id);
        }
      } else {
        await this.clearProjectData(project.id);
      }
    }

    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Retrieves a single projects from backend and updates the client store
   */
  public async loadSingleProjectData(projectId: string, forceLoad = false): Promise<void> {
    let result;

    const listOptions: ListOptions = {
      include: [
        'addresses',
        'projectActivities',
        'projectSpecialties',
        'projectMaterials',
        'projects',
        'contacts',
        'inventoryItems',
        'inventories',
        'quotationMaterials',
        'quotationTasks',
        'quotation.files.tags',
        'quotation',
        'client.contacts',
        'client.relationGroup',
        'client',
        'workAssignments',
        'apiLogs',
        'events',
        'pictures.files.tags',
        'pictures',
        'editingBy',
      ],
    };

    try {
      result = await firstValueFrom(this.api.get(`/project/${projectId}`, listOptions).pipe(responseData));
    } catch (e) {
      // Ignore error
    }

    if (!result) {
      return;
    }

    // Check if project is already available locally
    const existingProject = await Project.query.get({
      id: result.id,
    });

    if (!forceLoad && existingProject) {
      return;
    }

    // Determine order of processing
    const tables = this.getProjectTables();

    // store project to projects table
    const projectData = Object.keys(result)
      .filter((key) => {
        return !listOptions.include.includes(key);
      })
      .reduce((acc, key) => {
        acc[key] = result[key];

        return acc;
      }, {});

    if (result.editingBy) {
      projectData['editingBy'] = result.editingBy;
    }

    await this.store.table('projects').bulkPut([projectData]);

    Object.keys(tables).forEach(async (table) => {
      let validData = null;
      const tableData = this.getProjectPropertyValue(tables[table], result);

      if (Array.isArray(tableData) && tableData?.length > 0) {
        validData = tableData;
      } else if (tableData && !Array.isArray(tableData) && !this.isEmptyObject(tableData)) {
        validData = [tableData];
      }

      if (validData) {
        // Save data from backend in table
        await this.store.table(table).bulkPut(validData);

        delete result[table];
      }
    });

    // Store a copy of the project tree to track changes
    const updateProject = await Project.query.get({ id: projectId });

    if (updateProject) {
      const copy = await this.getSyncJson(updateProject);

      updateProject._original = JSON.parse(JSON.stringify(copy)); // Clone object
      await this.dataService.createOrUpdate('projects', updateProject);
    }
  }

  /**
   * Lists all tables containing project data
   */
  public getProjectTables(): any {
    return {
      quotation_materials: 'quotationMaterials',
      quotation_tasks: 'quotationTasks',
      quotations: 'quotation',
      inventory_items: 'inventoryItems',
      inventories: 'inventories',
      clients: 'client',
      contacts: 'client.contacts',
      addresses: 'addresses',
      project_activities: 'projectActivities',
      project_specialties: 'projectSpecialties',
      project_materials: 'projectMaterials',
      work_assignments: 'workAssignments',
      work_assignment_items: 'workAssignmentItems',
      address_work_assignments: 'addressWorkAssignments',
      signatures: 'signatures',
      pictures: 'pictures',
      events: 'events',
      api_logs: 'apiLogs',
    };
  }

  public async updateEditingByFlags(): Promise<void> {
    const openProjectIds: string[] = (await Project.query.toArray()).map((project: Project) => {
      return project.id;
    });

    await this.api.patch('/project/update-editing-by-flags', { open_project_ids: openProjectIds }).toPromise();
  }

  /**
   * Retrieves list and base data from backend and updates the client store
   */
  private async getListData(): Promise<void> {
    const listOptions = {
      include: ['relationGroups'],
    };

    const result = await firstValueFrom(this.api.get('/sync/lists', listOptions));
    const models = Object.keys(result);

    for (const model of models) {
      let mappedModel = model;

      if (model === 'clients') {
        mappedModel = 'client_templates';
      }
      // Clear contents of table and replace with backend data
      await this.store.table(mappedModel).clear();
      await this.store.table(mappedModel).bulkAdd(result[model]);
    }

    // Add ARent materials if enabled
    /** ToDo: Replace for myTenant$ behaviourSubject settings */
    if (environment.features.arent_materials) {
      const arentMaterials = await this.api.get('/arent/materials').toPromise();

      await MaterialGroup.query.clear();
      await MaterialGroup.query.bulkAdd(arentMaterials.material_groups);
      await Material.query.clear();
      await Material.query.bulkAdd(arentMaterials.materials);
    }
  }

  /**
   * Retrieves the transformed project data used for synchronising data to backend
   */
  public async getSyncJson(project: Project, isNew?: boolean): Promise<any> {
    // Gather project, client, address, contact, options and quotation data

    const item: any = {};

    item.id = project.id;

    // Project
    item.project = project.getData();
    if (isNew) {
      item.project._new = true;
    }

    // Address
    item.addresses = [];
    const addresses = await Address.query.where('project_id').equals(project.id).toArray();

    for (const address of addresses) {
      item.addresses.push(address.getData());
    }

    // Contact
    item.contacts = [];
    const contacts = await Contact.query.where('project_id').equals(project.id).toArray();

    for (const contact of contacts) {
      item.contacts.push(contact.getData());
    }

    // Client
    item.client = null;
    if (project.client_id) {
      const client = await Client.query.get(project.client_id);

      if (client && client.id && client.name) {
        item.client = client.getData();

        if (isNew) {
          item.client._new = true;
        }
      }
    }

    // ToDo: How to know which events you have to sync?
    // Events
    item.events = [];
    if (project.client_id) {
      const events = await Event.query
        // .where('client_id')
        // .equals(project.client_id)
        .toArray();

      for (const event of events) {
        item.events.push(event.getData());
      }
    }

    // Inventory
    item.inventories = [];
    item.inventory_items = [];
    const inventories = await Inventory.query.where('project_id').equals(project.id).toArray();

    for (const inventory of inventories) {
      item.inventories.push(inventory.getData());

      await inventory.init();

      // Inventory items
      for (const inventoryItem of inventory.items) {
        item.inventory_items.push(inventoryItem.getData());
      }
    }

    item.pictures = await Picture.query.where('project_id').equals(project.id).toArray();

    // Project activities
    item.project_activities = [];
    const projectActivities = await ProjectActivity.query.where('project_id').equals(project.id).toArray();

    for (const projectActivity of projectActivities) {
      item.project_activities.push(projectActivity.getData());
    }

    // Project specialties
    item.project_specialties = [];
    const projectSpecialties = await ProjectSpecialty.query.where('project_id').equals(project.id).toArray();

    for (const projectSpecialty of projectSpecialties) {
      item.project_specialties.push(projectSpecialty.getData());
    }

    // Project materials
    item.project_materials = [];
    const projectMaterials = await ProjectMaterial.query.where('project_id').equals(project.id).toArray();

    for (const projectMaterial of projectMaterials) {
      item.project_materials.push(projectMaterial.getData());
    }

    // Quotation
    const quotation = await Quotation.query.where('project_id').equals(project.id).first();

    if (quotation) {
      item.quotation = quotation.getData();

      if (isNew) {
        item.quotation._new = true;
      }

      item.quotation_materials = [];
      item.quotation_tasks = [];

      await quotation.init();

      // Quotation materials
      if (quotation?.materials) {
        for (const quotationMaterial of quotation.materials) {
          item.quotation_materials.push(quotationMaterial.getData());
        }
      }

      // Quotation tasks
      if (quotation?.tasks) {
        for (const quotationTask of quotation.tasks) {
          item.quotation_tasks.push(quotationTask.getData());
        }
      }
    }

    // Work assignment
    item.work_assignments = [];
    item.work_assignment_items = [];
    item.address_work_assignments = [];
    item.signatures = [];

    const workAssignments = await WorkAssignment.query.where('project_id').equals(project.id).toArray();

    for (const workAssignment of workAssignments) {
      item.work_assignments.push(workAssignment.getData());

      await workAssignment.init();

      // Work assignment items
      for (const workAssignmentItem of workAssignment.items) {
        item.work_assignment_items.push(workAssignmentItem.getData());
      }

      // Work assignment addresses
      for (const workAssignmentAddress of workAssignment.address_work_assignments) {
        item.address_work_assignments.push(workAssignmentAddress.getData());
      }

      // Get signatures of work assignment
      item.signatures = item.signatures.concat(
        await Signature.query.where({ work_assignment_id: workAssignment.id }).toArray()
      );
    }

    // Add project with associations to result
    return item;
  }

  public getDiff(newData: any, oldData: any): any {
    let result: any;

    if (Array.isArray(newData)) {
      result = [];
      for (const item of newData) {
        // Find item with same id in old data and compare
        const oldItem = oldData
          ? oldData.filter((o) => {
              return o.id === item.id;
            })[0]
          : undefined;

        if (!oldItem) {
          // Mark as new
          item._new = true;
          result.push(item);
        } else {
          // Item exists, add differences only
          const itemDiff = this.getDiff(item, oldItem);

          if (itemDiff) {
            // Always add id field
            itemDiff.id = item.id;
            result.push(itemDiff);
          }
        }
      }

      // Check if item is deleted
      if (oldData) {
        for (const oldDataItem of oldData) {
          if (
            newData.filter((o) => {
              return o.id === oldDataItem.id;
            }).length === 0
          ) {
            result.push({ _deleted: oldDataItem.id });
          }
        }
      }

      return result.length === 0 ? undefined : result;
    }

    result = {};
    for (const key of Object.keys(newData)) {
      const newEntity = newData[key];
      const oldEntity = oldData[key];

      // Always add if old item not exists
      if (newEntity && !oldEntity) {
        result[key] = newEntity;
        // Mark each array entry as _new if array
        if (Array.isArray(result[key])) {
          for (const item of result[key]) {
            if (typeof item === 'object') {
              // Mark as new
              item._new = true;
            }
          }
        } else if (typeof result[key] === 'object') {
          // Mark as new
          result[key]._new = true;
        }
        continue;
      }

      if (Array.isArray(newEntity)) {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity && typeof newEntity === 'object') {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity !== oldEntity) {
        result[key] = newEntity;
      }
    }

    // Add id field is result is available
    if (Object.keys(result).length === 0) {
      return undefined;
    }

    result.id = newData.id;

    return result;
  }

  private setMyTenantStyling(tenant: Tenant): void {
    const defaultStylesheet = {
      primary_color: '#00999c',
    };

    const stylesheet =
      tenant.config_quotation && tenant.config_stylesheet ? tenant.config_stylesheet : defaultStylesheet;

    this.themeService.setProps({
      [CSSVarNames.Primary]: stylesheet.primary_color,
      [CSSVarNames.Font]: 'Quicksand',
      [CSSVarNames.Text]: '#3C3C3B',
      ['--primary-color']: stylesheet.primary_color,
    });

    // @TODO: Delete these stylings when will get rid of the old design
    document.documentElement.style.setProperty(`--primary-color-value-h`, hexToHsl(stylesheet.primary_color)[0]);
    document.documentElement.style.setProperty(`--primary-color-value-l`, hexToHsl(stylesheet.primary_color)[2] + '%');
  }

  private getProjectPropertyValue(properties: string, project: Project): Record<string, any> {
    return properties.split('.').reduce((acc, key, index) => {
      if (index === 0) {
        acc[key] = project[key];
      } else if (acc !== null) {
        acc[key] = acc?.[key] || null;
      } else {
        return null;
      }

      return acc[key];
    }, {});
  }

  private isEmptyObject(value: any): boolean {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }
}
