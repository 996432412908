import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Setting } from '@domain/models/setting.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { responseData } from '@capturum/builders/core';

@Injectable()
export class SettingService extends ApiServiceWithLoaderService {
  public settingsChanged$: Subject<void> = new Subject();

  protected endpoint = '/setting';
  protected settingResolverEndpoint = '/role/setting';

  /**
   * Retrieve the settings and store them in de local database
   */
  public async loadAll(): Promise<any> {
    const settings = await firstValueFrom(this.get(this.settingResolverEndpoint).pipe(responseData));

    if (settings) {
      await Setting.query.clear();

      const settingRecords = [];

      for (const key in settings) {
        settingRecords.push({ id: key, value: settings[key] });
      }

      await Setting.query.bulkAdd(settingRecords);
      await Setting.loadSettings();

      this.settingsChanged$.next();
    }

    return Promise.resolve(settings);
  }

  /**
   * Set a setting for a tenant
   */
  public set(settingId: string, data: { value: any; tenant_id?: string }): Observable<any> {
    if (typeof data.value !== 'string') {
      data.value = JSON.stringify(data.value);
    }

    this.settingsChanged$.next();

    return this.post(`/setting/set/${settingId}`, data).pipe(
      tap((response) => {
        Setting.query.put({ id: response.data.key, value: response.data.value });
        Setting.loadSettings().then(() => {
          this.settingsChanged$.next();
        });
      })
    );
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValue(setting: string): any {
    return Setting.getBooleanValueByName(setting);
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValueString(setting: string): any {
    return Setting.getValue(setting);
  }
}
