import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-inventory-static',
  templateUrl: 'inventory-static.component.html',
})
export class InventoryStaticComponent implements OnInit, OnChanges {
  @Input() amount: number;
  @Input() increment: number;
  @Input() disabled;

  @Output() amountChange = new EventEmitter();

  public form: UntypedFormGroup;
  public hasBulkPrices: boolean = false;

  constructor(public settingService: SettingService) {
    // Without this, capturum input number event ngModelChange doesn't work :(
    this.form = new UntypedFormGroup({
      amount: new UntypedFormControl(),
    });
  }

  public ngOnInit(): void {
    this.hasBulkPrices = this.settingService.getValue('movers_complete.has_bulk_prices');

    if (!this.amount || this.amount < 0 || isNaN(this.amount)) {
      this.amount = 0;
    }

    if (!this.increment) {
      this.increment = 1;
    }
  }

  public ngOnChanges(): void {
    if (!this.amount || this.amount < 0 || isNaN(this.amount)) {
      this.amount = 0;
    } else if (typeof this.amount === 'string') {
      // Fix possible string values
      this.amount = parseFloat('' + this.amount);
    }
  }

  public changeAmount(amount: number) {
    if (!amount) {
      amount = 0;
    }

    this.amount = amount;
    this.amountChange.emit(this.amount);
  }
}
