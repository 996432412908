<app-manage-layout>
  <div class="ibox-title d-flex justify-content-between align-items-center">
    <h5>{{ 'movers_complete.entity.activity.plural' | translate }}</h5>

    <button type="button" class="btn btn-primary" (click)="addNewItem()">
      <i class="fa fa-plus"></i> {{ 'button.add' | translate }}
    </button>
  </div>

  <div class="ibox-content">
    <cpb-list-renderer [key]="listKey"></cpb-list-renderer>
  </div>
</app-manage-layout>
