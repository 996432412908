import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { WorkAssignment } from '@domain/models/work-assignment.model';

import { ProjectActivity } from '@domain/models/project-activity.model';
import { ConfirmationService } from 'primeng/api';
import { getMappedBaseDataByKey } from '@core/utils/base-data.utils';
import { AddressWorkAssignmentType } from '@core/enums/address-work-assignment-type.enum';
import { Subject } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-inventory-work-assignment-list',
  templateUrl: 'work-assignment-list.component.html',
})
export class InventoryWorkAssignmentListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent, { static: false })
  table: OfflineTableComponent;

  public project: Project;
  public tableOptions: OfflineTableOptions;
  public disabled = false;
  public addressWorkAssignmentTypes: Record<string, string>;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  private subscriptionProjectLoaded: Subscription;

  get orderedActivities() {
    return this.project.activities.sort((a: ProjectActivity, b: ProjectActivity) => {
      return <any>new Date(a.startDate) - <any>new Date(b.startDate);
    });
  }

  public constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    this.addressWorkAssignmentTypes = await getMappedBaseDataByKey('address-work-assignment-type');

    this.projectService.projectIsReadOnly.pipe(takeUntil(this.destroy$)).subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;

      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
    });

    this.setTableOptions();
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onRowClick(data: any): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + data.id);
  }

  public async onAddClick(): Promise<void> {
    if (!this.disabled) {
      // Create new work assignment, cope work description and open detail
      const workAssignment = new WorkAssignment({
        project_id: this.project.id,
        description_own: this.project.own_description_activities,
        description_client: this.project.client_description_activities,
      });

      workAssignment.setDefaults();
      await this.projectService.saveWorkAssignment(workAssignment);

      this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + workAssignment.id);
    }
  }

  public onDeleteClick(): void {
    if (!this.table.selectedRows || this.table.selectedRows.length === 0 || this.disabled) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde werkopdrachten verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        this.table.selectedRows.forEach(async (row: any) => {
          await this.projectService.deleteWorkAssignment(row);
        });
        this.table.onChangeTable();
      },
    });
  }

  private setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      paging: true,
      search: false,
      columns: [
        { title: 'Datum', name: 'date' },
        { title: 'Laadadres', name: 'pickupAddressName' },
        { title: 'Losadres', name: 'deliverAddressName' },
      ],
      noResultsMessage: 'Er zijn nog geen werkopdrachten aangemaakt',
      withDelete: true,
      itemsPerPage: 500,
      url: '/work_assignments',
      tableName: 'work_assignments',
      filtering: {
        hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }],
      },
      rowDataTransformer: (rows: any) => {
        for (const row of rows) {
          row.pickupAddressName = row.address_work_assignments
            .filter((item) => {
              return item.type_base_data_value_id === this.addressWorkAssignmentTypes[AddressWorkAssignmentType.pickup];
            })
            .map((item) => {
              return item.address ? item.address.getDisplayName() : '';
            })
            .join('<br/>');

          row.deliverAddressName = row.address_work_assignments
            .filter((item) => {
              return (
                item.type_base_data_value_id === this.addressWorkAssignmentTypes[AddressWorkAssignmentType.delivery]
              );
            })
            .map((item) => {
              return item.address ? item.address.getDisplayName() : '';
            })
            .join('<br/>');
          row.date = row.displayDate(row.assignmentDate);
        }

        return rows.sort((rowA, rowB) => {
          /** Sort the rows on date order, newest first */
          return rowA.assignmentDate - rowB.assignmentDate;
        });
      },
    });
  }
}
