<div class="middle-box text-center loginscreen">
  <div class="login-box">
    <div>
      <h1 class="logo-name"></h1>
    </div>

    <h3 class="application-name">{{ environment?.applicationName }}</h3>
    <p>{{ 'movers_complete.auth.login.header.text' | translate }}</p>

    <div class="alert alert-danger" *ngIf="loginError">{{ 'movers_complete.auth.login.error.text' | translate }}</div>

    <div class="alert alert-danger" [hidden]="resetError === false">
      {{ 'movers_complete.auth.login.email.error.text' | translate }}
    </div>

    <div class="alert alert-success" [hidden]="!resetSuccess">
      {{ 'movers_complete.auth.login.rest-password.text' | translate }}
    </div>

    <form class="m-t" role="form" action="#" #loginForm="ngForm">
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          [placeholder]="'user.profile.email' | translate"
          required
          [(ngModel)]="user.email"
          name="email" />
      </div>

      <div class="form-group">
        <input
          type="password"
          class="form-control"
          [placeholder]="'user.detail.password' | translate"
          required
          [(ngModel)]="user.password"
          name="password" />
      </div>

      <button type="submit" class="btn btn-primary block full-width m-b" (click)="onSubmit()">
        {{ 'auth.sign-in.button' | translate }}
      </button>

      <a class="login-link" (click)="resetPassword()">
        <small>{{ 'user.profile.forgot_password' | translate }}?</small>
      </a>
    </form>

    <p class="m-t">
      <small>Emendis &copy; {{ today | date : 'yyyy' }}</small>
    </p>
  </div>
</div>
