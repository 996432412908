import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { ProjectType } from '@core/enums/project-type.enum';

@Pipe({
  name: 'isProjectType',
})
export class IsProjectTypePipe implements PipeTransform {
  public async transform(id: string, projectType: ProjectType): Promise<boolean> {
    const projectTypes = await BaseDataIndexedDbModel.query().get({ key: 'project-type' });
    const currentProjectType = projectTypes?.values?.find((type) => {
      return type.id === id;
    });

    return currentProjectType?.value === projectType;
  }
}
