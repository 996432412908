<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="back-btn text-left page__main main ml-5 my-3">
          <a routerLink="/admin/manage">
            <i class="fa fa-chevron-left"></i>
            {{ 'button.back-to-overview' | translate }}
          </a>
        </div>

        <div class="ibox-title d-flex justify-content-between align-items-center">
          <h5>{{ 'movers_complete.entity.location.plural' | translate }}</h5>

          <button type="button" class="btn btn-primary" (click)="addNewItem()">
            <i class="fa fa-plus"></i> {{ 'button.add' | translate }}
          </button>
        </div>

        <div class="ibox-content">
          <cpb-list-renderer [key]="listKey"></cpb-list-renderer>
        </div>
      </div>
    </div>
  </div>
</div>
