import { ManageContactsDetailComponent } from './../contacts-detail/contacts-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacts-list',
  templateUrl: 'contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
})
export class ManageContactsListComponent {
  public listKey = 'list_contact_templates';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageContactsDetailComponent, {
      header: this.translateService.instant('movers_complete.manage.contact.contact_person.label'),
    });
  }
}
