import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '@domain/models/setting.model';

/**
 * Retrieves any given setting
 *
 * Usage:
 *   [setting name] | setting
 * Example:
 *   {{ name | setting }}
 *   formats to: "Tenant B.V."
 */
@Pipe({ name: 'setting', pure: false })
export class SettingPipe implements PipeTransform {
  public transform(settingName: string): string {
    return Setting.getValue(settingName) as string;
  }
}
