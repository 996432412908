<div class="ibox-content striped-forms" *ngIf="quotation">
  <div class="form-group">
    <div class="row pb-3 mb-3 border-bottom">
      <div class="col-md-3 col-sm-12 logo">
        <img [src]="logo" onerror="this.src = '/assets/images/zandbergen-logo.png'" />
      </div>

      <div class="col-md-3 col-sm-4 border-right">
        <span class="line-height-2 fade-text-color" *ngFor="let item of quotationCompanyDetailsKeys"
          >{{ quotationCompanyDetails[item?.key] }}<br
        /></span>
      </div>

      <div class="col-md-5 col-sm-8">
        <span class="line-height-2 fade-text-color" *ngFor="let item of quotationPaymentDetailsKeys"
          >{{ quotationPaymentDetails[item?.key] }}<br
        /></span>
      </div>
    </div>

    <div class="row border-bottom">
      <div class="col-md-4 border-right">
        <h5>{{ 'movers_complete.project.quotation.general-info.label' | translate }}</h5>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.reference.label' | translate }}:</div>
          <div class="col-6">{{ project.reference_nr || '-' }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.client.label' | translate }}:</div>
          <div class="col-6">{{ project.client.name }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.description.label' | translate }}:</div>
          <div class="col-6">{{ project.client.description }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">
            {{ 'movers_complete.project.quotation.desired-start-date.label' | translate }}:
          </div>
          <div class="col-6">{{ project.delivery_date | date : 'dd-MM-yyyy' }}</div>
        </div>
      </div>

      <div class="col-md-4 border-right">
        <h5>{{ 'movers_complete.project.quotation.address.label' | translate }}</h5>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">
            {{ 'movers_complete.project.quotation.street-house-number.label' | translate }}:
          </div>
          <div class="col-6">
            {{ project?.addresses[0]?.street || '-' }} {{ project?.addresses[0]?.housenumber || '-' }}
          </div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.postal-code.label' | translate }}:</div>
          <div class="col-6">{{ project?.addresses[0]?.zipcode || '-' }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.city.label' | translate }}:</div>
          <div class="col-6">{{ project?.addresses[0]?.city || '-' }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.country.label' | translate }}:</div>
          <div class="col-6">{{ project?.addresses[0]?.country || '-' }}</div>
        </div>
      </div>

      <div class="col-md-4">
        <h5>{{ 'movers_complete.project.quotation.contact.label' | translate }}</h5>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.client-name.label' | translate }}:</div>
          <div class="col-6">
            <ng-container *ngIf="contacts[contacts.length - 1]?.gender">
              {{ 'base-data.' + contacts[contacts.length - 1]?.gender | translate }}
            </ng-container>

            {{ contacts[contacts.length - 1]?.initials || '-' }}
            {{ contacts[contacts.length - 1]?.name || '-' }}
          </div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.email.label' | translate }}:</div>
          <div class="col-6">{{ contacts[contacts.length - 1]?.email || '-' }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.phone.label' | translate }}:</div>
          <div class="col-6">{{ contacts[contacts.length - 1]?.phone || '-' }}</div>
        </div>

        <div class="row col-12 tile-section">
          <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.remark.label' | translate }}:</div>
          <div class="col-6">{{ contacts[contacts.length - 1]?.remarks || '-' }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-2 border-bottom">
      <div class="col-5">
        <h5>{{ 'movers_complete.project.quotation.pricing.label' | translate }}</h5>
      </div>

      <div class="col-3 mb-2">
        <cap-select-button
          [(ngModel)]="project.type_base_data_value_id"
          [disabled]="disabled"
          [optionValue]="'value'"
          [options]="movingCostsOptions"
          [optionLabel]="'label'"
          (change)="setVatChoice($event.value)">
        </cap-select-button>
      </div>
    </div>

    <div class="row pb-3 mb-3 border-bottom" *ngIf="applicableSpecialties.length > 0">
      <div class="col-12">
        <h5>{{ 'movers_complete.project.quotation.options.label' | translate }}</h5>
      </div>

      <div class="col-12">
        <table id="options" class="custom-table">
          <tr>
            <th>{{ 'movers_complete.project.quotation.client-name.label' | translate }}</th>
            <th>{{ 'movers_complete.project.quotation.remark.label' | translate }}</th>
            <th>{{ 'movers_complete.project.quotation.date.label' | translate }}</th>
            <th>{{ 'movers_complete.inventory.option.amount.label' | translate }}</th>
            <th>{{ 'movers_complete.project.quotation.total-amount.label' | translate }}</th>
            <th>{{ 'movers_complete.project.quotation.vat.label' | translate }}</th>
          </tr>

          <tr *ngFor="let specialty of applicableSpecialties">
            <ng-container *ngIf="specialty.applicable">
              <td>{{ specialty.specialty.name }}</td>
              <td>{{ specialty.details }}</td>
              <td>{{ specialty.start | date : 'dd-MM-yyyy' }}</td>
              <td>{{ specialty.hours_estimate ?? 0 }}</td>
              <td>
                <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
                  {{
                    specialty.specialty.cost_rate *
                      (specialty.hours_estimate ?? 0) *
                      (1 + specialty.specialty.vat_rate / 100) | currency : 'EUR'
                  }}
                </ng-container>

                <ng-template #showPricesWithoutVat>
                  {{ specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0) | currency : 'EUR' }}
                </ng-template>
              </td>
              <td>{{ specialty.specialty.vat_rate }} %</td>
            </ng-container>
          </tr>
        </table>
      </div>
    </div>

    <div class="row pb-3 mb-3 border-bottom" *ngIf="newInventories.length > 0">
      <div class="col-12">
        <h5>{{ 'movers_complete.inventory.overview.inventories.label' | translate }}</h5>
      </div>

      <div class="col-12">
        <table id="inventory" class="custom-table">
          <tr>
            <th>{{ 'movers_complete.project.quotation.section.label' | translate }}</th>
            <th>{{ 'movers_complete.project.quotation.total-amount-of-items.label' | translate }}</th>
            <th>{{ 'BTW' }}</th>
            <th>
              <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
                {{ 'movers_complete.project.quotation.total-price.label' | translate }} incl BTW
              </ng-container>

              <ng-template #showPricesWithoutVat>
                {{ 'movers_complete.project.quotation.total-price.label' | translate }} excl BTW
              </ng-template>
            </th>
          </tr>

          <tr *ngFor="let inventory of newInventories">
            <td>
              <span *ngIf="inventory.number_of_items === 0 || inventory.parent_id === null" class="parent-inventory">
                {{ inventory.name }}
              </span>

              <span *ngIf="inventory.number_of_items !== 0 && inventory.parent_id !== null" class="ml-20">
                {{ inventory.name }}
              </span>
            </td>

            <td>
              {{ inventory.number_of_items > 0 ? inventory.number_of_items : '-' }}
            </td>

            <td>21%</td>

            <td>
              <ng-container *ngIf="inventory.number_of_items === 0; else priceTemplate">
                <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
                  <span class="parent-inventory">
                    {{ inventory.deep_total_price_incl_vat | currency : 'EUR' }}
                  </span>
                </ng-container>

                <ng-template #showPricesWithoutVat>
                  <span class="parent-inventory">
                    {{ inventory.deep_total_price_excl_vat | currency : 'EUR' }}
                  </span>
                </ng-template>
              </ng-container>

              <ng-template #priceTemplate>
                <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
                  <span class="ml-20">
                    {{ inventory.total_price_incl_vat | currency : 'EUR' }}
                  </span>
                </ng-container>

                <ng-template #showPricesWithoutVat>
                  <span class="ml-20">
                    {{ inventory.total_price_excl_vat | currency : 'EUR' }}
                  </span>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="pb-3 mb-3 border-bottom" *ngIf="project.hasActivities()">
      <h5>{{ 'movers_complete.project.quotation.activities.label' | translate }}:</h5>

      <ng-container *ngFor="let activity of project.activities | sortByDateProperty : 'start' : 'asc'; let i = index">
        <div class="row" *ngIf="activity?.applicable">
          <div class="col-3">
            <p class="line-height-2 fade-text-color">{{ activity?.activity?.name }}</p>
          </div>

          <div class="col-3">
            <p class="line-height-2 fade-text-color">
              {{ activity.number_movers ? activity.number_movers : 0 }}
              {{ 'movers_complete.project.quotation.movers.label' | translate }} /
              {{ activity.number_trucks ? activity.number_trucks : 0 }}
              {{ 'movers_complete.project.quotation.trucks.label' | translate }}
            </p>
          </div>

          <div class="col-2" *ngIf="activity?.start && activity?.start !== ''">
            <p class="line-height-2 fade-text-color">{{ activity.start | date : 'dd-MM-yyyy' }}</p>
          </div>

          <div class="col-3" *ngIf="activity?.details && activity?.details !== ''">
            <p class="line-height-2 fade-text-color">{{ activity.details }}</p>
          </div>

          <div class="col-3 spacing-address-activity" *ngIf="activity?.address">
            <p class="line-height-2 fade-text-color">
              {{ 'movers_complete.project.quotation.address.label' | translate }} {{ activity.address }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row pb-3 mb-3 border-bottom">
      <div class="col-12">
        <h5>{{ 'movers_complete.project.quotation.free-to-fill-in.label' | translate }}</h5>
      </div>

      <cap-button
        styleClass="primary"
        type="button"
        [hidden]="disabled"
        [label]="'movers_complete.project.quotation.add-additional-cost-field.label' | translate"
        (click)="openAdditionalCostForm()">
      </cap-button>

      <div class="col-12">
        <cpb-list-renderer
          #listRenderer
          key="list_project_quotation_additional_costs"
          [class]="!showPricesExclVat ? 'showPricesInclVat' : 'showPricesWithoutVat'"
          [context]="{ project_id: project.id, read_only: disabled }"
          (listRendererDataChange)="listRendererDataChange()">
        </cpb-list-renderer>
      </div>
    </div>

    <div class="row pb-3 mb-3 border-bottom">
      <div class="col-12">
        <h5>Totals</h5>
      </div>

      <div class="col-12">
        <table id="totals" class="custom-table">
          <tr>
            <td>{{ 'movers_complete.project.quotation.subtotal.label' | translate }}</td>
            <td>{{ subtotalPrice | currency : 'EUR' }}</td>
          </tr>

          <tr>
            <td>{{ 'movers_complete.project.quotation.vat.label' | translate }}</td>
            <td>
              <div *ngFor="let specialty of applicableSpecialties">
                <ng-container *ngIf="specialty.applicable">
                  <span class="vat-space">
                    {{
                      specialty.specialty.cost_rate *
                        (specialty.hours_estimate ?? 0) *
                        (1 + specialty.specialty.vat_rate / 100) -
                        specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0) | currency : 'EUR'
                    }}
                  </span>
                  {{ specialty.specialty.vat_rate }}%
                </ng-container>
              </div>

              <div *ngFor="let inventory of inventories">
                <span class="vat-space">
                  {{ inventory.totalPrice * 1.21 - inventory.totalPrice | currency : 'EUR' }}
                </span>
                21%
              </div>

              <div *ngFor="let totalVat of totalsVat">
                <span class="vat-space">
                  {{ totalVat.amount | currency : 'EUR' }}
                </span>
                {{ totalVat.vat }}%
              </div>
            </td>
          </tr>

          <tr>
            <td>{{ 'movers_complete.project.quotation.total-amount.label' | translate }}</td>
            <td>{{ subtotalPrice + totalVAT | currency : 'EUR' }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row pb-3 mb-3 border-bottom">
      <div class="col-12" [formGroup]="paymentForm">
        <h5>{{ 'movers_complete.project.quotation.payment.label' | translate }}</h5>

        <div class="row">
          <label class="col-6 control-label">
            {{ 'movers_complete.project.quotation.invoice-attn.label' | translate }}:
          </label>

          <div class="col-3">
            <input class="form-control" formControlName="invoice_attn" type="text" />
          </div>
        </div>

        <div class="row">
          <label class="col-6 control-label">
            {{ 'movers_complete.project.quotation.invoice-name.label' | translate }}:
          </label>

          <div class="col-3">
            <input class="form-control" [disabled]="disabled" formControlName="invoice_name" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row pb-3 mb-3 border-bottom">
    <div class="col-12 agreement">
      <h5>{{ 'movers_complete.project.quotation.accept.label' | translate }}</h5>

      <p class="line-height-2 fade-text-color">
        {{ 'movers_complete.project.quotation.agreement.text1' | translate }}
      </p>

      <div *ngFor="let check of quotationChecks" class="d-flex align-items-center">
        <cap-checkbox [(ngModel)]="check.is_selected" class="mr-3" [disabled]="disabled"></cap-checkbox>

        <p class="line-height-2 fade-text-color">{{ check.label }}</p>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-sm-6 col-md-4">
      <h5 class="line-height-2 mb-2">
        {{ 'movers_complete.project.quotation.signature-appraiser.label' | translate }}
      </h5>

      <p class="line-height-2 mb-2 fade-text-color">
        {{ 'movers_complete.project.quotation.signature-date.label' | translate }}
      </p>

      <cap-calendar
        [disabled]="disabled"
        [(ngModel)]="quotation.signatureValuatorDate"
        dateFormat="dd-mm-yy"
        [locale]="localeNL">
      </cap-calendar>

      <div *ngIf="quotation.signature_valuator_image">
        <img [src]="quotation.signature_valuator_image" class="signature" />
      </div>

      <div class="d-flex justify-content-between">
        <cap-button
          styleClass="primary"
          [disabled]="disabled"
          [label]="'movers_complete.project.quotation.button.enter-signature' | translate"
          (click)="showValuatorSignatureForm()">
        </cap-button>

        <cap-button
          *ngIf="currentUserSign"
          styleClass="primary"
          [disabled]="disabled"
          [label]="'movers_complete.project.quotation.button.load-signature' | translate"
          (click)="loadMySignature()">
        </cap-button>
      </div>

      <app-signature #valuatorSignature [(signature)]="quotation.signature_valuator_image"> </app-signature>
    </div>

    <div class="col-sm-6 col-md-4">
      <h5 class="line-height-2 mb-2">
        {{ 'movers_complete.project.quotation.signature-client.label' | translate }}
      </h5>

      <p class="line-height-2 mb-2 fade-text-color">
        {{ 'movers_complete.project.quotation.signature-date.label' | translate }}
      </p>

      <cap-calendar
        [disabled]="disabled"
        [(ngModel)]="quotation.signatureClientDate"
        dateFormat="dd-mm-yy"
        [locale]="localeNL">
      </cap-calendar>

      <div *ngIf="quotation.signature_client_image">
        <img [src]="quotation.signature_client_image" class="signature" />
      </div>

      <cap-button
        styleClass="primary"
        class="w-100"
        [disabled]="disabled"
        [label]="'movers_complete.project.quotation.button.enter-signature' | translate"
        (onClick)="showClientSignatureForm()">
      </cap-button>

      <app-signature #clientSignature [(signature)]="quotation.signature_client_image"> </app-signature>
    </div>
  </div>

  <div class="row mb-4">
    <ng-container *ngIf="project?.reference_nr && project.reference_nr !== null">
      <cap-button
        styleClass="warning"
        class="col-sm-6 col-md-4"
        [disabled]="disabled"
        [label]="'movers_complete.project.quotation.button.send-quote' | translate"
        (click)="submitQuotation('pending')"
        *ngIf="project.status === newStatusId || project.status === pendingStatusId">
      </cap-button>

      <cap-button
        styleClass="warning"
        class="col-sm-6 col-md-4"
        [disabled]="disabled"
        [label]="'movers_complete.project.quotation.button.send-confirmation' | translate"
        (click)="submitQuotation('booked')"
        *ngIf="project.status === bookedStatusId">
      </cap-button>

      <cap-button
        styleClass="warning"
        class="col-sm-6 col-md-4"
        [label]="'movers_complete.project.quotation.button.load-signature' | translate"
        (click)="submitToExact()"
        *ngIf="
          tenant?.api_exact_online &&
          project?.status === bookedStatusId &&
          onlineMode &&
          !sentToExact &&
          project?.quotation?.created_at
        ">
      </cap-button>

      <span class="text-reference col-sm-6 col-md-4 current-status">
        ({{ 'movers_complete.project.quotation.current-status.label' | translate }}: {{ projectStatusLabel }})
      </span>
    </ng-container>

    <ng-container *ngIf="!project?.reference_nr">
      <span class="col-12 text-reference">{{
        'movers_complete.project.quotation.status-message.label' | translate
      }}</span>
    </ng-container>
  </div>
</div>
