import { BaseDataIndexedDbModel } from '@capturum/complete';

export function getBaseDataByKey(key: string): Promise<BaseDataIndexedDbModel> {
  return BaseDataIndexedDbModel.query().where({ key }).first();
}

export async function getMappedBaseDataByKey(key: string): Promise<Record<string, string>> {
  return Object.assign(
    {},
    ...(await getBaseDataByKey(key))?.values?.map(({ id, value }) => {
      return {
        [value]: id,
      };
    })
  );
}
