<div class="addresses">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox-title">
        <h5 class="d-block">{{ 'movers_complete.entity.address.plural' | translate }}</h5>
      </div>
      <div class="ibox-content">
        <em-offline-table
          [options]="tableOptions"
          (actionClicked)="onActionClick($event)"
          (rowClicked)="onRowClick($event)"
          (selectedRowsChange)="checkForSelectedRows($event)">
        </em-offline-table>
      </div>
      <div *ngIf="project?.status !== 'booked' && !disabled" class="ibox-footer">
        <cap-button styleClass="primary" [label]="'button.add' | translate" (onClick)="onAddClick()">
          <i class="fa fa-plus"></i>
        </cap-button>

        <cap-button
          styleClass="primary"
          [disabled]="disableDelete"
          [label]="'button.delete' | translate"
          (onClick)="onDeleteClick()">
          <i class="fa fa-trash"></i>
        </cap-button>
        <cap-button
          styleClass="secondary"
          [disabled]="disableChangeOrder"
          [label]="'movers_complete.button.change-order' | translate"
          (onClick)="onChangeOrderClick()">
        </cap-button>
      </div>
    </div>
  </div>
</div>
