import { debounceTime } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { TreeTable } from 'primeng/treetable';
import { InfoTableColumn } from '@capturum/ui/info-table';
import { LazyLoadEvent } from 'primeng/api';
import { Address } from '@domain/models/address.model';

export class OfflineTableOptions {
  public paging = true;
  public search = true;
  public withDelete = false;
  public itemsPerPage = 10;
  public url: string;
  public tableName: string;
  public noResultsMessage = 'Er zijn geen gegevens gevonden';
  public columns: Array<any> = [{ title: 'Name', name: 'name' }];
  public selectedRows: Address[] = [];
  public actions: any;
  public sorting: any = { columns: [] };
  public filtering: any = { globalFilter: '', hiddenColumns: [] };
  public className: Array<string> = ['table-striped', 'table-bordered'];

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public rowDataTransformer: (data: any[]) => any[] = (data) => {
    return data;
  };
}

@Component({
  selector: 'em-offline-table',
  templateUrl: 'offline-table.component.html',
  styleUrls: ['./offline-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfflineTableComponent implements OnChanges {
  @Input() options: OfflineTableOptions = new OfflineTableOptions();
  @Input() rows: any;

  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedRowsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionClicked: EventEmitter<{ row: any; action: string }> = new EventEmitter<{
    row: any;
    action: string;
  }>();

  @ViewChild('dataTable', { static: false }) public dataTable: TreeTable;

  public page = 1;
  public filter = new QueryOptions();
  public selectedRows = [];
  public loading: boolean;
  public tableColumns: InfoTableColumn[] = [];
  public tableData = [];

  private apiQueryQueue = new Subject<string>();

  public constructor(private dataService: DataService, private api: ApiServiceWithLoaderService) {
    this.loading = true;

    this.apiQueryQueue.pipe(debounceTime(500)).subscribe(async (query: string) => {
      this.rows = await this.dataService.get(this.options.tableName, this.filter, this.options.url);
      this.rows = this.options.rowDataTransformer(this.rows);
      this.tableData = this.rows;
      this.tableColumns = this.options.columns.map((column) => {
        return {
          field: column.name,
          title: column.title,
        };
      });
      this.loading = false;
    });
  }

  public loadLazy(event: LazyLoadEvent): void {
    this.loading = true;

    if (this.dataTable) {
      this.dataTable.rows = event?.rows;
    }

    this.filter.pageSize = event?.rows;
    this.filter.pageIndex = event?.first / event?.rows;

    if (event?.sortField) {
      this.filter.sortColumn = event?.sortField;
      this.filter.sortOrder = event?.sortOrder === 1 ? 'asc' : 'desc';
    }

    this.apiQueryQueue.next(null);
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.options) {
      this.applyTableOptionsToFilter();
    }
  }

  public applyTableOptionsToFilter() {
    this.filter.columns = [];
    this.options.columns.map((column) => {
      this.filter.columns.push({ name: column.name, filter: column.filter, filterMode: 'equals' });
    });

    this.options.filtering.hiddenColumns.map((column) => {
      this.filter.columns.push({ name: column.name, filter: column.filter, filterMode: 'equals' });
    });

    this.filter.sortColumn = this.options.filtering.sortColumn || this.filter.sortColumn;
    this.filter.sortOrder = this.options.filtering.sortOrder || this.filter.sortOrder;

    this.onChangeTable();
  }

  public onRowClick(event) {
    this.rowClicked.emit(event);
  }

  public onChangeTable(
    config: any = {},
    page: any = {
      page: this.page,
      itemsPerPage: this.options.itemsPerPage,
    }
  ): any {
    if (config.filtering) {
      Object.assign(this.options.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.options.sorting, config.sorting);
    }

    let query = `?_pageIndex=${page.page}&_pageSize=${page.itemsPerPage}`;

    if (this.options.filtering.globalFilter) {
      query =
        `${query}&_globalFilter=${this.options.filtering.globalFilter}` +
        `&_globalFilterColumns=${this.options.columns
          .map((column) => {
            return column.name;
          })
          .join(',')}`;
    }

    // Create sort query
    this.options.columns.forEach((column) => {
      if (column.sort) {
        query = `${query}&_sortColumn=${column.name}&_sortDirection=${column.sort}`;
      }

      if (column.filter) {
        query = `${query}&${column.name}=${column.filter}`;
      }
    });

    this.options.filtering.hiddenColumns.forEach((column) => {
      query = `${query}&${column.name}=${column.filter}`;
    });

    this.apiQueryQueue.next(query);
  }

  public handleRowToggle(selectedRow: Address, checked: boolean): void {
    if (checked) {
      this.selectedRows.push(selectedRow);
    } else {
      const checkForIndex = this.selectedRows.findIndex((addresses) => {
        return addresses.index === selectedRow.index;
      });

      if (checkForIndex !== -1) {
        this.selectedRows.splice(checkForIndex, 1);
      }
    }

    this.selectedRowsChange.emit(this.selectedRows);
  }

  public selectAllRows(checkValue: boolean): void {
    this.selectedRows = checkValue ? [...this.rows] : [];
    this.selectedRowsChange.emit(this.selectedRows);
  }
}
