import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent extends ListRendererWidgetBase {
  constructor(private readonly translateService: TranslateService) {
    super();
  }

  public handleImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/images/image_not_found_icon.png';
    (event.target as HTMLImageElement).alt = this.translateService.instant('notfound.translations.key');
  }
}
