import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

declare const jQuery: any;

@Component({
  selector: 'app-signature',
  templateUrl: 'signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent {
  @ViewChild('modal', { static: false }) modal: any;
  @ViewChild('signaturePad', { static: false }) signaturePad: SignaturePad;
  @Input() signature;
  @Output() signatureChange = new EventEmitter();

  private signatureSigned = false;
  public signaturePadOptions: Object = {
    minWidth: 0.5,
    canvasWidth: 0.5 * window.innerWidth - 80,
    canvasHeight: 0.4 * window.innerHeight,
    backgroundColor: '#fafafa',
  };

  public showForm() {
    jQuery(this.modal.nativeElement).modal('show');
    this.signaturePad.fromDataURL(this.signature);
  }

  public hideForm() {
    jQuery(this.modal.nativeElement).modal('hide');
  }

  public signatureStart() {
    this.signatureSigned = true;
  }

  public clearSignature() {
    this.signaturePad.clear();
    this.signatureSigned = false;
  }

  public deleteSignature(): void {
    this.signatureChange.emit(null);
    this.clearSignature();
    this.hideForm();
  }

  public onSubmit() {
    this.signatureChange.emit(this.signaturePad.toDataURL());
    this.hideForm();
  }
}
