import { Component } from '@angular/core';
import { LocationDetailComponent } from '../location-detail/location-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent {
  public listKey = 'list_locations';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(LocationDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.location.single'),
    });
  }
}
