<div class="ibox-title">
  <h5>{{ 'movers_complete.inventory.board.room_add.label' | translate }}</h5>
  <span class="fa fa-times close-icon" (click)="onCloseClick()"></span>
</div>

<ng-container *ngIf="form">
  <form [formGroup]="form">
    <div class="ibox-content">
      <div [ngClass]="{ 'has-error': showErrors && !this.form.controls['name'].valid }">
        <div class="row">
          <div class="col-4">
            <label class="control-label" asteriks>
              {{ 'movers_complete.inventory.board.name.label' | translate }}
            </label>
          </div>

          <div class="col-8">
            <cap-input formControlName="name"></cap-input>
            <span class="help-block m-b-none" *ngIf="showErrors && !this.form.controls['name'].valid">
              {{ 'movers_complete.inventory.board.name.placeholder' | translate }}
            </span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="project.type_base_data_value_id === projectTypes[projectType.business]">
        <div class="row">
          <div class="col-4">
            <label class="control-label" asteriks>
              {{ 'movers_complete.inventory.board.building.label' | translate }}
            </label>
          </div>

          <div class="col-8">
            <cap-input formControlName="building"></cap-input>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="control-label" asteriks>{{
              'movers_complete.inventory.board.floor.label' | translate
            }}</label>
          </div>

          <div class="col-8">
            <cap-input formControlName="floor"></cap-input>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="col-4">
          <label class="control-label" asteriks>{{
            'movers_complete.inventory.board.default_inventory.label' | translate
          }}</label>
        </div>

        <div class="col-8">
          <cap-dropdown
            formControlName="default_inventory_id"
            [options]="defaultInventories"
            [autoDisplayFirst]="true"
            [showClear]="true">
          </cap-dropdown>
        </div>
      </div>

      <div [ngClass]="{ 'has-error': showErrors && !this.form.controls['parent_id'].valid }">
        <div class="row">
          <div class="col-4">
            <label class="control-label">
              {{ 'movers_complete.inventory.board.parent_id.label' | translate }}
            </label>
          </div>

          <div class="col-8">
            <cap-dropdown
              formControlName="parent_id"
              [options]="parentList"
              [placeholder]="'button.select' | translate"
              [showClear]="true">
            </cap-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-footer justify-content-end">
      <cap-button
        [label]="'button.add' | translate"
        icon="fa fa-plus"
        (onClick)="onSubmit()"
        [disabled]="!form.valid || defaultInventories.length === 0"
        data-dismiss="modal"
        styleClass="primary">
      </cap-button>
    </div>
  </form>
</ng-container>
