import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.scss'],
})
export class ManageTenantsListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Klantnaam', name: 'name', filter: { global: true } },
        { title: 'Planning', name: 'apiPlanningModule' },
        { title: 'ExactOnline', name: 'apiExactOnline' },
        { title: 'Boelaars', name: 'apiBoelaarsEnLambert' },
        { title: 'ARent', name: 'apiArent' },
      ],
      endpoint: '/tenant',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.apiPlanningModule = row.api_planning_module ? 'Ja' : 'Nee';
          row.apiExactOnline = row.api_exact_online ? 'Ja' : 'Nee';
          row.apiBoelaarsEnLambert = row.api_boelaars_en_lambert ? 'Ja' : 'Nee';
          row.apiArent = row.api_arent ? 'Ja' : 'Nee';
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/tenants(popup:admin/manage/tenants/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/tenants(popup:admin/manage/tenants/add)');
  }
}
