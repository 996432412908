import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from '@domain/models/contact.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { customEmailValidator } from '@shared/services/custom-validators.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { ContactTemplate } from '@domain/models/contact-template.model';
import * as cloneDeep from 'lodash/cloneDeep';
import { DataService } from '@shared/services/data.service';
import { v4 as uuidv4 } from 'uuid';
import { InventoryFlowValidationService } from '@core/services/inventory-flow-validation.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

const uuid = uuidv4;

import { SelectItem } from 'primeng/api';
import { getMappedBaseDataByKey } from '@core/utils/base-data.utils';

@Component({
  selector: 'app-inventory-contacts-detail',
  templateUrl: 'contact-detail.component.html',
  styleUrls: ['contact-detail.component.scss'],
})
export class InventoryContactsDetailComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public showErrors = false;
  public errors: any = {};
  public disabled = false;
  public project = new Project({});
  public contactTemplates: ContactTemplate[] = [];
  public contactTemplatesList: SelectItem[] = [];
  public companiesList: SelectItem[] = [];
  public contactTemplatesListDefault: SelectItem[] = [];
  public environment: object;
  public mode = { isAdd: true, alertText: 'Contact succesvol toegevoegd' };
  public genders: SelectItem[] = [];

  private contact = new Contact({});
  private routeContactId;
  private subscriptionContactsChanged: Subscription;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private validationService: InventoryFlowValidationService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.contactsAdded.subscribe(async (_) => {
      this.project.contacts = await Contact.query.where('project_id').equals(this.project.id).toArray();
    });

    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    this.contactTemplates = await ContactTemplate.query.toArray();

    this.contactTemplates.forEach((contactTemplate: ContactTemplate) => {
      this.contactTemplatesList.push({
        label: contactTemplate.name + ', ' + contactTemplate.email + ' (' + contactTemplate.company + ')',
        value: contactTemplate.id,
      });

      if (
        !this.companiesList.find((company: SelectItem) => {
          return company.value === contactTemplate.company;
        })
      ) {
        this.companiesList.push({
          label: contactTemplate.company,
          value: contactTemplate.company,
        });
      }
    });

    const salutationTypes = await getMappedBaseDataByKey('salutation-type');

    await this.loadLists(salutationTypes);

    this.companiesList = this.dataService.sortDropdownByLabel(this.companiesList);
    this.contactTemplatesList = this.dataService.sortDropdownByLabel(this.contactTemplatesList);

    this.contactTemplatesListDefault = cloneDeep(this.contactTemplatesList);
    this.project = await this.projectService.getProject();

    this.initForm();

    this.route.params.subscribe(async (params: any) => {
      this.routeContactId = params['id'];

      if (this.routeContactId) {
        this.mode.isAdd = false;

        this.subscriptionContactsChanged = this.projectService.contactsChanged.subscribe((contact: Contact) => {
          this.contact = contact;
          this.updateForm();
        });

        await this.projectService.getContact(this.routeContactId);
      }

      this.projectService.projectLoaded.subscribe((project: Project) => {
        this.project = project;
        this.updateForm();
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionContactsChanged) {
      this.subscriptionContactsChanged.unsubscribe();
    }
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control({ value: this.contact.id, disabled: this.disabled }),
      project_id: this.formBuilder.control(this.project.id || null),
      client_id: this.formBuilder.control({ value: this.project.client.id || null, disabled: this.disabled }),
      company_id: this.formBuilder.control({ value: this.contactTemplates, disabled: this.disabled }),
      contact_template_id: this.formBuilder.control({ value: this.contactTemplates, disabled: this.disabled }),
      name: this.formBuilder.control({ value: this.contact.name || '', disabled: this.disabled }, Validators.required),
      initials: this.formBuilder.control(
        { value: this.contact.initials || '', disabled: this.disabled },
        Validators.required
      ),
      gender: this.formBuilder.control(
        { value: this.contact.gender || '', disabled: this.disabled },
        Validators.required
      ),
      email: this.formBuilder.control(
        {
          value: this.contact.email || '',
          disabled: this.disabled,
        },
        [Validators.required, customEmailValidator]
      ),
      phone: this.formBuilder.control({ value: this.contact.phone || '', disabled: this.disabled }, [
        Validators.required,
        /* eslint-disable */
        Validators.pattern(/^(\+)?(?![\-])\d+(-?\d+){1,}$/),
        Validators.minLength(10),
      ]),
      mobile: this.formBuilder.control({ value: this.contact.mobile || '', disabled: this.disabled }, [
        /* eslint-disable */
        Validators.pattern(/^(\+)?(?![\-])\d+(-?\d+){1,}$/),
        Validators.minLength(10),
      ]),
      remarks: this.formBuilder.control({ value: this.contact.remarks, disabled: this.disabled }),
    });
  }

  public async onSubmit(): Promise<void> {
    if (!this.disabled) {
      if (this.form.valid) {
        this.contact = {
          ...this.form.value,
          project_id: this.project.id,
          id: this.contact.id,
        };

        if (this.mode.isAdd) {
          this.contact.id = uuid();
        }

        await this.projectService.saveContact(this.contact);
        this.projectService.setProjectUpdated();
        await this.projectService.saveProject();
        this.onCloseClick();

        this.validationService.setValidation(true);

        this.toastService.success(
          this.translateService.instant('movers_complete.inventory.contact.success'),
          this.translateService.instant(
            this.mode.isAdd ? 'movers_complete.inventory.contact.added' : 'movers_complete.inventory.contact.edited'
          )
        );
      } else {
        this.showErrors = true;
      }
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact');
  }

  public selectContactTemplate(contactTemplateId: string): void {
    if (contactTemplateId) {
      this.contact.client_id = contactTemplateId;

      this.updateFormToContactTemplate(
        this.contactTemplates.find((contactTemplate: ContactTemplate) => {
          return contactTemplate.id === contactTemplateId;
        })
      );
    }
  }

  public updateContactTemplatesList(selectedCompany: string): void {
    if (selectedCompany === null) {
      this.contactTemplatesList = cloneDeep(this.contactTemplatesListDefault);
    } else {
      this.contactTemplatesList = this.contactTemplatesListDefault.filter((address: SelectItem) => {
        if (address) {
          if (
            address.label.substring(address.label.lastIndexOf('(') + 1, address.label.lastIndexOf(')')) ===
            selectedCompany
          ) {
            return address;
          }
        }

        return;
      });
    }
  }

  private async loadLists(salutationTypes: Record<string, string>): Promise<void> {
    const salutationTypeKeys = ['salutation-type-family', 'salutation-type-mr', 'salutation-type-mrs'];

    this.genders = salutationTypeKeys.map((key) => {
      return {
        label: this.translateService.instant(`base-data.${salutationTypes[key]}`),
        value: salutationTypes[key],
      };
    });
  }

  private updateFormToContactTemplate(contactTemplate: ContactTemplate): void {
    if (contactTemplate) {
      this.form.patchValue(contactTemplate);
    }
  }

  /**
   * Update the form information
   */
  private updateForm(): void {
    this.form.reset({
      id: { value: this.contact.id, disabled: this.disabled },
      project_id: this.project.id || null,
      client_id: { value: this.project.client.id || null, disabled: this.disabled },
      company_id: { value: this.contact.company_id || this.contactTemplates, disabled: this.disabled },
      contact_template_id: {
        value: this.contact.contact_template_id || this.contactTemplates,
        disabled: this.disabled,
      },
      name: { value: this.contact.name || '', disabled: this.disabled },
      initials: { value: this.contact.initials || '', disabled: this.disabled },
      gender: { value: this.contact.gender || 'F', disabled: this.disabled },
      email: { value: this.contact.email || '', disabled: this.disabled },
      phone: { value: this.contact.phone || '', disabled: this.disabled },
      mobile: { value: this.contact.mobile || '', disabled: this.disabled },
      remarks: { value: this.contact.remarks, disabled: this.disabled },
    });
  }
}
