<div class="row">
  <div class="col-lg-12">
    <div class="ibox-title">
      <h5>Klant selecteren</h5>
      <span class="fa fa-times" (click)="onCloseClick()" style="float: right"></span>
    </div>

    <div class="ibox-content" *ngIf="tableOptions">
      <em-offline-table [options]="tableOptions" (rowClicked)="onRowClick($event)"></em-offline-table>
    </div>
  </div>
</div>
