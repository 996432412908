import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Confirmation, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked, OnInit {
  @ViewChild('projectOverview', { static: false }) projectOverview: ProjectOverviewComponent;

  public confirmation: Confirmation;
  public showLoadingSpinner = false;

  constructor(
    public synchronisationService: SynchronisationService,
    private router: Router,
    private api: ApiServiceWithLoaderService,
    private auth: AuthService,
    private cdRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService
  ) {
    this.api.getLoader().subscribe((amount) => {
      this.showLoadingSpinner = amount > 0;
    });

    const onAuthError = () => {
      this.auth.logout().subscribe();
      this.router.navigateByUrl('/login');
    };

    this.api.onAuthError = onAuthError;
  }

  public ngOnInit(): void {
    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
