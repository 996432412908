import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SynchronisationService } from '@shared/services/synchronisation.service';

@Injectable({ providedIn: 'root' })
export class TenantResolver {
  constructor(private synchronisationService: SynchronisationService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.synchronisationService.resolve();
  }
}
