import { DomainModel } from '@domain/domain.model';
import { Location } from '@domain/models/location.model';
import { Project } from '@domain/models/project.model';
import { RelationGroup } from '@domain/models/relation-group.model';

import { v4 as uuid } from 'uuid';
import { Contact } from '@domain/models/contact.model';

export class Client extends DomainModel {
  // Configuration
  public entity = 'client';
  public table = 'clients';
  public sync = true;
  public id: string;

  // Fields
  public name: string;
  public location_id?: number;
  public parent_id?: string;
  public relation_group_id?: number;
  public description: string;
  public remarks: string;
  public location: Location;
  public relationGroup: RelationGroup;
  public parent_project?: Project;
  public arent_order_number?: string;
  public contacts: Contact[];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    if (this.location_id) {
      this.location = await Location.query.get(this.location_id);
    }
    if (this.relation_group_id) {
      this.relationGroup = await RelationGroup.query.get(this.relation_group_id);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name || '',
      relation_group_id: this.relation_group_id,
      location_id: this.location_id,
      parent_id: this.parent_id,
      description: this.description || '',
      remarks: this.remarks || '',
      parent_project: this.parent_project,
    };
  }
}
