<div class="new-inventory-item flex-column">
  <div class="tile-header d-flex justify-content-between">
    <span class="mr-3">{{ inventoryItem.name }}</span>

    <div *ngIf="inventoryItem.id">
      <span class="fa-stack inventory-item-details-image" *ngIf="inventoryItem.image">
        <i class="fas fa-image"></i>
      </span>

      <span class="fa-stack inventory-item-details-btn" (click)="openAddItemDetails(inventoryItem)">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-info fa-stack-1x fa-inverse"></i>
      </span>
    </div>
  </div>

  <div class="tile-footer d-flex" [formGroup]="form">
    <cap-input-number
      *ngIf="!disabled"
      [showButtons]="true"
      [buttonLayout]="InputNumberButtonLayout.horizontal"
      decrementButtonClass="default"
      incrementButtonClass="default"
      incrementButtonIcon="pi pi-plus"
      decrementButtonIcon="fa fa-minus"
      [min]="0"
      [max]="100"
      formControlName="amount"
      [ngModel]="inventoryItem.amount"
      (ngModelChange)="changeAmount($event)">
    </cap-input-number>

    <span *ngIf="hasBulkPrices" class="d-flex flex-grow-1 align-items-center justify-content-center">
      {{ 'movers_complete.project.quotation.total-volume.label' | translate }}
      {{ inventoryItem.volume * inventoryItem.amount | number }} m<sup>3</sup>
    </span>
  </div>
</div>
