import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';

@Component({
  selector: 'app-inventory-work-assignment-item',
  templateUrl: 'work-assignment-item.component.html',
  styleUrls: ['./work-assignment-item.component.scss'],
})
export class InventoryWorkAssignmentItemComponent {
  @Input() item: WorkAssignmentItem;
  @Input() placeholderName;
  @Input() disabled: boolean;

  @Output() onDeleteItemClick = new EventEmitter();

  public times = [];

  constructor() {
    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }
  }

  public onDeleteClick() {
    this.onDeleteItemClick.emit(this.item);
  }
}
