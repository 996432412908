<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="ibox-title">
          <h5>Rapportage</h5>
        </div>

        <div class="ibox-content powerbi-box">
          <div class="row view">
            <ng-container *ngIf="embedConfig">
              <app-powerbi-component
                [settings]="embedConfig.settings"
                [type]="embedConfig.type"
                [id]="embedConfig.id"
                [embedUrl]="embedConfig.embedUrl"
                [tokenType]="embedConfig.tokenType"
                [accessToken]="embedConfig.accessToken">
              </app-powerbi-component>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
