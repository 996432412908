import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { InventoryFlowValidationService } from '@core/services/inventory-flow-validation.service';
import { ToastService } from '@capturum/ui/api';
import { Contact } from '@domain/models/contact.model';

@Component({
  selector: 'app-inventory-contact-list',
  templateUrl: 'contact-list.component.html',
})
export class InventoryContactListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent, { static: false }) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public disabled = false;

  private subscriptionProjectLoaded: Subscription;
  private subscriptionContactsAdded: Subscription;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private validationService: InventoryFlowValidationService,
    private toastService: ToastService
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.contactsAdded.subscribe(async (_) => {
      this.project.contacts = await Contact.query.where('project_id').equals(this.project.id).toArray();
    });
  }

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    this.setTableOptions();

    // In case project loaded
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;
      this.setTableOptions();
      if (this.table) {
        this.table.onChangeTable();
      }
      this.projectService.setCurrentClient(this.project.client);
    });

    // In case contact was added (from popup)
    this.subscriptionContactsAdded = this.projectService.contactsAdded.subscribe((_) => {
      if (this.table) {
        this.table.onChangeTable();
      }
    });

    this.validation();
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    if (this.subscriptionContactsAdded) {
      this.subscriptionContactsAdded.unsubscribe();
    }
  }

  public setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      columns: [
        {
          title: this.translateService.instant('movers_complete.inventory.contact.name.label'),
          name: 'name',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.contact.email.label'),
          name: 'email',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.contact.phone.label'),
          name: 'phone',
        },
        {
          title: this.translateService.instant('movers_complete.inventory.contact.mobile.label'),
          name: 'mobile',
        },
      ],
      paging: false,
      search: false,
      noResultsMessage: this.translateService.instant('movers_complete.inventory.contact.list.no_results.text'),
      withDelete: true,
      itemsPerPage: 500,
      url: '/contacts',
      tableName: 'contacts',
      filtering: { hiddenColumns: [{ name: 'client_id', filter: this.project.client_id, filterMode: 'equals' }] },
    });
  }

  public onRowClick(data: any): void {
    if (!this.disabled) {
      this.router.navigateByUrl(
        '/admin/project/' + this.project.id + '/contact(popup:admin/project/contact/' + data.id + ')'
      );
    }
  }

  public onAddClick(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact(popup:admin/project/contact/add)');
    }

    this.validation();
  }

  public onDeleteClick(): void {
    if (!this.disabled) {
      this.confirmationService.confirm({
        message: this.translateService.instant('movers_complete.confirmation.confirm.entity.text', {
          entity: this.translateService.instant('movers_complete.entity.contact.plural').toLowerCase(),
        }),
        header: this.translateService.instant('movers_complete.confirmation.confirm.label'),
        icon: 'fa fa-question-circle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nee',
        accept: async () => {
          try {
            for (const row of this.table.selectedRows) {
              await this.projectService.deleteContact(row.id);
            }

            this.table.onChangeTable();

            this.toastService.success(
              this.translateService.instant('movers_complete.inventory.contact.success'),
              this.translateService.instant('movers_complete.inventory.contact.deleted')
            );

            this.table.selectedRows = [];

            await this.validation();
          } catch (error) {
            console.error('Error during contact deletion:', error);
          }
        },
      });
    }
  }

  public async validation(): Promise<void> {
    await new Promise((resolve) => {
      return setTimeout(resolve, 1000);
    });

    if (this.table) {
      this.validationService.setValidation(this.table.rows.length > 0);
    }
  }
}
