import { DomainModel } from '@domain/domain.model';

export class Setting extends DomainModel {
  public static settings: Setting[] = [];

  // Configuration
  public entity = 'setting';
  public table = 'settings';
  public sync = false;

  // Fields
  public tenant_id: number;
  public name: string;
  public type: string;
  public description: string;
  public value: string | boolean;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current setting in static cache
   */
  public static async loadSettings(): Promise<void> {
    Setting.settings = await Setting.query.toArray();
  }

  /**
   * Get the setting by name
   *
   * @param name
   */
  public static getValue(name: string): string | boolean | null | undefined {
    if (!Setting.settings) {
      return undefined;
    }

    const currentSetting: Setting = Setting.settings.find((setting) => {
      return setting.id === name;
    });

    return currentSetting ? currentSetting.value : null;
  }

  /**
   * Get the boolean value of a setting
   *
   * @param name
   */
  public static getBooleanValueByName(name: string): boolean {
    if (!Setting.settings) {
      return false;
    }

    return Setting.getValue(name) === 'true' || Setting.getValue(name) === true;
  }
}
