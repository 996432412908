<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>
<router-outlet name="popup"></router-outlet>
<cap-toast position="top-right"></cap-toast>

<div class="loading-overlay" *ngIf="showLoadingSpinner && showLoadingSpinner === true">
  <p-progressSpinner></p-progressSpinner>
</div>

<p-confirmDialog #cd appendTo="body">
  <p-footer>
    <cap-button styleClass="secondary" type="button" [label]="confirmation?.rejectLabel || 'Nee'" (click)="cd.reject()">
    </cap-button>

    <cap-button
      data-test="dialog-confirm-button"
      styleClass="primary"
      type="button"
      [label]="confirmation?.acceptLabel || 'Ja'"
      (click)="cd.accept()">
    </cap-button>
  </p-footer>
</p-confirmDialog>
