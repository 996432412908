import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationDetailComponent } from '@features/manage/components/locations/location-detail/location-detail.component';
import { SharedModule as MoversCompleteSharedModule } from '@shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { RouterLink } from '@node_modules/@angular/router';

@NgModule({
  declarations: [LocationDetailComponent, LocationListComponent],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    MoversCompleteSharedModule,
    ReactiveFormsModule,
    DropdownModule,
    CapturumListRendererModule,
    CapturumFormRendererModule,
    RouterLink,
  ],
})
export class LocationModule {}
