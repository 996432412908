import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4;

import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { Inventory } from '@domain/models/inventory.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Client } from '@domain/models/client.model';
import { Observable } from '@node_modules/rxjs';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Picture } from '@domain/models/picture.model';

import { SelectItem } from 'primeng/api';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { AuthService } from '@capturum/auth';
import { Event } from '@domain/models/event.model';
import { HttpClient } from '@angular/common/http';

import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { getBaseDataByKey } from '@core/utils/base-data.utils';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';
import { ProjectStatus } from '@core/enums/project-status.enum';
import { MapItem } from '@node_modules/@capturum/auth';
import { Quotation } from '@domain/models/quotation.model';
/**
 * ProjectService
 * This service is designed to provide the project accross different classes and routing
 * IMPORTANT: include this service under provider section in a module.
 */
@Injectable()
export class ProjectService extends ApiServiceWithLoaderService {
  // Constants
  public maxInventoryItems = 100; // Maximum of items to show in inventory
  public maxListItems = 50; // Maximum of items to show in default inventory list
  public event: Event;
  public endpoint = 'project';

  // Observables
  public projectLoaded = new Subject<any>();
  public addressLoaded = new Subject<any>();
  public addressAdded = new Subject<any>();
  public quotationAdded = new Subject<any>();
  public contactsChanged = new Subject<any>();
  public contactsAdded = new Subject<any>();
  public clientChanged = new Subject<any>();
  public defaultInventoriesLoaded = new Subject<any>();
  public parentInventoriesLoaded = new Subject<any>();
  public inventoryDeleted = new Subject<any>();
  public inventoryAdded = new Subject<any>();
  public projectIsReadOnly = new BehaviorSubject<boolean>(false);
  public statuses$ = new BehaviorSubject<MapItem[]>([]);
  public eventAdded = new Subject<any>();
  public eventLoaded = new Subject<any>();
  // Models
  public project: Project;
  public address: Address;
  public contact: Contact;
  public dataQuery = new QueryOptions();
  private result;
  private currentClient$ = new Subject<Client>();

  constructor(
    private dataService: DataService,
    private router: Router,
    private synchronisationService: SynchronisationService,
    http: HttpClient,
    authService: AuthService,
    private translateService: TranslateService,
    private titleCasePipe: TitleCasePipe
  ) {
    super(http, authService);
  }

  /**
   * Get project
   * If not exist create new project
   */
  public async getProject(): Promise<Project> {
    if (!this.project) {
      const projectInUrlIndex = this.router.url.split('/').findIndex((segment) => {
        return segment === 'project';
      });

      const projectId = this.router.url.split('/')[projectInUrlIndex + 1];

      this.project = await Project.query.get(projectId);

      if (!this.project) {
        this.project = new Project({});
        this.project.client_id = null;
      }

      this.project.inventories.forEach((inventory: Inventory) => {
        inventory = new Inventory(inventory);
        inventory.init();
      });
    }

    return this.project;
  }

  /**
   * CRUD PROJECT
   */

  public async newProject(): Promise<any> {
    const projectStatusNew = (await getBaseDataByKey('project-status')).values.find((item) => {
      return item.value === ProjectStatus.new;
    });

    this.project = new Project({
      id: uuid(),
      status_base_data_value_id: projectStatusNew.id,
    });
    this.project.is_new = true;

    await this.saveProject();

    this.project.quotation = new Quotation({ project_id: this.project.id, estimated_distance_km: null });

    await this.saveQuotation(this.project.quotation);

    return this.project;
  }

  public async addDefaultInventories(): Promise<void> {
    const defaultInventories = await DefaultInventory.query.toArray();

    for (const defaultInventory of defaultInventories) {
      if (
        defaultInventory &&
        defaultInventory.auto_generate &&
        defaultInventory.type_base_data_value_id.includes(this.project.type_base_data_value_id)
      ) {
        await this.saveNewInventory(
          new Inventory({
            name: defaultInventory.cascading_name,
            default_inventory_id: defaultInventory.id,
            project_id: this.project.id,
            floor: '0',
            building: 'gebouw',
          })
        );
      }
    }
  }

  /**
   * Load Project by id
   */
  public async loadProject(id: string): Promise<any> {
    this.project = await this.dataService.getById('projects', id);

    if (this.project && this.project.is_new === false) {
      await this.synchronisationService.loadSingleProjectData(id);
    }

    if (!this.project) {
      // Project not found, redirect to home screen
      this.router.navigateByUrl('/');

      return;
    }

    await this.project.loadSpecialties();
    await this.project.loadActivities();
    await this.project.loadInventories();
    await this.project.loadQuotation();
    await this.project.loadAddresses();
    await this.project.loadContacts();
    await this.project.loadMaterials();
    await this.project.loadPictures();
    await this.project.loadEvents();
    this.projectLoaded.next(this.project);
    this.updateProjectReadOnlyStatus(this.project);
  }

  /**
   * Update updated_at field to mark project for synchronisation to backend
   *
   * TODO This synchronisation mark should be more efficient and only applied for real updates
   */
  public setProjectUpdated(): void {
    if (this.project) {
      this.project.is_changed = true;
    }

    if (this.project) {
      this.dataService.createOrUpdate('projects', this.project);
    }
  }

  /**
   * Save project with clients
   *
   * @returns {Promise<void>}
   */
  public async saveProject(): Promise<void> {
    const newProjectId = await this.dataService.createOrUpdate('projects', this.project);

    this.synchronisationService.setSynchronisingAction(true);
    await this.loadProject(newProjectId);
  }

  public async saveClientAndProject(): Promise<void> {
    await this.dataService.createOrUpdate('clients', this.project.client);

    this.project.client_id = this.project.client.id;

    await this.saveProject();
  }

  /**
   * Save address
   *
   * @param address: Address
   * @returns Promise<void>
   */
  public async saveAddress(address: Address): Promise<void> {
    await this.dataService.createOrUpdate('addresses', address);

    this.addressAdded.next(null);
    this.setProjectUpdated();
  }

  /**
   * Save multiple addresses
   *
   * @param addresses: Address[]
   * @returns Promise<void>
   */
  public async saveAddresses(addresses: Address[]): Promise<void> {
    await Address.query.bulkPut(addresses);
    this.addressAdded.next(null);
    this.setProjectUpdated();
  }

  /**
   * Save address
   *
   * @param quotation
   */
  public saveQuotation(quotation: any): void {
    this.setProjectUpdated();

    // Update date string value
    // TODO Refactor to more sustainable solution
    quotation.updateDate();

    this.dataService.createOrUpdate('quotations', quotation).then(() => {
      this.quotationAdded.next(null);
    });
  }

  /**
   * Check whether project can be edited by user or not
   *
   * @param project
   */
  public updateProjectReadOnlyStatus(project: Project): void {
    this.projectIsReadOnly.next(!!project.editingBy);
  }

  /**
   * Get address with id
   *
   * @param id Number
   */
  public async getAddress(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }],
    });

    const address = await this.dataService.get('addresses', this.dataQuery, '/address');

    this.address = address[0];
    this.addressLoaded.next(this.address);
  }

  /**
   * Delete address with id
   *
   * @param id Number
   */
  public deleteAddress(id: any): void {
    this.setProjectUpdated();
    this.dataService.delete('addresses', id);
  }

  /**
   * Save contact
   *
   * @param contact Contact model
   */
  public async saveContact(contact: any): Promise<void> {
    await this.dataService.createOrUpdate('contacts', contact);

    this.contactsAdded.next(null);
    this.setProjectUpdated();
  }

  /**
   * Get contact with id
   *
   * @param id Number
   */
  public async getContact(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }],
    });

    const contact = await this.dataService.get('contacts', this.dataQuery, '/contact');

    this.contact = contact[0];
    this.contactsChanged.next(this.contact);
  }

  /**
   * Delete contact with id
   *
   * @param id Number
   */
  public deleteContact(id): void {
    this.setProjectUpdated();
    this.dataService.delete('contacts', id);
  }

  /**
   * Get work assignment by id
   */
  public async getWorkAssignment(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }],
    });

    const workAssignment = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');

    if (workAssignment && workAssignment[0]) {
      workAssignment[0].init();

      return workAssignment[0];
    }
  }

  public async getAllWorkAssignments(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      columns: [{ name: 'project_id', filter: id, filterMode: 'equals' }],
    });

    const workAssignments = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');

    if (workAssignments && workAssignments[0]) {
      for (const assignment of workAssignments) {
        assignment.init();
      }

      return workAssignments;
    }
  }

  /**
   * Save work assignment
   */
  public async saveWorkAssignment(workAssignment: WorkAssignment): Promise<any> {
    this.setProjectUpdated();
    // Update date string value
    // TODO Refactor to more sustainable solution
    workAssignment.updateDate();
    const result = await this.dataService.createOrUpdate('work_assignments', workAssignment);

    // Save items
    for (const item of workAssignment.items) {
      await this.dataService.createOrUpdate('work_assignment_items', item);
    }

    // Save items
    for (const item of workAssignment.address_work_assignments) {
      await this.dataService.createOrUpdate('address_work_assignments', item);
    }
  }

  /**
   * Delete work assignment
   * @param workAssignment
   */
  public async deleteWorkAssignment(workAssignment: WorkAssignment): Promise<void> {
    // First delete items
    await workAssignment.init();
    for (const item of workAssignment.items) {
      await this.dataService.delete('work_assignment_items', item.id);
    }

    this.setProjectUpdated();
    await this.dataService.delete('work_assignments', workAssignment.id);
  }

  public async deleteProjectActivity(activityId: string): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('project_activities', activityId);
  }

  /**
   * Delete work assignment item
   * @param workAssignmentItem
   */
  public async deleteWorkAssignmentItem(workAssignmentItem: WorkAssignmentItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('work_assignment_items', workAssignmentItem.id);
  }

  /**
   * Delete work assignment address
   * @param workAssignmentAddress
   */
  public async deleteWorkAssignmentAddress(workAssignmentAddress: WorkAssignmentAddress): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('address_work_assignments', workAssignmentAddress.id);
  }

  /**
   * Save specialties
   *
   * @param specialities Array of specialties
   */
  public async saveSpecialties(specialities: ProjectSpecialty[]): Promise<void> {
    for (const specialty of specialities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      specialty.updateDate();

      await this.dataService.createOrUpdate('project_specialties', specialty);
    }

    this.setProjectUpdated();
  }

  /**
   * Save project materials
   *
   * @param projectMaterials Array of materials
   */
  public async saveProjectMaterials(projectMaterials): Promise<void> {
    for (const projectMaterial of projectMaterials) {
      await this.dataService.createOrUpdate('project_materials', projectMaterial);
    }
  }

  /**
   * Save Picture
   *
   * @param picture: Picture
   * @returns Promise<void>
   */
  public async savePicture(picture: Picture): Promise<void> {
    await this.dataService.createOrUpdate('pictures', picture);
  }

  public calculateBase64FileSize(base64: string): number {
    const size = +(base64?.length * (3 / 4)) - 2;

    return size && size > 0 ? Math.round(size) : 0;
  }

  /**
   * Delete Picture
   *
   * @param pictureId: string
   * @returns Promise<void>
   */
  public async deletePicture(pictureId: string): Promise<void> {
    await this.dataService.delete('pictures', pictureId);
  }

  /**
   * Save activities
   *
   * @param activities Array of activities
   */
  public async saveActivities(activities): Promise<void> {
    for (const activity of activities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      activity.updateDate();
      await this.dataService.createOrUpdate('project_activities', activity);
    }
  }

  /**
   * Get client with id
   * @param id Client id
   */
  public async getClient(id): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }],
    });

    const client = await this.dataService.get('clients', this.dataQuery, '/client');

    this.project.client = client[0];
    this.project.client_id = this.project.client.id;
    this.clientChanged.next(this.project.client);
  }

  /**
   * Create or update inventory_item
   * @param inventoryItem InventoryItem model
   */
  public async createOrUpdateInventoryItem(inventoryItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.createOrUpdate('inventory_items', inventoryItem);
  }

  /**
   * Write default_inventory_items to inventory_items for specific inventory
   *
   * @param inventoryId Number
   */
  public async writeDefaultsToInventoryItems(inventoryId): Promise<void> {
    // TODO: add remote route to get default inventory items and default items
    const inventory = await this.dataService.getById('inventories', inventoryId);

    if (!inventory) {
      return;
    }

    const defaultInventoryItems = await this.dataService.getBy(
      'default_inventory_items',
      'default_inventory_id',
      inventory.default_inventory_id
    );

    const currentInventoryItems = await this.dataService.getBy('inventory_items', 'inventory_id', inventory?.id);

    for (const item of defaultInventoryItems) {
      const defaultItem = await this.dataService.getById('default_items', item.default_item_id);

      if (defaultItem) {
        const exists: boolean =
          currentInventoryItems?.filter((currentInventoryItem) => {
            return currentInventoryItem.name === defaultItem?.name;
          }).length > 0;

        if (exists) {
          continue;
        }

        const inventoryItem = new InventoryItem({
          inventory_id: inventoryId,
          name: defaultItem.name,
          volume: defaultItem.volume,
          price: defaultItem.price,
          meterbox: defaultItem.meterbox,
        });

        await this.createOrUpdateInventoryItem(inventoryItem);
      }
    }

    await this.project.loadInventories();
  }

  /**
   * Logic because if a new inventory is created with a parent, the parent items should be moved to the child
   *
   * @param inventoryId
   */
  public async moveInventoryItemsFromParent(inventoryId: string): Promise<void> {
    const inventory: Inventory = await this.dataService.getById('inventories', inventoryId);

    // Nothing to do if not a parent or not existing
    if (!inventory || !inventory.parent_id) {
      return;
    }

    const inventoryItems: InventoryItem[] = await this.dataService.getBy(
      'inventory_items',
      'inventory_id',
      inventory?.parent_id
    );

    for (const inventoryItem of inventoryItems) {
      inventoryItem.inventory_id = inventory?.id;
      await this.createOrUpdateInventoryItem(inventoryItem);
    }

    await this.project.loadInventories();
  }

  /**
   * Delete inventory with id
   * @param id Number
   */
  public async deleteInventoryItem(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventory_items', id);
    await this.project.loadInventories();
  }

  /**
   * Delete inventory with id and assocciated inventory_items
   *
   * @param id Number
   */
  public async deleteInventory(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventories', id);

    this.dataQuery = new QueryOptions({
      pageSize: this.maxInventoryItems,
      columns: [{ name: 'inventory_id', filter: id, filterMode: 'equals' }],
    });

    this.result = await this.dataService.get('inventory_items', this.dataQuery, '/inventories');
    this.result.forEach(async (inventory) => {
      await this.dataService.delete('inventory_items', inventory.id);
    });
    await this.project.loadInventories();
    this.inventoryDeleted.next(null);
  }

  /**
   * Save new inventory
   * In addition write default_inventory_items to inventory_items
   *
   * @param inventory Inventory model
   */
  public async saveNewInventory(inventory): Promise<void> {
    this.setProjectUpdated();
    const newInventoryId = await this.dataService.createOrUpdate('inventories', inventory);

    if (inventory.default_inventory_id != null) {
      await this.moveInventoryItemsFromParent(newInventoryId);
      await this.writeDefaultsToInventoryItems(newInventoryId);
    } else {
      await this.project.loadInventories();
    }

    this.inventoryAdded.next(newInventoryId);
  }

  /**
   * Update inventory
   *
   * @param inventory Inventory model
   */
  public updateInventory(inventory): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('inventories', inventory);
  }

  public async getCascadingInventories(): Promise<SelectItem[]> {
    const inventories: SelectItem[] = [];

    this.dataQuery = new QueryOptions({
      pageSize: this.maxListItems,
      columns: [{ name: 'project_id', filter: this.project?.id, filterMode: 'equals' }],
    });
    this.result = await this.dataService.get('inventories', this.dataQuery, '/inventory/list');

    this.result.forEach((item) => {
      inventories.push({ label: item.cascading_name, value: item });
    }, this);

    this.parentInventoriesLoaded.next(inventories);

    return inventories;
  }

  /**
   * Get default inventories
   */
  public async getDefaultInventories(): Promise<SelectItem[]> {
    const rooms: SelectItem[] = [];

    this.dataQuery = new QueryOptions({
      pageSize: this.maxListItems,
      columns: [
        { name: 'type_base_data_value_id', filter: this.project.type_base_data_value_id, filterMode: 'equals' },
      ],
    });

    this.result = await this.dataService.get('default_inventories', this.dataQuery, '/default-inventory/list');
    this.result.forEach((item) => {
      rooms.push({ label: item.cascading_name, value: item });
    }, this);

    this.defaultInventoriesLoaded.next(rooms);

    return rooms;
  }

  /**
   * Update project material
   */
  public updateMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('project_materials', projectMaterial);
  }

  /**
   * Add project material
   */
  public addMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.add('project_materials', projectMaterial);
  }

  /**
   * Calculates total volume of all inventories for the current project
   */
  public calculateVolume(): number {
    let volumeTotal = 0;

    if (this.project && this.project.inventories && this.project.inventories.length > 0) {
      this.project.inventories.forEach((inventory) => {
        if (inventory) {
          let volume = 0;

          if (inventory.items) {
            inventory.items.forEach((item) => {
              volume += item.amount * item.volume || 0;
            });
          }

          volumeTotal += volume;
          inventory.volume = Math.round(volume * 100) / 100;
        }
      });
    }

    return Math.round(volumeTotal * 100) / 100;
  }

  public calculatePackingTotal(): number {
    if (!this.project || !this.project.inventories) {
      return 0;
    }
    let packingTotal = 0;

    for (const inventory of this.project.inventories) {
      packingTotal += +inventory.packing_amount;
    }

    return packingTotal;
  }

  public calculateAssemblyTotal(): number {
    if (!this.project || !this.project.inventories) {
      return 0;
    }

    let assemblyTotal = 0;

    for (const inventory of this.project.inventories) {
      if (inventory.items) {
        assemblyTotal += +inventory.assembly_amount;
      }
    }

    return +assemblyTotal;
  }

  public calculateMeterboxTotal(): number {
    if (!this.project || !this.project.inventories) {
      return 0;
    }

    let meterboxTotal = 0;

    for (const inventory of this.project.inventories) {
      for (const inventoryItem of inventory.items) {
        // Don't add any boxes when it's a "kast"
        if (!inventoryItem.name.toLowerCase().includes('kast')) {
          meterboxTotal += inventoryItem.amount * inventoryItem.meterbox || 0;
        }
      }
    }

    return meterboxTotal;
  }

  public setCurrentClient(client: Client): void {
    this.currentClient$.next(client);
  }

  public sendToExact(id): Observable<any> {
    return this.post(`/${this.endpoint}/send-to-exact`, { id: id });
  }

  /**
   * Save event
   *
   * @param event: Event
   * @param project: Project
   * @returns Promise<void>
   */
  public async saveEvent(event: Event, project: Project = null): Promise<void> {
    if (!event.eventable_id && project) {
      event.eventable_id = project.id;
    }

    if (!event.eventable_type && project) {
      event.eventable_type = 'PAVanRooyen\\Domain\\Project\\Project';
    }

    await this.dataService.createOrUpdate('events', event);

    this.eventAdded.next(null);
    this.setProjectUpdated();
  }

  /**
   * Get event with id
   *
   * @param id Number
   */
  public async getEvent(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }],
    });

    const event = await this.dataService.get('events', this.dataQuery, '/events');

    this.event = event[0];
    this.eventLoaded.next(this.event);
  }

  /**
   * Returns the project status list options
   */
  public async getStatusList(): Promise<SelectItem[]> {
    return (await getBaseDataByKey('project-status')).values.map((status) => {
      return {
        label: this.titleCasePipe.transform(this.translateService.instant(`base-data.${status.id}`)),
        value: status.id,
      };
    });
  }

  /**
   * Returns the project status list options
   */
  public async getFullStatusList(): Promise<SelectItem[]> {
    return (await getBaseDataByKey('project-status')).values.map((status) => {
      return {
        label: this.titleCasePipe.transform(this.translateService.instant(`base-data.${status.id}`)),
        value: status.id,
        key: status.value,
      };
    });
  }

  public async getTypeList(): Promise<SelectItem[]> {
    return (await getBaseDataByKey('project-type')).values.map((type) => {
      return {
        label: this.titleCasePipe.transform(this.translateService.instant(`base-data.${type.id}`)),
        value: type.id,
      };
    });
  }
}
