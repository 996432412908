<div class="box-content-wrap">
  <div class="ibox-title">
    <h5>{{ 'movers_complete.entity.contact.single' | translate }}</h5>
    <span class="fa fa-times close-icon" (click)="onCloseClick()"></span>
  </div>

  <ng-container *ngIf="form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
      <div class="ibox-content scroll-fix">
        <div class="form-group">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.contact.companies.label' | translate }}
          </label>

          <div class="col-sm-8">
            <cap-dropdown
              (changeSelection)="updateContactTemplatesList($event?.value)"
              [options]="companiesList"
              [showClear]="true"
              [filter]="true"
              formControlName="company_id"
              [placeholder]="'movers_complete.inventory.contact.companies.placeholder' | translate">
            </cap-dropdown>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.contact.fixed_contacts.label' | translate }}
          </label>

          <div class="col-sm-8">
            <cap-dropdown
              (changeSelection)="selectContactTemplate($event?.value)"
              [options]="contactTemplatesList"
              [disabled]="disabled"
              [readonly]="disabled"
              [showClear]="true"
              [filter]="true"
              formControlName="contact_template_id"
              [placeholder]="'movers_complete.general.placeholder.select_applicable' | translate">
            </cap-dropdown>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.gender }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.contact.salutation.label' | translate }}
          </label>

          <div class="col-sm-8">
            <cap-dropdown
              [options]="genders"
              [disabled]="disabled"
              formControlName="gender"
              [placeholder]="'button.select' | translate">
            </cap-dropdown>

            <span class="help-block m-b-none" *ngIf="errors.gender">
              {{ errors.gender[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.initials }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.contact.initials.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" [disabled]="disabled" formControlName="initials" />
            <span class="help-block m-b-none" *ngIf="errors.initials">
              {{ errors.initials[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.name }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.contact.name.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" [disabled]="disabled" formControlName="name" />
            <span class="help-block m-b-none" *ngIf="errors.name">
              {{ errors.name[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.email }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.contact.email.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" [disabled]="disabled" formControlName="email" />
            <span class="help-block m-b-none" *ngIf="errors.email">
              {{ errors.email[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.phone }">
          <label class="col-sm-4" asteriks>
            {{ 'movers_complete.inventory.contact.phone.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" [disabled]="disabled" formControlName="phone" />
            <span class="help-block m-b-none" *ngIf="errors.phone">
              {{ errors.phone[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.mobile }">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.contact.mobile.label' | translate }}
          </label>

          <div class="col-sm-8">
            <input class="form-control" [disabled]="disabled" formControlName="mobile" />
            <span class="help-block m-b-none" *ngIf="errors.mobile">
              {{ errors.mobile[0] }}
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': errors.remarks }">
          <label class="col-sm-4">
            {{ 'movers_complete.inventory.contact.remarks.label' | translate }}
          </label>

          <div class="col-sm-8">
            <cap-textarea
              class="form-control"
              [disabled]="disabled"
              [readonly]="disabled"
              formControlName="remarks"
              [rows]="3"></cap-textarea>

            <span class="help-block m-b-none" *ngIf="errors.remarks">
              {{ errors.remarks[0] }}
            </span>
          </div>
        </div>
      </div>

      <div class="ibox-footer" *ngIf="!disabled">
        <cap-button
          *ngIf="!disabled"
          type="submit"
          [disabled]="form.invalid"
          styleClass="primary"
          [label]="(mode.isAdd ? 'button.add' : 'button.edit') | translate"
          [icon]="mode.isAdd ? 'fa fa-plus' : 'fa fa-pencil'">
        </cap-button>

        <cap-button styleClass="secondary" (onClick)="onCloseClick()" [label]="'button.close' | translate"></cap-button>
      </div>
    </form>
  </ng-container>
</div>
