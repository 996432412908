<div class="" id="blank_invoice_pdf" style="display: none">
  <div class="ibox-title">
    <h5>Offerte</h5>
  </div>

  <div class="ibox-content striped-forms">
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          Saas
          <br />
          Molenweg 81 <br />
          5349 AC Oss <br />
          0412 632 069 <br />
          www.saas.nl
        </div>

        <div class="col-sm-6">
          info@saas.nl
          <br />
          ING Bank IBAN: NL65INGB0657789690 <br />
          BIC: INGBNL2A <br />
          BTW nr: NL 0081.83.557 B01 <br />
          KvK Den Bosch nr 16052832
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-sm-12">
          <h5>OVEREENKOMST VOOR DE VERHUIZING</h5>
        </div>

        <div class="col-sm-12">
          <b
            >Waarop van toepassing zijn de "Algemene Voorwaarden voor
            {{ type === 'private' ? 'Verhuizingen' : 'Bedrijfsverhuizingen' }} 2015"</b
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <br />
        </div>
      </div>

      <div class="row">
        <label class="col-sm-6 control-label">Referentienummer:</label>
        <div class="col-sm-6">
          <input class="form-control" />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-sm-12">
          <h5>OPDRACHTGEVER</h5>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-6">Naam:</label>
        <div class="col-sm-6">
          <input class="form-control" />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-sm-6">
          <h5>LAADADRES(SEN):</h5>
          <div class="row">
            <label class="col-sm-3">Adres:</label>
            <div class="col-sm-9">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-3">Plaats:</label>
            <div class="col-sm-9">
              <input class="form-control" />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <h5>LOSADRES(SEN):</h5>
          <div class="row">
            <label class="col-sm-3">Adres:</label>
            <div class="col-sm-9">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-3">Plaats:</label>
            <div class="col-sm-9">
              <input class="form-control" />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div class="row">
          <div class="col-sm-12">
            <h5>VAKWERK:</h5>

            <ng-container *ngFor="let specialty of specialties">
              <div class="row">
                <label class="col-sm-3">Naam:</label>
                <div class="col-sm-9">
                  <input class="form-control" />
                </div>
              </div>

              <div class="row">
                <label class="col-sm-3">Opmerkingen:</label>
                <div class="col-sm-9">
                  <input class="form-control" />
                </div>
              </div>

              <div
                class="row"
                style="margin-bottom: 20px"
                [ngClass]="{ 'use-page-break': specialties.indexOf(specialty) === 1 }">
                <div class="col-sm-6">
                  <div class="row">
                    <label class="col-sm-3">Aantal uur:</label>
                    <div class="col-sm-9">
                      <input class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="row">
                    <label class="col-sm-3">Datum:</label>
                    <div class="col-sm-9">
                      <input class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
      </div>

      <div class="use-page-break">
        <div class="row">
          <div class="col-sm-12">
            <h5>WERKZAAMHEDEN:</h5>

            <ng-container *ngFor="let activity of activities">
              <div class="row">
                <label class="col-sm-3">Naam:</label>
                <div class="col-sm-9">
                  <input class="form-control" />
                </div>
              </div>

              <div class="row">
                <label class="col-sm-3">Opmerkingen:</label>
                <div class="col-sm-9">
                  <input class="form-control" />
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col-sm-4">
                  <div class="row">
                    <label class="col-sm-3">Aantal uur:</label>
                    <div class="col-sm-9">
                      <input class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="row">
                    <label class="col-sm-3">Aantal wagens:</label>
                    <div class="col-sm-9">
                      <input class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="row">
                    <label class="col-sm-3">Datum:</label>
                    <div class="col-sm-9">
                      <input class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h5>VERHUISKOSTEN</h5>
          <div class="row">
            <label class="col-sm-8 control-label">Totaal berekend volume</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>

            <label class="col-sm-1 control-label"
              >m
              <sup>3</sup>
            </label>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Totaalvolume</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
            <label class="col-sm-1 control-label"
              >m
              <sup>3</sup>
            </label>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Berekende afstand</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
            <label class="col-sm-1 control-label">km</label>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Afstand</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
            <label class="col-sm-1 control-label">km</label>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Basis Verhuisprijs</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Inpakken</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Uitpakken</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Handyman</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">De- en montage</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'business'">
            <label class="col-sm-8 control-label">Af- en aankoppelen ICT</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'business'">
            <label class="col-sm-8 control-label">Kabelmanagement</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Specials</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Afhalen verlichting / gordijnrails</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Piano / vleugel / orgel</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row margin-bottom" *ngIf="type === 'business'">
            <label class="col-sm-8 control-label">Bescherming aanbrengen</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Toeslag Verhuislift</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Toeslag Piano-brandkast</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Toeslag Etage</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row" *ngIf="type === 'private'">
            <label class="col-sm-8 control-label">Garantiecertificaat tot &euro; 100.000,-</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Handymancertificaat tot &euro; 10.000</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-5 control-label">Verwachte aantal dagen in opslag á</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>

            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Handling t.b.v. opslag / Overslagkosten</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-5 control-label"
              >Door u opgegeven te verzekeren bedrag / verzekeringspremie per 4 weken
              <ng-container *ngIf="type === 'private'">, na 4 weken</ng-container></label
            >
            <div class="col-sm-3">
              <input class="form-control" />
            </div>

            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Parkeer-ontheffing</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <i>Vrij in te vullen:</i>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8">
              <input class="form-control" />
            </div>

            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8">
              <input class="form-control" />
            </div>

            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8">
              <input class="form-control" />
            </div>

            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">Subtotaal</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label">BTW (21%)</label>
            <div class="col-sm-3">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-8 control-label"><b>Totaalbedrag</b></label>
            <div class="col-sm-3">
              <input class="form-control total-price" />
            </div>
          </div>
          <hr />
        </div>
      </div>
      <!--
          <div class="row" *ngIf='quotation.materials && quotation.materials.length'>
            <div class="col-sm-12">
              <h5>MATERIAAL</h5>
              <div class="row">
                <div class="col-sm-6" *ngFor="let quotationMaterial of quotation.materials">
                  <p-checkbox class="pull-left" name="{{ quotationMaterial.material.name }}" value="unpack" [(ngModel)]="quotationMaterial.material_id"></p-checkbox>
                  <p>{{ quotationMaterial.material_name }}
                    <span>{{ quotationMaterial.amount }}</span>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        -->
      <div class="row">
        <div class="col-sm-12">
          <h5>BETALING</h5>
          <div class="row">
            <label class="col-sm-6 control-label">De rekening voor deze verhuizing moet worden gestuurd aan:</label>
            <div class="col-sm-6">
              <input class="form-control" />
            </div>
          </div>

          <div class="row">
            <label class="col-sm-6 control-label">Ten name van:</label>
            <div class="col-sm-6">
              <input class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h5>AKKOORD</h5>

        <p>
          In verband met de wet op de privacy willen wij u vragen om bij het bevestigen van de offerte de volgende
          punten te paraferen:
        </p>

        <p-checkbox class="pull-left" name="check1" value="check1" binary="true"></p-checkbox>
        <p>
          Bij het ondertekenen en accepteren van de offerte tekent u tevens voor ontvangst van de actuele
          {{ type === 'private' ? 'A.V.V.V.' : 'A.V.V.B.' }}.
        </p>

        <p-checkbox class="pull-left" name="check2" value="check2" binary="true"></p-checkbox>
        <p>De betaling tijdig te voldoen voor aanvang van de verhuizing.</p>

        <ng-container *ngIf="type === 'private'">
          <p-checkbox class="pull-left" name="check3" value="check3" binary="true"></p-checkbox>
          <p>Wij vragen uw toestemming om uw gegevens te gebruiken voor het aanmaken van het garantie certificaat.</p>
        </ng-container>

        <ng-container *ngIf="type === 'business'">
          <p-checkbox class="pull-left" name="check3" value="check3" binary="true"></p-checkbox>
          <p>
            Verzekering volgens {{ type === 'private' ? 'A.V.V.V.' : 'A.V.V.B.' }} tot &euro; 50.000,- per wagenlading
            of bij interne verhuizingen.
          </p>
        </ng-container>

        <p-checkbox class="pull-left" name="check4" value="check4" binary="true"></p-checkbox>
        <p>
          Wij vragen uw toestemming om uw gegevens door te geven aan "klanten vertellen" in verband met de enquête die
          wij nodig hebben voor onze certificering als Erkende Verhuizer
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h5>
          ONDERTEKENING (voor akkoord offerte voor de verhuizing & ontvangst
          {{ type === 'private' ? 'A.V.V.V.' : 'A.V.V.B.' }} condities)
        </h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-3">Datum:</div>

      <div class="col-sm-6 col-md-3 col-md-offset-3">Datum:</div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <p>Opdrachtgever</p>
      </div>

      <div class="col-sm-6">
        <p>Taxateur</p>
      </div>
    </div>

    <div class="row" style="padding-top: 100px">
      <div class="col-sm-12">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12"></div>
    </div>
  </div>
</div>
