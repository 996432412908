import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[asteriks]',
  templateUrl: './asteriks.component.html',
  styleUrls: ['./asteriks.component.scss'],
})
export class AsteriksComponent {
  @Input() asteriks = true;
}
