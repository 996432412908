import { Pipe, PipeTransform } from '@angular/core';
import { FilePreviewListItem } from '@capturum/ui/file-preview-list';
import { PreviewItem } from '@capturum/ui/image-viewer';

@Pipe({
  name: 'previewImage',
})
export class PreviewImagePipe implements PipeTransform {
  public transform(value: FilePreviewListItem[]): PreviewItem[] {
    return value?.map((item) => {
      return {
        src: item.url,
        name: item.name,
      };
    });
  }
}
