<app-dialog-layout *ngIf="project">
  <ng-container content>
    <cpb-form-renderer
      #formRenderer
      [readOnly]="disabled"
      [formKey]="formKey"
      [modelId]="modelId"
      [context]="{ project_id: project.id }"
      [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="primary" (onClick)="onCancel()" [icon]="'fa fa-ban'" [label]="'button.cancel' | translate">
    </cap-button>

    <cap-button
      styleClass="primary"
      (onClick)="onSubmit()"
      [label]="'button.submit' | translate"
      [icon]="'fa fa-check'"
      [disabled]="disabled">
    </cap-button>
  </ng-container>
</app-dialog-layout>
