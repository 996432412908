import { Component, OnInit, Input } from '@angular/core';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from '@node_modules/@capturum/complete';

@Component({
  selector: 'app-inventory-add-item',
  templateUrl: 'inventory-add-item.component.html',
})
export class InventoryAddItemComponent implements OnInit {
  @Input() disabled;
  @Input() inventoryId: number;

  public form: UntypedFormGroup;
  public showErrors = false;
  public hasBulkPrices: boolean = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private settingService: SettingService
  ) {}

  public ngOnInit(): void {
    this.hasBulkPrices = this.settingService.getValue('movers_complete.has_bulk_prices');

    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      inventory_id: this.formBuilder.control(this.inventoryId),
      name: this.formBuilder.control('', Validators.required),
      remarks: this.formBuilder.control(''),
      volume: this.formBuilder.control(''),
      price: this.formBuilder.control(''),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.form.valid) {
      if (!this.hasBulkPrices) {
        this.form.patchValue({ volume: '1' });
      }

      const inventoryItem: InventoryItem = new InventoryItem({ ...this.form.value });

      this.dialogRef.close(inventoryItem);

      this.toastService.success(
        this.translateService.instant('movers_complete.inventory.board.add-new-item.success'),
        this.translateService.instant('movers_complete.inventory.board.add-new-item.added')
      );
    } else {
      this.showErrors = true;

      this.toastService.error(
        this.translateService.instant('movers_complete.inventory.board.add-new-item.error'),
        this.translateService.instant('movers_complete.inventory.board.add-new-item.failed')
      );
    }
  }
}
