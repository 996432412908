export class TenantConfigQuotationCompanyDetails {
  public name: string;
  public street: string;
  public city: string;
  public zipcode: string;
  public phone: string;
  public website: string;

  constructor(attributes) {
    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        this[attributeName] = attributes[attributeName];
      }
    }
  }
}
