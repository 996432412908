import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { DexieStore } from '@domain/dexie-store';

@Injectable()
export class BootstrapGuard {
  constructor(private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      const store = DexieStore.getInstance();

      if (!store) {
        this.navigateToLogin(state.url);
        observer.next(false);
        observer.complete();
      }

      store
        .initialize()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch((error) => {
          this.navigateToLogin(state.url);
          observer.error(error);
          observer.complete();
        });
    });
  }

  private navigateToLogin(route: string): void {
    this.router.navigate(['/failure'], { queryParams: { redirectUrl: route } });
  }
}
