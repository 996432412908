<div class="table-filters cap-table-filter" *ngIf="filters">
  <div class="d-flex align-items-center">
    <strong class="filters-component-label">Filters</strong>

    <div class="filters">
      <div class="table-filter-row d-flex flex-wrap">
        <div
          *ngFor="let filter of config"
          [ngClass]="filter.wrapperStyleClass"
          [class.search-input]="filter.isSearch"
          class="align-vertical filter">
          <ng-container [ngSwitch]="filter.type">
            <div
              *ngSwitchCase="!filter.isSearch && FilterType.INPUT"
              [class.active]="!!filters[filter.field]?.value || !!filters?.global?.value"
              class="filterComponentWrapper">
              <cap-input
                [ngModel]="filters[filter.field]?.value"
                [placeholder]="filter.placeholder | observablePipe | async"
                styleClass="cap-rounded has-icon filterComponent"
                (onInput)="updateFilter($event.target.value, filter.field, filter.matchMode || FilterMatchMode.LIKE)">
              </cap-input>
            </div>

            <div
              *ngSwitchCase="filter.isSearch && FilterType.INPUT"
              [class.active]="!!filters[filter.field]?.value || !!filters?.global?.value"
              class="filterComponentWrapper">
              <cap-input
                [ngModel]="filters[filter.field]?.value"
                [placeholder]="filter.placeholder | observablePipe | async"
                styleClass="cap-rounded has-icon filterComponent"
                (onInput)="updateSearch($event.target.value)">
              </cap-input>
            </div>

            <div
              *ngSwitchCase="FilterType.MULTISELECT"
              class="filterComponentWrapper"
              [class.active]="!!filters[filter.field]?.value">
              <ng-container *ngIf="filter?.options | observablePipe | async as options">
                <cap-multi-select
                  [ngModel]="filters[filter.field]?.value"
                  [defaultLabel]="filter.placeholder | observablePipe | async"
                  [options]="options"
                  styleClass="cap-rounded has-icon filterComponent"
                  (change)="updateFilter($event.value, filter.field, FilterMatchMode.IN)">
                </cap-multi-select>
              </ng-container>
            </div>

            <div
              *ngSwitchCase="FilterType.SELECT"
              class="filterComponentWrapper"
              [class.active]="!!filters[filter.field]?.value">
              <ng-container *ngIf="filter?.options | observablePipe | async as options">
                <cap-dropdown
                  [ngModel]="filters[filter.field]?.value"
                  [autoDisplayFirst]="false"
                  [showClear]="true"
                  [placeholder]="filter.placeholder | observablePipe | async"
                  [options]="options"
                  [sortAlphabetically]="true"
                  styleClass="cap-rounded has-icon filterComponent"
                  (change)="updateFilter($event.value, filter.field, FilterMatchMode.EQUALS)">
                </cap-dropdown>
              </ng-container>
            </div>

            <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template #filterIcon let-icon="icon">
    <div class="filter-icon">
      <i [ngClass]="icon"></i>
    </div>
  </ng-template>
</div>
