<div class="box-content-wrap">
  <div class="ibox-title">
    <h5>Event</h5>
    <span class="fa fa-times cancel-button" (click)="onCloseClick()"></span>
  </div>

  <div class="ibox-content">
    <ng-container *ngIf="form">
      <form [formGroup]="form" class="form-horizontal">
        <div
          class="form-group"
          [ngClass]="{ 'has-error': errors.event || (showErrors && this.form.controls['event'].invalid) }">
          <label>Event</label>

          <cap-textarea
            class="form-control"
            [disabled]="disabled"
            [readOnly]="disabled"
            formControlName="event"
            rows="5">
          </cap-textarea>

          <span class="help-block m-b-none" *ngIf="errors.event">{{ errors.event[0] }}</span>
        </div>
      </form>
    </ng-container>
  </div>

  <div class="ibox-footer" *ngIf="form">
    <cap-button type="button" styleClass="secondary" label="Sluiten" (onClick)="onCloseClick()"> </cap-button>

    <cap-button
      type="submit"
      styleClass="primary"
      [disabled]="form.invalid"
      (onClick)="onSubmit()"
      [label]="mode.isAdd ? 'Toevoegen' : 'Bijwerken'"
      [icon]="mode.isAdd ? 'fa fa-plus' : 'fa fa-pencil'">
    </cap-button>
  </div>
</div>
