import { ManageHouseTypesDetailComponent } from '@features/manage/components/house-types/house-types-detail/house-types-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-house-types-list',
  templateUrl: './house-types-list.component.html',
  styleUrls: ['./house-types-list.component.scss'],
})
export class ManageHouseTypesListComponent {
  public listKey = 'list_house_types';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageHouseTypesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.house-type.single'),
    });
  }
}
