import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { LoginLayoutComponent } from './loginLayout.component';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';

import { NavigationComponent } from './../navigation/navigation.component';
import { HeaderComponent } from './../header/header.component';
import { SharedModule } from '@shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CapturumActionMenuModule } from '@capturum/ui/action-menu';
import { CommonModule } from '@angular/common';
import { PopupLayoutComponent } from './popup-layout/popup-layout.component';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  declarations: [
    HeaderComponent,
    LoginLayoutComponent,
    NavigationComponent,
    BasicLayoutComponent,
    PopupLayoutComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    SharedModule,
    CapturumActionMenuModule,
    CapturumSharedModule,
  ],
  exports: [HeaderComponent, NavigationComponent, BasicLayoutComponent, PopupLayoutComponent],
})
export class LayoutsModule {}
