import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { WorkAssignmentItemType } from '@core/enums/work-assignment-item-type.enum';
import { isNumeric } from '@core/utils/compare.utils';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-inventory-work-assignment-dropdown-item',
  templateUrl: 'work-assignment-dropdown-item.component.html',
  styleUrls: ['./work-assignment-dropdown-item.component.scss'],
})
export class InventoryWorkAssignmentDropdownItemComponent {
  private workAssignmentItemTypeCarId: string;
  private _item: WorkAssignmentItem;
  public get item(): WorkAssignmentItem {
    return this._item;
  }

  @Input()
  public set item(item: WorkAssignmentItem) {
    this._item = item;

    this.updateSelectedCar();
  }

  private _machines: SelectItem[];
  get machines(): SelectItem[] {
    return this._machines;
  }

  @Input()
  set machines(machines: SelectItem[]) {
    this._machines = machines;

    this.updateSelectedCar();
  }

  @Input() placeholderName;
  @Input() disabled: boolean;

  @Output() onDeleteItemClick = new EventEmitter();

  public times: SelectItem[] = [];

  constructor() {
    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }
  }

  public onDeleteClick(): void {
    this.onDeleteItemClick.emit(this.item);
  }

  private async updateSelectedCar(): Promise<void> {
    this.workAssignmentItemTypeCarId = (
      await BaseDataIndexedDbModel.query().where({ key: 'work-assignment-item-type' }).first()
    ).values?.find((item) => {
      return item.value === WorkAssignmentItemType.car;
    })?.id;

    if (this._item && this._item.type_base_data_value_id === this.workAssignmentItemTypeCarId) {
      if (this.machines && this.machines.length > 0 && this.item.details && !isNumeric(this.item.details)) {
        const machine = this.machines.find((item) => {
          return item.label === this.item.details;
        });

        if (machine) {
          this._item.details = machine.value;
        }
      }
    }
  }
}
