import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Tenant } from '@domain/models/tenant.model';
import { TenantConfigQuotationPaymentDetails } from '@domain/models/tenant-config-quotation-payment-details.model';
import { TenantConfigQuotationCompanyDetails } from '@domain/models/tenant-config-quotation-company-details.model';
import { TenantConfigStylesheet } from '@domain/models/tenant-config-stylesheet.model';
import { map } from 'rxjs/operators';
import { SettingService } from '@shared/services/setting.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';
import { merge } from '@node_modules/rxjs';
import { FilePreviewListItem } from '@core/models/file-preview-list-item.model';

@Component({
  selector: 'app-tenants-detail',
  templateUrl: './tenants-detail.component.html',
  styleUrls: ['./tenants-detail.component.scss'],
})
export class ManageTenantsDetailComponent implements OnInit, OnDestroy {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: UntypedFormGroup;
  public mode = { isAdd: true };
  public tenant: Tenant = new Tenant({});
  private privateFilesSubject = new BehaviorSubject<any[]>([]);
  private businessFilesSubject = new BehaviorSubject<any[]>([]);
  private logoFilesSubject = new BehaviorSubject<any[]>([]);
  public privateFiles$ = this.privateFilesSubject.asObservable();
  public businessFiles$ = this.businessFilesSubject.asObservable();
  public logoFiles$ = this.logoFilesSubject.asObservable();
  public savedPrivateFiles: FilePreviewListItem[];
  public savedBusinessFiles: FilePreviewListItem[];
  public savedLogoFiles: FilePreviewListItem[];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dataService: DataService,
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private settingService: SettingService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public get settingsForm(): UntypedFormArray {
    return this.form.get('settings') as UntypedFormArray;
  }

  public ngOnInit(): void {
    // Get id of url to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      const id = params['id'];
      const includeFiles = '?_meta[include]=filesPrivate.tags,filesBusiness.tags,filesLogo.tags';

      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api
          .get('/tenant/' + id + includeFiles)
          .pipe(
            map((result) => {
              // eslint-disable-next-line no-param-reassign
              result = result.data;

              result.settings = result.settings?.data;

              return result;
            })
          )
          .subscribe((result: Tenant) => {
            this.tenant = result;

            this.initForm();
            this.collectFilesOnInit();
            this.loading = false;
          });
      } else {
        // Load default settings
        this.settingService.get('/setting?_pageSize=999999').subscribe((result) => {
          this.tenant.settings = result.data.map((setting) => {
            setting.value = setting.default_value;

            return setting;
          });

          this.initForm();
          this.collectFilesOnInit();
          this.loading = false;
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.tenant.name || '', [Validators.required]],
      key: [this.tenant.key || '', [Validators.required]],
      host: [this.tenant.host || '', [Validators.required]],
      api_planning_module: [this.tenant.api_planning_module || false, [Validators.required]],
      api_exact_online: [this.tenant.api_exact_online || false, [Validators.required]],
      api_boelaars_en_lambert: [this.tenant.api_boelaars_en_lambert || false, [Validators.required]],
      api_arent: [this.tenant.api_arent || false, [Validators.required]],
      filesPrivate: [this.tenant.filesPrivate || false],
      filesBusiness: [this.tenant.filesBusiness || false],
      filesLogo: [this.tenant.filesLogo || false],
      config_stylesheet_primary_color: [
        this.isAdd ? '' : this.tenant.config_stylesheet.primary_color || '',
        [Validators.required],
      ],
      config_quotation_company_details_name: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.name || '',
        [Validators.required],
      ],
      config_quotation_company_details_street: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.street || '',
        [Validators.required],
      ],
      config_quotation_company_details_city: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.city || '',
        [Validators.required],
      ],
      config_quotation_company_details_zipcode: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.zipcode || '',
        [Validators.required],
      ],
      config_quotation_company_details_phone: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.phone || '',
        [Validators.required],
      ],
      config_quotation_company_details_website: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.website || '',
        [Validators.required],
      ],
      config_quotation_payment_details_bic: [
        this.isAdd ? '' : this.tenant.config_quotation.payment_details?.bic || '',
        [Validators.required],
      ],
      config_quotation_payment_details_email: [
        this.isAdd ? '' : this.tenant.config_quotation.payment_details?.email || '',
        [Validators.required],
      ],
      config_quotation_payment_details_iban: [
        this.isAdd ? '' : this.tenant.config_quotation.payment_details?.iban || '',
        [Validators.required],
      ],
      config_quotation_payment_details_kvk: [
        this.isAdd ? '' : this.tenant.config_quotation.payment_details?.kvk || '',
        [Validators.required],
      ],
      config_quotation_payment_details_vat: [
        this.isAdd ? '' : this.tenant.config_quotation.company_details?.name || '',
        [Validators.required],
      ],
      settings: this.formBuilder.array([]),
    });

    this.tenant.settings?.forEach((setting) => {
      const settingForm = this.formBuilder.group({
        id: [null],
        value: [null],
      });

      this.settingsForm.push(settingForm);
    });

    this.settingsForm.patchValue(this.tenant.settings);
  }

  public collectFilesOnInit(): void {
    const savedPrivateFiles = of(this.tenant?.filesPrivate).pipe(
      map((files: FilePreviewListItem[]) => {
        return files?.map((file: FilePreviewListItem) => {
          return { name: file.tags[1].value, content: file.public_url_presigned };
        });
      })
    );

    this.savedPrivateFiles = this.tenant.filesPrivate?.flatMap((file: FilePreviewListItem) => {
      return {
        name: file.tags[1].value,
        content: file.public_url_presigned,
      };
    });

    const savedBusinessFiles = of(this.tenant?.filesBusiness).pipe(
      map((files: FilePreviewListItem[]) => {
        return files?.map((file: FilePreviewListItem) => {
          return { name: file.tags[1].value, content: file.public_url_presigned };
        });
      })
    );

    this.savedBusinessFiles = this.tenant.filesBusiness?.flatMap((file: FilePreviewListItem) => {
      return {
        name: file.tags[1].value,
        content: file.public_url_presigned,
      };
    });

    const savedLogoFiles = of(this.tenant?.filesLogo).pipe(
      map((files: FilePreviewListItem[]) => {
        return files?.map((file: FilePreviewListItem) => {
          return { name: file.tags[1].value, content: file.public_url_presigned, isImage: true };
        });
      })
    );

    this.savedLogoFiles = this.tenant.filesLogo?.flatMap((file: FilePreviewListItem) => {
      return {
        name: file.tags[1].value,
        content: file.public_url_presigned,
        isImage: true,
      };
    });

    this.businessFiles$ = merge(this.businessFiles$, savedBusinessFiles);
    this.privateFiles$ = merge(this.privateFiles$, savedPrivateFiles);
    this.logoFiles$ = merge(this.logoFiles$, savedLogoFiles);
    this.privateFilesSubject.next(this.savedPrivateFiles);
    this.businessFilesSubject.next(this.savedBusinessFiles);
    this.logoFilesSubject.next(this.savedLogoFiles);
  }

  public async addFile(files: File[], type: string): Promise<void> {
    await Promise.all(
      files.map(async (file: File) => {
        const content = await this.readFileAsDataURL(file);

        if (type === 'private') {
          const privateFiles = [...this.privateFilesSubject.value];

          privateFiles.push({
            name: file.name,
            content: content,
          });

          this.privateFilesSubject.next(privateFiles);
        } else if (type === 'business') {
          const businessFiles = [...this.businessFilesSubject.value];

          businessFiles.push({
            name: file.name,
            content: content,
          });

          this.businessFilesSubject.next(businessFiles);
        } else if (type === 'logo') {
          const logoFiles = [];

          logoFiles.push({
            name: file.name,
            content: content,
            isImage: true,
          });

          this.logoFilesSubject.next(logoFiles);
        }
      })
    );
  }

  private async readFileAsDataURL(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result.toString());
        } else {
          reject(new Error('Failed to read file content.'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read file.'));
      };

      reader.readAsDataURL(file);
    });
  }

  public onSubmit(): void {
    this.form.patchValue({
      filesPrivate: this.privateFilesSubject.value,
      filesBusiness: this.businessFilesSubject.value,
      filesLogo: this.logoFilesSubject.value,
    });

    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const itemData = this.form.getRawValue();
      const tenant = new Tenant({
        name: itemData.name,
        key: itemData.key,
        host: itemData.host,
        api_planning_module: itemData.api_planning_module,
        api_exact_online: itemData.api_exact_online,
        api_boelaars_en_lambert: itemData.api_boelaars_en_lambert,
        api_arent: itemData.api_arent,
        files_private: itemData.filesPrivate,
        files_business: itemData.filesBusiness,
        files_logo: itemData.filesLogo,
        config_stylesheet: new TenantConfigStylesheet({
          primary_color: itemData.config_stylesheet_primary_color,
        }),
        config_quotation: {
          company_details: new TenantConfigQuotationCompanyDetails({
            city: itemData.config_quotation_company_details_city,
            name: itemData.config_quotation_company_details_name,
            phone: itemData.config_quotation_company_details_phone,
            street: itemData.config_quotation_company_details_street,
            website: itemData.config_quotation_company_details_website,
            zipcode: itemData.config_quotation_company_details_zipcode,
          }),
          payment_details: new TenantConfigQuotationPaymentDetails({
            bic: itemData.config_quotation_payment_details_bic,
            email: itemData.config_quotation_payment_details_email,
            iban: itemData.config_quotation_payment_details_iban,
            kvk: itemData.config_quotation_payment_details_kvk,
            vat: itemData.config_quotation_payment_details_vat,
          }),
        },
        settings: itemData.settings,
      });

      let request;

      if (this.isAdd) {
        request = this.api.post('/tenant', tenant);
      } else {
        request = this.api.patch('/tenant/' + this.route.snapshot.params['id'], tenant);
      }

      const toastTitle = this.translateService.instant('movers_complete.entity.tenant.plural');
      const entity = this.translateService.instant('movers_complete.entity.tenant.single');

      request.subscribe(
        (_) => {
          this.toastService.success(
            toastTitle,
            this.translateService.instant('movers_complete.entity.save.success.text', { entity })
          );
          this.router.navigateByUrl('/').then(() => {
            // HACK Use double navigation to force reload..
            this.router.navigateByUrl('/admin/manage/tenants');
          });
        },
        (error: any) => {
          if (error.status === 422 && error.json) {
            this.toastService.warning(
              toastTitle,
              this.translateService.instant('movers_complete.toast.input.error.text')
            );
          } else {
            this.toastService.error(
              toastTitle,
              this.translateService.instant('movers_complete.entity.save.error.text', { entity })
            );
          }

          this.loading = false;
        }
      );
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/tenants');
  }

  public removeFile(file, type: string): void {
    if (type === 'private') {
      const privateFiles = this.privateFilesSubject.value.slice(); // Make a copy
      const updatedFiles = privateFiles.filter((obj) => {
        return obj.name !== file.name;
      });

      this.privateFilesSubject.next(updatedFiles);
    } else if (type === 'business') {
      const businessFiles = this.businessFilesSubject.value.slice(); // Make a copy
      const updatedFiles = businessFiles.filter((obj) => {
        return obj.name !== file.name;
      });

      this.businessFilesSubject.next(updatedFiles);
    } else if (type === 'logo') {
      const logoFiles = this.logoFilesSubject.value.slice(); // Make a copy
      const updatedFiles = logoFiles.filter((obj) => {
        return obj.name !== file.name;
      });

      this.logoFilesSubject.next(updatedFiles);
    }
  }
}
