<app-inventory-header
  *ngIf="inventories"
  [disabled]="disabled"
  (inventoryChange)="onInventoryChange($event)"
  [project]="project"
  [inventory]="selectedInventory"
  [inventories]="inventories"
  [volumeTotal]="volumeTotal"
  [meterboxTotal]="meterboxTotal">
</app-inventory-header>

<div class="row mt-3">
  <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="!disabled">
    <div *ngIf="selectedInventoryId" class="new-inventory-item" (click)="openAddItem()">
      <i class="fas fa-plus mr-3"></i>

      <span class="new-item"> {{ 'movers_complete.inventory.board.item_add.label' | translate }}</span>
    </div>

    <div *ngIf="!selectedInventoryId" class="new-inventory-item disabled">
      <i class="fas fa-plus mr-3"></i>

      <span class="new-item"> {{ 'movers_complete.inventory.board.item_add.label' | translate }}</span>
    </div>
  </div>

  <ng-container *ngFor="let inventoryItem of filteredInventoryItems">
    <div class="col-lg-3 col-md-4 col-sm-6">
      <app-inventory-item
        [disabled]="disabled"
        class="active-item"
        [inventoryItem]="inventoryItem"
        (inventoryItemChanged)="onInventoryItemChange($event)"
        (inventoryItemDeleted)="onInventoryItemDelete($event)">
      </app-inventory-item>
    </div>
  </ng-container>

  <div class="col-lg-3 col-md-4 col-sm-6 d-none" *ngIf="selectedInventoryId">
    <app-inventory-static
      [disabled]="disabled"
      class="active-item"
      [(amount)]="selectedInventory.packing_amount"
      (amountChange)="onAmountChange()"
      >{{ 'movers_complete.inventory.board.packing_unpacking.label' | translate }}
    </app-inventory-static>
  </div>

  <div class="col-lg-3 col-md-4 col-sm-6 d-none" *ngIf="selectedInventoryId">
    <app-inventory-static
      [disabled]="disabled"
      class="active-item"
      [(amount)]="selectedInventory.assembly_amount"
      [increment]="0.5"
      (amountChange)="onAmountChange()"
      >{{ 'movers_complete.inventory.board.assembly_disassembly.label' | translate }}
    </app-inventory-static>
  </div>
</div>
