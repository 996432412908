<cap-file-upload
  #capFileUpload
  [id]="field.key"
  *ngIf="!to.readonly"
  [attr.data-test]="'builder-' + field.key"
  [multiple]="to?.multiple"
  [accept]="'image/*, .pdf'"
  [uploaderContent]="uploadContent"
  (onFileChange)="handleFileUpload($event)">
</cap-file-upload>

<!-- PREVIEW DOWNLOADED FILES SECTION -->
<div class="preview--section" [attr.data-test]="'builder-readonly-' + field.key">
  <cap-file-preview-list
    [files]="previewFiles"
    [showDelete]="!disabled"
    (itemClick)="openFile($event)"
    (onDeleteClick)="removeFile($event)"></cap-file-preview-list>
</div>

<ng-template #uploadContent>
  <div class="upload">
    <i class="fas fa-cloud-upload-alt"></i>
    <p [innerHTML]="field?.name"></p>
    <span class="placeholder" *ngIf="field?.templateOptions?.label as placeholder">{{
      'file.upload.description' | translate
    }}</span>
  </div>
</ng-template>

<cap-image-viewer
  [hidden]="!showImageViewer"
  [items]="imageViewerSrc | previewImage"
  (actionClick)="handleAction($event)"
  (closePreviewer)="closePreviewer()">
</cap-image-viewer>
