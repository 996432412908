import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutComponent } from '@features/gui/layouts/basic-layout/basic-layout.component';
import { LoginLayoutComponent } from '@features/gui/layouts/loginLayout.component';
import { PopupLayoutComponent } from '@features/gui/layouts/popup-layout/popup-layout.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryAddressSortComponent } from '@features/inventory/address/address-sort/address-sort.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { EventDetailComponent } from '@features/inventory/event/event-detail.component';
import { EventListComponent } from '@features/inventory/event/event-list.component';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryOverviewComponent } from '@features/inventory/overview/overview.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { InventoryPicturesComponent } from '@features/inventory/pictures/pictures.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { LoginComponent } from '@features/login/login.component';
import { ManageActivitiesDetailComponent } from '@features/manage/components/activities/activities-detail/activities-detail.component';
import { ManageActivitiesListComponent } from '@features/manage/components/activities/activities-list/activities-list.component';
import { ManageAddressTypesDetailComponent } from '@features/manage/components/address-types/address-types-detail/address-types-detail.component';
import { ManageAddressTypesListComponent } from '@features/manage/components/address-types/address-types-list/address-types-list.component';
import { ManageAddressesDetailComponent } from '@features/manage/components/addresses/addresses-detail/addresses-detail.component';
import { ManageAddressesListComponent } from '@features/manage/components/addresses/addresses-list/addresses-list.component';
import { ManageBulkPricesDetailComponent } from '@features/manage/components/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ManageBulkPricesListComponent } from '@features/manage/components/bulk-prices/bulk-prices-list/bulk-prices-list.component';
import { ManageContactsDetailComponent } from '@features/manage/components/contacts/contacts-detail/contacts-detail.component';
import { ManageContactsListComponent } from '@features/manage/components/contacts/contacts-list/contacts-list.component';
import { ManageDefaultInventoriesDetailComponent } from '@features/manage/components/default-inventories/default-inventories-detail/default-inventories-detail.component';
import { ManageDefaultInventoriesListComponent } from '@features/manage/components/default-inventories/default-inventories-list/default-inventories-list.component';
import { ManageDefaultItemsDetailComponent } from '@features/manage/components/default-items/default-items-detail/default-items-detail.component';
import { ManageDefaultItemsListComponent } from '@features/manage/components/default-items/default-items-list/default-items-list.component';
import { ManageHouseTypesDetailComponent } from '@features/manage/components/house-types/house-types-detail/house-types-detail.component';
import { ManageHouseTypesListComponent } from '@features/manage/components/house-types/house-types-list/house-types-list.component';
import { ManageComponent } from '@features/manage/manage.component';
import { ManageMaterialsDetailComponent } from '@features/manage/components/materials/materials-detail/materials-detail.component';
import { ManageMaterialsListComponent } from '@features/manage/components/materials/materials-list/materials-list.component';
import { ManageOptionsDetailComponent } from '@features/manage/components/options/options-detail/options-detail.component';
import { ManageOptionsListComponent } from '@features/manage/components/options/options-list/options-list.component';
import { ManageRelationGroupsDetailComponent } from '@features/manage/components/relation-groups/relation-groups-detail/relation-groups-detail.component';
import { ManageRelationGroupsListComponent } from '@features/manage/components/relation-groups/relation-groups-list/relation-groups-list.component';
import { ManageTenantsDetailComponent } from '@features/manage/components/tenants/tenants-detail/tenants-detail.component';
import { ManageTenantsListComponent } from '@features/manage/components/tenants/tenants-list/tenants-list.component';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { ReportOverviewComponent } from '@features/report/report-overview.component';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';
import { UserDetailComponent } from '@features/user/user-detail.component';
import { UserOverviewComponent } from '@features/user/user-overview.component';
import { AddressGuard } from '@shared/guards/address.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { SettingResolver } from '@shared/resolvers/setting.resolver';
import { LocationDetailComponent } from '@features/manage/components/locations/location-detail/location-detail.component';
import { QuotationCheckDetailComponent } from '@features/manage/components/quotation-checks/quotation-check-detail/quotation-check-detail.component';
import { QuotationCheckListComponent } from '@features/manage/components/quotation-checks/quotation-check-list/quotation-check-list.component';
import { LocationListComponent } from '@features/manage/components/locations/location-list/location-list.component';
import { TenantResolver } from '@shared/resolvers/tenant.resolver';
import { AuthGuard } from '@capturum/auth';
import { BaseDataResolver, IndexedDbGuard, PermissionGuard, TranslationResolver } from '@capturum/complete';
import { ExactOnlineComponent } from '@features/settings/exact/exact-online.component';
import { loginRoutes } from '@capturum/login';
import { InventoryPublicTranslationResolver } from '@core/resolvers/inventory-public-translation.resolver';
import { EmailQuotationAttachmentsListComponent } from '@features/manage/components/email-quotation-attachments/email-quotation-attachments-list/email-quotation-attachments-list.component';
import { QuotationSignComponent } from '@features/quotationSign/quotation-sign.component';

const resetPasswordPath = loginRoutes[0].children.find((route) => {
  return route.path === 'reset-password/:token/:email';
});

export const ROUTES: Routes = [
  // Admin section
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard, IndexedDbGuard, PermissionGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      tenant: TenantResolver,
      setting: SettingResolver,
      baseData: BaseDataResolver,
      translations: TranslationResolver,
    },
    component: BasicLayoutComponent,
    children: [
      // Main redirect
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full',
      },
      // App views
      {
        path: 'report',
        component: ReportOverviewComponent,
        data: {
          title: 'Rapportage',
        },
      },
      {
        path: 'settings',
        data: {
          title: 'Instellingen',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SettingsOverviewComponent,
          },
          {
            path: 'exact-online',
            component: ExactOnlineComponent,
            data: {
              title: 'Exact Online',
            },
          },
        ],
      },
      {
        path: 'projects',
        component: ProjectOverviewComponent,
        data: {
          title: 'Projecten',
        },
      },
      {
        path: 'users',
        component: UserOverviewComponent,
        canActivate: [UserGuard],
        data: {
          title: 'Gebruikers',
        },
      },
      {
        path: 'project/:project',
        component: InventoryFlowComponent,
        children: [
          {
            path: 'client',
            component: InventoryClientComponent,
            canDeactivate: [ClientGuard],
            data: {
              title: 'Klant',
            },
          },
          {
            path: 'address',
            component: InventoryAddressListComponent,
            canDeactivate: [AddressGuard],
            data: {
              title: 'Addressen',
            },
          },
          {
            path: 'contact',
            component: InventoryContactListComponent,
            canDeactivate: [ContactGuard],
            data: {
              title: 'Contactpersonen',
            },
          },
          {
            path: 'options',
            component: InventoryOptionsDetailComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [OptionsGuard],
            data: {
              title: 'Opties',
            },
          },
          {
            path: 'inventory',
            component: InventoryBoardComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [InventoryGuard],
            data: {
              title: 'Inventarisatie',
            },
          },
          {
            path: 'material',
            component: MaterialComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Materialen',
            },
          },
          {
            path: 'overview',
            component: InventoryOverviewComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Overzicht',
            },
          },
          {
            path: 'quotation',
            component: InventoryQuotationComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Offerte',
            },
          },
          {
            path: 'pictures',
            component: InventoryPicturesComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Bestanden',
            },
          },
          {
            path: 'work-assignment',
            component: InventoryWorkAssignmentListComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Werkopdracht',
            },
          },
          {
            path: 'work-assignment/:id',
            component: InventoryWorkAssignmentDetailComponent,
            canActivate: [ContactListGuard],
            data: {
              title: 'Werkopdracht',
            },
          },
        ],
      },
      {
        path: 'manage',
        canActivate: [],
        data: {
          title: 'Beheer',
        },
        children: [
          {
            path: 'tenants',
            component: ManageTenantsListComponent,
          },
          {
            path: 'address-types',
            component: ManageAddressTypesListComponent,
          },
          {
            path: 'house-types',
            component: ManageHouseTypesListComponent,
          },
          {
            path: 'addresses',
            component: ManageAddressesListComponent,
          },
          {
            path: 'contacts',
            component: ManageContactsListComponent,
          },
          {
            path: 'materials',
            component: ManageMaterialsListComponent,
          },
          {
            path: 'options',
            component: ManageOptionsListComponent,
          },
          {
            path: 'activities',
            component: ManageActivitiesListComponent,
          },
          {
            path: 'relation-groups',
            component: ManageRelationGroupsListComponent,
          },
          {
            path: 'default-inventories',
            component: ManageDefaultInventoriesListComponent,
          },
          {
            path: 'default-items',
            component: ManageDefaultItemsListComponent,
          },
          {
            path: 'bulk-prices',
            component: ManageBulkPricesListComponent,
          },
          {
            path: 'locations',
            component: LocationListComponent,
          },
          {
            path: 'quotation-checks',
            component: QuotationCheckListComponent,
          },
          {
            path: 'email-quotation-attachments',
            component: EmailQuotationAttachmentsListComponent,
          },
          {
            path: 'settings',
            loadChildren: () => {
              return import('./features/manage/components/tenant-settings/tenant-settings.module').then((m) => {
                return m.TenantSettingsModule;
              });
            },
          },
          {
            path: '',
            component: ManageComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      tenant: TenantResolver,
      setting: SettingResolver,
    },
    component: PopupLayoutComponent,
    outlet: 'popup',
    children: [
      {
        path: 'project/address/add',
        component: InventoryAddressDetailComponent,
      },
      {
        path: 'project/address/:id',
        component: InventoryAddressDetailComponent,
      },
      {
        path: 'manage/tenants/:id',
        component: ManageTenantsDetailComponent,
      },
      {
        path: 'manage/address-types/:id',
        component: ManageAddressTypesDetailComponent,
      },
      {
        path: 'manage/house-types/:id',
        component: ManageHouseTypesDetailComponent,
      },
      {
        path: 'manage/addresses/:id',
        component: ManageAddressesDetailComponent,
      },
      {
        path: 'manage/contacts/:id',
        component: ManageContactsDetailComponent,
      },
      {
        path: 'manage/materials/:id',
        component: ManageMaterialsDetailComponent,
      },
      {
        path: 'manage/options/:id',
        component: ManageOptionsDetailComponent,
      },
      {
        path: 'manage/activities/:id',
        component: ManageActivitiesDetailComponent,
      },
      {
        path: 'manage/relation-groups/:id',
        component: ManageRelationGroupsDetailComponent,
      },
      {
        path: 'manage/default-inventories/:id',
        component: ManageDefaultInventoriesDetailComponent,
      },
      {
        path: 'manage/default-items/:id',
        component: ManageDefaultItemsDetailComponent,
      },
      {
        path: 'manage/bulk-prices/:id',
        component: ManageBulkPricesDetailComponent,
      },
      {
        path: 'manage/locations/:id',
        component: LocationDetailComponent,
      },
      {
        path: 'project/address-sort',
        component: InventoryAddressSortComponent,
      },
      {
        path: 'project/contact/add',
        component: InventoryContactsDetailComponent,
      },
      {
        path: 'project/contact/:id',
        component: InventoryContactsDetailComponent,
      },
      {
        path: 'project/client-search',
        component: InventoryClientSearchComponent,
      },
      {
        path: 'project/inventory/add',
        component: InventoryAddComponent,
      },
      {
        path: 'project/pictures/add',
        component: InventoryPictureItemDetailComponent,
      },
      {
        path: 'project/pictures/:id',
        component: InventoryPictureItemDetailComponent,
      },
      {
        path: 'user/:id',
        component: UserDetailComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'project/client/events',
        component: EventListComponent,
      },
      {
        path: 'project/client/events/add',
        component: EventDetailComponent,
      },
      {
        path: 'manage/quotation-checks/:id',
        component: QuotationCheckDetailComponent,
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard, BootstrapGuard, IndexedDbGuard, PermissionGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      tenant: TenantResolver,
      setting: SettingResolver,
      baseData: BaseDataResolver,
      translations: TranslationResolver,
    },
    component: BasicLayoutComponent,
    children: [
      {
        path: 'builders',
        loadChildren: () => {
          return import('@capturum/builders/pages').then((m) => {
            return m.CapturumBuilderPagesModule;
          });
        },
      },
    ],
  },
  {
    path: '',
    redirectTo: 'admin/projects',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [IndexedDbGuard],
    resolve: {
      tenant: TenantResolver,
    },
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        resolve: {
          publicTranslations: InventoryPublicTranslationResolver,
        },
        component: LoginComponent,
      },
      {
        path: 'auth',
        resolve: {
          publicTranslations: InventoryPublicTranslationResolver,
        },
        children: [resetPasswordPath],
      },
    ],
  },
  {
    path: 'quotation/sign/:quotation_token/:quotation_project_id',
    canActivate: [IndexedDbGuard],
    resolve: {
      tenant: TenantResolver,
      publicTranslations: InventoryPublicTranslationResolver,
    },
    component: QuotationSignComponent,
  },
  // Handle all other routes
  { path: '**', redirectTo: 'admin' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
