import { Pipe, PipeTransform } from '@angular/core';
import { Inventory } from '@domain/models/inventory.model';

/**
 * Retrieves an inventory-id and project
 *
 * Usage:
 *   [inventory id] | totalPriceByRoom: [project]
 */
@Pipe({ name: 'totalPriceByRoom' })
export class TotalPriceByRoomPipe implements PipeTransform {
  public transform(inventoryId: string, inventories: Inventory[]): number {
    let total = 0;

    for (const inventory of inventories) {
      if (inventory.items && inventory.id === inventoryId) {
        for (const item of inventory.items) {
          if (item.amount) {
            total += item.amount * item.price;
          }
        }
      }
    }

    return total;
  }
}
