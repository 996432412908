<div class="row mb-1">
  <div class="col-sm-10 dropdowns">
    <cap-dropdown
      [options]="addresses"
      placeholder="Selecteer"
      [(ngModel)]="address.address_id"
      autoWidth="false"
      [showClear]="true"
      [disabled]="disabled"
      [readonly]="disabled">
    </cap-dropdown>
  </div>

  <div class="col-sm-2 deleteButton">
    <span class="input-group-btn" *ngIf="!disabled">
      <a class="btn btn-danger" (click)="onDeleteClick(address)">
        <i class="fa fa-times"></i>
      </a>
    </span>
  </div>
</div>
