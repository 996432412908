import { Component } from '@angular/core';
import { ManageAddressTypesDetailComponent } from '../address-types-detail/address-types-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address-types-list',
  templateUrl: './address-types-list.component.html',
  styleUrls: ['./address-types-list.component.scss'],
})
export class ManageAddressTypesListComponent {
  public listKey = 'list_address_types';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageAddressTypesDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.address-type.single'),
    });
  }
}
