import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';
import { TreeTableModule } from 'primeng/treetable';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ExactOnlineComponent } from '@features/settings/exact/exact-online.component';

@NgModule({
  providers: [],
  declarations: [SettingsOverviewComponent, ExactOnlineComponent],
  imports: [
    BrowserModule,
    FormsModule,
    TreeTableModule,
    PrimeNGSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    DialogModule,
    ConfirmDialogModule,
  ],
  exports: [SettingsOverviewComponent, ExactOnlineComponent],
})
export class SettingsModule {}
