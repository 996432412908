import { Injectable } from '@angular/core';
import { Signature } from '@domain/models/signature.model';
import { from, Observable } from 'rxjs';

@Injectable()
export class SignatureService {
  public addSignatures(signatures: Signature[]): Observable<any> {
    return from(Signature.query.bulkPut(signatures));
  }

  public removeSignature(id: string): Observable<any> {
    return from(Signature.query.delete(id));
  }
}
