import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { first, Subscription } from 'rxjs';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ConfirmationService } from 'primeng/api';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { Material } from '@domain/models/material.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { InventoryAddItemComponent } from '@features/inventory/inventory/add-item/inventory-add-item.component';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-board',
  styleUrls: ['./inventory-board.component.scss'],
  templateUrl: 'inventory-board.component.html',
})
export class InventoryBoardComponent implements OnInit, OnDestroy {
  @ViewChild('header', { static: false }) header: InventoryHeaderComponent;

  public project = new Project({});
  public inventories: Inventory[];
  public filteredInventoryItems: Array<any>;
  public selectedInventory = new Inventory({});
  public selectedInventoryId = null;
  public volumeTotal: number;
  public assemblyTotal: number;
  public packingTotal: number;
  public meterboxTotal: number;
  public disabled = true;
  public materials: Material[] = [];
  public projectMaterials: any[] = [];

  private subscriptionInventoryDeleted: Subscription;
  private subscriptionInventoryAdded: Subscription;
  private subscriptionProjectLoaded: Subscription;

  public constructor(
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private synchronisationService: SynchronisationService,
    private api: ApiServiceWithLoaderService
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public async ngOnInit(): Promise<void> {
    const localProjectInventories = await Inventory.query.get({
      project_id: (await this.projectService.getProject()).id,
    });

    if (!localProjectInventories || (localProjectInventories && localProjectInventories.length === 0)) {
      await this.projectService.addDefaultInventories();
    }

    this.filteredInventoryItems = [];
    this.project = await this.projectService.getProject();
    this.setInventories();

    // When inventory is deleted reset selected id and inventory then reload all inventories
    this.subscriptionInventoryDeleted = this.projectService.inventoryDeleted.subscribe(() => {
      this.setInventories();
      this.selectedInventoryId = 0;
      this.getSelectedInventory();
    });

    this.subscriptionInventoryAdded = this.projectService.inventoryAdded.subscribe(async (inventoryId) => {
      await this.project.loadInventories();
      this.setInventories();
      this.selectedInventoryId = inventoryId;
      this.getSelectedInventory();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
      this.setInventories();
      this.projectService.setCurrentClient(this.project.client);
    });

    this.volumeTotal = this.projectService.calculateVolume();
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();

    this.getSelectedInventory();
    await this.getMaterials();
    await this.getProjectMaterials();
  }

  /**
   * Set Inventories
   * @protected
   */
  protected setInventories(): void {
    this.project.init();
    this.inventories = this.project.inventories;
  }

  public async getMaterials(): Promise<void> {
    const queryOptions = new QueryOptions({ usePaging: false });

    this.materials = await this.dataService.get('materials', queryOptions, '/materials');
  }

  public async getProjectMaterials(): Promise<void> {
    this.projectMaterials = await ProjectMaterial.query.where('project_id').equals(this.project.id).toArray();
  }

  /**
   * Check all inventories which match floor and room (default_inventory)
   */
  public onInventoryChange(inventory): void {
    if (!inventory) {
      return;
    }
    this.selectedInventory = inventory;
    this.selectedInventoryId = inventory.id;
    this.getSelectedInventory();
    this.volumeTotal = this.projectService.calculateVolume();
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();
  }

  /**
   * Search inventory for selected inventory from header
   */
  public getSelectedInventory(): void {
    this.filteredInventoryItems = [];
    if (this.inventories.length > 0) {
      this.inventories.map((inventory) => {
        if (inventory.id === this.selectedInventoryId) {
          // this.filteredInventoryItems = inventory.items;
          this.selectedInventory = inventory;

          // Apply custom sorting to items
          // TODO Should be configured using sort order in backend
          this.filteredInventoryItems = inventory.items.sort((a, b) => {
            if (a.name === b.name) {
              return 0;
            }

            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });

          this.filteredInventoryItems.forEach((item) => {
            return this.capitalizeItemName(item);
          });
        }
      });
    }
  }

  /**
   * Update inventory item
   */
  public async onInventoryItemChange(inventoryItem): Promise<void> {
    this.updateInventoryItem(inventoryItem);
    this.volumeTotal = this.projectService.calculateVolume();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();

    /** Sync with materials */
    this.updateMaterials(inventoryItem);

    await this.project.loadInventories();
  }

  /**
   * Delete inventory item
   */
  public onInventoryItemDelete(inventoryItem): void {
    this.removeInventoryItem(inventoryItem);
  }

  /**
   * Handle static item amount changes
   */
  public onAmountChange(): void {
    if (!this.selectedInventory) {
      return;
    }

    this.projectService.updateInventory(this.selectedInventory);
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
  }

  /**
   * Create inventory item
   *
   * @param inventoryItem InventoryItem model
   */
  public createInventoryItem(inventoryItem: InventoryItem): void {
    // Add inventory item to current selected inventory and also update in store
    inventoryItem.inventory_id = this.selectedInventoryId;
    this.selectedInventory.items.push(inventoryItem);
    this.projectService.createOrUpdateInventoryItem(inventoryItem);
    this.getSelectedInventory();
  }

  /**
   * Update inventory item
   *
   * @param inventoryItem InventoryItem model
   */
  public updateInventoryItem(inventoryItem: any): void {
    inventoryItem.inventory_id = inventoryItem.inventory_id || this.selectedInventoryId;
    this.projectService.createOrUpdateInventoryItem(inventoryItem);
  }

  /**
   * Remove inventory item
   * @param inventoryItem
   */
  public removeInventoryItem(inventoryItem: any): void {
    this.confirmationService.confirm({
      message: 'Wilt u dit item verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: async (_) => {
        await this.projectService.deleteInventoryItem(inventoryItem.id);
        const index = this.filteredInventoryItems.indexOf(inventoryItem);

        this.filteredInventoryItems.splice(index, 1);
      },
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionInventoryDeleted) {
      this.subscriptionInventoryDeleted.unsubscribe();
    }

    if (this.subscriptionInventoryAdded) {
      this.subscriptionInventoryAdded.unsubscribe();
    }

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (!this.project.editingBy?.name) {
      const data = await this.synchronisationService.getSyncJson(this.project);
      const newData = JSON.parse(JSON.stringify(data));
      const originalData = this.project._original ? JSON.parse(JSON.stringify(this.project._original)) : {};

      // Compare new data and original
      const diff = this.synchronisationService.getDiff(newData, originalData);

      if (diff && this.project.id) {
        const somethingWentWrong =
          originalData &&
          originalData.project &&
          originalData.project.reference_nr &&
          newData &&
          newData.quotation &&
          newData.quotation._deleted;

        if (!somethingWentWrong) {
          await this.api.post('/sync/post', [diff]).toPromise();
        }
      }
    }
  }

  /** Capitalize the first character of item name */
  private capitalizeItemName(item: any): void {
    item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
  }

  private updateMaterials(inventoryItem): void {
    /** shorthand function */
    const createNewProjectMaterial = (material, inventoryItem) => {
      if (inventoryItem.amount === 0) {
        inventoryItem.amount += inventoryItem.increment;
      }

      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: inventoryItem.amount,
      });

      this.projectService.addMaterial(newProjectMaterial);
      this.getProjectMaterials();
    };

    if (!(this.materials.length > 0)) {
      return;
    }

    const name = this.getCorrectName(inventoryItem.name);
    const materialToUpdate = this.materials.find((material) => {
      return material.name.toLowerCase() === name.toLowerCase();
    });

    if (!materialToUpdate) {
      return;
    }

    const projectMaterialToUpdate = this.projectMaterials.find((item) => {
      return item.material_id === materialToUpdate.id;
    });

    /** If no material exists yet for given ID, create a new one */
    if (projectMaterialToUpdate) {
      if (inventoryItem.subtraction) {
        projectMaterialToUpdate.amount - inventoryItem.increment >= 0
          ? (projectMaterialToUpdate.amount -= inventoryItem.increment)
          : null;
      } else {
        projectMaterialToUpdate.amount += inventoryItem.increment;
      }

      this.projectService.updateMaterial(projectMaterialToUpdate);
    } else {
      createNewProjectMaterial(materialToUpdate, inventoryItem);
    }
  }

  private getCorrectName(inventoryName: string): string {
    let convertedName: string;

    /**
     * Needed cuz Arent materials have slightly different names than our own inventory items... :(
     * Luckily, dkb = DKB in Arent so that one is not necessary.
     */
    switch (inventoryName.toLowerCase()) {
      case 'mb':
        convertedName = 'Meterbak';
        break;
      case 'computerbak':
        convertedName = 'Computerbak incl antistatisch matje';
        break;
      case 'rolco':
        convertedName = 'Rolcontainer met nylon wielen';
        break;
      default:
        convertedName = inventoryName;
        break;
    }

    return convertedName;
  }

  public openAddItem(): void {
    const dialogRef = this.dialogService.open(InventoryAddItemComponent, {
      header: this.translateService.instant('movers_complete.inventory.board.item_add.label'),
    });

    dialogRef.onClose.pipe(first()).subscribe((inventoryItem) => {
      if (inventoryItem) {
        this.createInventoryItem(inventoryItem);
      }
    });
  }
}
