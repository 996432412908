import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from './login.component';
import { CommonModule } from '@angular/common';
import { CapturumSharedModule } from '@capturum/ui/api';
import { LoginModule as CapturumLoginModule } from '@capturum/login';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, FormsModule, CapturumSharedModule, SharedModule, CapturumLoginModule.forRoot({})],
  exports: [LoginComponent],
})
export class LoginModule {}
