<div class="ibox w-100">
  <ng-container *ngIf="form">
    <form [formGroup]="form" class="form-horizontal">
      <div class="ibox-content">
        <div class="row">
          <div class="left-column col-sm-12 col-md-6">
            <div class="form-group row fixed-height">
              <label class="col-sm-12 col-md-3 control-label client-type" asteriks>
                {{ 'movers_complete.inventory.client.type.label' | translate }}
              </label>
              <div class="col-sm-12 col-md-9">
                <cap-select-button
                  formControlName="client_type"
                  [optionValue]="'value'"
                  [options]="clientTypeOptions"
                  (change)="setType($event.value)"
                  [optionLabel]="'label'">
                </cap-select-button>

                <label *ngIf="disabled" class="col-sm-12 control-label text-align-left">
                  {{ 'base-data.' + project.type | translate | titlecase }}
                </label>
              </div>
            </div>

            <div class="form-group row" *ngIf="project?.reference_nr">
              <label class="col-sm-12 col-md-3 control-label">
                {{ 'movers_complete.inventory.client.reference_nr.label' | translate }}
              </label>
              <div class="col-sm-12 col-md-9">
                <cap-input class="input" [disabled]="true" name="reference_nr" [placeholder]="project?.reference_nr">
                </cap-input>
              </div>
            </div>

            <div class="form-group row" *ngIf="client?.arent_order_number">
              <label class="col-sm-12 col-md-3 control-label">
                {{ 'movers_complete.inventory.client.arent_order_number.label' | translate }}
              </label>
              <div class="col-sm-12 col-md-9">
                <div class="form-control-static">{{ client.arent_order_number }}</div>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{ 'has-error': errors.status || (showErrors && this.form.controls['status'].invalid) }">
              <label class="col-sm-12 col-md-3 control-label" asteriks>
                {{ 'movers_complete.inventory.client.status.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-dropdown
                  formControlName="status"
                  [disabled]="disabled"
                  [placeholder]="'button.select' | translate"
                  [showClear]="true"
                  [options]="statusList">
                </cap-dropdown>

                <span class="help-block m-b-none" *ngIf="errors.status">{{ errors.status[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['status'].invalid">
                  {{ 'movers_complete.inventory.client.status.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error': errors.accountmanager_id || (showErrors && this.form.controls['accountmanager_id'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label d-flex" asteriks>
                {{ 'movers_complete.inventory.client.accountmanager.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-dropdown
                  formControlName="accountmanager_id"
                  [disabled]="disabled"
                  [showClear]="true"
                  [required]="true"
                  [placeholder]="'movers_complete.inventory.client.accountmanager.placeholder' | translate"
                  [options]="accountManagersList">
                </cap-dropdown>

                <span class="help-block m-b-none" *ngIf="errors.accountmanager_id">{{
                  errors.accountmanager_id[0]
                }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['accountmanager_id'].invalid">
                  {{ 'movers_complete.inventory.client.accountmanager.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error': errors.executor_id || (showErrors && this.form.controls['executor_id'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label">
                {{ 'movers_complete.inventory.client.executor.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-dropdown
                  formControlName="executor_id"
                  [disabled]="disabled"
                  [showClear]="true"
                  [placeholder]="'movers_complete.inventory.client.executor.placeholder' | translate"
                  [options]="executorList">
                </cap-dropdown>

                <span class="help-block m-b-none" *ngIf="errors.executor_id">{{ errors.executor_id[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['executor_id'].invalid">
                  {{ 'movers_complete.inventory.client.executor.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{ 'has-error': errors.name || (showErrors && this.form.controls['name'].invalid) }">
              <label class="col-sm-12 col-md-3 control-label d-flex" asteriks>
                {{ 'movers_complete.inventory.client.name.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-autocomplete
                  [disabled]="disabled"
                  [required]="true"
                  formControlName="name"
                  [suggestionsFilter]="filteredClients"
                  [field]="'label'"
                  (completeMethod)="searchClient($event)">
                </cap-autocomplete>

                <span class="help-block m-b-none" *ngIf="errors.name">{{ errors.name[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['name'].invalid">
                  {{ 'movers_complete.inventory.client.name.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error': errors.relation_group_id || (showErrors && this.form.controls['relation_group_id'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label d-flex" asteriks>
                {{ 'movers_complete.inventory.client.relation_group.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-dropdown
                  formControlName="relation_group_id"
                  [disabled]="disabled"
                  [showClear]="true"
                  [placeholder]="'movers_complete.inventory.client.relation_group.placeholder' | translate"
                  [options]="relationGroups">
                </cap-dropdown>

                <span class="help-block m-b-none" *ngIf="errors.relation_group_id">{{
                  errors.relation_group_id[0]
                }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['relation_group_id'].invalid">
                  {{ 'movers_complete.inventory.client.relation_group.placeholder' | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="right-column col-sm-12 col-md-6">
            <div class="form-group row justify-content-end pr-3 fixed-height">
              <cap-button
                styleClass="primary"
                type="button"
                [disabled]="disabled"
                (onClick)="openTimeline()"
                [label]="'movers_complete.inventory.client.button.timeline' | translate">
              </cap-button>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error':
                  errors.location_id || (showErrors && this.form.controls['location_id'].invalid) || !isValidLocationId
              }">
              <label class="col-sm-12 col-md-3 control-label d-flex" asteriks>
                {{ 'movers_complete.inventory.client.location.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-dropdown
                  formControlName="location_id"
                  [disabled]="disabled"
                  [showClear]="true"
                  [required]="true"
                  [placeholder]="'movers_complete.inventory.client.location.placeholder' | translate"
                  [options]="locations">
                </cap-dropdown>

                <span class="help-block m-b-none" *ngIf="errors.location_id">{{ errors.location_id[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['location_id'].invalid">
                  {{ 'movers_complete.inventory.client.location.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error':
                  errors.relation_group_id ||
                  errors.description ||
                  (showErrors && this.form.controls['description'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label">
                {{ 'movers_complete.inventory.client.description.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-input class="input" [disabled]="disabled" formControlName="description"> </cap-input>

                <span class="help-block m-b-none" *ngIf="errors.description">{{ errors.description[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['description'].invalid">
                  {{ 'movers_complete.inventory.client.description.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error': errors.delivery_date || (showErrors && this.form.controls['delivery_date'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label d-flex" [asteriks]="hasBoelaarsEnLambert">
                {{ 'movers_complete.inventory.client.delivery_date.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-calendar
                  [locale]="localeNL"
                  dateFormat="dd-mm-yy"
                  formControlName="delivery_date"
                  [required]="true"
                  [minDate]="currentDate"
                  [disabled]="disabled">
                </cap-calendar>

                <span class="help-block m-b-none" *ngIf="errors.delivery_date">{{ errors.delivery_date[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['delivery_date'].invalid">
                  {{ 'movers_complete.inventory.client.delivery_date.placeholder' | translate }}
                </span>
              </div>
            </div>

            <div
              class="form-group row"
              [ngClass]="{
                'has-error':
                  errors.relation_group_id || errors.remarks || (showErrors && this.form.controls['remarks'].invalid)
              }">
              <label class="col-sm-12 col-md-3 control-label">
                {{ 'movers_complete.inventory.client.remarks.label' | translate }}
              </label>

              <div class="col-sm-12 col-md-9">
                <cap-input class="input" [disabled]="disabled" formControlName="remarks"> </cap-input>

                <span class="help-block m-b-none" *ngIf="errors.remarks">{{ errors.remarks[0] }}</span>

                <span class="help-block m-b-none" *ngIf="showErrors && this.form.controls['remarks'].invalid">
                  {{ 'movers_complete.inventory.client.remarks.placeholder' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <div class="separator"></div>
</div>
