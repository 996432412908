<div class="steps-wrapper">
  <p-steps [model]="routes" [activeIndex]="currentIndex" [readonly]="false"></p-steps>
</div>

<div class="wrapper wrapper-content d-flex flex-column">
  <div class="main-step-content">
    <router-outlet></router-outlet>
  </div>

  <div class="row pb-3 pt-3">
    <div class="col-12 flow-footer">
      <cap-button
        *ngIf="currentIndex > 0"
        type="button"
        styleClass="secondary"
        (onClick)="previous()"
        [label]="'movers_complete.inventory.button.previous' | translate">
      </cap-button>

      <cap-button
        *ngIf="onlineMode"
        type="submit"
        styleClass="primary"
        label="{{ labelSaveProgress }}"
        (onClick)="save()">
      </cap-button>

      <cap-button
        *ngIf="onlineMode && currentIndex === routes.length - 1"
        type="submit"
        styleClass="primary"
        label="{{ labelSave }}"
        (onClick)="save()">
      </cap-button>

      <cap-button
        *ngIf="currentIndex < routes.length - 1"
        type="button"
        styleClass="primary"
        (onClick)="next()"
        [label]="'movers_complete.inventory.button.next' | translate">
      </cap-button>
    </div>
  </div>
</div>
