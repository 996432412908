import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { DexieStore } from '@domain/dexie-store';
import { ExactOnlineService } from '@shared/services/exact-online.service';

@Component({
  selector: 'app-settings-overview',
  templateUrl: 'settings-overview.component.html',
})
export class SettingsOverviewComponent {
  public constructor(
    private api: ApiServiceWithLoaderService,
    private exactOnlineService: ExactOnlineService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  public onDatabaseReset(): void {
    this.confirmationService.confirm({
      message: 'Wilt u de database resetten?<br/>Nieuwe projecten of wijzigingen gaan hierdoor verloren.',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await DexieStore.getInstance()
          .reset()
          .then(async (_) => {
            window.location.reload();
          });
      },
    });
  }

  public onSyncNow(): void {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u nu wilt sychroniseren?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: async () => {
        await this.router.navigateByUrl('/');
      },
    });
  }

  public onLoginExactOnline(): void {
    this.exactOnlineService.getUrl().subscribe({
      next: (result) => {
        if (result) {
          window.location.href = result.url;
        }
      },
      error: (err) => {
        // Show login error message
      },
    });
  }
}
