import { ManageMaterialsDetailComponent } from '@features/manage/components/materials/materials-detail/materials-detail.component';
import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-materials-list',
  templateUrl: './materials-list.component.html',
  styleUrls: ['./materials-list.component.scss'],
})
export class ManageMaterialsListComponent {
  public listKey = 'list_materials';

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public addNewItem(): void {
    this.dialogService.open(ManageMaterialsDetailComponent, {
      header: this.translateService.instant('movers_complete.entity.material.single'),
    });
  }
}
