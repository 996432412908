import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventories-client-search',
  templateUrl: 'client-search.component.html',
})
export class InventoryClientSearchComponent implements OnInit {
  public tableOptions: OfflineTableOptions;
  private project: Project;

  public constructor(
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();

    this.tableOptions = new OfflineTableOptions({
      paging: true,
      columns: [{ title: 'Naam', name: 'name' }],
      url: '/client',
      tableName: 'clients',
    });
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + (this.project?.id || 'new') + '/client');
  }

  public async onRowClick(data: any): Promise<void> {
    await this.projectService.getClient(data.id);

    this.router.navigateByUrl('/admin/project/' + (this.project?.id || 'new') + '/client');
  }
}
