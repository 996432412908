<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="profile-element" dropdown>
          <!--<img alt="image" class="img-circle" src="assets/images/profile_small.jpg">-->
          <a dropdownToggle>
            <span class="block m-t-xs">
              <strong class="font-bold">{{ user ? user.name : 'Laden...' }}</strong>
            </span>
            <!--<span class="text-muted text-xs block">{{user ? user.role.name : 'Laden...'}} <b class="caret"></b> </span>-->
          </a>
        </div>

        <div class="logo-element">N</div>
      </li>
      <li [ngClass]="{ active: activeRoute('admin/customers') }">
        <a [routerLink]="['/admin/customers']"
          ><i class="fa fa-shopping-bag"></i> <span class="nav-label">Klanten</span></a
        >
      </li>
      <li [ngClass]="{ active: activeRoute('admin/users') }">
        <a [routerLink]="['/admin/users']"><i class="fa fa-users"></i> <span class="nav-label">Gebruikers</span></a>
      </li>
      <li [ngClass]="{ active: activeRoute('admin/projects') }">
        <a [routerLink]="['/admin/projects']"><i class="fa fa-tasks"></i> <span class="nav-label">Projecten</span></a>
      </li>
      <!--
      <li [ngClass]="{active: activeRoute('dashboards')}">
        <a href="#"><i class="fa fa-th-large"></i> <span class="nav-label">Dashboard examples</span> <span class="fa arrow"></span> </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('dashboards')}">
          <li [ngClass]="{active: activeRoute('dashboard1')}"><a [routerLink]="['/dashboards/dashboard1']">Dashboard v.1</a></li>
          <li [ngClass]="{active: activeRoute('dashboard2')}"><a [routerLink]="['/dashboards/dashboard2']">Dashboard v.2</a></li>
          <li [ngClass]="{active: activeRoute('dashboard3')}"><a [routerLink]="['/dashboards/dashboard3']">Dashboard v.3</a></li>
          <li [ngClass]="{active: activeRoute('dashboard4')}"><a [routerLink]="['/dashboards/dashboard4']">Dashboard v.4</a></li>
          <li [ngClass]="{active: activeRoute('dashboard5')}"><a [routerLink]="['/dashboards/dashboard5']">Dashboard v.5</a></li>
        </ul>
      </li>
      -->
    </ul>
  </div>
</nav>

<div class="emendis">
  <a href="http://www.emendis.nl" title="Emendis - Web, Mobile &amp; Internet of Things" target="_blank">
    <img
      [src]="'/assets/images/emendis-footer.png' | addMyTenant | async"
      onerror="this.src = '/assets/default/images/emendis-footer.png'"
      alt="emendis" />
  </a>
</div>
