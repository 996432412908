<nav class="header navbar navbar-static-top" role="navigation" #navbar>
  <a *ngIf="showActionsMenu" routerLink="admin/projects">
    <img onerror="this.src = '/assets/images/emendis-footer.png'" alt="emendis" [src]="tenantLogo" />
  </a>

  <a *ngIf="!showActionsMenu">
    <img onerror="this.src = '/assets/images/emendis-footer.png'" alt="emendis" [src]="tenantLogo" />
  </a>

  <span class="title">{{ pageTitle }}</span>

  <cap-action-menu
    *ngIf="showActionsMenu"
    buttonIcon="fas fa-solid fa-bars"
    [openPosition]="'left-bottom'"
    [actionItems]="actionMenuItems">
  </cap-action-menu>
</nav>
