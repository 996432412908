<div #modal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog popup-outer" role="document">
    <div class="modal-content popup-inner">
      <div class="w-100">
        <div class="modal-header ibox-title justify-content-between">
          <h5 class="modal-title">Handtekening plaatsen</h5>
          <span class="fa fa-times pull-right" (click)="hideForm()"></span>
        </div>

        <div class="modal-body">
          <form class="signature">
            <signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="signatureStart()">
            </signature-pad>
          </form>

          <i class="fas fa-refresh refresh-icon" (click)="clearSignature()"></i>
          <i class="fas fa-trash" (click)="deleteSignature()"></i>
        </div>

        <div class="modal-footer justify-content-between">
          <cap-button label="Annuleren" styleClass="default" data-dismiss="modal" (onClick)="hideForm()"></cap-button>
          <cap-button label="Opslaan" styleClass="primary" (onClick)="onSubmit()"></cap-button>
        </div>
      </div>
    </div>
  </div>
</div>
