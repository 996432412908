<form [formGroup]="form" class="w-100">
  <div class="inventory-item-details">
    <div class="modal-body">
      <section class="d-flex border-bottom details">
        <div class="d-flex">
          <cap-input-number
            *ngIf="!disabled"
            [showButtons]="true"
            class="input-increment"
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            decrementButtonClass="default"
            incrementButtonClass="default"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="fa fa-minus"
            [min]="0"
            formControlName="amount"
            [ngModel]="inventoryItem.amount"
            (ngModelChange)="changeAmount($event)">
          </cap-input-number>

          <span class="d-flex flex-grow-1 align-items-center justify-content-center checkbox">
            Totaal&nbsp;<b class="ml-2 mr-2">{{ inventoryItem.volume * inventoryItem.amount | number }}</b
            >&nbsp;m<sup>3</sup>
          </span>
        </div>
      </section>

      <section>
        <div class="d-flex mt-2 align-baseline">
          <label class="col-sm-12 col-md-3 control-label ml-0" asteriks>
            {{ 'movers_complete.inventory.overview.price.label' | translate }}
          </label>

          <div class="col-sm-6 col-md-4">
            <cap-input class="input" [disabled]="disabled" formControlName="price"> </cap-input>
          </div>
        </div>

        <div class="d-flex">
          <cap-textarea
            class="mr-20"
            [rows]="4"
            [label]="'movers_complete.inventory.client.remarks.label' | translate"
            [cols]="40"
            formControlName="remarks"
            [readonly]="disabled">
          </cap-textarea>

          <div class="d-flex flex-column icons">
            <i class="far fa-image" (click)="compressFile()" *ngIf="!disabled"></i>
          </div>

          <img *ngIf="form.value.image" [src]="form.value.image" class="uploaded-image" alt="Uploaded image" />
        </div>
      </section>

      <div class="d-flex justify-content-between">
        <cap-button
          [disabled]="disabled"
          label="Verwijderen"
          styleClass="secondary"
          icon="fas fa-trash"
          (onClick)="deleteItem()"></cap-button>

        <cap-button
          label="Opslaan & Sluiten"
          styleClass="primary"
          [disabled]="this.form.invalid || disabled"
          (onClick)="closeModalAndSave()"></cap-button>
      </div>
    </div>
  </div>
</form>
