import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { TableFilterComponent } from './components/table-filter/table-filter.component';

@NgModule({
  declarations: [TableFilterComponent],
  imports: [
    CommonModule,
    CapturumSharedModule,
    CapturumInputModule,
    CapturumDropdownModule,
    FormsModule,
    CapturumMultiSelectModule,
    ReactiveFormsModule,
    CapturumCalendarModule,
  ],
  exports: [TableFilterComponent],
})
export class TableModule {}
