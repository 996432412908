import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InventoryFlowValidationService {
  private validationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public getValidation(): Observable<boolean> {
    return this.validationSubject.asObservable();
  }

  public setValidation(validation: boolean): void {
    this.validationSubject.next(validation);
  }
}
