import { DomainModel } from '@domain/domain.model';

export class MachineType extends DomainModel {
  // Configuration
  public entity = 'machineType';
  public table = 'machine_types';
  public sync = true;

  // Fields
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
