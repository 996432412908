<div class="ibox scroll-fix">
  <div class="ibox-title">
    <h5>Tenant</h5>
    <span class="fa fa-times pull-right" (click)="onCancel()"></span>
  </div>

  <ng-container *ngIf="form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
      <div class="ibox-content">
        <app-loading *ngIf="loading"></app-loading>

        <div *ngIf="!loading">
          <div class="form-group">
            <label class="col-sm-12" asteriks>{{ 'movers_complete.manage.tenants.popup.name' | translate }}</label>
            <div class="col-sm-12" [ngClass]="{ 'has-error': showErrors && !this.form.controls['name'].valid }">
              <input type="text" class="form-control" placeholder="Naam" formControlName="name" />
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{ 'movers_complete.manage.tenants.popup.key' | translate }}</label>
            <div class="col-sm-12" [ngClass]="{ 'has-error': showErrors && !this.form.controls['key'].valid }">
              <input
                type="text"
                class="form-control"
                placeholder="Key"
                formControlName="key"
                [readOnly]="this.tenant.key" />
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{ 'movers_complete.manage.tenants.popup.host' | translate }}</label>
            <div class="col-sm-12" [ngClass]="{ 'has-error': showErrors && !this.form.controls['host'].valid }">
              <input type="text" class="form-control" placeholder="Host" formControlName="host" />
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{
              'movers_complete.manage.tenants.popup.planning-module' | translate
            }}</label>
            <div
              class="col-sm-12"
              [ngClass]="{ 'has-error': showErrors && !this.form.controls['api_planning_module'].valid }">
              <p-checkbox class="form-control" formControlName="api_planning_module" binary="true"> </p-checkbox>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{
              'movers_complete.manage.tenants.popup.exactonline' | translate
            }}</label>
            <div
              class="col-sm-12"
              [ngClass]="{ 'has-error': showErrors && !this.form.controls['api_exact_online'].valid }">
              <p-checkbox class="form-control" formControlName="api_exact_online" binary="true"> </p-checkbox>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{
              'movers_complete.manage.tenants.popup.boelaars-lambert' | translate
            }}</label>
            <div
              class="col-sm-12"
              [ngClass]="{ 'has-error': showErrors && !this.form.controls['api_boelaars_en_lambert'].valid }">
              <p-checkbox class="form-control" formControlName="api_boelaars_en_lambert" binary="true"> </p-checkbox>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12" asteriks>{{ 'movers_complete.manage.tenants.popup.arent' | translate }}</label>
            <div class="col-sm-12" [ngClass]="{ 'has-error': showErrors && !this.form.controls['api_arent'].valid }">
              <p-checkbox class="form-control" formControlName="api_arent" binary="true"> </p-checkbox>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12">{{ 'movers_complete.manage.tenants.popup.private-files' | translate }}</label>
            <div class="col-sm-12">
              <cap-file-upload
                [id]="'private'"
                [multiple]="true"
                class="form-control"
                (onFileChange)="addFile($event, 'private')"></cap-file-upload>
              <cap-file-preview-list
                [files]="privateFiles$ | async"
                [showDelete]="true"
                (onDeleteClick)="removeFile($event, 'private')"></cap-file-preview-list>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-12">{{ 'movers_complete.manage.tenants.popup.business-files' | translate }}</label>
            <div class="col-sm-12">
              <cap-file-upload
                [id]="'business'"
                [multiple]="true"
                class="form-control"
                (onFileChange)="addFile($event, 'business')"></cap-file-upload>
              <cap-file-preview-list
                [files]="businessFiles$ | async"
                [showDelete]="true"
                (onDeleteClick)="removeFile($event, 'business')"></cap-file-preview-list>
            </div>
          </div>

          <div class="form-group quotation-config">
            <div class="row">
              <h5>{{ 'movers_complete.manage.tenants.popup.invoice-configuration' | translate }}</h5>
            </div>

            <div class="row">
              <div class="form-group col-sm-6 company-details">
                <h5 class="form-group col-sm-12">
                  {{ 'movers_complete.manage.tenants.popup.company-info' | translate }}
                </h5>
                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.name' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_name'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Naam"
                      formControlName="config_quotation_company_details_name" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.street-and-housenr' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_street'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Straatnaam + huisnummer"
                      formControlName="config_quotation_company_details_street" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.place' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_city'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Plaats"
                      formControlName="config_quotation_company_details_city" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.postcode' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_zipcode'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Postcode"
                      formControlName="config_quotation_company_details_zipcode" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.phone' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_phone'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Telefoonnummer"
                      formControlName="config_quotation_company_details_phone" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.company-info.website' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_company_details_website'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Website"
                      formControlName="config_quotation_company_details_website" />
                  </div>
                </div>
              </div>

              <div class="form-group col-sm-6 payment-details">
                <h5 class="form-group col-sm-12">
                  {{ 'movers_complete.manage.tenants.popup.payment-info.label' | translate }}
                </h5>
                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.payment-info.bic' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_payment_details_bic'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="BIC"
                      formControlName="config_quotation_payment_details_bic" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.payment-info.email' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_payment_details_email'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      formControlName="config_quotation_payment_details_email" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.payment-info.iban' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_payment_details_iban'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="IBAN"
                      formControlName="config_quotation_payment_details_iban" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.payment-info.coc' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_payment_details_kvk'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="KVK-nummer"
                      formControlName="config_quotation_payment_details_kvk" />
                  </div>
                </div>

                <div class="form-group vertical-align col-sm-12">
                  <label class="col-sm-3" asteriks>{{
                    'movers_complete.manage.tenants.popup.payment-info.vat' | translate
                  }}</label>
                  <div
                    class="col-sm-9"
                    [ngClass]="{
                      'has-error': showErrors && !this.form.controls['config_quotation_payment_details_vat'].valid
                    }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="BTW-nummer"
                      formControlName="config_quotation_payment_details_vat" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="stylesheet-config">
            <h5>{{ 'movers_complete.manage.tenants.popup.styling-configuration' | translate }}</h5>

            <div class="form-group">
              <label class="col-sm-12" asteriks>{{
                'movers_complete.manage.tenants.popup.styling.primary-color' | translate
              }}</label>
              <div
                class="col-sm-12"
                [ngClass]="{ 'has-error': showErrors && !this.form.controls['config_stylesheet_primary_color'].valid }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="#00999c"
                  formControlName="config_stylesheet_primary_color" />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-12">{{ 'movers_complete.manage.tenants.popup.logo' | translate }}</label>
              <div class="col-sm-12">
                <cap-file-upload
                  [id]="'logo'"
                  [multiple]="false"
                  [accept]="'image/*, .pdf'"
                  class="form-control"
                  (onFileChange)="addFile($event, 'logo')"></cap-file-upload>
                <cap-file-preview-list
                  [files]="logoFiles$ | async"
                  [showDelete]="true"
                  (onDeleteClick)="removeFile($event, 'logo')"></cap-file-preview-list>
              </div>
            </div>
          </div>

          <div class="settings">
            <h5>Settings</h5>

            <ng-container formArrayName="settings">
              <div *ngFor="let setting of tenant.settings as settings; let i = index" [formGroupName]="i.toString()">
                <div [ngSwitch]="settings[i].type" class="form-group">
                  <label class="col-sm-12">{{ settings[i].name }}</label>

                  <div class="col-sm-12">
                    <p-checkbox
                      *ngSwitchCase="'boolean'"
                      [label]="settings[i].description"
                      class="form-control"
                      formControlName="value"
                      binary="true">
                    </p-checkbox>

                    <input
                      *ngSwitchDefault
                      [placeholder]="settings[i].description"
                      type="text"
                      class="form-control"
                      formControlName="value" />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="ibox-footer padding-sides-0 padding-bottom-0">
          <button class="btn btn-primary m-r-xs" type="button" (click)="onSubmit()">
            <i class="fa fa-check"></i> Opslaan
          </button>

          <button class="btn btn-white" type="button" (click)="onCancel()"><i class="fa fa-ban"></i> Annuleren</button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
