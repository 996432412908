<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="box-content-wrap">
        <div class="ibox-title">
          <h5>{{ 'setting.overview.title' | translate }}</h5>
        </div>
        <div class="ibox-content">
          <div class="row">
            <button class="btn btn-primary m-r-xs" (click)="onDatabaseReset()">
              <i class="fa fa-refresh"></i> {{ 'movers_complete.settings.database_reset.label' | translate }}
            </button>
            <button class="btn btn-primary m-r-xs" (click)="onSyncNow()">
              <i class="fa fa-refresh"></i> {{ 'movers_complete.settings.sync.label' | translate }}
            </button>
            <button class="btn btn-primary" (click)="onLoginExactOnline()">
              <i class="fa fa-refresh"></i> {{ 'movers_complete.settings.exact_online_login.label' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
