import { AuthService } from '@capturum/auth';
import { Injectable } from '@angular/core';
import { Observable } from '@root/node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { HttpClient } from '@angular/common/http';
import { ListOptions } from '@capturum/api';

@Injectable()
export class QuotationSignService extends ApiServiceWithLoaderService {
  public endpoint = 'quotation/sign';

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  public getQuotation(quotationSignId: string, projectId: string): Observable<any> {
    return this.get(`/${this.endpoint}/${quotationSignId}/${projectId}`);
  }

  public getQuotationChecks(quotationId: string, includes: ListOptions): Observable<any> {
    return this.get(`/quotation/${quotationId}`, includes);
  }

  public sendQuotationChecks(quotationId: string, projectId: string, quotation_checks: string[]): Observable<any> {
    return this.put(`/quotation/${quotationId}`, {
      project_id: projectId,
      quotation_checks,
    });
  }

  public signQuotation(data: { quotation_sign_id: string; quotation: any }): Observable<any> {
    return this.post(`/${this.endpoint}`, data);
  }

  public postValidateQuotation(data: any): Observable<any> {
    return this.post(`/verify/${this.endpoint}`, [data]);
  }
}
