import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OnlineStatusService implements OnDestroy {
  public online$: Observable<boolean>;
  private _online = new BehaviorSubject<boolean>(false);
  private url = '';
  private allowedHttpStatus: number[] = [200, 201, 202, 203, 204, 205, 206];

  constructor(private http: HttpClient) {
    this.online$ = this._online.asObservable();
    this.url = environment.baseUrl + '/auth/login';
    this._online.next(navigator.onLine);
    this.setUpListeners();
    this.checkStatus();
  }

  public checkStatus(): Observable<boolean> {
    if (!navigator.onLine) {
      return of(false);
    } else {
      return this.checkHttpConnection().pipe(
        map((data: any) => {
          return data.status && this.allowedHttpStatus.indexOf(data.status) !== -1;
        })
      );
    }
  }

  public ngOnDestroy() {
    window.removeEventListener('offline', this.switchOffline);
    window.removeEventListener('online', this.switchOnline);
  }

  private checkHttpConnection() {
    return this.http.options(this.url, {
      observe: 'response',
    });
  }

  private setUpListeners() {
    window.addEventListener('offline', this.switchOffline.bind(this));
    window.addEventListener('online', this.switchOnline.bind(this));
  }

  private switchOnline() {
    this._online.next(true);
  }

  private switchOffline() {
    this._online.next(false);
  }
}
