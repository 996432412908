export enum InventoryType {
  barrel = 'barrel',
  movingBoxes = 'verhuisdozen',
  BookBoxes = 'Boekendozen',
  wardrobeBoxes = 'garderobedozen',
  painting = 'schilderij',
  dkb = 'dkb',
  mb = 'mb',
  computerBox = 'computerbak',
  rolco = 'rolco',
}
