import { Component, OnDestroy, OnInit } from '@angular/core';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Subject } from '@node_modules/rxjs';
import { SelectItem } from 'primeng/api';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Inventory } from '@domain/models/inventory.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Tenant } from '@domain/models/tenant.model';
import { InventoryType } from '@core/enums/inventory-type.enum';
import { ProjectType } from '@core/enums/project-type.enum';
import { TenantConfigQuotationCompanyDetails } from '@domain/models/tenant-config-quotation-company-details.model';
import { TenantConfigQuotationPaymentDetails } from '@domain/models/tenant-config-quotation-payment-details.model';
import { RelationGroup } from '@domain/models/relation-group.model';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class InventoryOverviewComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: ProjectMaterial[] = [];
  public inventories: Inventory[] = [];
  public addresses: Address[] = [];
  public executorList: SelectItem[] = [];
  public contacts: Contact[] = [];
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public logo = '';

  public volumeTotal = 0;
  public meterboxTotal = 0;
  public invoiceFacturationId = 0;
  public tenant: Tenant;
  public inventoryType = InventoryType;
  public projectType = ProjectType;
  public relationGroup: RelationGroup;

  public quotationCompanyDetails: TenantConfigQuotationCompanyDetails[];
  public quotationPaymentDetails: TenantConfigQuotationPaymentDetails[];

  private destroy$: Subject<void> = new Subject<void>();
  private tenantCode: string;

  constructor(
    private projectService: ProjectService,
    private dataService: DataService,
    private synchronisationService: SynchronisationService
  ) {
    this.quotationCompanyDetails = this.synchronisationService.getTenantConfigQuotation('company_details');
    this.quotationPaymentDetails = this.synchronisationService.getTenantConfigQuotation('payment_details');
  }

  public async ngOnInit(): Promise<void> {
    this.logo = this.synchronisationService.getTenantLogo();
    await this.getAddressTypes();
    await this.getExecutors();

    this.synchronisationService
      .getTenantCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantCode) => {
        return (this.tenantCode = tenantCode);
      });
    this.synchronisationService.myTenant$.pipe(takeUntil(this.destroy$)).subscribe((tenant) => {
      return (this.tenant = tenant);
    });

    this.project = await this.projectService.getProject();
    this.materials = this.project.materials;
    this.inventories = this.project.inventories;
    this.addresses = this.project.addresses;
    this.contacts = this.project.contacts;

    await this.getSelectedActivities();
    await this.getSelectedSpecialties();
    await this.getRelationGroup();

    this.volumeTotal = this.projectService.calculateVolume();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();

    this.projectService.projectLoaded.pipe(takeUntil(this.destroy$)).subscribe((project: Project) => {
      this.project = project;
      this.materials = project.materials;
      this.inventories = project.inventories;
      this.addresses = project.addresses;
      this.contacts = project.contacts;

      this.getSelectedActivities();
      this.getSelectedSpecialties();
      this.getRelationGroup();

      this.volumeTotal = this.projectService.calculateVolume();
      this.meterboxTotal = this.projectService.calculateMeterboxTotal();
      this.initMaterials();
    });

    this.initMaterials();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Returns the name of executor, filtered on the executorList
   * If no executors available, return the executor ID
   * @param projectExecutorId: number
   */
  public getCorrectExecutor(projectExecutorId: number): string {
    if (this.executorList.length > 0) {
      const result = this.executorList.find((executor: SelectItem) => {
        return executor.value === projectExecutorId;
      });

      if (result) {
        return result.label;
      }
    }

    return projectExecutorId ? projectExecutorId.toString() : 'n.v.t.';
  }

  /**
   * Returns the address in one complete string
   * @param address: Address
   */
  public getDisplayAddress(address: Address): string {
    /** Return email address when type ID is facturationaddress */
    if (address.address_type_id === this.invoiceFacturationId) {
      return address.email;
    }

    const addressParts = [
      `${address.street || ''} ${address.housenumber || ''} ${
        address.housenumber_add ? '- ' + address.housenumber_add : ''
      }`.trim(),
      `${address.zipcode || ''} ${address.city || ''}`.trim(),
      `${address.country}`.trim(),
    ];

    return addressParts.reduce((prev, current) => {
      return (prev && current ? prev + ', ' : prev) + current;
    });
  }

  /**
   * Export to PDF
   */
  public printOverview(): void {
    window.print();
  }

  /**
   * Get total price of Room
   *
   * @param id
   */
  public getTotalPriceOfRoom(id: string): number {
    let total = 0;

    for (const inventory of this.project.inventories) {
      if (inventory.items && inventory.id === id) {
        for (const item of inventory.items) {
          if (item.amount) {
            total += item.amount * item.price;
          }
        }
      }
    }

    return total;
  }

  /**
   * Retrieve the executors from data Service
   */
  private async getExecutors(): Promise<void> {
    const queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false,
    });

    const result = await this.dataService.get('users', queryOptions, '/users/list');

    result.forEach((item) => {
      this.executorList.push({ label: item.name, value: item.id });
    });

    this.executorList.sort((one, two) => {
      return one > two ? 1 : -1;
    });
  }

  private async getRelationGroup(): Promise<void> {
    if (this.project.client.relation_group_id) {
      this.relationGroup = await RelationGroup.query.get(this.project.client.relation_group_id);
    }
  }

  /**
   * Retrieve the selected / active Activities
   */
  private getSelectedActivities(): void {
    this.activities = [];

    this.project.activities.forEach((projectActivity: ProjectActivity) => {
      if (projectActivity.applicable) {
        this.activities.push(projectActivity);
      }
    });
  }

  /**
   * Retrieve the selected / active Activities
   */
  private getSelectedSpecialties(): void {
    this.specialties = [];

    this.project.specialties.forEach((projectSpecialty: ProjectSpecialty) => {
      if (projectSpecialty.applicable) {
        this.specialties.push(projectSpecialty);
      }
    });
  }

  /**
   * Retrieve the Address Types
   */
  private async getAddressTypes(): Promise<void> {
    const result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');

    for (const item of result) {
      // @todo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5
      if (item.name === 'Facturatieadres') {
        this.invoiceFacturationId = item.id;
      }
    }
  }

  /**
   * Init the Materials
   */
  private initMaterials(): void {
    this.materials.forEach(async (material: ProjectMaterial) => {
      await material.init();
    });
  }
}
