import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-overview',
  templateUrl: 'user-overview.component.html',
})
export class UserOverviewComponent {
  public listkey = 'list_user';

  public constructor(private router: Router) {}

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/users(popup:admin/user/${row.id})`);
  }

  public onAddClick() {
    this.router.navigateByUrl('/admin/users(popup:admin/user/add)');
  }
}
