import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { UserOverviewComponent } from './user-overview.component';
import { UserDetailComponent } from './user-detail.component';
import { RouterModule } from '@angular/router';
import { ProjectModule } from '@features/project/project.module';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';

@NgModule({
  declarations: [UserOverviewComponent, UserDetailComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ProjectModule,
    CheckboxModule,
    DropdownModule,
    CapturumDropdownModule,
    CapturumInputModule,
    CapturumButtonModule,
    CapturumListRendererModule,
  ],
  exports: [UserOverviewComponent],
})
export class UserModule {}
