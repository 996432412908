import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { DisplayProjectActivity, ProjectActivity } from '@domain/models/project-activity.model';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Address } from '@domain/models/address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { takeUntil } from '@node_modules/rxjs/operators';
import { MapItem } from '@capturum/ui/api';

interface OptionsDetailFormGroup {
  own_description_activities: FormControl<string>;
  client_description_activities: FormControl<string>;
  insurance_certificate_link: FormControl<string>;
  delivery_date: FormControl<string | Date>;
}

@Component({
  selector: 'app-inventory-options-detail',
  styleUrls: ['./options-detail.component.scss'],
  templateUrl: 'options-detail.component.html',
})
export class InventoryOptionsDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup<OptionsDetailFormGroup>;
  public project = new Project({});
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public disabled = true;
  public addresses: Address[] = [];
  public addressOptions: { label: string; value: string }[] = [];
  public displayActivityArray: DisplayProjectActivity[];
  public value: Date;
  public numberTrucks: SelectItem[];
  public numberMovers: SelectItem[];
  public localeNL: CalendarLocale = new CalendarLocale();
  public currentDate: Date = new Date();
  public activityTypeOptions: MapItem[] = [
    {
      label: 'Ja',
      value: 'private',
    },
    {
      label: 'Nee',
      value: 'business',
    },
  ];

  private subscriptionProjectLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(private projectService: ProjectService, private formBuilder: UntypedFormBuilder) {
    this.projectService.projectIsReadOnly.pipe(takeUntil(this.destroy$)).subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.numberMovers = [];
    this.numberMovers.push({ label: '1', value: '1' });
    this.numberMovers.push({ label: '2', value: '2' });
    this.numberMovers.push({ label: '3', value: '3' });
    this.numberMovers.push({ label: '4', value: '4' });
    this.numberMovers.push({ label: '5', value: '5' });
    this.numberMovers.push({ label: '8', value: '8' });
    this.numberMovers.push({ label: '10', value: '10' });
    this.numberMovers.push({ label: '15', value: '15' });
    this.numberMovers.push({ label: '20', value: '20' });
    this.numberMovers.push({ label: '25', value: '25' });
    this.numberMovers.push({ label: '50', value: '50' });

    this.numberTrucks = this.numberMovers;
    this.displayActivityArray = [];
  }

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    // TODO: When navigation from 'Klant' to 'Options' tab directly after changing 'Relatiegroep' options are not refreshed yet
    await this.project.loadSpecialties();

    this.addresses = this.project.addresses;
    this.specialties = this.project.specialties;
    this.activities = this.project.activities;

    this.updateActivities();
    this.initAddresses();

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(async (project: Project) => {
      this.project = project;
      this.addresses = project.addresses;
      this.specialties = this.project.specialties;
      this.activities = this.project.activities;

      this.projectService.setCurrentClient(this.project.client);
      this.updateActivities();
      this.initForm();
      this.initAddresses();
    });

    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      own_description_activities: this.formBuilder.control(this.project.own_description_activities || ''),
      client_description_activities: this.formBuilder.control(this.project.client_description_activities || ''),
      insurance_certificate_link: this.formBuilder.control(this.project.insurance_certificate_link || ''),
      delivery_date: this.formBuilder.control(this.project.delivery_date || ''),
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.projectService.project) {
      this.projectService.project.own_description_activities = this.form.value.own_description_activities;
      this.projectService.project.client_description_activities = this.form.value.client_description_activities;
      this.projectService.project.delivery_date = this.form.value.delivery_date;

      // ToDo: Only save used project specialties
      // this.specialties = this.specialties.filter(specialty => specialty.applicable);

      await this.projectService.saveActivities(this.activities);
      await this.projectService.saveSpecialties(this.specialties);
      this.projectService.setProjectUpdated();
      await this.projectService.saveProject();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleSwitch(projectSpecialtyId: string): void {
    this.specialties.find((item) => {
      if (item.id === projectSpecialtyId) {
        item.applicable = !item.applicable;

        return true;
      }
    });
  }

  public setApplicable(activity: any): void {
    activity.activities.forEach((activityItem: ProjectActivity) => {
      activityItem.applicable = activity.applicable;
    });
  }

  public async onRemoveActivityClick(activity: ProjectActivity): Promise<void> {
    if (!this.disabled) {
      const index = this.project.activities.indexOf(activity);

      this.project.activities.splice(index, 1);

      // Also delete from displayArray
      const indexDisplayArray = this.displayActivityArray.findIndex((item) => {
        return item.id === activity.activity_id;
      });

      if (indexDisplayArray > -1) {
        const indexActivity = this.displayActivityArray[indexDisplayArray].activities.findIndex((item) => {
          return item.id === activity.id;
        });

        this.displayActivityArray[indexDisplayArray].activities.splice(indexActivity, 1);

        // Delete from database
        await this.projectService.deleteProjectActivity(activity.id);

        this.updateActivities();
        this.projectService.setProjectUpdated();
      }
    }
  }

  public async onAddActivityClick(type: number): Promise<void> {
    if (!this.disabled) {
      const newActivity = new ProjectActivity({
        project_id: this.project.id,
        activity_id: type,
        applicable: true,
      });

      await newActivity.init();

      this.project.activities.push(newActivity);
      this.updateActivities();
      this.projectService.setProjectUpdated();
    }
  }

  private updateActivities(): void {
    if (!this.project || !this.project.activities) {
      return;
    }

    const activityIdArray = [];
    // Slice original array to remove reference
    const projectActivities = this.project.activities.slice();

    // Read all activity ID's and put them in list to check later
    for (const element of projectActivities) {
      if (!activityIdArray.includes(element.activity_id)) {
        activityIdArray.push({
          id: element.activity_id,
          name: element.activity.name,
          applicable: element.applicable || false,
        });
      }
    }

    // Check for all activity ID's in list, put every activity in array to corresponding activity ID
    for (const key of activityIdArray) {
      const activities = projectActivities.filter((activity) => {
        return activity.activity_id === key.id;
      });
      const displayActivity = {
        id: key.id,
        activities,
        name: key.name,
        applicable: projectActivities
          .filter((activity) => {
            return activity.activity_id === key.id;
          })
          .some((activity) => {
            return activity.applicable;
          }),
      };
      const displayActivityIndex = this.displayActivityArray.findIndex((activity) => {
        return activity.id === key.id;
      });

      if (displayActivityIndex < 0) {
        this.displayActivityArray.push(displayActivity);
      } else {
        this.displayActivityArray[displayActivityIndex].activities = activities;
      }
    }
  }

  private initAddresses(): void {
    this.addressOptions = [];
    if (this.addresses) {
      for (const address of this.addresses) {
        const addressModel = new Address(address);

        if (addressModel && addressModel.getDisplayName()) {
          this.addressOptions.push({ label: addressModel.getDisplayName(), value: address.id });
        }
      }
    }
  }
}
