<div id="work-assignment">
  <div class="header d-flex border-bottom pb-3 pt-3 justify-content-between pb-3">
    <h2 id="inventory-overview-title">Werkopdracht</h2>

    <cap-button label="Printen" id="inventory-overview-print-button" styleClass="primary" (onClick)="printOverview()">
    </cap-button>
  </div>

  <div class="ibox-content border-bottom pt-3 pb-3">
    <div class="row">
      <div class="col-sm-12">
        <h5>KLANT</h5>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-3">Klantnaam</label>
      <div class="col-sm-9">{{ project?.client?.name ? project?.client?.name : 'n.n.b.' }}</div>
    </div>

    <div class="row">
      <label class="col-sm-3">Referentie</label>
      <div class="col-sm-9">{{ project?.reference_nr ? project.reference_nr : 'n.n.b.' }}</div>
    </div>
  </div>

  <div class="ibox-content" *ngIf="!readyToView">
    <div class="row">
      <div class="col-sm-12">
        <h5>Werkopdracht aan het laden..</h5>
      </div>
    </div>
  </div>

  <div class="ibox-content" *ngIf="readyToView">
    <div class="row pt-3">
      <div class="col-sm-12">
        <h5>DATUM EN TIJD</h5>
      </div>
    </div>
    <div class="row border-bottom pb-3">
      <div class="col-sm-12 col-lg-4">
        <div class="form-group">
          <cap-calendar
            dateFormat="dd-mm-yy"
            label="Datum"
            [disabled]="disabled"
            [placeholder]="
              workAssignment.assignmentDate ? (workAssignment.assignmentDate | date : 'dd-MM-yyyy') : 'Selecteer datum'
            "
            [(ngModel)]="workAssignment.assignmentDate"
            [locale]="localeNL">
          </cap-calendar>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="form-group">
          <cap-dropdown
            [sortAlphabetically]="false"
            [options]="times"
            label="Vertrek uit standplaats"
            placeholder="Tijdstip"
            [disabled]="disabled"
            [readonly]="disabled"
            [showClear]="true"
            [(ngModel)]="workAssignment.departure_time">
          </cap-dropdown>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="form-group">
          <cap-dropdown
            [sortAlphabetically]="false"
            [options]="times"
            placeholder="Tijdstip"
            label="Terug thuis standplaats"
            [disabled]="disabled"
            [readonly]="disabled"
            [showClear]="true"
            [(ngModel)]="workAssignment.arrival_time">
          </cap-dropdown>
        </div>
      </div>
    </div>

    <div class="row pb-3 pt-3">
      <div class="col-sm-4">
        <h5>LAADADRES</h5>
      </div>

      <div class="col-sm-4">
        <h5>LOSADRES</h5>
      </div>
    </div>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-4">
        <ng-container *ngFor="let address of workAssignment.address_work_assignments">
          <ng-container
            *ngIf="address.type_base_data_value_id === addressWorkAssignmentTypes[addressWorkAssignmentType.pickup]"
            class="row-address">
            <app-workassignment-address-detail
              (onDeleteAddressClick)="onDeleteAddressClick($event)"
              [address]="address"
              [addresses]="addressOptionsPickup"
              [disabled]="disabled">
            </app-workassignment-address-detail>
          </ng-container>
        </ng-container>

        <div class="row">
          <div class="col-sm-12">
            <cap-button
              [label]="'button.add' | translate"
              icon="fa fa-plus"
              styleClass="primary"
              *ngIf="!disabled"
              (onClick)="onAddAddressClick('pickup')">
            </cap-button>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div *ngFor="let address of workAssignment.address_work_assignments">
          <div
            *ngIf="address.type_base_data_value_id === addressWorkAssignmentTypes[addressWorkAssignmentType.delivery]"
            class="row-address">
            <app-workassignment-address-detail
              (onDeleteAddressClick)="onDeleteAddressClick($event)"
              [address]="address"
              [addresses]="addressOptionsDelivery"
              [disabled]="disabled">
            </app-workassignment-address-detail>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <cap-button
              [label]="'button.add' | translate"
              icon="fa fa-plus"
              styleClass="primary"
              *ngIf="!disabled"
              (onClick)="onAddAddressClick('delivery')">
            </cap-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-3 pt-3">
      <div class="col-sm-4">
        <h5>PERSONEN</h5>
      </div>

      <div class="col-sm-4">
        <h5>BEGINTIJD LOCATIE</h5>
      </div>

      <div class="col-sm-4">
        <h5>EINDTIJD LOCATIE</h5>
      </div>
    </div>

    <ng-container *ngFor="let item of workAssignment.items">
      <ng-container *ngIf="item.type_base_data_value_id === workAssignmentItemTypes[workAssignmentItemType.person]">
        <app-inventory-work-assignment-item
          [placeholderName]="'Naam'"
          [item]="item"
          [disabled]="disabled"
          (onDeleteItemClick)="onDeleteItemClick(item)">
        </app-inventory-work-assignment-item>
      </ng-container>
    </ng-container>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-12">
        <cap-button
          [label]="'button.add' | translate"
          id="inventory-overview-print-button"
          styleClass="primary"
          icon="fa fa-plus"
          (onClick)="onAddPersonClick()">
        </cap-button>
      </div>
    </div>

    <div class="row pb-3 pt-3">
      <div class="col-sm-4">
        <h5>AUTO</h5>
      </div>
      <div class="col-sm-4">
        <h5>BEGINTIJD LOCATIE</h5>
      </div>
      <div class="col-sm-4">
        <h5>EINDTIJD LOCATIE</h5>
      </div>
    </div>

    <ng-container *ngFor="let item of workAssignment.items">
      <ng-container *ngIf="item.type_base_data_value_id === workAssignmentItemTypes[workAssignmentItemType.car]">
        <ng-container *ngIf="hasMachines(); else appInventoryWorkAssignmentItem">
          <app-inventory-work-assignment-dropdown-item
            [item]="item"
            [machines]="machines"
            [disabled]="disabled"
            (onDeleteItemClick)="onDeleteItemClick(item)">
          </app-inventory-work-assignment-dropdown-item>
        </ng-container>

        <ng-template #appInventoryWorkAssignmentItem>
          <app-inventory-work-assignment-item
            [placeholderName]="'Nummer'"
            [item]="item"
            [disabled]="disabled"
            (onDeleteItemClick)="onDeleteItemClick(item)">
          </app-inventory-work-assignment-item>
        </ng-template>
      </ng-container>
    </ng-container>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-12">
        <cap-button
          styleClass="primary"
          *ngIf="!disabled"
          [label]="'button.add' | translate"
          icon="fa fa-plus"
          (onClick)="onAddCarClick()">
        </cap-button>
      </div>
    </div>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-4">
        <h5>OMSCHRIJVING WERKZAAMHEDEN</h5>
      </div>

      <div class="col-sm-8">
        <cap-textarea
          id="description_own"
          placeholder="Beschrijving"
          [rows]="4"
          [cols]="50"
          [disabled]="disabled"
          [readOnly]="disabled"
          autoResize="true"
          [(ngModel)]="workAssignment.description_own">
        </cap-textarea>
      </div>
    </div>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-4">
        <h5>BIJZONDERHEDEN/SCHADEOPMERKINGEN</h5>
      </div>

      <div class="col-sm-8">
        <cap-textarea
          id="description_client"
          placeholder="Beschrijving"
          [rows]="4"
          [cols]="50"
          [disabled]="disabled"
          [readOnly]="disabled"
          autoResize="true"
          [(ngModel)]="workAssignment.description_client">
        </cap-textarea>
      </div>
    </div>

    <div class="row border-bottom pb-3 pt-3">
      <div class="col-sm-12 d-flex flex-column">
        <h5>AKKOORD</h5>

        <p class="work-assignment-accept line-height-2">
          Als klant tekent u voor goede ontvangst van de inventaris en volgens afspraak uitgevoerde werkzaamheden.
        </p>

        <p class="work-assignment-accept line-height-2">
          Bij eventueel onstane schade verzoeken wij u om ons schriftelijk aansprakelijk te stellen, waarna de schade in
          behandeling kan worden genomen e.e.a volgens de algemene voorwaarden die van toepassing zijn.
        </p>

        <br />

        <cap-checkbox
          [(ngModel)]="workAssignment.accept_all_user_damage_reported"
          class="pull-left mb-1"
          label="Controle ronde uitgevoerd
                    i.v.m. vooraf bekende schade?"
          name="check1"
          [disabled]="disabled"
          binary="true">
        </cap-checkbox>

        <cap-checkbox
          [(ngModel)]="workAssignment.accept_check_pickup_and_deliver_address_executed"
          class="pull-left mb-1"
          label="Check laadadres / Check losadres uitgevoerd"
          name="check2"
          [disabled]="disabled"
          binary="true">
        </cap-checkbox>

        <cap-checkbox
          [(ngModel)]="workAssignment.customer_satisfied_with_service"
          class="pull-left mb-1"
          label="U bent tevreden over het uitgevoerde werk?"
          name="check3"
          [disabled]="disabled"
          binary="true">
        </cap-checkbox>

        <cap-checkbox
          [(ngModel)]="workAssignment.customer_fill_in_survey"
          class="pull-left mb-1"
          label="Wilt u onze enquête invullen?"
          name="check4"
          [disabled]="disabled"
          binary="true">
        </cap-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h5>ONDERTEKENING</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <p class="mb-2">Opdrachtgever</p>

        <cap-button
          styleClass="primary"
          *ngIf="!disabled"
          label="Handtekening invoeren"
          (onClick)="showClientSignatureForm()">
        </cap-button>

        <app-signature #clientSignature [(signature)]="workAssignment.signature_client_image"> </app-signature>

        <div *ngIf="workAssignment.signature_client_image">
          <img [src]="workAssignment.signature_client_image" class="signature" />
        </div>
      </div>

      <div class="col-sm-4">
        <p class="mb-2">Teamleader</p>

        <cap-button
          styleClass="primary"
          *ngIf="!disabled"
          label="Handtekening invoeren"
          (onClick)="showTeamleaderSignatureForm()">
        </cap-button>

        <app-signature #teamleaderSignature [(signature)]="workAssignment.signature_teamleader_image"> </app-signature>

        <div *ngIf="workAssignment.signature_teamleader_image">
          <img [src]="workAssignment.signature_teamleader_image" class="signature" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 signature-column" *ngFor="let signature of signatures; let index = index">
        <cap-input
          [label]="'Omschrijving'"
          [id]="'signature-' + index"
          type="text"
          [(ngModel)]="signature.description"></cap-input>

        <div class="d-flex">
          <cap-button
            styleClass="primary"
            class="flex-grow-1 mr-1"
            *ngIf="!disabled"
            label="Handtekening invoeren"
            (onClick)="showSignatureForm(signatureComponent)">
          </cap-button>

          <cap-button styleClass="error" *ngIf="!disabled" icon="fa fa-times" (onClick)="removeSignature(signature.id)">
          </cap-button>
        </div>

        <app-signature #signatureComponent [(signature)]="signature.signature_image"> </app-signature>

        <div *ngIf="signature.signature_image">
          <img [src]="signature.signature_image" class="signature" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 signature-column">
        <cap-button
          styleClass="primary"
          icon="fa fa-plus"
          label="Handtekening veld toevoegen"
          *ngIf="!disabled"
          (click)="addSignature()">
        </cap-button>
      </div>
    </div>
  </div>
</div>
